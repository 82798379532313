/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/klaviyo
 * @author  Jort Geurts <info@scandiweb.com>
 */
import { appendWithStoreCode } from 'Util/Url';

const componentDidUpdate = (args, callback, instance) => {
    // eslint-disable-next-line fp/no-let, prefer-const, no-use-before-define
    let _learnq = window._learnq || [];

    // TODO: Disable if klaviyo is disabled

    const { product } = instance.props;

    if (Object.keys(product).length === 0) {
        return callback(...args);
    }

    const { hasPushedToKlaviyo } = instance.state;

    if (hasPushedToKlaviyo) {
        return callback(...args);
    }

    const {
        product: {
            name,
            id,
            sku,
            categories, // TODO: Morph into string array
            image: {
                url: imageUrl
            },
            url,
            price_range: {
                minimum_price: {
                    final_price: {
                        value: finalMinimumPrice
                    }
                }
            }
        }
    } = instance.props;

    const item = {
        ProductName: name,
        ProductID: id,
        SKU: sku,
        Categories: categories,
        ImageURL: imageUrl,
        URL: appendWithStoreCode(url),
        Brand: '',
        Price: finalMinimumPrice,
        CompareAtPrice: 0
    };

    _learnq.push(['track', 'Viewed Product', item]);

    instance.setState({ hasPushedToKlaviyo: true });

    return callback(...args);
};

export default {
    'Route/ProductPage/Container': {
        'member-function': {
            componentDidUpdate
        }
    }
};
