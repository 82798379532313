import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ProductPrice from 'Component/ProductPrice/ProductPrice.component';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductPriceContainer as PrinterbaseProductPriceContainer
} from 'SourceComponent/ProductPrice/ProductPrice.container';
import { ProductType } from 'Type/ProductList';
import { formatPrice } from 'Util/Price';
import { replacePlaceholders } from 'Util/Price/Price';

/** @namespace Scandipwa/Component/ProductPrice/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace Scandipwa/Component/ProductPrice/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
});

/** @namespace Scandipwa/Component/ProductPrice/Container */
export class ProductPriceContainer extends PrinterbaseProductPriceContainer {
    static propTypes = {
        ...PrinterbaseProductPriceContainer.propTypes,
        pageType: PropTypes.string.isRequired,
        product: ProductType.isRequired,
        renderOnlyOldPrice: PropTypes.bool,
        hideMobileOldPrice: PropTypes.bool,
        isMobile: PropTypes.bool.isRequired
    };

    static defaultProps = {
        ...this.defaultProps,
        renderOnlyOldPrice: false,
        hideMobileOldPrice: false
    };

    containerFunctions = {
        getOldPriceHtml: this.getOldPriceHtml.bind(this)
    };

    containerProps() {
        const {
            isMobile, pageType, renderOnlyOldPrice, hideMobileOldPrice
        } = this.props;

        return {
            ...super.containerProps(),
            isMobile,
            pageType,
            renderOnlyOldPrice,
            hideMobileOldPrice
        };
    }

    getOldPriceHtml() {
        const { product, price: productPrice } = this.props;

        if (!productPrice || !product) {
            return null;
        }

        const {
            attributes: {
                special_price_text: { attribute_value: specialPriceText } = {},
                special_price_discount_mode: { attribute_value: specialPriceMode } = {}
            } = {}
        } = product;

        const {
            price: {
                originalPrice: {
                    value: originalPriceValue,
                    valueFormatted: originalPriceFormatted,
                    currency
                } = {},
                finalPrice: {
                    value: specialPrice
                },
                discount: { percentOff } = {}
            } = {}
        } = productPrice;

        if (percentOff === 0 || originalPriceValue === 0) {
            return null;
        }

        const discount = specialPriceMode === '1'
            ? formatPrice(Math.round(originalPriceValue - specialPrice), currency)
            : `${Math.round(percentOff)}%`;

        const result = specialPriceText
            ? replacePlaceholders(
                specialPriceText,
                { discount, oldPrice: originalPriceFormatted }
            )
            : originalPriceFormatted;

        return {
            isHtml: !!specialPriceText,
            text: result
        };
    }

    render() {
        return (
            <ProductPrice
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPriceContainer);
