export * from 'SourceComponent/AddToCart/AddToCart.config';

export const PRODUCT_TYPES = [
    'Printers',
    'Dot Matrix',
    'Fax Machine',
    'Label Printer',
    'Large Format Printer',
    'Mobile Printer',
    'Multifunction',
    'Photo Printer'
];
