import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
    TierPricesContainer as PrinterbaseProductListContainer
} from 'PrinterbaseComponent/TierPrices/TierPrices.container';

/** @namespace Scandipwa/Component/TierPrices/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace Scandipwa/Component/TierPrices/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Scandipwa/Component/TierPrices/Container */
export class TierPricesContainer extends PrinterbaseProductListContainer {
    static propTypes = {
        ...this.propTypes,
        isMobile: PropTypes.bool.isRequired
    };

    containerProps() {
        const { isMobile } = this.props;

        return {
            ...super.containerProps(),
            isMobile
        };
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TierPricesContainer));
