// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Link from 'Component/Link';
import { CategoryTreeType } from 'Type/Category';

import './LookingForTop5Button.style';

/** @namespace Scandipwa/Component/LookingForTop5Button/Component */
export class LookingForTop5ButtonComponent extends PureComponent {
    static propTypes = {
        category: CategoryTreeType.isRequired
    };

    render() {
        const {
            category: {
                breadcrumbs,
                name
            } = {}
        } = this.props;

        if (breadcrumbs.length > 1) {
            const url = breadcrumbs[1].category_url;
            const removeTitle = __('Our Top 5 ');
            const title = __('View All ') + name.replace(removeTitle, '');

            return (
                <Link block="LookingForTop5Button" to={ url }>
                    { title }
                </Link>
            );
        }

        return (
            <div block="LookingForTop5Button">
                View all laser printers
            </div>
        );
    }
}

export default LookingForTop5ButtonComponent;
