import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CategorySubcategories from 'Component/CategorySubcategories';

import { BLOCK_ATTR } from './Subcategories.config';

import './Subcategories.style';

/** @namespace Scandipwa/Component/Subcategories/Component */
export class SubcategoriesComponent extends PureComponent {
    static propTypes = {
        subcategories: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                product_count: PropTypes.number,
                url: PropTypes.string
            })
        ),
        onDropDownClick: PropTypes.func.isRequired,
        isMobile: PropTypes.bool.isRequired,
        isDropdownVisible: PropTypes.bool.isRequired
    };

    static defaultProps = {
        subcategories: []
    };

    renderCategories() {
        const {
            subcategories
        } = this.props;

        return subcategories.map((category) => {
            const {
                id,
                url,
                name,
                image,
                description,
                display_mode,
                product_count
            } = category;

            if (product_count === 0) {
                return null;
            }

            return (
                <CategorySubcategories
                  option={ {
                      id,
                      value_string: url,
                      label: name,
                      productCount: product_count,
                      categoryData: {
                          image,
                          description,
                          display_mode
                      }
                  } }
                />
            );
        });
    }

    renderCategoriesMobile() {
        const {
            onDropDownClick,
            isDropdownVisible
        } = this.props;

        if (!isDropdownVisible) {
            return (
                <button
                  block={ BLOCK_ATTR }
                  elem="CategoryDropdown"
                  onClick={ onDropDownClick }
                >
                    <div block={ BLOCK_ATTR } elem="CategoryDropdownTitle">{ __('Category') }</div>
                    <div block={ BLOCK_ATTR } elem="CategoryDropdownSign">+</div>
                </button>
            );
        }

        return (
            <div block={ BLOCK_ATTR }>
                <button
                  block={ BLOCK_ATTR }
                  elem="CategoryDropdown"
                  onClick={ onDropDownClick }
                >
                    <div block={ BLOCK_ATTR } elem="CategoryDropdownTitle">{ __('Category') }</div>
                    <div block={ BLOCK_ATTR } elem="CategoryDropdownSign">-</div>
                </button>
                { this.renderCategories() }
            </div>
        );
    }

    renderCategoriesDesktop() {
        return (
            <div block={ BLOCK_ATTR }>
                <span block={ BLOCK_ATTR } elem="Title">{ __('Category') }</span>
                <div block={ BLOCK_ATTR } elem="Categories">
                    { this.renderCategories() }
                </div>
            </div>
        );
    }

    render() {
        const { isMobile } = this.props;

        if (isMobile) {
            return this.renderCategoriesMobile();
        }

        return this.renderCategoriesDesktop();
    }
}

export default SubcategoriesComponent;
