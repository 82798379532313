/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import BestSellingToggleButtons from 'Component/BestSellingToggleButtons/BestSellingToggleButtons.component';

import { BEST_SELLING_PRINTERS } from './BestSellingToggleButtons.config';

/** @namespace Scandipwa/Component/BestSellingToggleButtons/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace Scandipwa/Component/BestSellingToggleButtons/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Scandipwa/Component/BestSellingToggleButtons/Container */
export class BestSellingToggleButtonsContainer extends PureComponent {
    state = {
        listDisplayed: BEST_SELLING_PRINTERS
    };

    static propTypes = {
        isMobile: PropTypes.bool.isRequired,
        printersTitle: PropTypes.string.isRequired,
        otherTitle: PropTypes.string.isRequired
    };

    containerFunction = {
        setDisplayed: this.setDisplayed.bind(this)
    };

    componentDidUpdate() {
        const { listDisplayed } = this.state;

        document.documentElement.style.setProperty('--best-selling-printers-display', 'none');
        document.documentElement.style.setProperty('--best-selling-others-display', 'none');

        document.documentElement.style.setProperty(`--best-selling-${listDisplayed}-display`, 'block');
    }

    setDisplayed(event) {
        const { target: { value } } = event;

        this.setState({ listDisplayed: value });
    }

    containerProps() {
        const { isMobile, printersTitle, otherTitle } = this.props;
        const { listDisplayed } = this.state;

        return {
            isMobile,
            listDisplayed,
            printersTitle,
            otherTitle
        };
    }

    render() {
        return (
            <BestSellingToggleButtons
              { ...this.containerProps() }
              { ...this.containerFunction }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BestSellingToggleButtonsContainer);
