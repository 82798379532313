import KlevuSearchReducer from '../store/KlevuSearch.reducer';

export default {
    'Store/Index/getStaticReducers': {
        function: (args, callback) => ({
            ...callback(...args),
            KlevuSearchReducer
        })
    }
};
