/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Image from 'Component/Image';

import './Top5quote.style';

/** @namespace Scandipwa/Component/Top5quote/Component */
export class Top5quoteComponent extends PureComponent {
    static propTypes = {
        quote: PropTypes.string
    };

    render() {
        const {
            quote
        } = this.props;
        const src = '/media/images/Vector.png';

        return (
            <div block="Top5quote">
                <div block="Top5quote" elem="Image">
                    <Image src={ src } />
                </div>
                { ' ' }
                { quote }
            </div>
        );
    }
}

export default Top5quoteComponent;
