/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { KLEVU } from '../component/KlevuSearch/KlevuSearch.config';
import { klevuConfigType } from '../types/KlevuSearch';

/** @namespace KlevuSearch/Plugin/SearchFieldContainer/Plugin/__construct */
export const __construct = (args, callback, instance) => {
    instance.initKlevuRedirects = initKlevuRedirects.bind(instance);
};

/** @namespace KlevuSearch/Plugin/SearchFieldContainer/Plugin/componentDidMount */
export const componentDidMount = (args, callback, instance) => {
    const { isKlevuInitialized = false } = instance.state;
    const { klevuConfig: { klevu_api_key } = {} } = instance.props;

    if (!isKlevuInitialized && klevu_api_key) {
        instance.setState({ isKlevuInitialized: true });

        window.addEventListener('initialize_klevu', instance.initKlevuRedirects);
    }
};

/** @namespace KlevuSearch/Plugin/SearchFieldContainer/Plugin/initKlevuRedirects */
export const initKlevuRedirects = () => {
    const { klevuConfig: { klevu_api_key } = {} } = instance.props;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://js.klevu.com/klevu-js-v1/klevu-js-api/${klevu_api_key}-maps.js`;

    const target = document.getElementsByTagName('script')[0];
    target.parentNode.insertBefore(script, target);
};

/** @namespace KlevuSearch/Plugin/SearchFieldContainer/Plugin/propTypes */
export const propTypes = (originalMember) => ({
    ...originalMember,
    klevuConfig: klevuConfigType
});

/** @namespace KlevuSearch/Plugin/SearchFieldContainer/Plugin/defaultProps */
export const defaultProps = (originalMember) => ({
    ...originalMember,
    klevuConfig: {
        klevu_api_key: null
    },
    isSearchForSupplies: false
});

/** @namespace KlevuSearch/Plugin/SearchFieldContainer/Plugin/mapStateToProps */
export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        klevuConfig: state.ConfigReducer.klevu
    };
};

export default {
    'Component/SearchField/Container': {
        'member-function': {
            __construct,
            componentDidMount
        },
        'static-member': {
            propTypes,
            defaultProps
        }
    },
    'Component/SearchField/Container/mapStateToProps': {
        function: mapStateToProps
    }
};
