/**
 * @category  Printerbase
 * @author    Scandiweb <info@scandiweb.com>
 * @namespace Scandipwa/Component/CheckoutAddressForm/CheckoutAddressFormInstance
 */

import CheckoutAddressFormInstance from 'Component/CheckoutAddressForm/CheckoutAddressFormInstance';

const onAddressSelectPlugin = (args, callback, instance) => {
    callback(...args);

    const { isBilling } = instance.props;

    const {
        id: addressId
    } = args[0];

    CheckoutAddressFormInstance.setSelectedAddressId(addressId, isBilling);
};

const onAddressSelectPluginAfterConstruct = (args, callback, instance) => {
    callback(...args);

    const { isBilling } = instance.props;

    const addressId = instance?.state?.selectedAddressId;

    CheckoutAddressFormInstance.setSelectedAddressId(addressId, isBilling);
};

export default {
    'Component/CheckoutAddressBook/Container': {
        'member-function': {
            onAddressSelect: onAddressSelectPlugin,
            __construct: onAddressSelectPluginAfterConstruct
        }
    }
};
