/* eslint-disable max-lines */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { toggleOverlayByKey } from 'Store/Overlay/Overlay.action';
import { debounce } from 'Util/Request';

import { klevuConfigType } from '../../types/KlevuSearch';
import {
    fetchCategoriesInOrder,
    formatKlevuCats,
    getSuppliersSearchApiKey,
    getSuppliersSearchURL
} from '../../util/functions';
import KlevuSearch from './KlevuSearch.component';
import {
    getCategoriesRequestBody,
    getRequestBody,
    getSuppliersRequestBody,
    KLEVU_SEARCH_FREQUENCY,
    KLEVU_SEARCH_PRODUCTS_ID,
    KLEVU_SEARCH_SUPPLIES_API,
    KLEVU_SEARCH_SUPPLIES_URL
} from './KlevuSearch.config';

/** @namespace KlevuSearch/Component/KlevuSearch/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showOverlay: (overlayKey) => dispatch(toggleOverlayByKey(overlayKey))
});

/** @namespace KlevuSearch/Component/KlevuSearch/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
    klevuConfig: state.ConfigReducer.klevu,
    base_link_url: state.ConfigReducer.base_link_url,
    secure_base_link_url: state.ConfigReducer.secure_base_link_url
});

/** @namespace KlevuSearch/Component/KlevuSearch/Container */
export class KlevuSearchContainer extends PureComponent {
    static propTypes = {
        klevuConfig: klevuConfigType,
        searchCriteria: PropTypes.string,
        base_link_url: PropTypes.string,
        secure_base_link_url: PropTypes.string,
        showOverlay: PropTypes.func.isRequired,
        device: PropTypes.shape({ isMobile: PropTypes.bool }).isRequired,
        isSuppliesSearch: PropTypes.bool
    };

    static defaultProps = {
        searchCriteria: null,
        base_link_url: '',
        secure_base_link_url: '',
        klevuConfig: {},
        isSuppliesSearch: false
    };

    state = {
        popularSearch: [],
        isLoading: false,
        result: null
    };

    fetchKlevuSearch = debounce(() => {
        const {
            searchCriteria,
            klevuConfig: {
                klevu_api_key,
                klevu_display_cat,
                klevu_limit,
                klevu_search_url_v2
            } = {}
        } = this.props;

        if (!klevu_api_key) {
            return;
        }

        if (parseInt(klevu_display_cat, 10) !== 1) {
            fetch(`https://${klevu_search_url_v2}/cs/v2/search`, {
                method: 'POST',
                body: JSON.stringify(getCategoriesRequestBody(
                    klevu_api_key, searchCriteria,
                    {
                        limit: klevu_limit
                    }
                ))
            }).then(
                /** @namespace KlevuSearch/Component/KlevuSearch/Container/KlevuSearchContainer/debounce/then/catch/then/then/then/then/fetch/then */
                (res) => res.json()
            ).then(
                /** @namespace KlevuSearch/Component/KlevuSearch/Container/KlevuSearchContainer/debounce/then/catch/then/then/then/then/formatKlevuCats */
                (res) => formatKlevuCats(res)
            ).then(
                /** @namespace KlevuSearch/Component/KlevuSearch/Container/KlevuSearchContainer/debounce/then/catch/then/then */
                ({ categories, suggestions }) => fetchCategoriesInOrder(categories).then(
                    /** @namespace KlevuSearch/Component/KlevuSearch/Container/KlevuSearchContainer/debounce/then/catch/then/then/fetchCategoriesInOrder/then */
                    (res) => {
                        const items = res.filter(
                            ({ status }) => status === 'fulfilled'
                        ).map(
                            ({ value: { categories: { items } } }) => items[0]
                        );

                        this.setState({ result: { categories: items, suggestions } });
                        this.setIsLoadingStatus(false);
                    }
                )
            )
                .catch(
                    /** @namespace KlevuSearch/Component/KlevuSearch/Container/KlevuSearchContainer/debounce/then/catch */
                    () => {
                        this.setState({ result: null });
                        this.setIsLoadingStatus(false);
                    }
                );

            return;
        }

        fetch(`https://${klevu_search_url_v2}/cs/v2/search`, {
            method: 'POST',
            body: JSON.stringify(getRequestBody(
                klevu_api_key, searchCriteria,
                {
                    limit: klevu_limit,
                    categories: klevu_display_cat
                }
            ))
        })
            .then(
                /** @namespace KlevuSearch/Component/KlevuSearch/Container/KlevuSearchContainer/debounce/then/catch/then/then/fetch/then */
                (response) => response.json()
            )
            .then(
                /** @namespace KlevuSearch/Component/KlevuSearch/Container/KlevuSearchContainer/debounce/then/catch/then/then */
                (result) => {
                    this.setState({ result });
                    this.setIsLoadingStatus(false);
                }
            )
            .catch(
                /** @namespace KlevuSearch/Component/KlevuSearch/Container/KlevuSearchContainer/debounce/then/catch */
                () => {
                    this.setState({ result: null });
                    this.setIsLoadingStatus(false);
                }
            );
        // eslint-disable-next-line no-magic-numbers
    }, KLEVU_SEARCH_FREQUENCY);

    fetchSuppliersSearch = debounce(() => {
        const {
            searchCriteria,
            klevuConfig: {
                klevu_limit
            } = {}
        } = this.props;

        fetch(`https://${ KLEVU_SEARCH_SUPPLIES_URL}/cs/v2/search`, {
            method: 'POST',
            body: JSON.stringify(getSuppliersRequestBody(
                KLEVU_SEARCH_SUPPLIES_API, searchCriteria,
                {
                    limit: klevu_limit
                }
            ))
        })
            .then(
                /** @namespace KlevuSearch/Component/KlevuSearch/Container/KlevuSearchContainer/debounce/then/catch/then/then/fetch/then */
                (response) => response.json()
            )
            .then(
                /** @namespace KlevuSearch/Component/KlevuSearch/Container/KlevuSearchContainer/debounce/then/catch/then/then */
                (result) => {
                    this.setState({ result });
                    this.setIsLoadingStatus(false);
                }
            )
            .catch(
                /** @namespace KlevuSearch/Component/KlevuSearch/Container/KlevuSearchContainer/debounce/then/catch */
                () => {
                    this.setState({ result: null });
                    this.setIsLoadingStatus(false);
                }
            );
    }, KLEVU_SEARCH_FREQUENCY);

    componentDidMount() {
        const {
            klevuConfig: {
                klevu_api_key
            } = {}
        } = this.props;

        if (!klevu_api_key) {
            return;
        }

        fetch(`https://js.klevu.com/klevu-js-v1/klevu-js-api/${klevu_api_key}.js`)
            .then(
                /** @namespace KlevuSearch/Component/KlevuSearch/Container/KlevuSearchContainer/componentDidMount/then/then/fetch/then */
                (r) => r.text()
            )
            .then(
                /** @namespace KlevuSearch/Component/KlevuSearch/Container/KlevuSearchContainer/componentDidMount/then/then */
                (text) => {
                    if (text.search(/klevu_webstorePopularTerms/g) > 0) {
                        const popularSearchJson = text.split('klevu_webstorePopularTerms=').pop();
                        const popularSearchSearchArray = JSON.parse(popularSearchJson.replace(';', '').replace(/'/g, '"'));
                        this.setState({ popularSearch: popularSearchSearchArray });
                    }
                }
            );
    }

    componentDidUpdate(prevProps, prevState) {
        const { searchCriteria, isSuppliesSearch = false } = this.props;
        const { searchCriteria: prevSearchCriteria } = prevProps;

        if (searchCriteria.length <= 2) {
            return;
        }

        if (searchCriteria !== prevSearchCriteria) {
            this.setIsLoadingStatus(true);

            if (isSuppliesSearch) {
                this.fetchSuppliersSearch();

                return;
            }

            this.fetchKlevuSearch();
        }

        const prevKlevuResultCount = this.getCountFromKlevuResponse(prevState);
        const curKlevuResultCount = this.getCountFromKlevuResponse(this.state);

        if (prevKlevuResultCount !== curKlevuResultCount && curKlevuResultCount) {
            this.triggerKlevuAnalytics(searchCriteria, curKlevuResultCount);
        }
    }

    getCountFromKlevuResponse({ result }) {
        if (!result) {
            return 0;
        }

        return result.queryResults?.find(({ id }) => id === KLEVU_SEARCH_PRODUCTS_ID)?.meta?.totalResultsFound;
    }

    triggerKlevuAnalytics(searchTerm, count) {
        const searchTermURL = searchTerm.replace('+', '%20');
        const analyticsReportUrl = 'https://stats.ksearchnet.com/analytics/n-search/search'
        + `?klevu_apiKey=${ KLEVU_SEARCH_SUPPLIES_API }`
        + `&klevu_term=${ searchTermURL }`
        + `&klevu_totalResults=${ count }`
        + '&klevu_typeOfQuery=WILDCARD_AND';

        fetch(analyticsReportUrl);
    }

    setIsLoadingStatus(status) {
        this.setState({ isLoading: status });
    }

    getIsEmptyResponse() {
        const { result } = this.state;

        if (!result) {
            return true;
        }

        const { queryResults = [] } = result;

        const products = queryResults.find(({ id }) => id === KLEVU_SEARCH_PRODUCTS_ID);
        const { records: items = [] } = products || [];

        if (!items.length) {
            return true;
        }

        return false;
    }

    containerProps() {
        const {
            base_link_url,
            secure_base_link_url,
            searchCriteria,
            device,
            isSuppliesSearch
        } = this.props;

        const {
            klevuConfig = {}
        } = this.props;

        const klevu_display_cat = klevuConfig?.klevu_display_cat;
        const klevu_display_suggestions = klevuConfig?.klevu_display_suggestions;
        const klevu_display_cms = klevuConfig?.klevu_display_cms;

        const {
            popularSearch,
            isLoading,
            result
        } = this.state;

        return {
            base_link_url,
            secure_base_link_url,
            searchCriteria,
            klevuSearchResult: result,
            isSuggestions: Boolean(Number(klevu_display_suggestions)),
            isCategories: Boolean(Number(klevu_display_cat)),
            isCMS: Boolean(Number(klevu_display_cms)),
            popularSearch,
            isLoading,
            isEmptyResponse: this.getIsEmptyResponse(),
            device,
            isSuppliesSearch
        };
    }

    render() {
        return (
            <KlevuSearch
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KlevuSearchContainer);
