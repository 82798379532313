export const containerProps = (args, callback, instance) => {
    const { isAmazonPay } = instance.props;

    const instanceContainerProps = callback(...args);

    return {
        ...instanceContainerProps,
        isAmazonPay
    };
};

const config = {
    'Component/MyAccountAddressTable/Container': {
        'member-function': {
            containerProps
        }
    }
};

export default config;
