export const AMAZON_PAY_STATE = 'AMAZON_PAY_STATE';

export const AMAZON_PAY_CONFIG = 'AMAZON_PAY_CONFIG';
export const AMAZON_PAY_CHECKOUT_SESSION_CONFIG = 'AMAZON_PAY_CHECKOUT_SESSION_CONFIG';

export const AMAZON_PAY_CHECKOUT_SESSION = 'AMAZON_PAY_CHECKOUT_SESSION';

export const AMAZON_PAY_RESET_STATE = 'AMAZON_PAY_RESET_STATE';

export const AMAZON_PAY_FORM_CHECKOUT_SESSION = 'AMAZON_PAY_FORM_CHECKOUT_SESSION';

export const AMAZON_PAY_SHIPPING_METHODS = 'AMAZON_PAY_SHIPPING_METHODS';

export const AMAZON_PAY_SHIPPING_CARRIER = 'AMAZON_PAY_SHIPPING_CARRIER';

/** @namespace Amazonpay/Store/AmazonPay/Action/setState */
export const setState = (state) => ({
    type: AMAZON_PAY_STATE,
    state
});

/** @namespace Amazonpay/Store/AmazonPay/Action/setIsAmazonProcessing */
export const setIsAmazonProcessing = (isPaymentProcessing) => setState({ isPaymentProcessing });

/** @namespace Amazonpay/Store/AmazonPay/Action/setIsAmazonLoadingAddress */
export const setIsAmazonLoadingAddress = (isLoadingAddress) => setState({ isLoadingAddress });

/** @namespace Amazonpay/Store/AmazonPay/Action/setIsAmazonLoadingShipments */
export const setIsAmazonLoadingShipments = (isLoadingShipments) => setState({ isLoadingShipments });

/** @namespace Amazonpay/Store/AmazonPay/Action/updateAmazonPayConfig */
export const updateAmazonPayConfig = (config) => ({
    type: AMAZON_PAY_CONFIG,
    config
});

/** @namespace Amazonpay/Store/AmazonPay/Action/updateAmazonPayCheckoutSessionConfig */
export const updateAmazonPayCheckoutSessionConfig = (checkoutSessionConfig) => ({
    type: AMAZON_PAY_CHECKOUT_SESSION_CONFIG,
    checkoutSessionConfig
});

/** @namespace Amazonpay/Store/AmazonPay/Action/updateAmazonCheckoutSession */
export const updateAmazonCheckoutSession = (checkoutSession) => ({
    type: AMAZON_PAY_CHECKOUT_SESSION,
    checkoutSession
});

/** @namespace Amazonpay/Store/AmazonPay/Action/setInitialState */
export const setInitialState = () => ({
    type: AMAZON_PAY_RESET_STATE
});

/** @namespace Amazonpay/Store/AmazonPay/Action/setLoading */
export const setLoading = (isLoading) => setState({ isLoading });

/** @namespace Amazonpay/Store/AmazonPay/Action/updateAmazonFormCheckoutSession */
export const updateAmazonFormCheckoutSession = (formCheckoutSession) => ({
    type: AMAZON_PAY_FORM_CHECKOUT_SESSION,
    formCheckoutSession
});

/** @namespace Amazonpay/Store/AmazonPay/Action/updateShippingMethods */
export const updateShippingMethods = (shippingMethods) => ({
    type: AMAZON_PAY_SHIPPING_METHODS,
    shippingEstimate: { shippingMethods }
});

/** @namespace Amazonpay/Store/AmazonPay/Action/updateShippingCarrier */
export const updateShippingCarrier = (selectedCarrier) => ({
    type: AMAZON_PAY_SHIPPING_CARRIER,
    shippingEstimate: { selectedCarrier }
});
