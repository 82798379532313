export const AMAZON_PAY_SCRIPT_ID = 'amazon-pay';

export const AMAZON_PAY = 'amazon_payment_v2';

export const AMAZON_PAY_PRODUCT_TYPES = {
    PAY_AND_SHIP: 'PayAndShip',
    SIGN_IN: 'SignIn',
    PAY_ONLY: 'PayOnly'
};

export const AMAZON_PAY_API_PLACEMENTS = {
    HOME: 'Home',
    PRODUCT: 'Product',
    CART: 'Cart',
    CHECKOUT: 'Checkout',
    OTHER: 'Other'
};

export const AMAZON_PAY_FE_LOCATIONS = {
    CART: 'cart',
    MINICART: 'minicart',
    CHECKOUT: 'checkout'
};

export const AMAZON_PAY_STEPS = {
    AMAZON_CHECKOUT: 0,
    AMAZON_PAYNOW: 1
};

export const AMAZON_PAY_ACTION = {
    PAY_NOW: 'PAY_NOW',
    CHECKOUT: 'CHECKOUT'
};

export const MAX_INTERVAL_ATTEMPTS = 50;
export const INTERVAL_TIMEOUT = 100;
