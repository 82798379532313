import { PureComponent } from 'react';

import CmsBlock from 'Component/CmsBlock';
import ConsumablesPromoCtaButton from 'Component/ConsumablesPromoCtaButton';
import { ProductType } from 'Type/ProductList';

import { CONSUMABLES_PROMO } from './ConsumablesPromo.config';

import './ConsumablesPromo.style';

/** @namespace Scandipwa/Component/ConsumablesPromo/Component */
export class ConsumablesPromoComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired
    };

    render() {
        const { product } = this.props;

        return (
            <div block="ConsumablesPromo">
                <CmsBlock identifier={ CONSUMABLES_PROMO } />
                <ConsumablesPromoCtaButton
                  product={ product }
                >
                    { __('Shop ink, toner, tapes, labels') }
                </ConsumablesPromoCtaButton>
            </div>
        );
    }
}

export default ConsumablesPromoComponent;
