const aroundContainerProps = (args, callback, instance) => {
    if (!window.location.pathname.includes('search')) {
        return callback(...args);
    }

    const containerProps = callback(...args);

    // we don't need categories filter bacause we already have
    // KlevuSearchPageCategories component to display categoried
    const { availableFilters } = containerProps;
    const { category_id, ...restAvailableFilters } = availableFilters;

    return { ...containerProps, availableFilters: restAvailableFilters };
};

export default {
    'Component/CategoryFilterOverlay/Container': {
        'member-function': {
            containerProps: aroundContainerProps
        }
    }
};
