/* eslint-disable max-lines */
/**
 * Klevu Search compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { TYPE_CATEGORY } from 'Route/UrlRewrites/UrlRewrites.config.js';
import {
    appendPage, updateLoadStatus, updatePageLoadingStatus, updateProductListItems
} from 'Store/ProductList/ProductList.action';
import {
    updateInfoLoadStatus,
    updateProductListInfo
} from 'Store/ProductListInfo/ProductListInfo.action';

import {
    setSearchPageCategories,
    setSearchPageProductIds,
    updateTotalKlevuResults
} from '../store/KlevuSearch.actions';
import {
    getFilteredProductsFromBE,
    getKlevuFiltersFromBE,
    getKlevuResults,
    getProductsFromBE
} from '../util/functions';

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        updateProductListInfo: (products, filters) => {
            dispatch(updateProductListInfo(products, filters));
        },
        updateInfoLoadStatus: (loadStatus) => {
            dispatch(updateInfoLoadStatus(loadStatus));
        },
        setSearchPageProductIds: (ids) => {
            dispatch(setSearchPageProductIds(ids));
        },
        setSearchPageCategories: (categories) => {
            dispatch(setSearchPageCategories(categories));
        },
        updateTotalKlevuResults: (results) => dispatch(updateTotalKlevuResults(results)),
        appendPage: (items, currentPage) => dispatch(appendPage(items, currentPage)),
        updatePageLoadingStatus: () => dispatch(updatePageLoadingStatus()),
        updateLoadStatus: (loadStatus) => dispatch(updateLoadStatus(loadStatus)),
        setProducts: (items,
            currentPage,
            total_count,
            total_pages,
            args) => dispatch(updateProductListItems(items,
            currentPage,
            total_count,
            total_pages,
            args))
    };
};

const mapStateToProps = (args, callback) => {
    const [state] = args;
    const { isSuppliesSearch } = state.KlevuSearchReducer;
    const klevuConfig = state.ConfigReducer.klevu ?? {};
    const klevuReducer = state.KlevuSearchReducer ?? {};
    const urlRewrites = state.UrlRewritesReducer ?? {};

    const {
        klevu_api_key = '',
        klevu_limit = 1,
        klevu_search_url_v2 = ''
    } = klevuConfig;

    const {
        isDisplayProduct = true,
        totalProductsNum = 0,
        totalOthersNum = 0
    } = klevuReducer;

    const { category = {} } = state.CategoryReducer || {};

    return {
        ...callback(...args),
        klevu_api_key,
        klevu_limit,
        klevu_url: klevu_search_url_v2,
        isDisplayProduct,
        totalProductsNum,
        totalOthersNum,
        category,
        urlRewrites,
        isSuppliesSearch,
        searchPageProductIds: state.KlevuSearchReducer.searchPageProductIds,
        searchPageCategories: state.KlevuSearchReducer.searchPageCategories
    };
};

const requestProductList = async (args, callback, instance) => {
    const {
        klevu_api_key,
        setProducts,
        updateTotalKlevuResults,
        isDisplayProduct,
        klevu_limit,
        klevu_url,
        category,
        urlRewrites: {
            urlRewrite,
            isLoading: isUrlLoading
        },
        appendPage,
        updatePageLoadingStatus,
        searchPageProductIds,
        setSearchPageProductIds,
        setSearchPageCategories,
        updateProductListInfo,
        updateLoadStatus,
        updateInfoLoadStatus,
        search: searchTerm
    } = instance.props;

    if (isUrlLoading || !window.location.pathname.includes('search')) {
        callback(...args);
        return;
    }

    const pagePath = window.location.pathname;

    // If on category page, fetch category products instead of search results
    if (
        Object.keys(category).length
         && category.url === pagePath
         && Object.keys(urlRewrite).length
         && urlRewrite.type === TYPE_CATEGORY
    ) {
        callback(...args);
        return;
    }

    const { resolveSearchResultPageCount } = instance;

    const [options] = args;
    const {
        args: {
            sort,
            currentPage,
            search,
            filter: { customFilters }
        }, isNext
    } = options;

    const isFilteredSearch = Object.keys(customFilters).length > 0;

    // we need to display placeholders while products and filters are loading
    updateInfoLoadStatus(true);
    if (!isNext) {
        updateLoadStatus(true);
    } else {
        updatePageLoadingStatus();
    }

    // if we don't heave downloaded products than we need to fetch it from klevu
    const needNewRequest = isNext === false && isFilteredSearch === false;

    const { productIds: ids = searchPageProductIds } = await getKlevuResults(
        search.replaceAll('+', ' '),
        klevu_api_key,
        {
            sort,
            url: klevu_url
        },
        setSearchPageProductIds,
        setSearchPageCategories,
        needNewRequest // if we need next page, that we have data and don't need to get it from BE one more time
    );

    const productIds = ids.length ? ids : ['-1'];
    const productsTotalCount = ids.length ? productIds.length : 0;
    // now we need to obtain products from backend
    const idsFrom = currentPage === 1 ? 0 : (currentPage - 1) * parseInt(klevu_limit, 10);
    const idsTo = idsFrom + parseInt(klevu_limit, 10) - 1;

    const idsToFetch = productIds.slice(idsFrom, idsTo);

    if (isFilteredSearch) {
        const {
            items: filteredPageItems,
            total_count: filteredTotalCount,
            current_page: fiteredCurrentPage,
            total_pages: filteredTotalPages
        } = await getFilteredProductsFromBE(productIds, options);

        if (!isNext) {
            setProducts(
                filteredPageItems,
                fiteredCurrentPage,
                filteredTotalCount,
                filteredTotalPages,
                options.args
            );
        } else {
            appendPage(
                filteredPageItems,
                fiteredCurrentPage
            );
        }
        // We need to get filters on the set of products that we received from the server
        const { products: klevuFilters } = await getKlevuFiltersFromBE(productIds, options);

        const { args: { filter } } = options;
        updateProductListInfo(klevuFilters, filter);

        updateTotalKlevuResults({ productsNum: filteredTotalCount, othersNum: 0 });
        // updateLoadStatus(false);
        return;
    }

    const items = await getProductsFromBE(idsToFetch, options);
    const resultsPageCount = resolveSearchResultPageCount(
        productsTotalCount,
        0,
        isDisplayProduct,
        klevu_limit
    );

    const validatedCurrentPage = currentPage > resultsPageCount ? resultsPageCount : currentPage;

    if (!isNext) {
        setProducts(
            items,
            validatedCurrentPage,
            productsTotalCount,
            resultsPageCount,
            options.args
        );
    } else {
        appendPage(
            items,
            validatedCurrentPage
        );
    }

    // We need to get filters on the set of products that we received from the server
    const { products: klevuFilters } = await getKlevuFiltersFromBE(productIds, options);

    const { args: { filter } } = options;
    updateProductListInfo(klevuFilters, filter);

    // update KlevuSearch reducer
    updateTotalKlevuResults({ productsNum: productsTotalCount, othersNum: 0 });

    const searchTermURL = searchTerm.replace('+', '%20');
    const analyticsReportUrl = 'https://stats.ksearchnet.com/analytics/n-search/search'
    + `?klevu_apiKey=${ klevu_api_key }`
    + `&klevu_term=${ searchTermURL }`
    + `&klevu_totalResults=${ instance.props.totalItems }`
    + '&klevu_typeOfQuery=WILDCARD_AND';

    fetch(analyticsReportUrl);
    // updateLoadStatus(false);
};

export const resolveSearchResultPageCount = (args) => {
    // Resolves search results page count for prodcut tab or others tab
    const [productsTotalCount, othersTotalCount, isDisplayProduct, limit] = args;

    if (isDisplayProduct) {
        const productsResultPageCount = Math.ceil(productsTotalCount / limit);

        return productsResultPageCount;
    }

    const othersResultPageCount = Math.ceil(othersTotalCount / limit);

    return othersResultPageCount;
};

export const componentDidUpdate = (args, callback, instance) => {
    const { search, updateTotalKlevuResults } = instance.props;
    const [prevProps] = args;
    const { search: prevSearch } = prevProps;

    if (search !== prevSearch) {
        updateTotalKlevuResults({ productsNum: 0, othersNum: 0 });
    }
};

export default {
    'Component/CategoryProductList/Container': {
        'member-function': {
            requestProductList,
            resolveSearchResultPageCount,
            componentDidUpdate
        }
    },
    'Component/CategoryProductList/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Component/CategoryProductList/Container/mapStateToProps': {
        function: mapStateToProps
    }
};
