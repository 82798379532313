import { memo } from 'react';

import {
    FieldContainer as PrinterbaseFieldContainer
} from 'PrinterbaseComponent/PureForm/Field/Field.container';

/** @namespace Scandipwa/Component/PureForm/Field/Container */
export class FieldContainer extends PrinterbaseFieldContainer {
    containerProps() {
        const { showSubscriptionPopup } = this.props;

        return {
            showSubscriptionPopup,
            ...super.containerProps()
        };
    }
}

export const MemoizedFieldContainer = memo(FieldContainer, (prevProps, nextProps) => {
    const {
        mix,
        attr,
        events,
        validateOn,
        validationRule,
        ...restProps
    } = prevProps;

    const {
        mix: nextMix,
        attr: nextAttr,
        events: nextEvents,
        validateOn: nextValidateOn,
        validationRule: nextValidationRule,
        ...restNextProps
    } = nextProps;

    if (
        JSON.stringify(mix) !== JSON.stringify(nextMix)
        || JSON.stringify(attr) !== JSON.stringify(nextAttr)
        || JSON.stringify(events) !== JSON.stringify(nextEvents)
        || JSON.stringify(validateOn) !== JSON.stringify(nextValidateOn)
        || JSON.stringify(validationRule) !== JSON.stringify(nextValidationRule)
    ) {
        return false;
    }

    const restPropsAreNotEqual = Object.keys(nextProps).some(
        (key) => (typeof restProps[key] === 'function' ? false : restProps[key] !== restNextProps[key])
    );

    return !restPropsAreNotEqual;
});

export default MemoizedFieldContainer;
