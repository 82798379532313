/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { ATTACHMENTS } from '@scandipwa/mageworx-attachment/src/plugin/ProductPage.plugin';

import { DELIVERY_INFORMATION } from 'Component/DeliveryInformationTab/DeliveryInformationTab.config';
import { WHATS_IN_THE_BOX } from 'Component/WhatsInTheBoxTab/WhatsInTheBoxTab.config';
import {
    PRODUCT_ATTRIBUTES,
    PRODUCT_INFORMATION,
    PRODUCT_REVIEWS,
    PRODUCT_SYNDICATED_CONTENT,
    PROMO_TERMS,
    WILL_IT_WORK
} from 'Route/ProductPage/ProductPage.config';

/**
 * This const should be changed according to the desired tab ordering
 */
const tabOrdering = [
    WILL_IT_WORK,
    PRODUCT_ATTRIBUTES,
    PRODUCT_INFORMATION,
    PRODUCT_SYNDICATED_CONTENT,
    WHATS_IN_THE_BOX,
    PRODUCT_REVIEWS,
    PROMO_TERMS,
    DELIVERY_INFORMATION,
    ATTACHMENTS
];

const shouldTabsRenderPlugin = (args, callback, instance) => {
    const { tabMap } = instance;

    const defaultTabMap = {};
    const reorderedTabMap = {};
    Object.keys(tabMap).forEach((value, index) => {
        if (index in tabOrdering) {
            // If we specified and order for this item, we use custom key/order
            reorderedTabMap[ tabOrdering.indexOf(value) ] = tabMap[value];
        } else {
            // use default key/order
            defaultTabMap[value] = tabMap[value];
        }
    });

    const tempTabMap = { ...reorderedTabMap, ...defaultTabMap };

    /**
     * We temporarily change instance's tabMap to our ordered one, because
     * shouldTabsRender function does not get it by parameter but, instead,
     * grabs it directly from member-property.
     * So, we got no other option, because we also cannot change it
     * with member-property plugin because we must run it after all
     * member-property plugins placed their stuff on tabMap
     */
    // eslint-disable-next-line no-param-reassign
    instance.tabMap = tempTabMap;

    /**
     * Let the tabs render the tabs in our custom order
     */
    const customOrderedTabs = callback(...args);

    /**
     * Restore original tabMap value to instance
     */
    // eslint-disable-next-line no-param-reassign
    instance.tabMap = tabMap;

    return customOrderedTabs;
};

export default {
    'Route/ProductPage/Component': {
        'member-function': {
            shouldTabsRender: shouldTabsRenderPlugin
        }
    }
};
