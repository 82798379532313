const componentDidMount = () => {};

export const config = {
    'Component/NewVersionPopup/Container': {
        'member-function': {
            componentDidMount
        }
    }
};

export default config;
