/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/**
 * Amasty Custom Forms compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import ReviewStar from 'Component/ReviewStar';

import { FIELD_TYPE_CUSTOM } from '../component/CustomForms/CustomForms.config';

function renderNumberType() {
    const {
        type,
        setRef,
        attr,
        events,
        isDisabled
    } = this.props;

    return (
        <input
          ref={ (elem) => setRef(elem) }
          disabled={ isDisabled }
          type={ type }
          { ...attr }
          { ...events }
        />
    );
}

export const renderRatingType = (args, callback, instance) => {
    const { selectOptions, name, style } = instance.props;

    return (
        <fieldset name={ name } id={ name }>
            { selectOptions.map((value) => (
                <ReviewStar
                  name={ name }
                  value={ value.value }
                  title={ value.label }
                  style={ style }
                  option_id={ value.value }
                  rating_id=""
                />
            )) }
        </fieldset>
    );
};

const renderMap = (member, instance) => ({
    ...member,
    rating: renderRatingType.bind(instance),
    [FIELD_TYPE_CUSTOM.number]: renderNumberType.bind(instance)
});

export default {
    'Component/PureForm/Field/Component': {
        'member-property': {
            renderMap
        },
        'member-function': {
            renderRatingType
        }
    }
};
