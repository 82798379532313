const dataPairArray = (originalMember, instance) => {
    const {
        address,
        isAmazonPay
    } = instance.props;

    const resultDataPairArray = originalMember;

    if (!isAmazonPay) {
        return resultDataPairArray;
    }

    const modifiedDataFields = [
        {
            key: 'email',
            label: __('Email address'),
            source: address
        }
    ];

    const replacedDataFields = resultDataPairArray.map((dataPair) => {
        const { key } = dataPair;
        return modifiedDataFields.find(({ key: mKey }) => mKey === key) || dataPair;
    });

    return replacedDataFields;
};

const config = {
    'Component/MyAccountAddressTable/Component': {
        'member-function': {
            dataPairArray
        }
    }
};

export default config;
