import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { PriceType, ProductType } from 'Type/ProductList';
import history from 'Util/History';

import AddToBasket from './AddToBasket.component';

/** @namespace Scandipwa/Component/AddToBasket/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile,
    itemsQty: state.CartReducer.cartTotals.items_qty,
    cartPrice: state.CartReducer.cartTotals.prices
});

/** @namespace Scandipwa/Component/AddToBasket/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({
});

/** @namespace Scandipwa/Component/AddToBasket/Container */
export class AddToBasketContainer extends PureComponent {
    static propTypes = {
        relatedProductsData: PropTypes.objectOf(ProductType).isRequired,
        product: ProductType.isRequired,
        isMobile: PropTypes.bool.isRequired,
        itemsQty: PropTypes.number.isRequired,
        cartPrice: PriceType.isRequired,
        hidePromoPopup: PropTypes.func.isRequired,
        isLoaded: PropTypes.bool.isRequired,
        showStockNotifyPopup: PropTypes.func.isRequired
    };

    containerFunctions = {
        goToBasket: this.goToBasket.bind(this)
    };

    containerProps() {
        const {
            relatedProductsData,
            product,
            isMobile,
            itemsQty,
            cartPrice,
            hidePromoPopup,
            isLoaded,
            showStockNotifyPopup
        } = this.props;

        return {
            relatedProductsData,
            product,
            isMobile,
            itemsQty,
            cartPrice,
            hidePromoPopup,
            isLoaded,
            showStockNotifyPopup
        };
    }

    goToBasket() {
        history.push('/cart');
    }

    render() {
        return (
            <AddToBasket
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToBasketContainer);
