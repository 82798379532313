/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/klaviyo
 * @author  Jort Geurts <info@scandiweb.com>
 */
import { CUSTOMER } from 'SourceStore/MyAccount/MyAccount.dispatcher';
import BrowserDatabase from 'Util/BrowserDatabase';

const signIn = async (args, callback) => {
    const returnValue = await callback(...args);

    if (!returnValue) {
        return returnValue;
    }

    // eslint-disable-next-line fp/no-let, prefer-const, no-use-before-define
    let _learnq = window._learnq || [];

    // TODO: Disable if klaviyo is disabled

    const customer = BrowserDatabase.getItem(CUSTOMER);

    if (!customer) {
        return returnValue;
    }

    _learnq.push(['identify', {
        $email: customer.email,
        $first_name: customer.firstname,
        $last_name: customer.lastname
    }]);

    return returnValue;
};

export default {
    'Store/MyAccount/Dispatcher': {
        'member-function': {
            signIn
        }
    }
};
