/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const UPDATE_ATTACHMENTS = 'UPDATE_ATTACHMENTS';
export const UPDATE_ATTACHMENTS_CONFIG = 'UPDATE_ATTACHMENTS_CONFIG';

/** @namespace MageworxAttachment/Store/Attachments/Action/updateAttachments */
export const updateAttachments = (attachments) => ({
    type: UPDATE_ATTACHMENTS,
    attachments
});

/** @namespace MageworxAttachment/Store/Attachments/Action/updateAttachmentsConfig */
export const updateAttachmentsConfig = (config) => ({
    type: UPDATE_ATTACHMENTS_CONFIG,
    config
});
