/**
 * @category  Printerbase
 * @author    Scandiweb <info@scandiweb.com>
 * @package   @scandiweb/printerbase
 */

/**
 * Plugin to create dummy function if no function was passed down.
 * This is done to avoid other plugins to throw errors if these functions
 * are not present
 */
const renderPaymentsPlugin = (args, callback, instance) => {
    const { setOrderButtonVisibility } = instance;
    if (!setOrderButtonVisibility) {
        // eslint-disable-next-line no-param-reassign
        instance.setOrderButtonVisibility = () => {};
    }

    const { setOrderButtonEnableStatus } = instance;
    if (!setOrderButtonEnableStatus) {
        // eslint-disable-next-line no-param-reassign
        instance.setOrderButtonEnableStatus = () => {};
    }

    return callback.apply(instance, args);
};

export default {
    'Scandiweb/PrinterbaseTheme/Component/CheckoutShipping/Component': {
        'member-function': {
            renderPayments: renderPaymentsPlugin
        }
    }
};
