/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import React, { lazy, Suspense } from 'react';

import Loader from 'Component/Loader/Loader.component';
import {
    WHATS_IN_THE_BOX,
    WHATS_IN_THE_BOX_LABEL
} from 'Component/WhatsInTheBoxTab/WhatsInTheBoxTab.config';

export const WhatsInTheBoxTab = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-info" */
    '../../../component/WhatsInTheBoxTab'
));

const renderWhatsInTheBoxTab = (key, instance) => {
    const { whatsInTheBoxItems } = instance.props;

    return (
        <Suspense fallback={ <Loader /> } key={ key }>
            <WhatsInTheBoxTab
              whatsInTheBoxItems={ whatsInTheBoxItems }
              key={ key }
            />
        </Suspense>
    );
};

const tabMapPlugin = (member, instance) => {
    const newMember = {
        ...member,
        [WHATS_IN_THE_BOX]: {
            name: WHATS_IN_THE_BOX_LABEL,
            shouldTabRender: () => instance?.props?.whatsInTheBoxItems?.length > 0,
            render: (key) => renderWhatsInTheBoxTab(key, instance)
        }
    };

    return newMember;
};

export default {
    'Route/ProductPage/Component': {
        'member-property': {
            tabMap: tabMapPlugin
        }
    }
};
