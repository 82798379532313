import {
    FieldSelectContainer as PrinterbaseFieldSelectContainer
} from 'PrinterbaseComponent/PureForm/FieldSelect/FieldSelect.container';

/** @namespace Scandipwa/Component/PureForm/FieldSelect/Container */
export class FieldSelectContainer extends PrinterbaseFieldSelectContainer {
    componentDidUpdate(prevProps) {
        const { options, attr: { defaultValue } = {} } = this.props;
        const { options: prevOptions, attr: { defaultValue: prevDefaultValue } = {} } = prevProps;

        if (window.location.search && options?.length !== prevOptions?.length && defaultValue === prevDefaultValue) {
            const option = options.find((option) => (option?.value === defaultValue));

            if (option) {
                this.handleSelectListOptionClick(option);
            }
        }
    }

    containerProps() {
        const { showSubscriptionPopup } = this.props;

        return {
            showSubscriptionPopup,
            ...super.containerProps()
        };
    }
}

export default FieldSelectContainer;
