/* eslint-disable max-lines */
import _uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';

import AmazonPay from './AmazonPay.component';
import {
    AMAZON_PAY_ACTION,
    AMAZON_PAY_API_PLACEMENTS,
    AMAZON_PAY_PRODUCT_TYPES,
    AMAZON_PAY_SCRIPT_ID,
    INTERVAL_TIMEOUT,
    MAX_INTERVAL_ATTEMPTS
} from './AmazonPay.config';

export const AmazonPayDispatcher = import(
    '../../store/AmazonPay/AmazonPay.dispatcher'
);

/** @namespace Amazonpay/Component/AmazonPay/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    amazonPayConfig: state.AmazonPayReducer.config,
    amazonCheckoutSessionConfig: state.AmazonPayReducer.checkoutSessionConfig,
    isLoading: state.AmazonPayReducer.state.isLoading
});

/** @namespace Amazonpay/Component/AmazonPay/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    setAmazonPayState: (state) => AmazonPayDispatcher.then(({ default: dispatcher }) => {
        dispatcher.setAmazonPayState(dispatch, state);
    }),
    fetchAmazonPayConfig: () => AmazonPayDispatcher.then(({ default: dispatcher }) => {
        dispatcher.fetchAmazonPayConfig(dispatch);
    }),
    fetchAmazonPayCheckoutSessionConfig:
        () => AmazonPayDispatcher.then(
            ({ default: dispatcher }) => dispatcher.fetchAmazonPayCheckoutSessionConfig(dispatch)
        ),
    setAmazonPaymentMethodOnCart: () => AmazonPayDispatcher.then(({ default: dispatcher }) => {
        dispatcher.setAmazonPaymentMethodOnCart(dispatch);
    })
});

/** @namespace Amazonpay/Component/AmazonPay/Container */
export class AmazonPayContainer extends PureComponent {
    static propTypes = {
        amazonPayConfig: PropTypes.shape().isRequired,
        amazonCheckoutSessionConfig: PropTypes.shape().isRequired,
        fetchAmazonPayConfig: PropTypes.func.isRequired,
        fetchAmazonPayCheckoutSessionConfig: PropTypes.func.isRequired,
        setAmazonPaymentMethodOnCart: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        type: PropTypes.string,
        location: PropTypes.string.isRequired
    };

    static defaultProps = {
        type: ''
    };

    amazonButtonIdRef = null;

    amazonPayRef = null;

    amazonPayButtonContainerRef = null;

    amazonCheckoutSessionId = null;

    setMaxWidthNoneInterval = null;

    handleAmazonScriptLoad = this.handleAmazonScriptLoad.bind(this);

    onAmazonPayClick = this.onAmazonPayClick.bind(this);

    renderButton = this.renderButton.bind(this);

    componentDidMount() {
        const { fetchAmazonPayConfig } = this.props;

        // eslint-disable-next-line fp/no-let
        let intervalCounter = 0;
        this.setMaxWidthNoneInterval = setInterval(() => {
            intervalCounter += 1;

            if (this.amazonPayButtonContainerRef?.current?.shadowRoot) {
                this.amazonPayButtonContainerRef.current.shadowRoot
                    .querySelector('.amazonpay-button-container')
                    .setAttribute('style', 'max-width:none');

                clearInterval(this.setMaxWidthNoneInterval);
            }

            if (intervalCounter > MAX_INTERVAL_ATTEMPTS) {
                clearInterval(this.setMaxWidthNoneInterval);
            }
        }, INTERVAL_TIMEOUT);

        fetchAmazonPayConfig();
    }

    componentDidUpdate() {
        const {
            amazonPayConfig: {
                amazonScript: amazonScriptURL
            }
        } = this.props;

        if (amazonScriptURL) {
            const amazonScriptElement = document.getElementById(AMAZON_PAY_SCRIPT_ID);

            if (amazonScriptElement) {
                // amazonScriptElement.onload = this.handleAmazonScriptLoad;
                setTimeout(() => {
                    this.handleAmazonScriptLoad();
                });
            } else {
                const script = document.createElement('script');

                script.src = amazonScriptURL;
                script.id = AMAZON_PAY_SCRIPT_ID;

                document.head.appendChild(script);

                script.onload = this.handleAmazonScriptLoad;
            }
        }
    }

    componentWillUnmount() {
        if (this.setMaxWidthNoneInterval) {
            clearInterval(this.setMaxWidthNoneInterval);
        }
    }

    __construct() {
        this.amazonButtonIdRef = createRef();
        this.amazonPayRef = createRef();
        this.amazonPayButtonContainerRef = createRef();

        this.amazonButtonIdRef.current = _uniqueId('amazonpay-');
    }

    containerProps() {
        const {
            isLoading,
            location
        } = this.props;

        return {
            isLoading,
            location,
            amazonButtonIdRef: this.amazonButtonIdRef,
            amazonPayButtonContainerRef: this.amazonPayButtonContainerRef
        };
    }

    getAmazonPayConfig() {
        const {
            amazonPayConfig: {
                merchantId,
                publicKeyId,
                sandbox,
                ledgerCurrency,
                checkoutLanguage,
                // placement,
                buttonColor
            }
        } = this.props;

        const filteredConfig = {
            merchantId,
            publicKeyId,
            ledgerCurrency,
            sandbox,
            checkoutLanguage,
            productType: AMAZON_PAY_PRODUCT_TYPES.PAY_AND_SHIP,
            placement: AMAZON_PAY_API_PLACEMENTS.CHECKOUT,
            buttonColor
        };

        return filteredConfig;
    }

    handleAmazonScriptLoad() {
        this.renderButton();
    }

    renderButton() {
        const { isLoading } = this.props;

        if (!window.amazon || isLoading) {
            return;
        }

        const { amazonPayRef, amazonButtonIdRef } = this;

        const buttonId = `#${amazonButtonIdRef.current}`;

        try {
            const button = window.amazon.Pay.renderButton(buttonId, this.getAmazonPayConfig());
            amazonPayRef.current = button;
            button.onClick(this.onAmazonPayClick);
        } catch (error) {
            if (error.message.indexOf('attachShadow') !== -1) {
                // eslint-disable-next-line no-console
                console.info('AmazonPay.renderButton: button already rendered');
            } else {
                // eslint-disable-next-line no-console
                console.error(`AmazonPay.renderButton: ${error}`);
            }
        }
    }

    onAmazonPayClick(e) {
        const { amazonPayRef } = this;

        const {
            fetchAmazonPayCheckoutSessionConfig,
            setAmazonPaymentMethodOnCart,
            type
        } = this.props;

        fetchAmazonPayCheckoutSessionConfig().then(
            /** @namespace Amazonpay/Component/AmazonPay/Container/AmazonPayContainer/onAmazonPayClick/fetchAmazonPayCheckoutSessionConfig/then */
            async (checkoutSessionConfig) => {
                const {
                // merchantId,
                // publicKeyId,
                // ledgerCurrency,
                // sandbox,
                // checkoutLanguage,
                // buttonColor,
                    paynowPayload,
                    paynowSignature,
                    checkoutPayload,
                    checkoutSignature
                } = checkoutSessionConfig;

                const isPayNow = type === AMAZON_PAY_ACTION.PAY_NOW;

                /**
                 * PayNow payload is used in normal checkout, it includes all the quote data.
                 * Since its fetched evreytime on click, it should be up-to-date with quote.
                 *
                 * CheckoutPayload is used in express checkout, it includes data neccessary for
                 * Amazon Checkout - like redirect URLs.
                 */

                const createCheckoutSessionConfig = {
                    payloadJSON: isPayNow ? paynowPayload : checkoutPayload,
                    signature: isPayNow ? paynowSignature : checkoutSignature
                };

                if (isPayNow) {
                    await setAmazonPaymentMethodOnCart();
                }

                amazonPayRef.current.initCheckout({
                    createCheckoutSessionConfig
                });
            }
        );
    }

    render() {
        return (
            <AmazonPay
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AmazonPayContainer);
