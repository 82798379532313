import FieldSelectContainer from 'Component/PureForm/FieldSelect/FieldSelect.container';
import { FieldComponent as SourceField } from 'PrinterbaseComponent/PureForm/Field/Field.component';

import './Field.override.style.scss';

/** @namespace Scandipwa/Component/PureForm/Field/Component */
export class FieldComponent extends SourceField {
    renderSelect() {
        const {
            attr,
            events,
            setRef,
            options,
            isDisabled = false,
            showSubscriptionPopup
        } = this.props;

        return (
                <FieldSelectContainer
                  attr={ attr }
                  events={ events }
                  options={ options }
                  setRef={ setRef }
                  isDisabled={ isDisabled }
                  showSubscriptionPopup={ showSubscriptionPopup }
                />
        );
    }

    /**
     * Overridden to fix radio button checked simultaniously
     */
    renderCheckboxOrRadio() {
        const {
            type,
            setRef,
            attr,
            attr: { id = '' } = {},
            events: { onChange },
            events,
            isDisabled,
            label
        } = this.props;

        const elem = type.charAt(0).toUpperCase() + type.slice(1);
        const inputEvents = {
            ...events,
            onChange: onChange || (() => {})
        };

        const { checked } = attr;

        return (
            <label htmlFor={ id } block="Field" elem={ `${elem}Label` }>
                <input
                  ref={ (elem) => setRef(elem) }
                  disabled={ isDisabled }
                  type={ type }
                  // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...attr }
                  // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...inputEvents }
                />
                <div block="input-control" mods={ { checked } } />
                { label }
            </label>
        );
    }
}

export default FieldComponent;
