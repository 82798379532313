/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export const KLEVU_JS_URL = '//js.klevu.com/core/2.4.1/klevu.js';
export const KLEVU_SEARCH_URL = 'https://eucs29v2.ksearchnet.com/cs/v2/search';
export const TRENDING_PRODUCT_COUNT = 4;
export const CATEGORY_ITEM_COUNT = 3;
export const CMS_ITEM_COUNT = 3;
export const PRODUCT_LIST_COUNT = 6;
export const SUGGESTION_ITEM_COUNT = 6;
export const LV_STORE_CODE = 'lv';
