/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

/**
 * Product Attachments Query
 * @class AttachmentsQuery
 * @namespace MageworxAttachment/Query/Attachments/Query */
export class AttachmentsQuery {
    /**
     * Returns a single attachment
     * @param {number} id
     */
    getQuery(id) {
        return new Field('getProductAttachments')
            .addArgument('id', 'ID', id)
            .addFieldList([
                'attachment_id',
                'description',
                'filename',
                'link',
                'name',
                'section_id',
                'section_name',
                'size',
                'type',
                'downloads',
                'downloads_limit',
                'icon',
                'file_type',
                'section_description',
                'url'
            ])
            .setAlias('attachments');
    }

    /**
     * Returns an array of attachments
     * @param {array.<number>} ids - attachment ids
     * @returns {Array}
     */
    getAttachments(ids) {
        return new Field('getAttachments')
            .addArgument('id', '[ID]', ids)
            .addFieldList([
                'attachment_id',
                'description',
                'filename',
                'link',
                'name',
                'section_id',
                'section_name',
                'size',
                'type',
                'downloads',
                'downloads_limit',
                'icon',
                'file_type',
                'section_description'
            ])
            .setAlias('attachments');
    }

    /**
     * Returns an array of attachments based on section ids
     * @param {array.<number>} ids - sections ids
     * @returns {Array}
     */
    getAttachmentsBySections(ids) {
        return new Field('getAttachmentsBySections')
            .addArgument('id', '[ID]', ids)
            .addFieldList([
                'attachment_id',
                'description',
                'filename',
                'link',
                'name',
                'section_id',
                'section_name',
                'size',
                'type',
                'downloads',
                'downloads_limit',
                'icon',
                'file_type',
                'section_description'
            ])
            .setAlias('attachments');
    }
}

export default new (AttachmentsQuery)();
