/**
 * @category  Printerbase
 * @author    Scandiweb <info@scandiweb.com>
 * @namespace Scandipwa/Component/CheckoutAddressForm/CheckoutAddressFormInstance
 */

import CheckoutAddressFormInstance from 'Component/CheckoutAddressForm/CheckoutAddressFormInstance';

const componentDidMountPlugin = (args, callback, instance) => {
    callback(...args);

    CheckoutAddressFormInstance.setInstance(instance);
};

const componentDidUnmountPlugin = (args, callback, instance) => {
    CheckoutAddressFormInstance.removeInstance(instance);
    callback(...args);
};

const componentDidUpdatePlugin = (args, callback, instance) => {
    callback(...args);

    if (!instance.hasEmptyForm && !instance.addedOnFormChangeEventListeners) {
        CheckoutAddressFormInstance.addFormChangeEventListeners(instance);
    }
};

export default {
    'Component/CheckoutAddressForm/Component': {
        'member-function': {
            componentDidMount: componentDidMountPlugin,
            componentDidUnmount: componentDidUnmountPlugin,
            componentDidUpdate: componentDidUpdatePlugin
        }
    }
};
