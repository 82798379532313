/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { AttachmentsDispatcher } from '../store/Attachments';

export class ProductDispatcherPlugin {
    aroundOnSuccess = (args, callback, instance) => {
        const [data, dispatch] = args;

        if (!data.products) {
            return callback.apply(instance, args);
        }
        const { products: { items } } = data;

        if ((items && items.length > 0)) {
            const [{ id }] = items;
            AttachmentsDispatcher.handleData(dispatch, id);
        }

        return callback.apply(instance, args);
    };
}

const { aroundOnSuccess } = new ProductDispatcherPlugin();

export const config = {
    'Store/Product/Dispatcher': {
        'member-function': {
            onSuccess: [
                {
                    position: 100,
                    implementation: aroundOnSuccess
                }
            ]
        }
    }
};

export default config;
