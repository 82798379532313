/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { QueryDispatcher as SourceQueryDispatcher } from 'SourceUtil/Request/QueryDispatcher';

export const ONE_MONTH_IN_SECONDS = 2592000;
export const FIVE_MINUTES_IN_SECONDS = 300;

/**
 * @class QueryDispatcher
 * @namespace Scandipwa/Util/Request/QueryDispatcher */
export class QueryDispatcher extends SourceQueryDispatcher {
    // eslint-disable-next-line no-unused-vars
    onUpdate(data, dispatch, options) {}
}

export default QueryDispatcher;
