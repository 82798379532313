/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PRODUCT_SPECS_ATTRIBUTES } from './DetailsTab.ProductAttributesContainer.plugin.config';

const _getAttributesWithValuesPlugin = (args, callback/* , instance */) => {
    const attributes = callback(...args);

    return Object.entries(attributes).reduce((acc, [key, attribute]) => (
        PRODUCT_SPECS_ATTRIBUTES.includes(attribute.attribute_code)
            ? { ...acc, [key]: attribute }
            : acc
    ), {});
};

export default {
    'Component/ProductAttributes/Container': {
        'member-function': {
            _getAttributesWithValues: _getAttributesWithValuesPlugin
        }
    }
};
