import PropTypes from 'prop-types';

export const _suggestionType = PropTypes.shape({ suggest: PropTypes.string });

export const _metaType = PropTypes.shape({
    qTime: PropTypes.number,
    responseCode: PropTypes.number
});

export const _queryResultsMetaType = PropTypes.shape({
    apiKey: PropTypes.string,
    debuggingInformation: PropTypes.shape({}),
    isPersonalised: PropTypes.bool,
    noOfResults: PropTypes.number,
    notificationCode: PropTypes.number,
    offset: PropTypes.number,
    qTime: PropTypes.number,
    searchedTerm: PropTypes.string,
    totalResultsFound: PropTypes.number,
    typeOfSearch: PropTypes.string
});

export const _cmsCompressedRecordType = PropTypes.shape({
    id: PropTypes.string,
    klevu_category: PropTypes.string,
    name: PropTypes.string,
    shortDesc: PropTypes.string,
    typeOfRecord: PropTypes.string,
    url: PropTypes.string
});

export const _productListRecordType = PropTypes.shape({
    category: PropTypes.string,
    currency: PropTypes.string,
    deliverInfo: PropTypes.string,
    discount: PropTypes.string,
    freeShipping: PropTypes.string,
    groupPrices: PropTypes.string,
    hideAddToCart: PropTypes.string,
    hideGroupPrices: PropTypes.string,
    id: PropTypes.string,
    image: PropTypes.string,
    imageHover: PropTypes.string,
    imageUrl: PropTypes.string,
    inStock: PropTypes.string,
    itemGroupId: PropTypes.string,
    klevu_category: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.string,
    salePrice: PropTypes.string,
    shortDesc: PropTypes.string,
    sku: PropTypes.string,
    startPrice: PropTypes.string,
    storeBaseCurrency: PropTypes.string,
    swatchesInfo: PropTypes.string,
    toPrice: PropTypes.string
});

export const _klevuProductFmt = PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    category: PropTypes.string,
    price_range: PropTypes.shape({}),
    // eslint-disable-next-line react/forbid-prop-types
    price_tiers: PropTypes.array,
    rating_summary: PropTypes.number,
    review_count: PropTypes.number,
    short_description: PropTypes.shape({ html: PropTypes.string }),
    sku: PropTypes.string,
    stock_item: PropTypes.shape({}),
    thumbnail: PropTypes.shape({ label: PropTypes.string, path: PropTypes.string, url: PropTypes.string }),
    type_id: PropTypes.string,
    url: PropTypes.string,
    url_rewrites: PropTypes.arrayOf(PropTypes.shape({
        parameters: PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.string
        }),
        url: PropTypes.string
    }))
});

export const _queryResultsType = PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    filters: PropTypes.array,
    id: PropTypes.string,
    meta: _queryResultsMetaType,
    records: PropTypes.arrayOf(PropTypes.oneOfType([
        _cmsCompressedRecordType,
        _productListRecordType,
        // Don't know the exact type of the record for categoryCompressed
        PropTypes.array
    ]))
});

export const klevuResultType = PropTypes.oneOf([
    PropTypes.shape({
        meta: _metaType,
        queryResults: PropTypes.arrayOf(_queryResultsType),
        suggestionsResults: PropTypes.arrayOf(_suggestionType)
    }),
    // This is a standin for 'null', since PropTypes does not allow 'null'
    PropTypes.number
]);

export const klevuConfigType = PropTypes.shape({
    klevu_api_key: PropTypes.string,
    klevu_display_cat: PropTypes.string,
    klevu_display_cms: PropTypes.string,
    klevu_limit: PropTypes.string,
    klevu_search_url_v2: PropTypes.string,
    klevu_display_suggestions: PropTypes.string
});

export const klevuCardType = PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
    id: PropTypes.string
});

export const klevuResultFmt = PropTypes.shape({
    products: _klevuProductFmt,
    categories: klevuCardType,
    pages: klevuCardType
});

export const klevuSearchPageCategoryType = PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    categoryPageTitle: PropTypes.string,
    url: PropTypes.string
});

export const klevuBannerType = PropTypes.shape({
    showOnLandingPage: PropTypes.bool,
    showOnQuickSearch: PropTypes.bool,
    showOnCategoryPage: PropTypes.bool,
    bannerAltTag: PropTypes.string,
    redirectUrl: PropTypes.string,
    bannerName: PropTypes.string,
    position: PropTypes.string,
    bannerImg: PropTypes.string,
    bannerRef: PropTypes.number,
    endDate: PropTypes.string,
    startDate: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    showForTerms: PropTypes.array
});
