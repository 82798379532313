/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    WHATS_IN_THE_BOX_ITEM_SEPARATOR,
    WHATS_IN_THE_BOX_MAGENTO_ATTRIBUTE
} from 'Component/WhatsInTheBoxTab/WhatsInTheBoxTab.config';

const getWhatsInTheBoxItems = (instance) => {
    const dataSource = instance.getDataSource();

    const dataSourceAttributes = dataSource?.attributes;
    if (!dataSourceAttributes) {
        return [];
    }

    const whatsInTheBoxString = dataSource.attributes[WHATS_IN_THE_BOX_MAGENTO_ATTRIBUTE]?.attribute_value;
    if (!whatsInTheBoxString) {
        return [];
    }

    return whatsInTheBoxString.split(WHATS_IN_THE_BOX_ITEM_SEPARATOR).map((elem) => elem.trim());
};

const containerPropsPlugin = (args, callback, instance) => {
    const defaultContainerProps = callback(args);
    const whatsInTheBoxItems = getWhatsInTheBoxItems(instance);

    return {
        ...defaultContainerProps,
        whatsInTheBoxItems
    };
};

export default {
    'Route/ProductPage/Container': {
        'member-function': {
            containerProps: containerPropsPlugin
        }
    }
};
