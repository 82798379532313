/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @package scandipwa/printerbase
 */
export const DEFAULT_PAYMENT_METHOD = {
    code: 'sagepaysuiteserver'
};

export const ADDRESS_DEPENDANT_PAYMENT_METHODS = [
    'amazon_payment_v2',
    'paypal_express'
];
