/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

/**
  * Product Attachments Query
  * @class AttachmentsQuery
  * @namespace MageworxAttachment/Query/AttachmentsConfig/Query */
export class AttachmentsConfigQuery {
    getQuery() {
        const displaySize = new Field('mageworx_display_file_size')
            .setAlias('display_file_size');
        const groupBy = new Field('mageworx_group_by_section')
            .setAlias('group_by_section');
        const displayDownloads = new Field('mageworx_display_number_of_downloads')
            .setAlias('display_number_of_downloads');
        const hideFiles = new Field('mageworx_hide_files')
            .setAlias('hide_files');
        const noticeText = new Field('mageworx_notice_text')
            .setAlias('notice_text');
        const tabTitle = new Field('mageworx_tab_title')
            .setAlias('tab_title');
        const widgetTitle = new Field('mageworx_widget_title')
            .setAlias('widget_title');

        return new Field('storeConfig')
            .addFieldList([
                displaySize,
                groupBy,
                displayDownloads,
                hideFiles,
                noticeText,
                tabTitle,
                widgetTitle
            ])
            .setAlias('config');
    }
}

export default new (AttachmentsConfigQuery)();
