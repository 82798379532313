import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import CategoryDescription from './CategoryDescription.component';

/** @namespace Scandipwa/Component/CategoryDescription/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    description: state.CategoryReducer.category.description
});

/** @namespace Scandipwa/Component/CategoryDescription/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Scandipwa/Component/CategoryDescription/Container */
export class CategoryDescriptionContainer extends PureComponent {
    static propTypes = {
        description: PropTypes.string
    };

    static defaultProps = {
        description: ''
    };

    containerProps() {
        const { description } = this.props;

        return {
            description
        };
    }

    render() {
        return (
            <CategoryDescription
              { ...this.containerProps() }
            />
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CategoryDescriptionContainer));
