/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
// ^^ no index.js in the source
import ProductPrice from 'Component/ProductPrice';
import {
    Product as SourceProduct
} from 'SourceComponent/Product/Product.component';

/** @namespace Scandipwa/Component/Product/Component */
export class Product extends SourceProduct {
    /**
     * Overriden to pass isSchemaRequired
     */
    renderPrice(isPreview = false) {
        const { getActiveProduct, productPrice } = this.props;
        const product = getActiveProduct();

        const {
            type_id: type,
            price_tiers: priceTiers
        } = product;

        if (!productPrice) {
            return null;
        }

        return (
            <div
              block={ this.className }
              elem="PriceWrapper"
            >
                <ProductPrice
                  meta
                  price={ productPrice }
                  priceType={ type }
                  product={ product }
                  tierPrices={ priceTiers }
                  isPreview={ isPreview }
                  mix={ { block: this.className, elem: 'Price' } }
                  isSchemaRequired={ this.className === 'ProductActions' }
                />
            </div>
        );
    }

    /*
     * Overridden to replace with Part. No
     */
    renderSku() {
        const { getActiveProduct } = this.props;
        const { attributes: { part_no: { attribute_value, attribute_label } = {} } = {} } = getActiveProduct();
        const label = `${attribute_label}: ` || __('Part No: ');

        if (!attribute_value) {
            return null;
        }

        if (this.className === 'ProductCard') {
            return (
                <span block={ this.className } elem="Sku">
                    { label + attribute_value }
                </span>
            );
        }

        return (
            <span block={ this.className } elem="Sku">
                { label }
                <span itemProp="sku">
                    { attribute_value }
                </span>
            </span>
        );
    }
}

export default Product;
