import { InView } from 'react-intersection-observer';

import {
    RenderWhenVisible as SourceRenderWhenVisible
} from 'SourceComponent/RenderWhenVisible/RenderWhenVisible.component';

/** @namespace Scandipwa/Component/RenderWhenVisible/Component */
export class RenderWhenVisibleComponent extends SourceRenderWhenVisible {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    checkIsVisible() {}

    renderVisibilitySensor() {
        const { margin = '' } = this.props;

        return (
            <InView
              triggerOnce
              rootMargin={ margin }
              onChange={ this.handleVisibilityToggle }
            >
                { this.renderFallback() }
            </InView>
        );
    }
}

export default RenderWhenVisibleComponent;
