/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import SearchField from 'Component/SearchField';

import './LookingForPrinterSearchField.style';

/** @namespace Scandipwa/Component/LookingForPrinterSearchField/Component */
export class LookingForPrinterSearchFieldComponent extends PureComponent {
    static propTypes = {
        onSearchOutsideClick: PropTypes.func.isRequired,
        onSearchBarFocus: PropTypes.func.isRequired,
        onSearchBarChange: PropTypes.func.isRequired,
        onClearSearchButtonClick: PropTypes.func.isRequired,
        searchCriteria: PropTypes.string.isRequired,
        isSearchFocused: PropTypes.bool.isRequired
    };

    render() {
        const {
            onSearchOutsideClick,
            onSearchBarFocus,
            onSearchBarChange,
            onClearSearchButtonClick,
            searchCriteria,
            isSearchFocused
        } = this.props;

        // In this component we render SearchField to Supplies not printers
        const isSuppliesSearch = true;

        return (
            <div block="LookingForPrinterSearchField">
                <SearchField
                  key="lookingForPrinter"
                  searchCriteria={ searchCriteria }
                  onSearchOutsideClick={ onSearchOutsideClick }
                  onSearchBarFocus={ onSearchBarFocus }
                  onSearchBarChange={ onSearchBarChange }
                  onClearSearchButtonClick={ onClearSearchButtonClick }
                  isActive={ isSearchFocused }
                  searchButtonText={ __('Search') }
                  fieldMix={ [
                      { block: 'LookingForPrinterSearchField', elem: 'SearchButton' },
                      { block: 'Button', mods: { isAccent: true } }
                  ] }
                  placeholder={ __('Search for ink, toner, paper...') }
                  isSuppliesSearch={ isSuppliesSearch }
                />
            </div>
        );
    }
}

export default LookingForPrinterSearchFieldComponent;
