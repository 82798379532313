/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
import { lazy } from 'react';
import { Route } from 'react-router-dom';

const BLACKFRIDAY = 'BLACKFRIDAY';
const BUY_AGAIN = 'buy-again';

/** @namespace Scandipwa/Component/Router/Component/withStoreRegex */
export const withStoreRegex = (path) => window.storeRegexText.concat(path);

export const BlackFriday = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "black friday" */ 'Component/BlackFriday')
);

export const MyAccount = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'SourceRoute/MyAccount'));

export class RouterComponentPlugin {
    SWITCH_ITEMS_TYPE = (originalMember) => [
        ...originalMember,
        {
            component: <Route path={ withStoreRegex('/blackfriday.html') } render={ (props) => <BlackFriday { ...props } /> } />,
            position: 90,
            name: BLACKFRIDAY
        },
        {
            component: <Route
              path={ withStoreRegex('/buy-again') }
              render={ (props) => <MyAccount { ...props } selectedTab={ BUY_AGAIN } /> }
            />,
            position: 91,
            name: BUY_AGAIN
        }
    ];
}

const {
    SWITCH_ITEMS_TYPE
} = new RouterComponentPlugin();

export const config = {
    'Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE
        }
    }
};

export default config;
