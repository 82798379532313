/**
 * Wyomind google customer reviews compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';

export const OrderConfigType = PropTypes.shape({
    merchantId: PropTypes.string,
    eta: PropTypes.string,
    optinStyle: PropTypes.string,
    gtinAttribute: PropTypes.string,
    listProducts: PropTypes.string,
    delivery_country: PropTypes.string
});
