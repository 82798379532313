import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import TextPlaceholder from 'Component/TextPlaceholder';

import './KlevuSearchSwitch.style';

/** @namespace KlevuSearch/Component/KlevuSearchSwitch/Component */
export class KlevuSearchSwitchComponent extends PureComponent {
    static propTypes = {
        isDisplayProduct: PropTypes.bool.isRequired,
        handleSwitch: PropTypes.func.isRequired,
        productsCount: PropTypes.number.isRequired,
        othersCount: PropTypes.number.isRequired,
        isLoading: PropTypes.bool.isRequired,
        isDisplayCms: PropTypes.bool.isRequired
    };

    static defaultProps = {
    };

    renderTab(title, cb, isActive, results = null) {
        const { isLoading } = this.props;

        const showStyle = isActive && !Object.is(results, null);

        return (
            <button
              block="KlevuSearchSwitch"
              elem="Tab"
              mods={ { isActive: showStyle } }
              onClick={ results ? cb : null }
            >
                <TextPlaceholder content={ !isLoading ? __(`%s ${title}`, results) : null } length="short" />
            </button>
        );
    }

    renderSwitch() {
        const {
            isDisplayProduct,
            handleSwitch,
            productsCount,
            othersCount,
            isDisplayCms
        } = this.props;

        if (!isDisplayCms) {
            return null;
        }

        return (
            <div>
                { this.renderTab('Products', () => handleSwitch(true), isDisplayProduct, productsCount) }
                { this.renderTab('Other results', () => handleSwitch(false), !isDisplayProduct, othersCount) }
            </div>
        );
    }

    render() {
        return (
            <div block="KlevuSearchSwitch">
                { this.renderSwitch() }
            </div>
        );
    }
}

export default KlevuSearchSwitchComponent;
