/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/klaviyo
 * @author  Jort Geurts <info@scandiweb.com>
 */

import { Field } from 'Util/Query';

/** @namespace Klaviyo/Plugin/KlaviyoLiveChat/Query/ConfigQueryPlugin */
export const getKlaviyoFields = () => {
    const KlaviyoField = new Field('klaviyo');
    KlaviyoField.addFieldList(
        [
            'klaviyo_enabled',
            'klaviyo_url',
            'klaviyo_key'
        ]
    );

    return KlaviyoField;
};

export const _getStoreConfigFields = (args, callback) => ([
    ...callback(...args),
    getKlaviyoFields()
]);

export default {
    'Query/Config/Query': {
        'member-function': {
            _getStoreConfigFields
        }
    }
};
