/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { getStore } from 'Util/Store';

/** ProductCard */
const ProductCard_registerSharedElement = (args, callback, instance) => {
    const {
        product: {
            id,
            name,
            url
        }
    } = instance.props;

    const {
        klevu: {
            klevu_api_key = ''
        }
    } = getStore().getState().ConfigReducer;

    if (!klevu_api_key) {
        return callback(...args);
    }

    const isSearch = window.location.href.includes('/search/');

    // Klevu statistics currently support only search results
    if (!isSearch) {
        return callback(...args);
    }

    const searchTermInUrl = window.location.pathname.replace(/^.*\/search\//, '');
    const clickReportUrl = `${'https://stats.ksearchnet.com/analytics/productTracking'
        + '?klevu_apiKey='}${ klevu_api_key }&klevu_type=clicked`
        + `&klevu_keywords=${ searchTermInUrl }&klevu_productId=${ id }`
        + `&klevu_productName=${ encodeURIComponent(name) }&klevu_productUrl=${ encodeURIComponent(url)}`;

    fetch(clickReportUrl);

    return callback(...args);
};

export default {
    'Component/ProductCard/Component': {
        'member-function': {
            registerSharedElement: ProductCard_registerSharedElement
        }
    }
};
