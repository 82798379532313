import {
    ProductCompareButtonComponent as PrinterbaseProductCompareButtonComponent
} from 'PrinterbaseComponent/ProductCompareButton/ProductCompareButton.component';

import './ProductCompareButton.override.second.style';

/** @namespace Scandipwa/Component/ProductCompareButton/Component */
export class ProductCompareButtonComponent extends PrinterbaseProductCompareButtonComponent {
    // TODO implement logic
}

export default ProductCompareButtonComponent;
