import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductCustomizableOptionContainer as SourceProductCustomizableOptionContainer
} from 'SourceComponent/ProductCustomizableOption/ProductCustomizableOption.container';
import { customizableOptionsToSelectTransform } from 'Util/Product/Transform';

import { CONFIG_FIELD_TYPE } from './ProductCustomizableOption.config';

/** @namespace Scandipwa/Component/ProductCustomizableOption/Container */
export class ProductCustomizableOptionContainer extends SourceProductCustomizableOptionContainer {
    getDropdownOptions() {
        const { options, currencyCode, type } = this.props;

        if (type !== CONFIG_FIELD_TYPE.select) {
            return null;
        }

        return customizableOptionsToSelectTransform(options, currencyCode);
    }

    containerProps() {
        const { showSubscriptionPopup } = this.props;

        return {
            showSubscriptionPopup,
            ...super.containerProps()
        };
    }
}

export default connect(sourceMapStateToProps, sourceMapDispatchToProps)(ProductCustomizableOptionContainer);
