/**
 * Klevu Search compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import CmsPageQuery from 'Query/CmsPage.query';
import ProductListQuery from 'Query/ProductList.query';
import { prepareQuery } from 'Util/Query';
import { executeGet, fetchQuery } from 'Util/Request';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

import {
    getSearchPageResults,
    KLEVU_SEARCH_CATEGORY_ID,
    KLEVU_SEARCH_PRODUCTS_ID,
    KLEVU_SEARCH_SUPPLIES_API,
    KLEVU_SEARCH_SUPPLIES_URL
} from '../component/KlevuSearch/KlevuSearch.config';
import CategoryQuery from '../query/Category.query';

export const CACHE = 2592000;

/** @namespace KlevuSearch/Util/Functions/getCmsPageQueryResult */
export const getCmsPageQueryResult = async (pages) => {
    const requests = pages.map(({ id }) => {
        const itemId = id.includes('pageid_') ? id.split('pageid_')[1] : null;
        const query = CmsPageQuery.getQuery({ id: itemId });
        return executeGet(prepareQuery(query), id, CACHE);
    });

    const results = await Promise.allSettled(requests);

    return results.filter(({ status }) => status === 'fulfilled')
        .map(({ value: { cmsPage } }) => cmsPage);
};

/** @namespace KlevuSearch/Util/Functions/formatKlevuResponse */
export const formatKlevuResponse = (res) => {
    const { queryResults } = res;

    // Get all records in an array
    // as we don't need cms pages on search
    const { records } = queryResults.find(({ id }) => id === KLEVU_SEARCH_PRODUCTS_ID);
    const { records: categories } = queryResults.find(({ id }) => id === KLEVU_SEARCH_CATEGORY_ID);

    const productIds = records.map(({ id }) => id);

    return {
        productIds,
        categories
    };
};

/** @namespace KlevuSearch/Util/Functions/formatKlevuCats */
export const formatKlevuCats = (res) => {
    const { queryResults, suggestionResults } = res;
    const { records } = queryResults[0];
    const { suggestions } = suggestionResults[0];

    return {
        categories: records.map((rec) => rec.id.split('categoryid_')[1]),
        suggestions
    };
};

/** @namespace KlevuSearch/Util/Functions/getProductsFromBE */
export const getProductsFromBE = async (ids, options) => {
    const { args, ...rest } = options;
    const klevuOptions = {
        ...rest,
        args: {
            ...args,
            currentPage: 1,
            filter: {
                customFilters: { id: ids }
            },
            search: ''
        }
    };

    const query = ProductListQuery.getQuery(klevuOptions);

    const { products: { items } } = await executeGet(prepareQuery(query), 'searchProd', ONE_MONTH_IN_SECONDS);

    // sorting the items array to fix the order by id comming from SPWA
    items.sort((a, b) => ids.indexOf(`${a.id}`) - ids.indexOf(`${b.id}`));

    return items;
};

/** @namespace KlevuSearch/Util/Functions/getFilteredProductsFromBE */
export const getFilteredProductsFromBE = async (ids, options) => {
    const { args, ...rest } = options;
    const { filter: { customFilters }, filter } = args;
    const newCustomFilters = { ...customFilters, id: ids };
    const klevuOptions = {
        ...rest,
        args: {
            ...args,
            filter: { ...filter, customFilters: newCustomFilters },
            search: ''
        }
    };

    const query = ProductListQuery.getQuery(klevuOptions);
    const resp = await fetchQuery(query);
    const { products: { items, total_count, page_info: { current_page, total_pages } } } = resp;

    return {
        items, total_count, current_page, total_pages
    };
};

/** @namespace KlevuSearch/Util/Functions/getKlevuResults */
export const getKlevuResults = async (criteria,
    api_key,
    options = {
        sort: {}, offset: 0, url: null, limit: 24
    },
    setSearchPageProductIds,
    setSearchPageCategories,
    needNewRequest) => {
    // if we need the next page, then we have already requested data
    if (!needNewRequest) {
        return {};
    }

    const {
        sort, offset, url
    } = options;

    const res = await fetch(`https://${url}/cs/v2/search`, {
        method: 'POST',
        body: JSON.stringify(getSearchPageResults(api_key, criteria, { sort, offset }))
    });

    const resFmt = await res.json();

    const {
        productIds,
        categories
    } = formatKlevuResponse(resFmt);

    setSearchPageProductIds(productIds);
    setSearchPageCategories(categories);

    return {
        productIds,
        categories
    };
};

/** @namespace KlevuSearch/Util/Functions/fetchCategoriesInOrder */
export const fetchCategoriesInOrder = (ids) => {
    const promiseArr = ids.map((id) => fetchQuery(CategoryQuery.getQuery([id])));

    return Promise.allSettled(promiseArr);
};

/** @namespace KlevuSearch/Util/Functions/getSuppliersSearchApiKey */
export const getSuppliersSearchApiKey = () => KLEVU_SEARCH_SUPPLIES_API;

/** @namespace KlevuSearch/Util/Functions/getSuppliersSearchURL */
export const getSuppliersSearchURL = () => KLEVU_SEARCH_SUPPLIES_URL;

/** @namespace KlevuSearch/Util/Functions/getKlevuFiltersFromBE */
export const getKlevuFiltersFromBE = async (ids, options) => {
    const { args } = options;

    const {
        filter, filter: { customFilters }, ...restArgs
    } = args;

    const newFilter = { ...filter, customFilters: { ...customFilters, id: ids } };

    const newOptions = {
        ...options,
        args: { ...restArgs, filter: newFilter }
    };

    const query = ProductListQuery.getQuery({ ...newOptions, requireInfo: true });
    const resp = await fetchQuery(query);
    return resp;
};
