/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export const MAX_TRY_COUNT = 10;
export const TRY_INTERVAL_MS = 150;

/** @namespace Scandipwa/Util/Wait/waitForCallback */
export const waitForCallback = async (callback, n = 0) => {
    if (n === MAX_TRY_COUNT) {
        return false;
    }

    const val = callback();

    if (val) {
        return true;
    }

    await new Promise((res) => setTimeout(res, TRY_INTERVAL_MS));

    return waitForCallback(callback, n + 1);
};

export default waitForCallback;
