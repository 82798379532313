/* eslint-disable max-lines */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ('OSL') v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    CATEGORY_ITEM_COUNT,
    CMS_ITEM_COUNT,
    PRODUCT_LIST_COUNT,
    SUGGESTION_ITEM_COUNT
} from '../KlevuAutocomplete/KlevuAutocomplete.config';

export const KLEVU_SEARCH_SUPPLIES_API = 'klevu-165243176390915183';
export const KLEVU_SEARCH_SUPPLIES_URL = 'eucs30v2.ksearchnet.com';

export const KLEVU_SEARCH_OVERLAY_ID = 'kelvu_search';
export const KLEVU_SEARCH_SUPPLIES_OVERLAY_ID = 'kelvu_search_supplies';

export const KLEVU_SEARCH_CATEGORY_ID = 'categoryCompressed';
export const KLEVU_SEARCH_PRODUCTS_ID = 'productList';
export const KELVU_SEARCH_SUGGESTIONS = 'autosuggestion';
export const KLEVU_SEARCH_CMS_ID = 'cmsCompressed';

export const KLEVU_GET_ALL_PRODUCT_ID_LIMIT = 5000;

export const KLEVU_SORT_RELEVANCE = 'RELEVANCE';
export const KLEVU_SORT_PRICE_ASC = 'PRICE_ASC';
export const KLEVU_SORT_PRICE_DESC = 'PRICE_DESC';
export const KLEVU_SORT_NAME_ASC = 'NAME_ASC';
export const KLEVU_SORT_NAME_DESC = 'NAME_DESC';

export const KLEVU_CATEGORIES_POPUP_LIMIT = 10;
export const KLEVU_SEARCH_FREQUENCY = 200;

export const KLEVU_SEARCH_LIMIT = 24;

/** @namespace KlevuSearch/Component/KlevuSearch/Config/getDefaultSettingFields */
export const getDefaultSettingFields = () => [
    'id',
    'name',
    'shortDesc',
    'url',
    'typeOfRecord',
    'image',
    'categoryPageTitle'
];

/** @namespace KlevuSearch/Component/KlevuSearch/Config/getCategoryFields */
export const getCategoryFields = (searchCriteria, limit = CATEGORY_ITEM_COUNT) => ({
    id: 'categoryCompressed',
    settings: {
        fields: getDefaultSettingFields(),
        limit,
        query: {
            term: searchCriteria
        },
        searchPrefs: [
            'searchCompoundsAsAndQuery'
        ],
        sort: 'RELEVANCE',
        typeOfRecords: [
            'KLEVU_CATEGORY'
        ]
    },
    typeOfRequest: 'SEARCH'
});

/** @namespace KlevuSearch/Component/KlevuSearch/Config/getCmsFields */
export const getCmsFields = (searchCriteria, limit = CMS_ITEM_COUNT) => ({
    id: 'cmsCompressed',
    settings: {
        fields: getDefaultSettingFields(),
        limit,
        query: {
            term: searchCriteria
        },
        searchPrefs: [
            'searchCompoundsAsAndQuery'
        ],
        sort: 'RELEVANCE',
        typeOfRecords: [
            'KLEVU_CMS'
        ]
    },
    typeOfRequest: 'SEARCH'
});

/** @namespace KlevuSearch/Component/KlevuSearch/Config/getProductListFields */
export const getProductListFields = (searchCriteria, limit = PRODUCT_LIST_COUNT) => ({
    id: 'productList',
    settings: {
        query: {
            term: searchCriteria
        },
        typeOfRecords: ['KLEVU_PRODUCT'],
        limit,
        fallbackQueryId: 'productListFallback',
        searchPrefs: ['searchCompoundsAsAndQuery'],
        sort: 'RELEVANCE'
    },
    typeOfRequest: 'SEARCH'
});

/** @namespace KlevuSearch/Component/KlevuSearch/Config/getSuggestionsFields */
export const getSuggestionsFields = (searchCriteria) => ([{
    id: 'autosuggestion',
    limit: SUGGESTION_ITEM_COUNT,
    query: searchCriteria,
    typeOfRequest: 'AUTO_SUGGESTIONS'
}]);

/** @namespace KlevuSearch/Component/KlevuSearch/Config/getRequestBody */
export const getRequestBody = (
    klevu_api_key = null, searchCriteria = '',
    options = {
        limit: KLEVU_SEARCH_LIMIT,
        categories: true
    }
) => {
    const { limit, categories } = options;

    if (categories) {
        return {
            context: {
                apiKeys: [
                    klevu_api_key
                ]
            },
            recordQueries: [
                getCategoryFields(searchCriteria, KLEVU_CATEGORIES_POPUP_LIMIT), // in main popup we don't need a large amount of categories
                getCmsFields(searchCriteria, limit),
                getProductListFields(searchCriteria, limit)
            ],
            suggestions: getSuggestionsFields(searchCriteria)
        };
    }

    return {
        context: {
            apiKeys: [
                klevu_api_key
            ]
        },
        recordQueries: [
            getCmsFields(searchCriteria, limit),
            getProductListFields(searchCriteria, limit)
        ],
        suggestions: getSuggestionsFields(searchCriteria)
    };
};

/** @namespace KlevuSearch/Component/KlevuSearch/Config/getSuppliersRequestBody */
export const getSuppliersRequestBody = (
    klevu_api_key = null, searchCriteria = '',
    options = {
        limit: KLEVU_SEARCH_LIMIT
    }
) => {
    const { limit } = options;

    return {
        context: {
            apiKeys: [
                klevu_api_key
            ]
        },
        recordQueries: [
            getProductListFields(searchCriteria, limit)
        ]
    };
};

/** @namespace KlevuSearch/Component/KlevuSearch/Config/getCategoriesRequestBody */
export const getCategoriesRequestBody = (
    klevu_api_key = null, searchCriteria = '',
    options = {
        limit: KLEVU_SEARCH_LIMIT
    }
) => {
    const { limit } = options;

    return {
        context: {
            apiKeys: [
                klevu_api_key
            ]
        },
        recordQueries: [
            getCategoryFields(searchCriteria, limit)
        ],
        suggestions: getSuggestionsFields(searchCriteria)
    };
};

/** @namespace KlevuSearch/Component/KlevuSearch/Config/getSearchPageFields */
export const getSearchPageFields = (fields = []) => (
    [
        'id',
        'name',
        'url',
        ...fields
    ]
);

/** @namespace KlevuSearch/Component/KlevuSearch/Config/getCmsSearchRecords */
export const getCmsSearchRecords = (searchCriteria, offset, sort, limit = KLEVU_SEARCH_LIMIT) => ({
    id: 'cmsCompressed',
    settings: {
        limit,
        offset,
        query: {
            term: searchCriteria
        },
        searchPrefs: [
            'searchCompoundsAsAndQuery'
        ],
        sort,
        typeOfRecords: [
            'KLEVU_CMS'
        ],
        fields: getSearchPageFields()
    },
    typeOfRequest: 'SEARCH'
});

/** @namespace KlevuSearch/Component/KlevuSearch/Config/getCategorySearchRecords */
export const getCategorySearchRecords = (searchCriteria, offset, sort, limit = CATEGORY_ITEM_COUNT) => ({
    id: 'categoryCompressed',
    settings: {
        limit,
        offset,
        query: {
            term: searchCriteria
        },
        searchPrefs: [
            'searchCompoundsAsAndQuery'
        ],
        sort: 'RELEVANCE',
        typeOfRecords: [
            'KLEVU_CATEGORY'
        ],
        fields: getSearchPageFields(['categoryPageTitle'])
    },
    typeOfRequest: 'SEARCH'
});

/** @namespace KlevuSearch/Component/KlevuSearch/Config/getProductSearchRecords */
export const getProductSearchRecords = (searchCriteria, offset, sort, limit = KLEVU_SEARCH_LIMIT) => ({
    id: 'productList',
    settings: {
        query: {
            term: searchCriteria
        },
        typeOfRecords: ['KLEVU_PRODUCT'],
        limit,
        offset,
        fallbackQueryId: 'productListFallback',
        searchPrefs: ['searchCompoundsAsAndQuery'],
        sort,
        fields: ['id']
    },
    typeOfRequest: 'SEARCH'
});

/** @namespace KlevuSearch/Component/KlevuSearch/Config/resolveKlevuSort */
export const resolveKlevuSort = (sort) => {
    if (!sort || (Object.keys(sort).length < 2)) {
        return KLEVU_SORT_RELEVANCE;
    }
    const { sortDirection, sortKey } = sort;
    switch (sortKey) {
    case 'name':
        return sortDirection === 'ASC' ? KLEVU_SORT_NAME_ASC : KLEVU_SORT_NAME_DESC;
    case 'price':
        return sortDirection === 'ASC' ? KLEVU_SORT_PRICE_ASC : KLEVU_SORT_PRICE_DESC;
    default:
        return KLEVU_SORT_RELEVANCE;
    }
};

/** @namespace KlevuSearch/Component/KlevuSearch/Config/getSearchPageResults */
export const getSearchPageResults = (
    klevu_api_key = null,
    searchCriteria = '',
    options = {
        offset: 0,
        sort: {},
        categories: false
    }
) => {
    const {
        offset, sort, categories
    } = options;

    const sortMethod = resolveKlevuSort(sort);

    const records = [
        getProductSearchRecords(searchCriteria, 0, sortMethod, KLEVU_GET_ALL_PRODUCT_ID_LIMIT),
        getCategorySearchRecords(searchCriteria, offset, sortMethod, KLEVU_GET_ALL_PRODUCT_ID_LIMIT)
    ];

    return {
        context: {
            apiKeys: [
                klevu_api_key
            ]
        },
        recordQueries: Array.from(records.filter((recT, i) => (!(!categories && i === 2))))
    };
};

/** @namespace KlevuSearch/Component/KlevuSearch/Config/getKlevuProducts */
export const getKlevuProducts = (limit, offset, sort) => ({
    id: 'productList',
    settings: {
        limit,
        offset,
        sort,
        typeOfRecords: ['KLEVU_PRODUCT'],
        searchPrefs: ['searchCompoundsAsAndQuery'],
        fallbackQueryId: 'productListFallback'
    },
    typeOfRequest: 'SEARCH'
});

/** @namespace KlevuSearch/Component/KlevuSearch/Config/klevuFilter */
export const klevuFilter = ({ key, values, singleSelect = true }) => ({
    filters: {
        applyFilters: {
            filters: [
                {
                    key,
                    values: Array.from(values),
                    settings: {
                        singleSelect
                    }
                }
            ]
        }
    }
});

/** @namespace KlevuSearch/Component/KlevuSearch/Config/getKlevuCategoryProducts */
export const getKlevuCategoryProducts = (
    klevu_api_key = null,
    category,
    options = {
        offset: 0,
        sort: {},
        limit: KLEVU_SEARCH_LIMIT
    }
) => {
    const { offset, sort, limit } = options;

    const sortMethod = resolveKlevuSort(sort);

    const records = [
        klevuFilter({ key: 'category', values: [category] }),
        getKlevuProducts(limit, offset, sortMethod)
    ];

    return {
        context: {
            apiKeys: [
                klevu_api_key
            ]
        },
        recordQueries: records
    };
};

/** @namespace KlevuSearch/Component/KlevuSearch/Config/klevuCategoryRecords */
export const klevuCategoryRecords = () => ({
    id: 'categoryCompressed',
    settings: {
        searchPrefs: [
            'searchCompoundsAsAndQuery'
        ],
        sort: 'RELEVANCE',
        typeOfRecords: [
            'KLEVU_CATEGORY'
        ],
        fields: getSearchPageFields()
    },
    typeOfRequest: 'SEARCH'
});

/** @namespace KlevuSearch/Component/KlevuSearch/Config/getKlevuCategory */
export const getKlevuCategory = (api_key = null, url = null) => ({
    context: {
        apiKeys: [
            api_key
        ]
    },
    recordQueries: [
        klevuFilter({ key: 'url', values: [url] }),
        klevuCategoryRecords()
    ]
});
