/**
 * Wyomind google customer reviews compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const UPDATE_DELIVERY_COUNTRY = 'UPDATE_DELIVERY_COUNTRY';

/** @namespace Scandiweb/WyomindGoogleCustomerReviews/Store/Config/OrderConfig/Action/updateDeliveryCountry */
export const updateDeliveryCountry = (countryId) => ({
    type: UPDATE_DELIVERY_COUNTRY,
    countryId
});
