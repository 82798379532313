/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './Top5lead.style';

/** @namespace Scandipwa/Component/Top5lead/Component */
export class Top5leadComponent extends PureComponent {
    static propTypes = {
        lead: PropTypes.string
    };

    render() {
        const {
            lead
        } = this.props;

        return (
            <div block="Top5lead">
                { lead }
            </div>
        );
    }
}

export default Top5leadComponent;
