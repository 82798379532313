import { AmazonPayReducer } from '../store/AmazonPay/AmazonPay.reducer';

const getStaticReducers = (args, callback) => ({
    ...callback(...args),
    AmazonPayReducer
});

export const config = {
    'Store/Index/getStaticReducers': {
        function: getStaticReducers
    }
};

export default config;
