/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CopyIcon from 'Component/CopyIcon';

import './ConsumablesPromoCopyButton.style';

/** @namespace Scandipwa/Component/ConsumablesPromoCopyButton/Component */
export class ConsumablesPromoCopyButtonComponent extends PureComponent {
    static propTypes = {
        children: PropTypes.array.isRequired,
        copyCodeToClipboard: PropTypes.func.isRequired,
        isCopied: PropTypes.bool.isRequired
    };

    render() {
        const { children, copyCodeToClipboard, isCopied } = this.props;

        return (
            <div block="ConsumablesPromoCopyButton">
                <button
                  block="ConsumablesPromoCopyButton"
                  elem="Button"
                  mods={ { isCopied } }
                  onClick={ copyCodeToClipboard }
                >
                    <CopyIcon />
                </button>
                <div
                  block="ConsumablesPromoCopyButton"
                  elem="ButtonText"
                >
                    { children.map((child) => child.data.trim()) }
                </div>
            </div>
        );
    }
}

export default ConsumablesPromoCopyButtonComponent;
