import KlevuSearchSwitch from '@scandipwa/klevu-search/src/component/KlevuSearchSwitch';

import ContentWrapper from 'Component/ContentWrapper';

export const renderMiscellaneous = (args, callback) => (
        <ContentWrapper>
            <KlevuSearchSwitch />
            { callback(...args) }
        </ContentWrapper>
);

export default {
    'Route/SearchPage/Component': {
        'member-function': {
            renderMiscellaneous
        }
    }
};
