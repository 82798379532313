import { showNotification } from 'Store/Notification/Notification.action';
import { QueryDispatcher } from 'Util/Request';

import AttachmentsConfigQuery from '../../query/AttachmentsConfig.query';
import { updateAttachmentsConfig } from './Attachments.action';

/** @namespace MageworxAttachment/Store/Attachments/AttachmentsConfig/Dispatcher */
export class AttachmentsConfigDispatcher extends QueryDispatcher {
    __construct() {
        super.__construct('AttachmentsConfig');
    }

    onSuccess({ config }, dispatch) {
        dispatch(updateAttachmentsConfig(config));
    }

    onError(error, dispatch) {
        dispatch(showNotification('error', __('Error fetching attachments config!'), error));
    }

    prepareRequest() {
        return AttachmentsConfigQuery.getQuery();
    }
}

export default new (AttachmentsConfigDispatcher)();
