/* eslint-disable max-len */
import { lazy, Suspense } from 'react';

import ClickOutside from 'Component/ClickOutside';
import SearchIcon from 'Component/SearchIcon';

import { SearchFieldComponent } from '../SearchField/SearchField.component';

export const SearchOverlay = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "overlay" */'Component/SearchOverlay'));

/** @namespace Scandipwa/Component/InkTonerSearchField/Component */
export class InkTonerSearchFieldComponent extends SearchFieldComponent {
    /**
     * Render Search bar for ink toner page
     */
    renderInkTonerSearch() {
        const {
            searchCriteria,
            onSearchBarFocus,
            isActive,
            device: {
                isMobile
            } = {}
        } = this.props;

        return (
            <div
              block="SearchField"
              elem="SearchInnerWrapper"
            >
                <SearchIcon />
                <input
                  ref={ this.searchBarRef }
                  block="SearchField"
                  elem="Input"
                  onFocus={ onSearchBarFocus }
                  onChange={ this.handleChange }
                  onKeyDown={ this.onSearchEnterPress }
                  value={ searchCriteria }
                  mods={ { isActive } }
                  placeholder={ __('Search by Printer or Keyword...') }
                  autoComplete="off"
                  aria-label={ __('Search') }
                />
                { this.renderIcon() }
                <Suspense fallback={ this.renderOverlayFallback() }>
                    <SearchOverlay
                      isHideOverlay={ !isMobile }
                      clearSearch={ this.clearSearch }
                      searchCriteria={ searchCriteria }
                    />
                </Suspense>
            </div>
        );
    }

    /**
     * Render text and icon
     */
    renderIcon() {
        const {
            device: {
                isMobile
            } = {}
        } = this.props;

        if (isMobile) {
            return (
                <button
                  block="SearchField"
                  elem="SearchIcon"
                  tabIndex="0"
                  onClick={ this.onSearchButtonPress }
                  aria-label={ __('Search') }
                >
                    <SearchIcon />
                </button>
            );
        }

        return (
            <button
              block="SearchField"
              elem="SearchText"
              onClick={ this.onSearchButtonPress }
            >
                { __('Search') }
            </button>
        );
    }

    /**
     * Overridden to render ink toner search bar
     */
    render() {
        const {
            isVisible,
            isActive
        } = this.props;

        return (
            <div block="SearchField" mods={ { isVisible, isActive } }>
                <ClickOutside onClick={ this.closeSearch }>
                    <div block="SearchField" elem="Wrapper">
                        { this.renderInkTonerSearch() }
                    </div>
                </ClickOutside>
            </div>
        );
    }
}

export default InkTonerSearchFieldComponent;
