import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Link from 'Component/Link';

import { klevuSearchPageCategoryType } from '../../types/KlevuSearch';

import './KlevuSearchPageCategories.style';

/** @namespace KlevuSearch/Component/KlevuSearchPageCategories/Component */
export class KlevuSearchPageCategoriesComponent extends PureComponent {
    static propTypes = {
        categories: PropTypes.arrayOf(klevuSearchPageCategoryType).isRequired,
        onShowMoreClick: PropTypes.func.isRequired,
        displayShowMore: PropTypes.func.isRequired,
        categoriesCountToDisplay: PropTypes.number.isRequired
    };

    renderNoCategoriesResult = () => (
        <div block="KlevuSearchPageCategories" elem="NoResult" />
    );

    renderShowMore = () => {
        const {
            displayShowMore,
            onShowMoreClick,
            categoriesCountToDisplay
        } = this.props;

        if (!displayShowMore()) {
            return;
        }

        return (
            <div block="KlevuSearchPageCategories" elem="ShowWrap">
                <button
                  block="Button"
                  mods={ { likeLink: true } }
                  mix={ { block: 'KlevuSearchPageCategories', elem: 'ShowMore' } }
                  onClick={ onShowMoreClick }
                >
                    { __('Show more (%s categor%s)', categoriesCountToDisplay, categoriesCountToDisplay == 1 ? 'y' : 'ies') }
                </button>
            </div>
        );
    };

    renderCategories = () => {
        const { categories } = this.props;

        if (!categories.length) {
            return this.renderNoCategoriesResult();
        }

        const categoriesList = categories.map(({
            name, id, url, categoryPageTitle = ''
        }) => {
            const categoryText = categoryPageTitle === '' ? name : categoryPageTitle;

            return (
                <div block="KlevuSearchPageCategories" elem="ListItem" key={ id }>
                    <Link
                      to={ url }
                      block="KlevuSearchPageCategories"
                      elem="Link"
                    >
                        { categoryText }
                    </Link>
                </div>
            );
        });

        return (
            <>
                <div block="KlevuSearchPageCategories" elem="CategoriesList">
                    { categoriesList }
                </div>
                { this.renderShowMore() }

            </>
        );
    };

    render() {
        const { categories } = this.props;

        if (categories.length === 0) {
            return this.renderNoCategoriesResult();
        }

        return (
            <section block="KlevuSearchPageCategories" elem="Wrap">
                <div block="KlevuSearchPageCategories" elem="Title">
                    { __('Categories:') }
                </div>
                <div block="KlevuSearchPageCategories" elem="ListWrapper">
                    { this.renderCategories() }
                </div>
            </section>
        );
    }
}

export default KlevuSearchPageCategoriesComponent;
