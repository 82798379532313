import {
    MyAccountAddressTableComponent as SourceMyAccountAddressTable
} from 'SourceComponent/MyAccountAddressTable/MyAccountAddressTable.component';

/** @namespace Scandipwa/Component/MyAccountAddressTable/Component */
export class MyAccountAddressTableComponent extends SourceMyAccountAddressTable {
// eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    get dataPairArray() {
        const {
            address,
            getFormatedRegion,
            showAdditionalFields,
            customer
        } = this.props;
        const regionData = getFormatedRegion(address);

        const additionalFields = [
            {
                key: 'region',
                label: __('State/Province'),
                source: regionData
            },
            {
                key: 'city',
                label: __('City'),
                source: address
            }
        ];

        return [
            {
                key: 'firstname',
                label: __('First name'),
                source: address
            },
            {
                key: 'lastname',
                label: __('Last name'),
                source: address
            },
            {
                key: 'email',
                label: __('Email address'),
                source: customer
            },
            {
                key: 'telephone',
                label: __('Telephone'),
                source: address
            },
            {
                key: 'country',
                label: __('Country'),
                source: regionData
            },
            {
                key: 'company',
                label: __('Company'),
                source: address
            },
            {
                key: 'street',
                label: __('Address'),
                source: address
            },
            ...(showAdditionalFields ? additionalFields : []),
            {
                key: 'postcode',
                label: __('Post code'),
                source: address
            }
        ];
    }
}

export default MyAccountAddressTableComponent;
