import { KLEVU_UPDATE_CURRENT_PAGE, KLEVU_UPDATE_PAGE_COUNT } from '../store/ProductList.action';

export const ProductListReducer = (args, callback) => {
    const [state, action] = args;

    const {
        type
    } = action;

    switch (type) {
    case KLEVU_UPDATE_PAGE_COUNT:
        const { total_pages: totalPages } = action;

        return {
            ...state,
            totalPages
        };
    case KLEVU_UPDATE_CURRENT_PAGE:
        const { currentPage, args: currentArgs } = action;

        return {
            ...state,
            currentArgs: { ...currentArgs, currentPage }
        };
    default:
        return callback(...args);
    }
};

export default {
    'Store/ProductList/Reducer/ProductListReducer': {
        function: ProductListReducer
    }
};
