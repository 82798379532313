/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import { createPortal } from 'react-dom';

import { RELATED_POP_UP } from 'Component/AddToBasket/AddToBasket.config';
import ClickOutside from 'Component/ClickOutside';
import CloseIcon from 'Component/CloseIcon';
import FacebookIcon from 'Component/FacebookIcon';
import GoogleIcon from 'Component/GoogleIcon';
import {
    PopupComponent as SourcePopupComponent
} from 'SourceComponent/Popup/Popup.component';
import { stopPropagation } from 'Util/Event';
import media from 'Util/Media';
import { LOGO_MEDIA } from 'Util/Media/Media';

import {
    LOGIN_POPUP_ID,
    MYACCOUNTADDRESSPOPUPID,
    SEARCH_MOBILE_POPUP,
    WRITE_REVIEW_POPUP_ID
} from './Popup.config';

import './Popup.style.override';

/** @namespace Scandipwa/Component/Popup/Component */
export class PopupComponent extends SourcePopupComponent {
    __construct(props) {
        super.__construct(props);

        this.state = {
            isVisible: false
        };
    }

    /**
     * created to modify the popup close button for add to basket popup
     */
    renderBasketCloseButton() {
        return (
            <button
              block="Popup"
              elem="CloseBtn"
              mix={ { block: 'AddToBasket', elem: 'CloseBtn' } }
              aria-label={ __('Close') }
              onClick={ this.hidePopupAndSlideBack }
            >
                <CloseIcon />
            </button>
        );
    }

    hidePopupAndSlideBack = () => {
        const TIME_TO_CLOSE = 500;

        document.getElementById(RELATED_POP_UP).classList.remove('Popup_isVisible');

        setTimeout(() => {
            this.hidePopupAndGoBack();
        }, TIME_TO_CLOSE);
    };

    /**
     * Overriden to add Socials buttons
     * Overriden to remove clickoutside for MyAccountAddressPopup
     */
    renderContent() {
        const {
            children, contentMix, noHeader, id, isMobile
        } = this.props;
        const isVisible = this.getIsVisible();

        if (!isVisible) {
            return null;
        }

        const { block } = contentMix;

        if (isMobile && id === WRITE_REVIEW_POPUP_ID) {
            return (
                <ClickOutside onClick={ this.handleClickOutside }>
                    <div block="Popup" elem="Content" mix={ contentMix }>
                        { !noHeader
                        && (
                            <header block="Popup" elem="Header">
                                { this.renderTitle() }
                                { this.renderCloseButton() }
                            </header>
                        ) }
                        { this.renderSignInWithSocials() }
                        { children }
                    </div>
                </ClickOutside>
            );
        }

        if (isMobile && (block === 'AddToCart')) {
            return (
                <ClickOutside onClick={ this.handleClickOutside }>
                    <div block="Popup" elem="Content" mix={ contentMix }>
                        { !noHeader
                        && (
                        <header block="Popup" elem="Header">
                            { this.renderTitle() }
                            { this.renderCloseButton() }
                        </header>
                        ) }
                        { this.renderSignInWithSocials() }
                        { children }
                    </div>
                </ClickOutside>
            );
        }

        if (id === MYACCOUNTADDRESSPOPUPID) {
            return (
                <div block="Popup" elem="Content" mix={ contentMix }>
                    { !noHeader
                    && (
                    <header block="Popup" elem="Header">
                        { this.renderTitle() }
                        { this.renderCloseButton() }
                    </header>
                    ) }
                    { this.renderSignInWithSocials() }
                    { this.renderNotifications() }
                    { children }
                </div>
            );
        }

        if (isMobile && id === SEARCH_MOBILE_POPUP) {
            return (
                <ClickOutside onClick={ this.handleClickOutside }>
                    <div block="Popup" elem="Content" mix={ contentMix }>
                        <div>
                            { !noHeader
                            && (
                            <header block="Popup" elem="Header">
                                { this.renderTitle() }
                                { this.renderCloseButton() }
                            </header>
                            ) }
                            { this.renderSignInWithSocials() }
                            { this.renderNotifications() }
                            { children }
                        </div>
                    </div>
                </ClickOutside>
            );
        }

        if (block === 'AddToBasket') {
            return (
                <div block="Popup" elem="Content" mix={ contentMix }>
                    <header block="Popup" elem="Header">
                        { this.renderBasketCloseButton() }
                    </header>
                    { this.renderSignInWithSocials() }
                    { children }
                </div>
            );
        }

        return (
            <ClickOutside onClick={ this.handleClickOutside }>
                <div block="Popup" elem="Content" mix={ contentMix }>
                    { !noHeader
                    && (
                    <header block="Popup" elem="Header">
                        { this.renderTitle() }
                        { this.renderCloseButton() }
                    </header>
                    ) }
                    { this.renderSignInWithSocials() }
                    { this.renderNotifications() }
                    { children }
                </div>
            </ClickOutside>
        );
    }

    /**
     * Overridden to hide popup before changing page
     */
    componentWillUnmount() {
        super.componentWillUnmount();
        this.hidePopUp();
    }

    componentDidUpdate(prevProps) {
        const { shouldPopupClose, resetHideActivePopup } = this.props;
        const { shouldPopupClose: prevShouldPopupClose } = prevProps;

        const { isVisible } = this.state;

        // Only update the state if visibility has actually changed
        if (isVisible !== this.getIsVisible()) {
            this.setState({ isVisible: this.getIsVisible() });
        }

        const prevWasVisible = this.getIsVisible(prevProps);
        const isCurrentlyVisible = this.getIsVisible();

        if (isCurrentlyVisible && !prevWasVisible) {
            this.onVisible();
        } else if (!isCurrentlyVisible && prevWasVisible) {
            this.onHide();
        }

        if (shouldPopupClose && shouldPopupClose !== prevShouldPopupClose) {
            this.hidePopUp();
            resetHideActivePopup();
        }

        super.componentDidUpdate(prevProps);
    }

    renderLogoImage() {
        const {
            header_logo_src
        } = this.props;

        const logoSrc = header_logo_src ? media(header_logo_src, LOGO_MEDIA) : null;

        return <img block="Popup" elem="OrderLogoImage" src={ logoSrc } alt="logo" />;
    }

    renderTitle() {
        const { id } = this.props;

        if (id === 'MyAccountOrderPopup') {
            return this.renderLogoImage();
        }

        return super.renderTitle();
    }

    onHide() {
        super.onHide();
        this.setState({ isVisible: false });
    }

    renderSignInWithSocials() {
        const { id } = this.props;

        if (id === LOGIN_POPUP_ID) {
            return (
                <div block="Popup" elem="SignInWithSocialsWrapper">
                    <div block="Popup" elem="SocialsTitle">
                        { __('Why not sign in with:') }
                    </div>
                    { this.renderSocialLoginButtons() }
                </div>
            );
        }

        return null;
    }

    /**
     * Render buttons
     */
    renderSocialLoginButtons() {
        return (
            <div block="Popup" elem="Buttons">
                <button block="Popup" elem="Social" mods={ { isGoogle: true } }>
                    <GoogleIcon />
                    Google
                </button>
                <button block="Popup" elem="Social" mods={ { isFacebook: true } }>
                    <FacebookIcon />
                    Facebook
                </button>
            </div>
        );
    }

    /**
     * Overridden to add onClick and onKeyDown handlers to stop the event from
     * propagating to the parent component
     */
    render() {
        const { mix, areOtherOverlaysOpen, id } = this.props;
        const { isVisible } = this.state;

        return createPortal(
            <div
              ref={ this.overlayRef }
              block="Popup"
              id={ id }
              mods={ { isVisible, isInstant: areOtherOverlaysOpen } }
              mix={ { ...mix, mods: { ...mix.mods, isVisible } } }
              onClick={ stopPropagation }
              onKeyDown={ stopPropagation }
              role="none"
            >
                { this.renderContent() }
            </div>,
            document.body
        );
    }
}

export default PopupComponent;
