/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ConsumablesPromoCopyButton from './ConsumablesPromoCopyButton.component';

/** @namespace Scandipwa/Component/ConsumablesPromoCopyButton/Container */
export class ConsumablesPromoCopyButtonContainer extends PureComponent {
    static propTypes = {
        children: PropTypes.array
    };

    static defaultProps = {
        children: []
    };

    state = {
        isCopied: false
    };

    containerFunctions = {
        copyCodeToClipboard: this.copyCodeToClipboard.bind(this)
    };

    copyCodeToClipboard() {
        const { innerHTML } = document.querySelector('#discount-code-text');
        navigator.clipboard.writeText(innerHTML);
        this.setState({ isCopied: true });
    }

    containerProps() {
        const { children } = this.props;
        const { isCopied } = this.state;

        return {
            children,
            isCopied
        };
    }

    render() {
        return (
            <ConsumablesPromoCopyButton
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default ConsumablesPromoCopyButtonContainer;
