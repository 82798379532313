/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { isSignedIn } from 'Util/Auth';
import { appendWithStoreCode } from 'Util/Url';

import { AMAZON_PAY_BILLING_STEP } from '../route/Checkout/Checkout.config';
import { getOrderFromUrl, isAmazonCheckout } from '../util/AmazonPay.util';

const AmazonPayDispatcher = import('../store/AmazonPay/AmazonPay.dispatcher');

export const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;
    const dispatchProps = { ...callback(...args) };

    return {
        ...dispatchProps,
        fetchAmazonPayCheckoutSession:
            () => AmazonPayDispatcher.then(
                ({ default: dispatcher }) => dispatcher.fetchAmazonPayCheckoutSession(dispatch)
            ),
        fetchShippingEstimate:
            (shippingAddress) => AmazonPayDispatcher.then(
                ({ default: dispatcher }) => dispatcher.fetchShippingEstimate(
                    dispatch,
                    shippingAddress
                )
            ),
        saveAddressInformation:
            (addressData) => AmazonPayDispatcher.then(
                ({ default: dispatcher }) => dispatcher.saveAddressInformation(dispatch, addressData)
            )
    };
};

const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        amazonCheckoutSessionConfig: state.AmazonPayReducer.checkoutSessionConfig,
        selectedCarrier: state.AmazonPayReducer.shippingEstimate.selectedCarrier
    };
};

const componentDidMount = (args, callback, instance) => {
    const orderId = getOrderFromUrl(instance);

    if (orderId) {
        instance.setDetailsStep(orderId);
        return null;
    }

    if (!isAmazonCheckout()) {
        return callback(...args);
    }

    const {
        fetchAmazonPayCheckoutSession,
        fetchShippingEstimate,
        history,
        guest_checkout,
        updateMeta,
        saveAddressInformation
    } = instance.props;

    fetchAmazonPayCheckoutSession().then(({
        shippingAddress: amazonShippingAddress,
        billingAddress: amazonBillingAddress
    }) => {
        fetchShippingEstimate(amazonShippingAddress).then((result) => {
            const initialAddressData = {
                shipping_address: amazonShippingAddress,
                billing_address: amazonBillingAddress,
                shipping_carrier_code: result[0].carrier_code,
                shipping_method_code: result[0].method_code
            };

            saveAddressInformation(initialAddressData);
        });
    });

    // if guest checkout is disabled and user is not logged in => throw him to homepage
    if (!guest_checkout && !isSignedIn()) {
        history.push(appendWithStoreCode('/'));
    }

    updateMeta({ title: __('Checkout') });
    instance._getPaymentMethods();
};

const __construct = (args, callback, instance) => {
    callback(...args);

    if (!isAmazonCheckout()) {
        return;
    }

    instance.state.checkoutStep = AMAZON_PAY_BILLING_STEP;
};

// disabled because saving in CheckoutBilling.container
const disableCheckoutSaveAddress = (args, callback) => {
    if (!isAmazonCheckout()) {
        return callback(...args);
    }

    return null;
};

export default {
    'Route/Checkout/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Route/Checkout/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Route/Checkout/Container': {
        'member-function': {
            __construct,
            componentDidMount,
            saveAddressInformation: disableCheckoutSaveAddress
        }
    }
};
