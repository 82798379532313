import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './DiscountBannerCoppyButton.style';

/** @namespace Scandipwa/Component/DiscountBannerCoppyButton/Component */
export class DiscountBannerCoppyButtonComponent extends PureComponent {
    static propTypes = {
        textData: PropTypes.string.isRequired,
        copyCodeToClipboard: PropTypes.func.isRequired,
        isCopied: PropTypes.bool.isRequired
    };

    render() {
        const {
            textData,
            copyCodeToClipboard,
            isCopied
        } = this.props;

        return (
            <button
              block="DiscountBannerCoppyButton"
              // eslint-disable-next-line
              onClick={ (e) => copyCodeToClipboard(e) }
              mods={ { isCopied } }
            >
                { textData }
            </button>
        );
    }
}

export default DiscountBannerCoppyButtonComponent;
