/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PaypalSmartButtons from '../../component/PaypalSmartButtons';
import { PAYPAL_PLACEMENT_MINI_CART } from '../../component/PaypalSmartButtons/PaypalSmartButtons.config';

const addPayPalButtonToMiniCartActions = (args, callback, instance) => {
    const {
        paypalConfigs: {
            isVisibleOnCart = false
        } = {}
    } = instance.props;

    if (!isVisibleOnCart) {
        return callback(...args);
    }

    return (
        <>
            <PaypalSmartButtons
              page={ PAYPAL_PLACEMENT_MINI_CART }
            />
            { callback(...args) }
        </>
    );
};

const addPaypalSmartButtonsConfigToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        paypalConfigs: state.ConfigReducer?.paypalConfigs
    };
};

const addPaypalSmartButtonsConfigToContainerProps = (args, callback, instance) => {
    const {
        paypalConfigs
    } = instance.props;

    return {
        ...callback(...args),
        paypalConfigs
    };
};

export default {
    'Component/CartOverlay/Component': {
        'member-function': {
            renderActions: [
                {
                    position: 10,
                    implementation: addPayPalButtonToMiniCartActions
                }
            ]
        }
    },
    'Component/CartOverlay/Container/mapStateToProps': {
        function: addPaypalSmartButtonsConfigToProps
    },
    'Component/CartOverlay/Container': {
        'member-function': {
            containerProps: addPaypalSmartButtonsConfigToContainerProps
        }
    }
};
