/**
 * Wyomind google customer reviews compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { UPDATE_CONFIG } from 'SourceStore/Config/Config.action';

import { UPDATE_DELIVERY_COUNTRY } from '../store/Config/OrderConfig.action';

const addGcrConfigsToInitialState = (args, callback) => (
    {
        ...callback(...args),
        badgeConfig: {},
        orderConfig: {}
    }
);

const getGcrConfigsFromAction = (args, callback) => {
    const result = callback(...args);
    const [state, action] = args;
    const { orderConfig: prevOrderConfig = {} } = state || {};

    const {
        type,
        config: {
            badgeConfig,
            orderConfig
        } = {},
        countryId
    } = action;

    switch (type) {
    case UPDATE_CONFIG:
        return {
            ...result,
            badgeConfig,
            orderConfig
        };

    case UPDATE_DELIVERY_COUNTRY:
        return {
            ...result,
            orderConfig: {
                ...prevOrderConfig,
                delivery_country: countryId
            }
        };
    default:
        return result;
    }
};

export default {
    'Store/Config/Reducer/getInitialState': {
        function: addGcrConfigsToInitialState
    },
    'Store/Config/Reducer/ConfigReducer': {
        function: getGcrConfigsFromAction
    }
};
