import CompatibleConsumableReducer from 'Store/ProductCompatibleConsumable/ProductCompatibleConsumable.reducer';

export default {
    'Store/Index/getStaticReducers': {
        function: (args, callback) => ({
            ...callback(args),
            CompatibleConsumableReducer
        })
    }
};
