import PropTypes from 'prop-types';

import ProductCustomizableOption from 'Component/ProductCustomizableOption';
import {
    ProductCustomizableOptions as SourceProductCustomizableOptions
} from 'SourceComponent/ProductCustomizableOptions/ProductCustomizableOptions.component';

/** @namespace Scandipwa/Component/ProductCustomizableOptions/Component */
export class ProductCustomizableOptionsComponent extends SourceProductCustomizableOptions {
    static propTypes = {
        ...this.propTypes,
        showSubscriptionPopup: PropTypes.func.isRequired
    };

    renderOptionGroup = (group) => {
        const {
            title,
            value,
            type,
            required,
            uid
        } = group;

        const { updateSelectedValues, showSubscriptionPopup } = this.props;

        return (
            <ProductCustomizableOption
              key={ uid }
              uid={ uid }
              title={ title }
              options={ value }
              isRequired={ required }
              type={ type }
              updateSelectedValues={ updateSelectedValues }
              showSubscriptionPopup={ showSubscriptionPopup }
            />
        );
    };
}

export default ProductCustomizableOptionsComponent;
