import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { klevuSearchPageCategoryType } from '../../types/KlevuSearch';
import KlevuSearchPageCategoriesComponent from './KlevuSearchPageCategories.component';
import { KLEVU_CATEGORIES_TO_DISPLAY } from './KlevuSearchPageCategories.config';

/** @namespace KlevuSearch/Component/KlevuSearchPageCategories/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({

});

/** @namespace KlevuSearch/Component/KlevuSearchPageCategories/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    categories: state.KlevuSearchReducer.searchPageCategories,
    secureBaseUrl: state.ConfigReducer.secure_base_link_url
});

/** @namespace KlevuSearch/Component/KlevuSearchPageCategories/Container */
export class KlevuSearchPageCategoriesContainer extends PureComponent {
    static propTypes = {
        categories: PropTypes.arrayOf(klevuSearchPageCategoryType).isRequired,
        secureBaseUrl: PropTypes.string.isRequired
    };

    containerFunctions = {
        onShowMoreClick: this.onShowMoreClick.bind(this),
        displayShowMore: this.displayShowMore.bind(this)
    };

    componentDidUpdate(prevProps) {
        const { categories: prevCategories } = prevProps;
        const { categories } = this.props;

        if (categories.length !== prevCategories.length) {
            this.setState({ categoriesToDisplay: KLEVU_CATEGORIES_TO_DISPLAY });
        }
    }

    __construct(props) {
        super.__construct(props);

        this.state = {
            categoriesToDisplay: KLEVU_CATEGORIES_TO_DISPLAY
        };
    }

    getCategoriesToDisplay = () => {
        const { categories = [] } = this.props;
        const { categoriesToDisplay } = this.state;

        const displayedCategories = categoriesToDisplay < categories.length
            ? categories.slice(0, categoriesToDisplay)
            : categories;

        return displayedCategories;
    };

    getCategoriesCount = () => {
        const { categories = [] } = this.props;
        const { categoriesToDisplay } = this.state;

        const categoriesForShowMore = (categories.length - categoriesToDisplay) < KLEVU_CATEGORIES_TO_DISPLAY
            ? (categories.length - categoriesToDisplay)
            : KLEVU_CATEGORIES_TO_DISPLAY;

        return categoriesForShowMore;
    };

    onShowMoreClick() {
        const { categoriesToDisplay } = this.state;

        this.setState({ categoriesToDisplay: categoriesToDisplay + KLEVU_CATEGORIES_TO_DISPLAY });
    }

    displayShowMore() {
        const { categories = [] } = this.props;
        const { categoriesToDisplay } = this.state;

        if (categories.length <= KLEVU_CATEGORIES_TO_DISPLAY) {
            return false;
        }

        if ((categories.length - categoriesToDisplay) <= 0) {
            return false;
        }

        return true;
    }

    containerProps() {
        const { secureBaseUrl } = this.props;

        return {
            categories: this.getCategoriesToDisplay(),
            secureBaseUrl,
            categoriesCountToDisplay: this.getCategoriesCount()
        };
    }

    render() {
        return (
            <KlevuSearchPageCategoriesComponent
              { ...this.containerProps() }
              { ... this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KlevuSearchPageCategoriesContainer);
