import { CheckoutAddressFormComponent as PrinterbaseCheckoutAddressFormComponent }
from 'PrinterbaseComponent/CheckoutAddressForm/CheckoutAddressForm.component';

// import CheckoutAddressFormInstance from './CheckoutAddressFormInstance';

/** @namespace Scandipwa/Component/CheckoutAddressForm/Component */
export class CheckoutAddressFormComponent extends PrinterbaseCheckoutAddressFormComponent {
    render() {
        const { isSameAsBilling } = this.props;

        if (isSameAsBilling) {
            this.hasEmptyForm = true;

            return (
                <>
                </>
            );
        }

        this.hasEmptyForm = false;

        return (
            <div block="CheckoutAddressForm">
                { this.renderFormBody() }
            </div>
        );
    }
}

export default CheckoutAddressFormComponent;
