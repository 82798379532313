/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/**
 * @category  Printerbase
 * @author    Scandiweb <info@scandiweb.com>
 * @namespace Scandipwa/Component/CheckoutAddressForm/CheckoutAddressFormInstance
 */
// eslint-disable-next-line max-len
// import { AmazonpayButtons } from '@scandipwa/amazonpay-printerbase-compatibility/src/plugin/component/AmazonpayComponent/AmazonpayContainer.plugin';
import { createRef } from 'react';

import { validateGroup } from 'SourceUtil/Validator';

/** @namespace Scandipwa/Component/CheckoutAddressForm/CheckoutAddressFormInstance */
export class CheckoutAddressFormInstance {
    static billingFormInstance = null;

    static shippingFormInstance = null;

    static selectedBillingAddressId = null;

    static selectedShippingAddressId = null;

    static checkoutPaymentsInstance = null;

    static checkoutShippingContainerInstance = null;

    static resetPaymentMethodWhenChangeAddress = false;

    static onAddressChangeCallbacks = [];

    static SHIPPING_ADDRESS_FORM_SELECTORS = {
        firstname: 'input[name="firstname"]',
        lastname: 'input[name="lastname"]',
        street: (idx = null) => {
            if (idx === null) {
                return 'input[name^=street_]';
            }

            return `input[name=street_${idx}]`;
        },
        city: 'input[name=city]',
        region: '#address-region-id',
        postcode: 'input[name=postcode]',
        country_id: '#address-country-id',
        telephone: 'input[name=telephone]',
        same_as_billing: '#sameAsBillingAddress'
    };

    // static BILLING_EMAIL_SELECTOR = '.CheckoutBilling-Wrapper .CheckoutAddressForm input[type=email]';
    static BILLING_EMAIL_SELECTOR = 'input[type=email]';

    static shippingAddressIsSameAsBillingAddress() {
        const sameAsBillingAddress = document.getElementById('sameAsBillingAddress') ?? null;

        if (!sameAsBillingAddress) {
            return false;
        }

        return sameAsBillingAddress.checked;
    }

    static setCheckoutShippingContainerInstance(instance) {
        this.checkoutShippingContainerInstance = instance;
    }

    static getCheckoutShippingContainerInstance() {
        return this.checkoutShippingContainerInstance;
    }

    static setOnAddressChangeCallback(paymentMethodCode, callback, overwriteCurrentCallback = true) {
        if (overwriteCurrentCallback) {
            this.onAddressChangeCallbacks[paymentMethodCode] = callback;
            return;
        }

        const currentCallback = this.onAddressChangeCallbacks[paymentMethodCode] ?? null;

        if (currentCallback === null) {
            this.onAddressChangeCallbacks[paymentMethodCode] = callback;
        }
    }

    static setInstance(inst) {
        const { isBilling } = inst.props;

        if (isBilling) {
            this.billingFormInstance = inst;
        } else {
            this.shippingFormInstance = inst;
        }

        if (!inst.formRef) {
            // eslint-disable-next-line no-param-reassign
            inst.formRef = createRef();
        }

        this.addFormChangeEventListeners(inst);
    }

    static removeInstance(inst) {
        const { isBilling } = inst.props;

        if (inst.formRef) {
            this.removeFormChangeEventListeners(inst);
        }

        if (isBilling) {
            this.billingFormInstance = null;
        } else {
            this.shippingFormInstance = null;
        }
    }

    static setSelectedAddressId(addressId, isBilling) {
        if (isBilling) {
            this.selectedBillingAddressId = addressId;
        } else {
            this.selectedShippingAddressId = addressId;
        }

        const checkoutPaymentsInstance = this?.getCheckoutPaymentsInstance();

        if (!checkoutPaymentsInstance) {
            return;
        }

        if (checkoutPaymentsInstance.isAddressDependantPaymentCodeSelected()) {
            if (isBilling && !this.shippingAddressIsSameAsBillingAddress()) {
                return;
            }

            // Wait a little bit to make sure it will be sent after other concurrent graphql request that
            // also deal with quote_address
            const waitBeforeChangeAddress = 1000;
            setTimeout(() => {
                const successCallback = this.executeOnAddressChangeCallback.bind(this);
                checkoutPaymentsInstance.saveCustomerAddressToCart(successCallback);
            }, waitBeforeChangeAddress);
        }
    }

    static setCheckoutPaymentsInstance(inst) {
        this.checkoutPaymentsInstance = inst;
    }

    static getCheckoutPaymentsInstance() {
        return this.checkoutPaymentsInstance;
    }

    static getShippingAddressFormInstance() {
        const isSameAsBilling = this.shippingAddressIsSameAsBillingAddress();

        return isSameAsBilling ? this.billingFormInstance : this.shippingFormInstance;
    }

    static getSelectedBillingAddressId() {
        const selectedBillingAddress = Number.parseInt(this.selectedBillingAddressId ?? 0, 10);

        return selectedBillingAddress > 0 ? selectedBillingAddress : null;
    }

    static getSelectedShippingAddressId() {
        if (this.shippingAddressIsSameAsBillingAddress()) {
            return this.getSelectedBillingAddressId();
        }

        const selectedShippingAddressId = Number.parseInt(this.selectedShippingAddressId ?? 0, 10);

        return selectedShippingAddressId > 0 ? selectedShippingAddressId : null;
    }

    static isAnyShippingAddressSelected() {
        return this.getSelectedShippingAddressId() !== null
            && this.getSelectedShippingAddressId() > 0;
    }

    static getFormElement(formInstance, element, addititionalData = null) {
        if (!formInstance) {
            return null;
        }

        return this._getElementFromFormInstance(formInstance, element, addititionalData);
    }

    static _getElementFromFormInstance(formInstance, element, addititionalData) {
        const { current: DomNode } = formInstance.formRef;

        if (!DomNode) {
            return null;
        }

        if (element === 'street') {
            return DomNode.querySelectorAll(this.SHIPPING_ADDRESS_FORM_SELECTORS.street(addititionalData));
        }

        return DomNode.querySelectorAll(this.SHIPPING_ADDRESS_FORM_SELECTORS[element]);
    }

    static _getGuestEmailElement() {
        if (!this.billingFormInstance) {
            return null;
        }

        const { current: DomNode } = this.billingFormInstance.formRef;

        if (!DomNode) {
            return null;
        }

        return DomNode.querySelector(this.BILLING_EMAIL_SELECTOR);
    }

    static getEmailAddress() {
        return this._getGuestEmailElement()?.value;
    }

    static isBillingAddressSelected() {
        return this.getSelectedBillingAddressId() !== null
            && this.getSelectedBillingAddressId() > 0;
    }

    static isShippingAddressSelected() {
        return this.getSelectedShippingAddressId() !== null
            && this.getSelectedShippingAddressId() > 0;
    }

    static validateForms() {
        if (!this.isShippingAddressSelected()
            && !this?.getShippingAddressFormInstance()?.formRef?.current
        ) {
            return {
                billingFormValidation: false,
                shippingFormValidation: false
            };
        }

        const billingFormIsValid = this.isBillingAddressSelected()
                                || validateGroup(this?.billingFormInstance?.formRef?.current);

        const shippingFormIsvalid = this.shippingAddressIsSameAsBillingAddress()
                                 || this.isShippingAddressSelected()
                                 || validateGroup(this?.shippingFormInstance?.formRef?.current);

        return {
            billingFormValidation: billingFormIsValid,
            shippingFormValidation: shippingFormIsvalid
        };
    }

    static getFormFieldValue(formInstance, getFormElement, field) {
        const element = this.getFormElement(formInstance, getFormElement, field);

        if (!element) {
            return null;
        }

        if (element.length < 1) {
            return null;
        }

        if (element.length > 1) {
            const streetLines = [];

            /**
             * At this point element variable is NodeList, which does not have a map function,
             * so we use the good'n'old for loop
             */
            // eslint-disable-next-line fp/no-let
            for (let i = 0; i < element.length; i++) {
                streetLines.push(element[i].value);
            }

            return streetLines;
        }

        return element[0].value;
    }

    static getBillingAddressFormData() {
        const formValues = Object.keys(
            this.SHIPPING_ADDRESS_FORM_SELECTORS
        ).reduce(
            (returnVal, formItem) => {
                // eslint-disable-next-line no-param-reassign
                returnVal[formItem] = this.getFormFieldValue(this.billingFormInstance, formItem);

                return returnVal;
            },
            {}
        );

        const same_as_billing = this.shippingAddressIsSameAsBillingAddress();

        return {
            ...formValues,
            country_code: formValues.country_id,
            same_as_billing
        };
    }

    static getShippingAddressFormData() {
        const formValues = Object.keys(
            this.SHIPPING_ADDRESS_FORM_SELECTORS
        ).reduce(
            (returnVal, formItem) => {
                // eslint-disable-next-line no-param-reassign
                returnVal[formItem] = this.getFormFieldValue(this.shippingFormInstance, formItem);

                return returnVal;
            },
            {}
        );

        const same_as_billing = this.shippingAddressIsSameAsBillingAddress();

        return {
            ...formValues,
            country_code: formValues.country_id,
            same_as_billing
        };
    }

    static executeOnAddressChangeCallback() {
        const checkoutPaymentsInstance = this?.getCheckoutPaymentsInstance();

        if (!checkoutPaymentsInstance) {
            return;
        }

        const selectedPaymentCode = checkoutPaymentsInstance.getSelectedPaymentCode() ?? '';

        const fn = this.onAddressChangeCallbacks[selectedPaymentCode] ?? null;
        if (fn instanceof Function) {
            fn();
        }

        this.reselectCheckoutShippingMethod();
    }

    // Reselection of shipping method is done because it gets erased from database
    // when saving addresses to cart through magento graphql
    static reselectCheckoutShippingMethod() {
        const checkoutShippingContainerInstance = this?.getCheckoutShippingContainerInstance();
        if (!checkoutShippingContainerInstance) {
            return;
        }

        const { selectedShippingMethod } = checkoutShippingContainerInstance.state;
        if (selectedShippingMethod != null) {
            checkoutShippingContainerInstance.onShippingMethodSelect(selectedShippingMethod);
        }
    }

    static _getInputOnChangeEventListener(isBillingFormInput) {
        const checkoutPaymentsInstance = this?.getCheckoutPaymentsInstance();

        if (!checkoutPaymentsInstance) {
            return;
        }

        if (isBillingFormInput && !this.shippingAddressIsSameAsBillingAddress()) {
            return;
        }

        if (checkoutPaymentsInstance.isAddressDependantPaymentCodeSelected()) {
            const successCallback = this.executeOnAddressChangeCallback.bind(this);
            checkoutPaymentsInstance.saveCustomerAddressToCart(successCallback);
        }
    }

    static _getEmailInputOnChangeEventListener() {
        const checkoutPaymentsInstance = this?.getCheckoutPaymentsInstance();

        if (!checkoutPaymentsInstance) {
            return;
        }

        if (checkoutPaymentsInstance.isAddressDependantPaymentCodeSelected()) {
            // const successCallback = this.executeOnAddressChangeCallback.bind(this);
            // checkoutPaymentsInstance.saveCustomerAddressToCart(successCallback);
            checkoutPaymentsInstance.saveGuestEmailToCart(() => {});
        }
    }

    static addFormChangeEventListeners(formInstance) {
        // eslint-disable-next-line no-param-reassign
        formInstance.addedOnFormChangeEventListeners = false;

        const { isBilling } = formInstance.props;

        Object.keys(
            this.SHIPPING_ADDRESS_FORM_SELECTORS
        ).forEach((formItemSelector) => {
            const formElement = this._getElementFromFormInstance(formInstance, formItemSelector);

            if (formElement instanceof NodeList) {
                // eslint-disable-next-line no-param-reassign
                formInstance.addedOnFormChangeEventListeners = true;

                // eslint-disable-next-line fp/no-let
                for (let i = 0; i < formElement.length; i++) {
                    if (formElement[i].nodeName.toLowerCase() === 'input') {
                        formElement[i].addEventListener(
                            'input',
                            this._getInputOnChangeEventListener.bind(this, isBilling)
                        );
                    }
                }
            }
        });

        if (isBilling) {
            this._getGuestEmailElement()?.addEventListener(
                'input',
                this._getEmailInputOnChangeEventListener.bind(this)
            );
        }
    }

    static removeFormChangeEventListeners(formInstance) {
        const { isBilling } = formInstance.props;

        Object.keys(
            this.SHIPPING_ADDRESS_FORM_SELECTORS
        ).forEach((formItemSelector) => {
            const formElement = this._getElementFromFormInstance(formInstance, formItemSelector);

            if (formElement instanceof NodeList) {
                // eslint-disable-next-line fp/no-let
                for (let i = 0; i < formElement.length; i++) {
                    if (formElement[i].nodeName.toLowerCase() === 'input') {
                        formElement[i].removeEventListener('input', this._getInputOnChangeEventListener);
                    }
                }
            }
        });

        if (isBilling) {
            this._getGuestEmailElement()?.removeEventListener(
                'input',
                this._getEmailInputOnChangeEventListener
            );
        }
    }
}

// Setup all external address dependant payment methods here.
// CheckoutAddressFormInstance.setOnAddressChangeCallback(
//     'amazon_payment_v2', () => {
//         AmazonpayButtons.mustReRender();
//     }
// );

export default CheckoutAddressFormInstance;
