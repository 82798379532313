/* eslint-disable max-lines */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const PRODUCT_SPECS_ATTRIBUTES = [
    // PNB-191 BEGIN
    'memory',
    'manufacturer',
    'barcode',
    'gtin',
    'paper_size',
    'printer_type',
    'connectivity',
    'compatibility',
    'double_sided',
    'print_speed',
    'print_from',
    'functions',
    'first_page_out',
    'pages_per_min_colour',
    'pages_per_min_mono',
    'print_dpi',
    'paper_handling_output',
    'input_capacity',
    'mult_purpose_tray',
    'adf',
    'scan_dpi',
    'fax_speed',
    'double_sided_print_size',
    'boarderless_printing',
    'processor_speed',
    'hard_drive_size',
    'emulation',
    'max_paper_weight',
    'display_screen',
    'dimensions_w_x_d_x_h',
    'disc_printing',
    'battery',
    'print_speed_dropdown',
    'min_width',
    'max_width',
    'qwerty_keyboard',
    'cutter',
    'battery_type',
    'barcode_printing',
    'pins',
    'carriage',
    'print_speed_cps',
    'no_of_copies',
    'no_of_inks',
    'roll_width_inches',
    'roll_feed',
    'cut_sheet_feeder',
    'scanner_type',
    'scan_speed_ppm',
    'opitical_scan_resolution_dpi',
    'interpolated_scan_resolution',
    'duplex_scan',
    'supplies_type',
    'capacity_unit',
    'capacity',
    'output',
    'weight_kg',
    'size',
    'color',
    'st_attr7532',
    'st_attr7530',
    'st_attr7531',
    'st_attr7529',
    'st_attr7623',
    'st_attr14253',
    'st_arrt14656',
    'st_attr454641',
    'st_attr8290',
    'st_attr454396',
    'st_attr7536',
    'st_attr7517',
    'st_attr14184',
    'st_attr14252',
    'st_attr462477',
    'st_attr454587',
    'st_attr455787',
    'st_attr14212',
    'st_attr14229',
    'st_attr13977',
    'st_attr14639',
    'st_attr453093',
    'st_attr454386',
    'st_attr455650',
    'st_attr455799',
    'st_attr7552',
    'st_attr7581',
    'st_attr8322',
    'st_attr14280',
    'st_attr13833',
    'st_attr455677',
    'st_attr14459',
    'st_attr451096',
    'st_attr454488',
    'st_attr455707',
    'st_attr454363',
    'st_attr454424',
    'st_attr14271',
    'st_attr455691',
    'st_attr455715',
    'st_attr8303',
    'st_attr13890',
    'st_attr13915',
    'st_attr14061',
    'st_attr14110',
    'st_attr14272',
    'st_attr452070',
    'st_attr454387',
    'st_attr13919',
    'st_attr454303',
    'st_attr13609',
    'st_attr13840',
    'st_attr13925',
    'st_attr13929',
    'st_attr13955',
    'st_attr13994',
    'st_attr14022',
    'st_attr14036',
    'st_attr14267',
    'st_attr450584',
    'st_attr450585',
    'st_attr451095',
    'st_attr451382',
    'st_attr452131',
    'st_attr453147',
    'st_attr454208',
    'st_attr454480',
    'st_attr454536',
    'st_attr455657',
    'st_attr8302',
    'st_attr451388',
    'st_attr13611',
    'st_attr14259',
    'st_attr14260',
    'st_attr14261',
    'st_attr14265',
    'st_attr14268',
    'st_attr14269',
    'st_attr14273',
    'st_attr17018',
    'st_attr7413',
    'st_attr8276',
    'st_attr9358',
    'st_attr452236',
    'st_attr451013',
    'st_attr7414',
    'st_attr455611',
    'st_attr14102',
    'st_attr8313',
    'st_attr451973',
    'st_attr451887',
    'st_attr14232',
    'st_attr453115',
    'st_attr451394',
    'st_attr14600',
    'st_attr451873',
    'st_attr452091',
    'st_attr14118',
    'st_attr14475',
    'st_attr13801',
    'st_attr451052',
    'st_attr452168',
    'st_attr452248',
    'st_attr13839',
    'st_attr13968',
    'st_attr452001',
    'st_attr455858',
    'st_attr7428',
    'st_attr13838',
    'st_attr13935',
    'st_attr451891',
    'st_attr455648',
    'st_attr455664',
    'st_attr455672',
    'st_attr455674',
    'st_attr13981',
    'st_attr14132',
    'st_attr451045',
    'st_attr451369',
    'st_attr453076',
    'st_attr453102',
    'st_attr455581',
    'st_attr7492',
    'st_attr7494',
    'st_attr7510',
    'st_attr17129',
    'st_attr454573',
    'st_attr455585',
    'st_attr8223',
    'st_attr452139',
    'st_attrinkcolour',
    'st_attr469002',
    'st_attr451858',
    'st_attr455654',
    'st_attr7598',
    'st_attr7593',
    'st_attr14479',
    'st_attr14642',
    'st_attr451255',
    'st_attr14122',
    'st_attr455621',
    'st_attr455619',
    'st_attr7539',
    'st_attr14659',
    'st_attr455630',
    'st_attr13927',
    'st_attr452181',
    'st_attr14641',
    'st_attr451572',
    'st_attr8304',
    'st_attr8306',
    'st_attr14131',
    'st_attr7592',
    'st_attr451122',
    'st_attr454556',
    'st_attr13789',
    'st_attr14651',
    'st_attr453132',
    'st_attr455622',
    'st_attr450902',
    'st_attr451054',
    'st_attr451348',
    'st_attr451886',
    'st_attr452220',
    'st_attr454528',
    'st_attr7545',
    'st_attr7573',
    'st_attr8288',
    'st_attr451111',
    'st_attr452188',
    'st_attr455635',
    'st_attr13857',
    'st_attr13961',
    'st_attr13983',
    'st_attr14155',
    'st_attr14296',
    'st_attr14650',
    'st_attr450677',
    'st_attr451089',
    'st_attr451293',
    'st_attr451294',
    'st_attr451718',
    'st_attr452088',
    'st_attr452254',
    'st_attr453106',
    'st_attr453108',
    'st_attr453154',
    'st_attr454509',
    'st_attr454701',
    'st_attr455587',
    'st_attr455614',
    'st_attr455625',
    'st_attr455675',
    'st_attr7434',
    'st_attr7436',
    'st_attr453835',
    'st_attr453423',
    'st_attr453637',
    'st_attr452222',
    'st_attr455696',
    'st_attr455694',
    'st_attr451916',
    'st_attr14166',
    'st_attr14644',
    'st_attr455577',
    'st_attr455576',
    'st_attr455578',
    'st_attr7597',
    'st_attr450582',
    'st_attr452251',
    'st_attr452071',
    'st_attr455579',
    'st_attr455687',
    'st_attr8319',
    'st_attr451630',
    'st_attr450583',
    'st_attr451616',
    'st_attr451857',
    'st_attr452059',
    'st_attr454700',
    'st_attr453707',
    'st_attr14107',
    'st_attr454113',
    'st_attr455620',
    'st_attr455666',
    'st_attr451405',
    'st_attr13865',
    'st_attr14633',
    'st_attr7446',
    'st_attr7553',
    'st_attr14636',
    'st_attr452046',
    'st_attr454825',
    'st_attr14329',
    'st_attr14454',
    'st_attr14602',
    'st_attr455596',
    'st_attr7587',
    'st_attr13822',
    'st_attr13924',
    'st_attr451112',
    'st_attr451389',
    'st_attr451403',
    'st_attr451979',
    'st_attr454311',
    'st_attr454740',
    'st_attr7463',
    'st_attr7563',
    'st_attr7422',
    'st_attr14601',
    // PNB-191 END
    // PNB-712 BEGIN
    'st_arrt14656',
    'st_attr13609',
    'st_attr13611',
    'st_attr13789',
    'st_attr13801',
    'st_attr13822',
    'st_attr13833',
    'st_attr13838',
    'st_attr13839',
    'st_attr13840',
    'st_attr13857',
    'st_attr13865',
    'st_attr13890',
    'st_attr13895',
    'st_attr13915',
    'st_attr13919',
    'st_attr13924',
    'st_attr13925',
    'st_attr13927',
    'st_attr13929',
    'st_attr13935',
    'st_attr13955',
    'st_attr13961',
    'st_attr13968',
    'st_attr13977',
    'st_attr13981',
    'st_attr13983',
    'st_attr13994',
    'st_attr14022',
    'st_attr14036',
    'st_attr14061',
    'st_attr14102',
    'st_attr14107',
    'st_attr14110',
    'st_attr14118',
    'st_attr14122',
    'st_attr14131',
    'st_attr14132',
    'st_attr14155',
    'st_attr14166',
    'st_attr14183',
    'st_attr14184',
    'st_attr14212',
    'st_attr14229',
    'st_attr14232',
    'st_attr14245',
    'st_attr14247',
    'st_attr14248',
    'st_attr14249',
    'st_attr14252',
    'st_attr14253',
    'st_attr14254',
    'st_attr14256',
    'st_attr14259',
    'st_attr14260',
    'st_attr14261',
    'st_attr14264',
    'st_attr14265',
    'st_attr14267',
    'st_attr14268',
    'st_attr14269',
    'st_attr14270',
    'st_attr14271',
    'st_attr14272',
    'st_attr14273',
    'st_attr14274',
    'st_attr14278',
    'st_attr14280',
    'st_attr14281',
    'st_attr14282',
    'st_attr14283',
    'st_attr14296',
    'st_attr14329',
    'st_attr14446',
    'st_attr14454',
    'st_attr14459',
    'st_attr14460',
    'st_attr14475',
    'st_attr14479',
    'st_attr14600',
    'st_attr14601',
    'st_attr14602',
    'st_attr14633',
    'st_attr14636',
    'st_attr14639',
    'st_attr14641',
    'st_attr14642',
    'st_attr14644',
    'st_attr14650',
    'st_attr14651',
    'st_attr14654',
    'st_attr14659',
    'st_attr17018',
    'st_attr17129',
    'st_attr450582',
    'st_attr450583',
    'st_attr450584',
    'st_attr450585',
    'st_attr450677',
    'st_attr450902',
    'st_attr451013',
    'st_attr451045',
    'st_attr451051',
    'st_attr451052',
    'st_attr451054',
    'st_attr451089',
    'st_attr451095',
    'st_attr451096',
    'st_attr451111',
    'st_attr451112',
    'st_attr451122',
    'st_attr451255',
    'st_attr451293',
    'st_attr451294',
    'st_attr451296',
    'st_attr451348',
    'st_attr451369',
    'st_attr451382',
    'st_attr451388',
    'st_attr451389',
    'st_attr451394',
    'st_attr451403',
    'st_attr451405',
    'st_attr451572',
    'st_attr451616',
    'st_attr451630',
    'st_attr451718',
    'st_attr451857',
    'st_attr451858',
    'st_attr451873',
    'st_attr451886',
    'st_attr451887',
    'st_attr451891',
    'st_attr451916',
    'st_attr451973',
    'st_attr451979',
    'st_attr452001',
    'st_attr452046',
    'st_attr452059',
    'st_attr452070',
    'st_attr452071',
    'st_attr452088',
    'st_attr452091',
    'st_attr452126',
    'st_attr452131',
    'st_attr452139',
    'st_attr452168',
    'st_attr452181',
    'st_attr452188',
    'st_attr452214',
    'st_attr452220',
    'st_attr452222',
    'st_attr452235',
    'st_attr452236',
    'st_attr452248',
    'st_attr452251',
    'st_attr452254',
    'st_attr453076',
    'st_attr453093',
    'st_attr453097',
    'st_attr453102',
    'st_attr453106',
    'st_attr453108',
    'st_attr453115',
    'st_attr453120',
    'st_attr453132',
    'st_attr453147',
    'st_attr453154',
    'st_attr453423',
    'st_attr453637',
    'st_attr453707',
    'st_attr453835',
    'st_attr454113',
    'st_attr454208',
    'st_attr454303',
    'st_attr454311',
    'st_attr454363',
    'st_attr454386',
    'st_attr454387',
    'st_attr454396',
    'st_attr454424',
    'st_attr454480',
    'st_attr454488',
    'st_attr454509',
    'st_attr454528',
    'st_attr454536',
    'st_attr454547',
    'st_attr454556',
    'st_attr454573',
    'st_attr454587',
    'st_attr454641',
    'st_attr454700',
    'st_attr454701',
    'st_attr454740',
    'st_attr454825',
    'st_attr455576',
    'st_attr455577',
    'st_attr455578',
    'st_attr455579',
    'st_attr455581',
    'st_attr455585',
    'st_attr455587',
    'st_attr455596',
    'st_attr455610',
    'st_attr455611',
    'st_attr455614',
    'st_attr455619',
    'st_attr455620',
    'st_attr455621',
    'st_attr455622',
    'st_attr455625',
    'st_attr455630',
    'st_attr455635',
    'st_attr455648',
    'st_attr455650',
    'st_attr455654',
    'st_attr455657',
    'st_attr455658',
    'st_attr455664',
    'st_attr455666',
    'st_attr455672',
    'st_attr455674',
    'st_attr455675',
    'st_attr455677',
    'st_attr455687',
    'st_attr455691',
    'st_attr455694',
    'st_attr455696',
    'st_attr455707',
    'st_attr455708',
    'st_attr455715',
    'st_attr455768',
    'st_attr455787',
    'st_attr455799',
    'st_attr455858',
    'st_attr456065',
    'st_attr462477',
    'st_attr469002',
    'st_attr7413',
    'st_attr7414',
    'st_attr7418',
    'st_attr7422',
    'st_attr7428',
    'st_attr7434',
    'st_attr7436',
    'st_attr7441',
    'st_attr7446',
    'st_attr7463',
    'st_attr7492',
    'st_attr7494',
    'st_attr7510',
    'st_attr7517',
    'st_attr7518',
    'st_attr7536',
    'st_attr7539',
    'st_attr7545',
    'st_attr7552',
    'st_attr7553',
    'st_attr7563',
    'st_attr7573',
    'st_attr7581',
    'st_attr7587',
    'st_attr7592',
    'st_attr7593',
    'st_attr7597',
    'st_attr7598',
    'st_attr7623',
    'st_attr8223',
    'st_attr8237',
    'st_attr8274',
    'st_attr8276',
    'st_attr8288',
    'st_attr8290',
    'st_attr8302',
    'st_attr8303',
    'st_attr8304',
    'st_attr8305',
    'st_attr8306',
    'st_attr8313',
    'st_attr8319',
    'st_attr8322',
    'st_attr9229',
    'st_attr9230',
    'st_attr9231',
    'st_attr9232',
    'st_attr9234',
    'st_attrinkcolour'
    // PNB-712 END
];
