import BrowserDatabase from 'Util/BrowserDatabase';
import transformToNameValuePair from 'Util/Form/Transform';

import {
    getAmazonCheckoutSessionId,
    isAmazonCheckout,
    transformAddressForQuery
} from '../util/AmazonPay.util';
import {
    AMAZONSELECTEDSHIPPINGMETHOD,
    ONE_DAY_IN_SECONDS
} from './AmazonPayShipping.config';

const AmazonPayDispatcher = import('../store/AmazonPay/AmazonPay.dispatcher');
const CartDispatcher = import('Store/Cart/Cart.dispatcher');

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    if (!isAmazonCheckout()) {
        return callback(...args);
    }

    return {
        ...callback(...args),
        shippingEstimate: state.AmazonPayReducer.shippingEstimate,
        isCustomAddressExpanded: state.AmazonPayReducer.state.isCustomAddressExpanded,
        selectedCarrier: state.AmazonPayReducer.shippingEstimate.selectedCarrier,
        checkoutSession: state.AmazonPayReducer.checkoutSession,
        isPaymentProcessing: state.AmazonPayReducer.state.isPaymentProcessing
    };
};

export const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;
    const dispatchProps = { ...callback(...args) };

    return {
        ...dispatchProps,
        selectShippingCarrier:
            (selectedCarrier) => AmazonPayDispatcher.then(
                ({ default: dispatcher }) => dispatcher.selectShippingCarrier(
                    dispatch,
                    selectedCarrier,
                )
            ),
        setAmazonPaymentMethodOnCart:
            (billingAddress) => AmazonPayDispatcher.then(
                ({ default: dispatcher }) => dispatcher.setAmazonPaymentMethodOnCart(
                    dispatch,
                    billingAddress
                )
            ),
        amazonCheckoutSessionUpdate:
            () => AmazonPayDispatcher.then(
                ({ default: dispatcher }) => dispatcher.amazonCheckoutSessionUpdate(dispatch)
            ),
        saveAddressInformation:
            (addressData) => AmazonPayDispatcher.then(
                ({ default: dispatcher }) => dispatcher.saveAddressInformation(dispatch, addressData)
            ),
        updateCartData: (cartData) => CartDispatcher.then(
            ({ default: dispatcher }) => dispatcher._updateCartData(cartData, dispatch)
        ),
        setIsAmazonProcessing: (isLoading) => AmazonPayDispatcher.then(
            ({ default: dispatcher }) => dispatcher.setIsAmazonProcessing(dispatch, isLoading)
        )
    };
};

const onShippingMethodSelect = (instance) => async (shippingMethod) => {
    const {
        selectShippingCarrier,
        updateCartData
    } = instance.props;

    BrowserDatabase.setItem(
        shippingMethod,
        AMAZONSELECTEDSHIPPINGMETHOD,
        ONE_DAY_IN_SECONDS
    );

    const result = await selectShippingCarrier(shippingMethod);

    updateCartData(result);
};

const onBillingSuccess = async (args, cb, instance) => {
    const {
        isCustomAddressExpanded,
        setAmazonPaymentMethodOnCart,
        amazonCheckoutSessionUpdate,
        selectedCarrier: {
            carrier_code,
            method_code
        },
        saveAddressInformation,
        checkoutSession: {
            billingAddress: amazonBillingAddress,
            shippingAddress: amazonShippingAddress
        },
        setIsAmazonProcessing
    } = instance.props;

    const amazonCheckoutSessionId = getAmazonCheckoutSessionId();

    if (!amazonCheckoutSessionId) {
        return cb(...args);
    }
    const initialShippingMethod = BrowserDatabase.getItem(AMAZONSELECTEDSHIPPINGMETHOD);

    const adddressData = {
        shipping_address: amazonShippingAddress,
        billing_address: amazonBillingAddress,
        shipping_carrier_code: carrier_code === '' ? initialShippingMethod.carrier_code : carrier_code,
        shipping_method_code: method_code === '' ? initialShippingMethod.method_code : method_code
    };

    if (isCustomAddressExpanded) {
        const [fields] = args;

        const formAddress = transformToNameValuePair(fields);

        const transformedAddress = transformAddressForQuery(formAddress);

        adddressData.shipping_address = transformedAddress;
        adddressData.billing_address = amazonBillingAddress;
    }

    try {
        setIsAmazonProcessing(true);

        await saveAddressInformation(adddressData);

        await setAmazonPaymentMethodOnCart();

        const redirectUrl = await amazonCheckoutSessionUpdate();

        window.location.replace(redirectUrl);
    } catch (e) {
        setIsAmazonProcessing(false);
    }

    return null;
};

const containerProps = (args, cb, instance) => {
    if (!isAmazonCheckout()) {
        return cb(...args);
    }

    const { shippingEstimate, isPaymentProcessing } = instance.props;

    return {
        ...cb.call(instance),
        onShippingMethodSelect: onShippingMethodSelect(instance),
        shippingEstimate,
        isPaymentProcessing
    };
};

const config = {
    'Component/CheckoutBilling/Container': {
        'member-function': {
            onBillingSuccess,
            containerProps
        }
    },
    'Component/CheckoutBilling/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/CheckoutBilling/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};

export default config;
