/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names, @scandipwa/scandipwa-guidelines/only-render-in-component */
import { lazy, Suspense } from 'react';

import BestSellingToggleButtons from 'Component/BestSellingToggleButtons';
import CategoryDescription from 'Component/CategoryDescription';
import CategoryFaqBlock from 'Component/CategoryFaqBlock';
import CategorySubacategoriesTiles from 'Component/CategorySubacategoriesTiles';
import ChooseManufacturer from 'Component/ChooseManufacturer';
import ConsumablesPromoCopyButton from 'Component/ConsumablesPromoCopyButton';
import DiscountBannerCoppyButton from 'Component/DiscountBannerCoppyButton';
import InkTonerSearchFieldBlock from 'Component/InkTonerSearchFieldBlock';
import Loader from 'Component/Loader/Loader.component';
import LookingForPrinterSearchField from 'Component/LookingForPrinterSearchField';
import LookingForTop5Button from 'Component/LookingForTop5Button';
import Subcategories from 'Component/Subcategories';
import {
    Html as SourceHtml
} from 'SourceComponent/Html/Html.component';

export const WidgetFactory = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget" */
    'Component/WidgetFactory'
));
/** @namespace Scandipwa/Component/Html/Component */
export class Html extends SourceHtml {
    rules = [
        ...this.rules,
        {
            query: { attribs: [{ id: 'best_selling_toggle_buttons' }] },
            replace: this.replaceBestSellingToggleButtons
        },
        {
            query: { attribs: [{ id: 'consumables-promo-copy-button' }] },
            replace: this.replaceConsumablesPromoCopyButton
        },
        {
            query: { attribs: [{ id: 'home-looking-for-printer-search' }] },
            replace: this.replaceHomeLookingForPrinterSearchField
        },
        {
            query: { attribs: [{ id: 'ink-toner-search-bar' }] },
            replace: this.replaceInkTonerSearchBlock
        },
        {
            query: { attribs: [{ id: 'category-faq-block' }] },
            replace: this.replaceCategoryFaqBlock
        },
        {
            query: { attribs: [{ id: 'ink-and-toner-choose-manufacturer' }] },
            replace: this.replaceInkAndTonerChooseManufacturer
        },
        {
            query: { attribs: [{ id: 'category-subcategories' }] },
            replace: this.replaceCategorySubcategories
        },
        {
            query: { attribs: [{ id: 'category-subacategories-tiles' }] },
            replace: this.replaceCategorySubcategoriesTitles
        },
        {
            query: { attribs: [{ id: 'category-description-block' }] },
            replace: this.replaceCategoryDescription
        },
        {
            query: { attribs: [{ id: 'ink-and-toner-choose-manufacturer' }] },
            replace: this.replaceInkAndTonerChooseManufacturer
        },
        {
            query: { attribs: [{ id: 'pdp-10-discount-code' }] },
            replace: this.replaceDiscountBannerCoppyButton
        },
        {
            query: { attribs: [{ id: 'looking-for-top-5-button' }] },
            replace: this.replaceLookingForTop5Button
        }
    ];

    state = {
        printersTitle: '',
        otherTitle: ''
    };

    /**
     * Overridden to get the title from widget
     */
    replaceWidget({ attribs, parent }) {
        const { attribs: { class: className } } = parent;
        const { title } = attribs;

        if (className === 'best_selling_printers') {
            this.setState({ printersTitle: title });
        }

        if (className === 'best_selling_others') {
            this.setState({ otherTitle: title });
        }

        return (
            <Suspense fallback={ <Loader isLoading /> }>
                <WidgetFactory { ...this.attributesToProps(attribs) } />
            </Suspense>
        );
    }

    replaceLookingForTop5Button() {
        return <LookingForTop5Button />;
    }

    replaceDiscountBannerCoppyButton({ children }) {
        return (
            <DiscountBannerCoppyButton>
                { children }
            </DiscountBannerCoppyButton>
        );
    }

    replaceCategoryFaqBlock() {
        return <CategoryFaqBlock />;
    }

    replaceCategoryDescription() {
        return <CategoryDescription />;
    }

    replaceCategorySubcategories() {
        return <Subcategories />;
    }

    replaceCategorySubcategoriesTitles() {
        return <CategorySubacategoriesTiles />;
    }

    replaceInkTonerSearchBlock() {
        return <InkTonerSearchFieldBlock />;
    }

    /**
     * Overridden to pass the title from widget
     */
    replaceBestSellingToggleButtons() {
        const { printersTitle, otherTitle } = this.state;

        return <BestSellingToggleButtons printersTitle={ printersTitle } otherTitle={ otherTitle } />;
    }

    replaceConsumablesPromoCopyButton({ children }) {
        return (
            <ConsumablesPromoCopyButton>
                { children }
            </ConsumablesPromoCopyButton>
        );
    }

    replaceHomeLookingForPrinterSearchField() {
        return <LookingForPrinterSearchField />;
    }

    replaceInkAndTonerChooseManufacturer() {
        return <ChooseManufacturer />;
    }
}

export default Html;
