/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PaypalSmartButtons from '../../component/PaypalSmartButtons';
import { PAYPAL_PLACEMENT_CART_PAGE } from '../../component/PaypalSmartButtons/PaypalSmartButtons.config';

const addPayPalButtonToOrderSummary = (args, callback, instance) => {
    const {
        paypalConfigs: {
            isVisibleOnCart = false
        } = {},
        hasOutOfStockProductsInCart
    } = instance.props;

    if (hasOutOfStockProductsInCart) {
        return callback(...args);
    }

    if (!isVisibleOnCart) {
        return callback(...args);
    }

    return (
        <>
            { callback(...args) }
            <PaypalSmartButtons
              page={ PAYPAL_PLACEMENT_CART_PAGE }
            />
        </>
    );
};

const addPaypalSmartButtonsConfigToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        paypalConfigs: state.ConfigReducer?.paypalConfigs
    };
};

const addPaypalSmartButtonsConfigToContainerProps = (args, callback, instance) => {
    const {
        paypalConfigs
    } = instance.props;

    return {
        ...callback(...args),
        paypalConfigs
    };
};

export default {
    'Route/CartPage/Component': {
        'member-function': {
            renderAlternatePayments: addPayPalButtonToOrderSummary
        }
    },
    'Route/CartPage/Container/mapStateToProps': {
        function: addPaypalSmartButtonsConfigToProps
    },
    'Route/CartPage/Container': {
        'member-function': {
            containerProps: addPaypalSmartButtonsConfigToContainerProps
        }
    }
};
