import { connect } from 'react-redux';

import {
    CheckoutDeliveryOptionsContainer as SourceCheckoutDeliveryOptionsContainer,
    mapDispatchToProps,
    mapStateToProps
} from 'SourceComponent/CheckoutDeliveryOptions/CheckoutDeliveryOptions.container';
import BrowserDatabase from 'Util/BrowserDatabase';

import {
    SELECTEDSHIPPINGMETHOD
} from '../../route/Checkout/Checkout.config';

/** @namespace Scandipwa/Component/CheckoutDeliveryOptions/Container */
export class CheckoutDeliveryOptionsContainer extends SourceCheckoutDeliveryOptionsContainer {
    /**
     * Overriden to get selected shipping method from browser databbase
     */
     static defaultProps = {
         ...this.defaultProps,
         selectedShippingMethod: BrowserDatabase.getItem(SELECTEDSHIPPINGMETHOD) || {}
     };
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutDeliveryOptionsContainer);
