import {
    CheckoutAddressTableComponent as SourceCheckoutAddressTable
} from 'PrinterbaseComponent/CheckoutAddressTable/CheckoutAddressTable.component';

/** @namespace Scandipwa/Component/CheckoutAddressTable/Component */
export class CheckoutAddressTableComponent extends SourceCheckoutAddressTable {
    renderTable() {
        const { isSelected } = this.props;

        return (
            <button
              block="CheckoutAddressTable"
              elem="Button"
              type="button"
              mods={ { isSelected } }
              onClick={ this.onAddressClick }
            >
                { super.renderTable() }
            </button>
        );
    }
}

export default CheckoutAddressTableComponent;
