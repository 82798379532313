/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import React, { lazy, Suspense } from 'react';

import {
    DELIVERY_INFORMATION,
    DELIVERY_INFORMATION_LABEL
} from 'Component/DeliveryInformationTab/DeliveryInformationTab.config';
import Loader from 'Component/Loader/Loader.component';

export const DeliveryInformationTab = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-info" */
    '../../../component/DeliveryInformationTab'
));

const renderDeliveryInformationTab = (key, instance) => {
    const { deliveryInformationContent } = instance.props;

    return (
        <Suspense fallback={ <Loader /> } key={ key }>
            <DeliveryInformationTab
              deliveryInformationContent={ deliveryInformationContent }
              key={ key }
            />
        </Suspense>
    );
};

const tabMapPlugin = (member, instance) => {
    const newMember = {
        ...member,
        [DELIVERY_INFORMATION]: {
            name: DELIVERY_INFORMATION_LABEL,
            shouldTabRender: () => true,
            render: (key) => renderDeliveryInformationTab(key, instance)
        }
    };

    return newMember;
};

export default {
    'Route/ProductPage/Component': {
        'member-property': {
            tabMap: tabMapPlugin
        }
    }
};
