import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { showPopup } from 'Store/Popup/Popup.action';
import { DeviceType } from 'Type/Device';

import InkTonerSearchFieldBlockComponent from './InkTonerSearchFieldBlock.component';

/** @namespace Scandipwa/Component/InkTonerSearchFieldBlock/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
    name: state.CategoryReducer.category.name,
    title: state.CategoryReducer.category.category_page_title
});

/** @namespace Scandipwa/Component/InkTonerSearchFieldBlock/Container/mapDispatchToProps */
// eslint-disable-next-line no-unused-vars
export const mapDispatchToProps = (dispatch) => ({
    showPopup: (id, payload) => dispatch(showPopup(id, payload))
});

/** @namespace Scandipwa/Component/InkTonerSearchFieldBlock/Container */
export class InkTonerSearchFieldBlockContainer extends PureComponent {
    static propTypes = {
        device: DeviceType.isRequired,
        name: PropTypes.string.isRequired,
        title: PropTypes.string,
        showPopup: PropTypes.func.isRequired
    };

    static defaultProps = {
        title: ''
    };

    containerFunctions = {
        onSearchOutsideClick: this.onSearchOutsideClick.bind(this),
        onSearchBarFocus: this.onSearchBarFocus.bind(this),
        onSearchBarChange: this.onSearchBarChange.bind(this),
        onClearSearchButtonClick: this.onClearSearchButtonClick.bind(this)
    };

    __construct() {
        this.state = {
            searchCriteria: '',
            isSearchFocused: false
        };
    }

    /**
     * Handle click outside
     */
    onSearchOutsideClick() {
        this.setState({ isSearchFocused: false });
    }

    /**
     * Handle search bar on focus
     */
    onSearchBarFocus() {
        this.setState({ isSearchFocused: true });
    }

    /**
     * handle search bar change
     */
    onSearchBarChange({ target: { value: searchCriteria } }) {
        this.setState({ searchCriteria });
    }

    /**
     * clear button is disabled
     */
    onClearSearchButtonClick() {
        return false;
    }

    containerProps() {
        const {
            device, name, title, showPopup
        } = this.props;
        const { searchCriteria, isSearchFocused } = this.state;

        return {
            searchCriteria,
            isSearchFocused,
            device,
            name,
            title,
            showPopup
        };
    }

    render() {
        return (
            <InkTonerSearchFieldBlockComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InkTonerSearchFieldBlockContainer));
