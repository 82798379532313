import KlevuSearchPageCategories from '../component/KlevuSearchPageCategories';

const aroundRenderPages = (args, callback, instance) => {
    if (!window.location.pathname.includes('search')) {
        return callback(...args);
    }

    return (
        <>
            <KlevuSearchPageCategories />
            { callback(...args) }
        </>
    );
};

export default {
    'Component/ProductList/Component': {
        'member-function': {
            renderPages: aroundRenderPages
        }
    }
};
