import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Html from 'Component/Html';
import Image from 'Component/Image';
import Link from 'Component/Link';

import { BLOCK_ATTR, TEXT_NUMBER } from './CategorySubacategoriesTiles.config';

import './CategorySubacategoriesTiles.style';

/** @namespace Scandipwa/Component/CategorySubacategoriesTiles/Component */
export class CategorySubacategoriesTilesComponent extends PureComponent {
    static propTypes = {
        subcategories: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                product_count: PropTypes.number,
                url: PropTypes.string
            })
        ),
        title: PropTypes.string
    };

    static defaultProps = {
        subcategories: [],
        title: ''
    };

    renderImage(image, alt) {
        return (
            <div block={ BLOCK_ATTR } elem="TileImage">
                <Image showIsLoading alt={ alt } src={ image } />
            </div>
        );
    }

    renderTitle(title) {
        return (
            <h2 block={ BLOCK_ATTR } elem="TileTitle">{ title }</h2>
        );
    }

    renderDescription(description) {
        if (description) {
            const removeTags = `${description.replace(/<\/?[^>]+(>|$)/g, '').substr(0, TEXT_NUMBER)}...`;

            return (
                <div block={ BLOCK_ATTR } elem="TileDescription">
                    <Html content={ removeTags } />
                </div>
            );
        }

        return null;
    }

    renderButton() {
        return (
            <div block={ BLOCK_ATTR } elem="TileButton">
                { __('view now') }
            </div>
        );
    }

    /**
     * Overridden to change url
     */
    renderTile(category) {
        const {
            id,
            image,
            name,
            description,
            display_mode,
            url,
            category_page_title,
            include_in_menu,
            breadcrumbs = []
        } = category;
        const { category_url = '' } = breadcrumbs[0];
        const categoryName = category_url.replaceAll('.html', '').replaceAll('/', '').toLowerCase();
        const replacedUrl = (url === `/${categoryName}/${categoryName}-inks-toners.html`)
            ? `/ink-toner-cartridges/${categoryName}.html` : '';
        const linkData = {
            pathname: replacedUrl || url
        };

        if (replacedUrl === '') {
            linkData.state = {
                category: id,
                title: category_page_title || name,
                categoryData: {
                    image,
                    description,
                    display_mode
                }
            };
        }
        if (include_in_menu) {
            return (
                <Link
                  key={ id }
                  to={ linkData }
                  block={ BLOCK_ATTR }
                  elem="SubcategoryTile"
                >
                    { this.renderImage(image, category_page_title || name) }
                    { this.renderTitle(category_page_title || name) }
                    { !category_page_title && this.renderDescription(description) }
                    { this.renderButton() }
                </Link>
            );
        }

        return null;
    }

    renderTiles() {
        const {
            subcategories
        } = this.props;

        return subcategories.map((category) => this.renderTile(category));
    }

    render() {
        const { title } = this.props;

        return (
            <div block={ BLOCK_ATTR }>
                <h1 block={ BLOCK_ATTR } elem="Title">{ title }</h1>
                <div block={ BLOCK_ATTR } elem="Tiles">
                    { this.renderTiles() }
                </div>
            </div>
        );
    }
}

export default CategorySubacategoriesTilesComponent;
