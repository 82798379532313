import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { transformAddressForAddressForm, transformAddressForQuery } from '../../util/AmazonPay.util';
import AmazonAddressComponent from './AmazonAddress.component';

export const AmazonPayDispatcher = import(
    '../../store/AmazonPay/AmazonPay.dispatcher'
);

/** @namespace Amazonpay/Component/AmazonAddress/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    formCheckoutSession: state.AmazonPayReducer.formCheckoutSession,
    checkoutSession: state.AmazonPayReducer.checkoutSession,
    isCustomAddressExpanded: state.AmazonPayReducer.state.isCustomAddressExpanded
});

/** @namespace Amazonpay/Component/AmazonAddress/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateFormCheckoutSession:
        (checkoutSessionFields) => AmazonPayDispatcher.then(({ default: dispatcher }) => {
            dispatcher.updateFormCheckoutSession(dispatch, checkoutSessionFields);
        }),
    fetchShippingEstimate:
        (shippingAddress) => AmazonPayDispatcher.then(({ default: dispatcher }) => {
            dispatcher.fetchShippingEstimate(
                dispatch,
                shippingAddress
            );
        }),
    setIsCustomAddressExpanded:
        (isCustomAddressExpanded) => AmazonPayDispatcher.then(({ default: dispatcher }) => {
            dispatcher.setIsCustomAddressExpanded(dispatch, isCustomAddressExpanded);
        })
});

/** @namespace Amazonpay/Component/AmazonAddress/Container */
export class AmazonAddressContainer extends PureComponent {
    static propTypes = {
        formCheckoutSession: PropTypes.shape().isRequired,
        checkoutSession: PropTypes.shape().isRequired,
        fetchShippingEstimate: PropTypes.func.isRequired,
        isCustomAddressExpanded: PropTypes.bool.isRequired,
        setIsCustomAddressExpanded: PropTypes.func.isRequired
    };

    toggleCustomAddress = this.toggleCustomAddress.bind(this);

    onShippingEstimationFieldsChange = this.onShippingEstimationFieldsChange.bind(this);

    containerFunctions = {
        onShippingEstimationFieldsChange: this.onShippingEstimationFieldsChange,
        onCheckoutAddressTableClick: () => {},
        toggleCustomAddress: this.toggleCustomAddress
    };

    containerProps = () => {
        const { isCustomAddressExpanded } = this.props;

        const {
            checkoutSession,
            formCheckoutSession: {
                formShippingAddress
            },
            checkoutSession: {
                shippingAddress
            }
        } = this.props;

        const transformedFormShippingAddress = transformAddressForAddressForm(formShippingAddress);
        const transformedCheckoutShippingAddress = transformAddressForAddressForm(shippingAddress);

        return {
            isCustomAddressExpanded,
            formShippingAddress: transformedFormShippingAddress,
            shippingAddress: transformedCheckoutShippingAddress
        };
    };

    onShippingEstimationFieldsChange(shippingAddress) {
        const { fetchShippingEstimate } = this.props;
        const transformedAddress = transformAddressForQuery(shippingAddress);

        fetchShippingEstimate(transformedAddress);
    }

    toggleCustomAddress() {
        const { isCustomAddressExpanded, setIsCustomAddressExpanded } = this.props;
        setIsCustomAddressExpanded(!isCustomAddressExpanded);
    }

    render() {
        return (
            <AmazonAddressComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AmazonAddressContainer);
