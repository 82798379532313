import { ProductListQuery as PrinterbaseProductListQuery } from 'PrinterbaseQuery/ProductList.query';
import { Field } from 'Util/Query';

/** @namespace Scandipwa/Query/ProductList/Query */
export class ProductListQuery extends PrinterbaseProductListQuery {
    getSalableQty(productIds) {
        return new Field('salableQuantity')
            .addArgument('productIds', '[Int]', productIds)
            .addFieldList(this._getSalableQuantityFieldList());
    }

    _getSalableQuantityFieldList() {
        return [
            'productId',
            'qty'
        ];
    }

    _getPromotionsField() {
        return new Field('promotions')
            .addFieldList(this._getPromotionsFieldList());
    }

    _getCartProductInterfaceFields() {
        return [
            ...super._getCartProductInterfaceFields(),
            this._getCategoriesField(),
            'manufacturer'
        ];
    }

    _getPromotionsFieldList() {
        return [
            'name',
            'description',
            'color',
            'icon',
            'datefrom',
            'dateto'
        ];
    }

    _getCategoryFields() {
        return [
            'id',
            'name',
            'url',
            'category_page_title',
            this._getBreadcrumbsField()
        ];
    }

    _getCapacityColourFields() {
        return new Field('capacity_colour')
            .addFieldList([
                'colour_mnemonic',
                'colour_name',
                'colour_thumb_image_url',
                'capacity_value',
                'capacity_unity'
            ]);
    }

    /**
     * Overriden to add attribute_set_name
     */
    _getProductInterfaceFields(isVariant, isForLinkedProducts = false) {
        const {
            isPlp = false,
            isSingleProduct = false,
            noAttributes = false,
            noVariants = false,
            noVariantAttributes = false,
            notRequireInfo = false
        } = this.options;

        const fields = [
            'uid',
            'id',
            'sku',
            'name',
            'type_id',
            'stock_status',
            'salable_qty',
            'promotion',
            'manufacturer',
            'discount_percentage',
            'attribute_set_name',
            'upsell_exists',
            'compat_basket_saving',
            'custom_pdp_layout',
            'custom_position',
            'replacement_url',
            'replacement_text',
            this._getPromotionsField(),
            this._getStockItemField(),
            this._getPriceRangeField(),
            this._getProductLinksField(),
            this._getShortDescriptionField(),
            this._getProductImageField()
        ];

        if (isSingleProduct) {
            fields.push(
                'syndicated_content'
            );
        }

        // Additional fields, which we want to return always, except when it's variants on PLP (due to hugh number of items)
        if (!(isPlp && isVariant)) {
            fields.push(
                this._getProductSmallField(),
                this._getTierPricesField()
            );

            if (!notRequireInfo) {
                fields.push(
                    this._getProductThumbnailField(),
                    'special_from_date',
                    'special_to_date'
                );
            }
        }

        // if it is normal product and we need attributes
        // or if, it is variant, but we need variant attributes or variants them-self
        if ((!isVariant && !noAttributes) || (isVariant && !noVariantAttributes && !noVariants)) {
            fields.push(this._getAttributesField(isVariant));
        }

        // to all products (non-variants)
        if (!isVariant) {
            fields.push(
                'url'
            );

            if (!notRequireInfo) {
                fields.push(
                    this._getUrlRewritesFields(),
                    this._getReviewCountField(),
                    this._getRatingSummaryField(),
                    this._getCustomizableProductFragment()
                );
            }

            // if variants are not needed
            if (!noVariants) {
                fields.push(
                    this._getConfigurableProductFragment(),
                    this._getBundleProductFragment(),
                    this._getGroupedProductItems()
                );
            }
        }

        // prevent linked products from looping
        if (isForLinkedProducts) {
            fields.push(this._getProductLinksField());
        }

        // additional information to PDP loads
        if (isSingleProduct) {
            fields.push(
                'stock_status',
                'breadcrumbCategoryId',
                this._getDescriptionField(),
                this._getMediaGalleryField(),
                this._getSimpleProductFragment()
            );

            // for variants of PDP requested product
            if (!isVariant) {
                fields.push(
                    'canonical_url',
                    'meta_title',
                    'meta_keyword',
                    'meta_description',
                    this._getCategoriesField(),
                    this._getReviewsField(),
                    this._getVirtualProductFragment(),
                    this._getCustomizableProductFragment()
                );
            }
        }

        fields.push(this._getCapacityColourFields());

        return fields;
    }

    /**
     * Overriden to add to add instock filter argument
     */
    _getFilterArgumentMap() {
        const foo = {
            ...super._getFilterArgumentMap(),
            stockStatus: (value) => ({ stock_status: { eq: value } })
        };

        return foo;
    }

    _getCustomizableSelectionValueFields() {
        return [
            'uid',
            'option_type_id',
            'price',
            'priceInclTax',
            'priceExclTax',
            'price_type',
            'currency',
            'sku',
            'title',
            'sort_order',
            'display_price',
            'total_savings',
            'mono_prints',
            'colour_prints',
            'extra_pages_mono',
            'extra_pages_colour',
            'fixed_cost'
        ];
    }
}

export default new ProductListQuery();
