import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import SubcategoriesComponent from './Subcategories.component';

/** @namespace Scandipwa/Component/Subcategories/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    subcategories: state.CategoryReducer.category.children,
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace Scandipwa/Component/Subcategories/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Scandipwa/Component/Subcategories/Container */
export class SubcategoriesContainer extends PureComponent {
    static propTypes = {
        subcategories: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                product_count: PropTypes.number,
                url: PropTypes.string
            })
        ),
        isMobile: PropTypes.bool.isRequired
    };

    static defaultProps = {
        subcategories: []
    };

    containerFunctions = {
        onDropDownClick: this.onDropDownClick.bind(this)
    };

    __construct() {
        this.state = {
            isDropdownVisible: false
        };
    }

    onDropDownClick() {
        this.setState((prevState) => ({
            ...prevState,
            isDropdownVisible: !prevState.isDropdownVisible
        }));
    }

    containerProps() {
        const { subcategories, isMobile } = this.props;
        const { isDropdownVisible } = this.state;

        return {
            subcategories,
            isDropdownVisible,
            isMobile
        };
    }

    render() {
        return (
            <SubcategoriesComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SubcategoriesContainer));
