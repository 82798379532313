export const KLEVU_SET_SEARCHPAGE_PRODUCT_IDS = 'KLEVU_SET_SEARCHPAGE_PRODUCT_IDS';
export const KLEVU_SET_SEARCHPAGE_CATEGORIES = 'KLEVU_SET_SEARCHPAGE_CATEGORIES';
export const UPDATE_TOTAL_RESULTS = 'UPDATE_TOTAL_RESULTS';
export const UPDATE_DISPLAY_PRODUCT = 'UPDATE_DISPLAY_PRODUCT';

/** @namespace KlevuSearch/Store/KlevuSearch/Actions/updateTotalKlevuResults */
export const updateTotalKlevuResults = ({ productsNum, othersNum }) => ({
    type: UPDATE_TOTAL_RESULTS,
    payload: {
        totalProductsNum: productsNum,
        totalOthersNum: othersNum
    }
});

/** @namespace KlevuSearch/Store/KlevuSearch/Actions/updateIsDisplayProduct */
export const updateIsDisplayProduct = (bool) => ({
    type: UPDATE_DISPLAY_PRODUCT,
    payload: { isDisplayProduct: bool }
});

/** @namespace KlevuSearch/Store/KlevuSearch/Actions/setSearchPageProductIds */
export const setSearchPageProductIds = (searchPageProductIds) => ({
    type: KLEVU_SET_SEARCHPAGE_PRODUCT_IDS,
    payload: { searchPageProductIds }
});

/** @namespace KlevuSearch/Store/KlevuSearch/Actions/setSearchPageCategories */
export const setSearchPageCategories = (searchPageCategories) => ({
    type: KLEVU_SET_SEARCHPAGE_CATEGORIES,
    payload: { searchPageCategories }
});
