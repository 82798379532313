import React from 'react';

import AmazonPay from '../component/AmazonPay';
import { AMAZON_PAY_FE_LOCATIONS } from '../component/AmazonPay/AmazonPay.config';

const addAmazonBeforeActions = (args, cb) => (
    <>
        <AmazonPay
          location={ AMAZON_PAY_FE_LOCATIONS.MINICART }
        />
        { cb(...args) }
    </>
);

export default {
    'Component/CartOverlay/Component': {
        'member-function': {
            renderActions: [
                {
                    position: 1,
                    implementation: addAmazonBeforeActions
                }
            ]
        }
    }
};
