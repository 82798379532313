import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/PureForm/Field';
import FIELD_TYPE from 'Component/PureForm/Field/Field.config';
import Form from 'Component/PureForm/Form';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

import { DONT_MISS_MESSAGE, SUBSCRIBE_MESSAGE } from './CheckoutSuccessNewsletter.config';

import './CheckoutSuccessNewsletter.style';

/** @namespace Scandipwa/Component/CheckoutSuccessNewsletter/Component */
export class CheckoutSuccessNewsletterComponent extends PureComponent {
    static propTypes = {
        email: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        onSubSuccess: PropTypes.func.isRequired,
        onSubError: PropTypes.func.isRequired
    };

    renderNewsletterSubscriptionForm() {
        const {
            email,
            name,
            onSubError,
            onSubSuccess
        } = this.props;

        return (
        <div
          block="CheckoutSuccessNewsletter"
          elem="NewsletterForm"
        >
            <h3>{ DONT_MISS_MESSAGE }</h3>
            <p>{ SUBSCRIBE_MESSAGE }</p>
            <Form
              key="Subscribe"
              onSubmit={ onSubSuccess }
              onError={ onSubError }
            >
                <Field
                  label={ __('Email') }
                  type={ FIELD_TYPE.email }
                  attr={ {
                      id: 'email',
                      name: 'email',
                      placeholder: __('Your email address'),
                      defaultValue: email
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: VALIDATION_INPUT_TYPE.email
                  } }
                />
                <Field
                  type={ FIELD_TYPE.text }
                  label={ __('Name') }
                  attr={ {
                      id: 'name',
                      name: 'name',
                      defaultValue: name,
                      placeholder: __('Your name')
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      inputType: VALIDATION_INPUT_TYPE.alphaSpace,
                      isRequired: true
                  } }
                />
                <div block="CheckoutSuccessNewsletter" elem="SubscribeButton">
                    <button
                      block="Button"
                      mods={ { isAccent: true } }
                    >
                        { __('Subscribe') }
                    </button>
                </div>
            </Form>
        </div>
        );
    }

    render() {
        return (
            <div block="CheckoutSuccessNewsletter">
                { this.renderNewsletterSubscriptionForm() }
            </div>
        );
    }
}

export default CheckoutSuccessNewsletterComponent;
