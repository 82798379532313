import {
    ProductCustomizableOptionsContainer as SourceProductCustomizableOptionsContainer
} from 'SourceComponent/ProductCustomizableOptions/ProductCustomizableOptions.container';

/** @namespace Scandipwa/Component/ProductCustomizableOptions/Container */
export class ProductCustomizableOptionsContainer extends SourceProductCustomizableOptionsContainer {
    containerProps() {
        const { showSubscriptionPopup } = this.props;

        return {
            showSubscriptionPopup,
            ...super.containerProps()
        };
    }
}

export default ProductCustomizableOptionsContainer;
