export const CONSUMABLES = 'Consumables';
export const PAPER = 'Paper';
export const ACCESSORIES = 'Accessories';
export const WARRANTIES = 'Warranties';
export const DEFAULT = 'default';
export const THREE_BUTTONS = 3;

export const DEFINED_PRODUCT_TYPES = [
    CONSUMABLES, PAPER, ACCESSORIES, WARRANTIES
];
