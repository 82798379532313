import CheckoutBilling from 'Component/CheckoutBilling';
import {
    CHECKOUT_URL
} from 'SourceRoute/Checkout/Checkout.config';
import { appendWithStoreCode } from 'SourceUtil/Url';

import { AMAZON_PAY_BILLING_STEP, BILLING_STEP } from '../route/Checkout/Checkout.config';

export const renderAmazonPayBillingStep = (instance) => {
    const {
        email,
        setLoading,
        onEmailChange,
        setDetailsStep,
        shippingAddress,
        isGuestEmailSaved,
        savePaymentInformation,
        updatePrivacyConsentSelection,
        onShippingMethodSelect
    } = instance.props;

    const mail = {
        email,
        onEmailChange,
        isGuestEmailSaved
    };

    return (
        <CheckoutBilling
          mailProps={ mail }
          setLoading={ setLoading }
          paymentMethods={ [] }
          setDetailsStep={ setDetailsStep }
          shippingAddress={ shippingAddress }
          savePaymentInformation={ savePaymentInformation }
          updatePrivacyConsentSelection={ updatePrivacyConsentSelection || (() => {}) }
          onShippingMethodSelect={ onShippingMethodSelect }
        />
    );
};

const componentDidMount = (args, callback, instance) => {
    const { checkoutStep, history } = instance.props;
    const { url } = instance.stepMap[checkoutStep];

    const searchQuery = location.search;

    instance.updateHeader();

    history.replace(appendWithStoreCode(`${CHECKOUT_URL}${url}${searchQuery}`));
};

const updateStep = (args, callback, instance) => {
    const { checkoutStep, history } = instance.props;
    const { url } = instance.stepMap[checkoutStep];

    const searchQuery = location.search;

    history.push(appendWithStoreCode(`${CHECKOUT_URL}${url}${searchQuery}`));
};

export const stepMap = (originalMember, instance) => ({
    ...originalMember,
    [AMAZON_PAY_BILLING_STEP]: {
        ...originalMember[BILLING_STEP],
        title: __('AmazonPay'),
        url: '/billing',
        render: () => renderAmazonPayBillingStep(instance)
    }
});

export default {
    'Route/Checkout/Component': {
        'member-function': {
            componentDidMount,
            updateStep
        },
        'member-property': {
            stepMap
        }
    }
};
