import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CmsBlockQuery from 'Query/CmsBlock.query';
import DataContainer from 'Util/Request/DataContainer';

import CategoryFaqBlock from './CategoryFaqBlock.component';

/** @namespace Scandipwa/Component/CategoryFaqBlock/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    category_faq_block: state.CategoryReducer.category.category_faq_block
});
/** @namespace Scandipwa/Component/CategoryFaqBlock/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Scandipwa/Component/CategoryFaqBlock/Container */
export class CategoryFaqBlockContainer extends DataContainer {
    static propTypes = {
        ...this.propTypes,
        category_faq_block: PropTypes.number
    };

    static defaultProps = {
        ...this.defaultProps,
        category_faq_block: ''
    };

    state = {
        cmsBlock: {}
    };

    componentDidMount() {
        this._getCmsBlock();
    }

    componentDidUpdate(prevProps) {
        const { category_faq_block } = this.props;

        if (!category_faq_block) {
            return;
        }

        const { category_faq_block: preCategory_faq_block } = prevProps;

        if (category_faq_block !== preCategory_faq_block) {
            this._getCmsBlock();
        }
    }

    _getCmsBlock() {
        const { category_faq_block } = this.props;

        if (!category_faq_block) {
            return;
        }

        this.fetchData(
            [CmsBlockQuery.getQuery({ identifiers: [category_faq_block] })],
            ({ cmsBlocks: { items } }) => {
                if (!items.length) {
                    return;
                }

                this.setState({ cmsBlock: items[0] });
            }
        );
    }

    containerProps() {
        const { blockType } = this.props;
        const { cmsBlock } = this.state;

        return { cmsBlock, blockType };
    }

    render() {
        return (
            <CategoryFaqBlock
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryFaqBlockContainer);
