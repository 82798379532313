/**
 * Wyomind google customer reviews compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import Badge from '../component/Badge';

const BADGE = 'BADGE_SHOWN';

const renderBadge = () => <Badge />;

const AFTER_ITEMS_TYPE = (originalMember) => [
    ...originalMember,
    {
        component: renderBadge(),
        position: 30,
        name: BADGE
    }
];

export const config = {
    'Component/Router/Component': {
        'member-function': {
            AFTER_ITEMS_TYPE
        }
    }
};

export default config;
