// import { CONFIGURABLE, SIMPLE} from '@scandipwa/scandipwa/src/util/Product/Types';
import PRODUCT_TYPE from 'Component/Product/Product.config';

import { NOT_APPLICABLE } from '../component/GoogleTagManager/GoogleTagManager.config';
import {
    getBrand,
    getCategories, getPrice, getProductName, getVariant
} from './baseProduct.data';

/** @namespace GtmNew/EventData/ActionProduct/Data/getDimensionOne */
export const getDimensionOne = (type_id, product, configurableVariantIndex, mainSku) => {
    if (type_id !== PRODUCT_TYPE.configurable) {
        return NOT_APPLICABLE;
    }

    if (!configurableVariantIndex && mainSku && type_id === PRODUCT_TYPE.configurable) {
        const { variants } = product;
        return variants.find(({ sku }) => sku === mainSku).name;
    }

    if (!isNaN(configurableVariantIndex)) {
        const {
            name = ''
        } = product.variants[configurableVariantIndex];

        return name;
    }
};

/** @namespace GtmNew/EventData/ActionProduct/Data/getDimensionTwo */
export const getDimensionTwo = (type_id, product, configurableVariantIndex, inQtyChange = false, mainSku) => {
    if (type_id !== PRODUCT_TYPE.configurable) {
        return NOT_APPLICABLE;
    }

    if (!isNaN(configurableVariantIndex)) {
        if (inQtyChange) {
            return mainSku;
        }
        const {
            sku
        } = product.variants[configurableVariantIndex];

        return sku;
    }
};

/** @namespace GtmNew/EventData/ActionProduct/Data/getCustomDimensionsQtyChange */
export const getCustomDimensionsQtyChange = (item) => {
    const { sku: actionSku, product: { variants = [], dimensions, type_id } } = item;

    if (type_id === PRODUCT_TYPE.simple) {
        return dimensions ? JSON.parse(dimensions) : null;
    }

    if (type_id === 'configurable') {
        const correctVarint = variants.find(({ sku }) => sku === actionSku);
        const custom = correctVarint.dimensions;

        return custom ? JSON.parse(custom) : null;
    }
};

/** @namespace GtmNew/EventData/ActionProduct/Data/getCustomDimensions */
export const getCustomDimensions = (product, confIndex) => {
    const {
        type_id = PRODUCT_TYPE.simple, dimensions, variants
    } = product;

    if (type_id === PRODUCT_TYPE.simple) {
        return dimensions ? JSON.parse(dimensions) : null;
    }

    if (type_id === 'configurable') {
        const custom = variants[confIndex].dimensions;
        return custom ? JSON.parse(custom) : null;
    }
};

/** @namespace GtmNew/EventData/ActionProduct/Data/productAddToCart */
export const productAddToCart = ({ product, quantity = 0, configurableVariantIndex = false }) => {
    const {
        type_id = PRODUCT_TYPE.simple, categories, sku, price = false, dimensions, variants, attribute_set_name
    } = product;

    const dimenstion5 = attribute_set_name.split(' ')[0];
    return {
        name: getProductName(type_id, product),
        id: sku,
        price: getPrice(product, type_id, price),
        category: getCategories(categories),
        brand: getBrand(product),
        quantity,
        ...getCustomDimensions(product, configurableVariantIndex),
        dimenstion5
    };
};

/** @namespace GtmNew/EventData/ActionProduct/Data/productQtyChangeData */
export const productQtyChangeData = ({ product: item, configurableVariantIndex = false }) => {
    const {
        qty = false, price, sku: mainSku = '', product: {
            type_id = PRODUCT_TYPE.simple, categories, sku: parentSku, name: parentName, variants, dimensions, attribute_set_name
        }, product
    } = item;

    const dimension5 = attribute_set_name.split(' ')[0];
    return {
        name: parentName,
        id: parentSku,
        price: (type_id === PRODUCT_TYPE.configurable)
            ? getPrice(variants[0], type_id, price, false)
            : getPrice(product, type_id, price, false),
        category: getCategories(categories),
        brand: getBrand(product),
        quantity: Math.abs(qty),
        ...getCustomDimensionsQtyChange(item),
        dimension5
    };
};
