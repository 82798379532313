import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { updateIsDisplayProduct } from '../../store/KlevuSearch.actions';
import { changeTotalPageCount, updateCurrentPage } from '../../store/ProductList.action';
import { updateSortFields } from '../../store/ProductListInfo.action';
import { klevuConfigType } from '../../types/KlevuSearch';
import { KLEVU_SEARCH_LIMIT } from '../KlevuSearch/KlevuSearch.config';
import KlevuSearchSwitch from './KlevuSearchSwitch.component';

/** @namespace KlevuSearch/Component/KlevuSearchSwitch/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
    isDisplayProduct: _state.KlevuSearchReducer.isDisplayProduct,
    productsCount: _state.KlevuSearchReducer.totalProductsNum,
    othersCount: _state.KlevuSearchReducer.totalOthersNum,
    currentPage: _state.ProductListReducer.currentArgs.currentPage,
    isLoading: _state.ProductListReducer.currentArgs.isLoading,
    klevuConfig: _state.ConfigReducer.klevu
});

/** @namespace KlevuSearch/Component/KlevuSearchSwitch/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    updateKlevuFilter: (bool) => _dispatch(updateIsDisplayProduct(bool)),
    updatePageCount: (pageCount) => _dispatch(changeTotalPageCount(pageCount)),
    updateCurrentPage: (currentPage) => _dispatch(updateCurrentPage(currentPage)),
    updateSortFields: (bool) => _dispatch(updateSortFields(bool))
});

/** @namespace KlevuSearch/Component/KlevuSearchSwitch/Container */
export class KlevuSearchSwitchContainer extends PureComponent {
    static propTypes = {
        isDisplayProduct: PropTypes.bool.isRequired,
        updateKlevuFilter: PropTypes.func.isRequired,
        updatePageCount: PropTypes.func.isRequired,
        updateCurrentPage: PropTypes.func.isRequired,
        updateSortFields: PropTypes.func.isRequired,
        productsCount: PropTypes.number.isRequired,
        othersCount: PropTypes.number.isRequired,
        currentPage: PropTypes.number.isRequired,
        isLoading: PropTypes.bool.isRequired,
        klevuConfig: klevuConfigType.isRequired
    };

    containerFunctions = {
        handleSwitch: this.handleSwitch.bind(this)
    };

    handleSwitch(bool) {
        const {
            updateKlevuFilter,
            updateSortFields,
            updatePageCount,
            productsCount,
            othersCount,
            updateCurrentPage,
            currentPage
        } = this.props;

        if (bool) {
            const pageCount = Math.ceil(productsCount / KLEVU_SEARCH_LIMIT);

            if (currentPage > pageCount) {
                updateCurrentPage(pageCount);
            }

            updatePageCount(pageCount);
        } else {
            const pageCount = Math.ceil(othersCount / KLEVU_SEARCH_LIMIT);

            if (currentPage > pageCount) {
                updateCurrentPage(pageCount);
            }

            updatePageCount(pageCount);
        }

        updateKlevuFilter(bool);
        updateSortFields(bool);
    }

    containerProps = () => {
        const {
            isDisplayProduct, productsCount,
            othersCount, isLoading,
            klevuConfig = {}
        } = this.props;

        const klevu_display_cms = klevuConfig?.klevu_display_cms;

        return ({
            isDisplayProduct,
            productsCount,
            othersCount,
            isLoading,
            isDisplayCms: Boolean(Number(klevu_display_cms))
        });
    };

    render() {
        return (
            <KlevuSearchSwitch
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KlevuSearchSwitchContainer);
