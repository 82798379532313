import React from 'react';

import CheckoutDeliveryOptions from 'Component/CheckoutDeliveryOptions';
import Loader from 'Component/Loader';
import Form from 'Component/PureForm/Form';
import BrowserDatabase from 'Util/BrowserDatabase';

import AmazonAddress from '../component/AmazonAddress';
import { BILLING_STEP } from '../route/Checkout/Checkout.config';
import { isAmazonCheckout } from '../util/AmazonPay.util';
import {
    AMAZONSELECTEDSHIPPINGMETHOD,
    ONE_DAY_IN_SECONDS
} from './AmazonPayShipping.config';

import '../component/CheckoutBilling/CheckoutBilling.style.scss';
import './CheckoutBilling.override.style.plugin.scss';

const renderCarrier = (instance) => {
    const {
        shippingEstimate: {
            shippingMethods,
            selectedCarrier
        },
        onShippingMethodSelect
    } = instance.props;

    const firstCarrier = {
        method_code: shippingMethods[0] ? shippingMethods[0].method_code : '',
        carrier_code: shippingMethods[0] ? shippingMethods[0].carrier_code : ''
    };

    BrowserDatabase.setItem(
        selectedCarrier.method_code === '' ? firstCarrier : selectedCarrier,
        AMAZONSELECTEDSHIPPINGMETHOD,
        ONE_DAY_IN_SECONDS
    );

    if (shippingMethods && shippingMethods.length) {
        return (
                <CheckoutDeliveryOptions
                  shippingMethods={ shippingMethods }
                  onShippingMethodSelect={ onShippingMethodSelect }
                  estimateAddress={ {} }
                  onStoreSelect={ null }
                  selectedShippingMethod={ selectedCarrier.method_code === '' ? firstCarrier : selectedCarrier }
                />
        );
    }

    return <Loader />;
};

const render = (args, cb, instance) => {
    const {
        onBillingError,
        onBillingSuccess,
        isPaymentProcessing
    } = instance.props;

    if (!isAmazonCheckout()) {
        return cb(...args);
    }

    const buttonText = isPaymentProcessing ? __('Please wait...') : __('Pay with Amazon');

    return (
            <Form
              mix={ { block: 'CheckoutBilling' } }
              attr={ {
                  id: BILLING_STEP
              } }
              onError={ onBillingError }
              onSubmit={ onBillingSuccess }
            >
                { instance.renderPayments() }
                { <AmazonAddress /> }
                { renderCarrier(instance) }
                { instance.renderTermsAndConditions() }
                { instance.renderPopup() }
                <button
                  block="CheckoutBilling"
                  elem="Button"
                  disabled={ isPaymentProcessing }
                  mods={ {
                      isLoading: isPaymentProcessing
                  } }
                  mix={ { block: 'Button', mod: 'full' } }
                  type="submit"
                >
                    { buttonText }
                </button>
            </Form>
    );
};

const config = {
    'Component/CheckoutBilling/Component': {
        'member-function': {
            render
        }
    }
};

export default config;
