import PRODUCT_TYPE from 'Component/Product/Product.config';
import { roundPrice } from 'Util/Price';

import {
    CATEOGRY_LIMIT, GROUPED, NOT_APPLICABLE
} from '../component/GoogleTagManager/GoogleTagManager.config';

/** @namespace GtmNew/EventData/BaseProduct/Data/getProductName */
export const getProductName = (type, product, mainSku = '', click) => {
    const { name = '' } = product;
    if (click) {
        return NOT_APPLICABLE;
    }

    if (type === PRODUCT_TYPE.simple) {
        return name;
    }

    if (type === PRODUCT_TYPE.configurable && mainSku) {
        const { variants } = product;
        return variants[0].name;
    }

    return name;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getProductSKU */
export const getProductSKU = (type, product, mainSku = '', click) => {
    const { sku = '' } = product;
    if (click) {
        return NOT_APPLICABLE;
    }

    if (type === PRODUCT_TYPE.simple) {
        return sku;
    }

    if (type === PRODUCT_TYPE.configurable && mainSku) {
        const { variants } = product;
        return variants.find(({ sku }) => sku === mainSku).sku;
    }

    return sku;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getCategories */
export const getCategories = (categories = []) => {
    if (!Array.isArray(categories)) {
        return [];
    }

    const limitedCategories = categories.slice(0, CATEOGRY_LIMIT).map(({ name }) => name);
    const categoryString = limitedCategories.join('/');
    return categoryString.charAt(0) === '/' ? categoryString.substring(1) : categoryString;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getQty */
export const getQty = (qty) => {
    if (qty) {
        return { quantity: qty };
    }

    return null;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getVariantIndex */
export const getVariantIndex = (type_id, variants, configurableVariantIndex) => {
    if (type_id === PRODUCT_TYPE.simple) {
        return -1;
    // eslint-disable-next-line no-restricted-globals
    } if (!isNaN(configurableVariantIndex) && configurableVariantIndex >= 0 && configurableVariantIndex !== false) {
        return configurableVariantIndex;
    }
    if (variants[0] !== undefined) {
        return 0;
    }

    return -1;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getPrice */
export const getPrice = (variant, type_id, price, notInGetProduct = true) => {
    if (price && notInGetProduct) {
        return price;
    }

    const {
        cart_price: cartItemPrice = null,
        price_range: {
            minimum_price: {
                final_price: {
                    value: discountValue = null
                } = {},
                regular_price: {
                    value = 0
                } = {}
            } = {}
        } = {}
    } = variant;

    return type_id !== GROUPED
        ? +roundPrice(cartItemPrice || discountValue || value) || 0
        : 0;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getBrand */
export const getBrand = (product) => {
    const {
        attributes: {
            manufacturer = {}
        } = {},
        manufacturer: productClickManufacturer = []
    } = product || {};

    if ((manufacturer === undefined
        && productClickManufacturer === undefined)
        || !productClickManufacturer.length
        || productClickManufacturer[0] === 'false') {
        return NOT_APPLICABLE;
    }

    if (productClickManufacturer[0]) {
        return productClickManufacturer[0];
    }
    const {
        attribute_options,
        attribute_value
    } = manufacturer;

    if (!attribute_options[attribute_value]) {
        return NOT_APPLICABLE;
    }

    const { label } = attribute_options[attribute_value];
    return label;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getVariant */
export const getVariant = (product, type_id, configurableVariantIndex = false) => {
    const {
        variants,
        attributes: {
            size
        } = {}
    } = product;

    if (size === undefined || type_id === PRODUCT_TYPE.simple || variants[0] === undefined) {
        return NOT_APPLICABLE;
    }

    const configIndex = !configurableVariantIndex ? 0 : configurableVariantIndex;

    const {
        attribute_options
    } = size;

    const {
        attributes: {
            size: {
                attribute_value
            } = {}
        } = {}
    } = variants[configIndex];

    const { swatch_data: { value } = {} } = attribute_options[attribute_value];
    return value;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getPosition */
export const getPosition = (position, click) => {
    if (click && position) {
        return { position };
    }

    return null;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getBaseCustomDimensions */
// eslint-disable-next-line consistent-return
export const getBaseCustomDimensions = (item, click) => {
    const { sku: actionSku, product: { variants = [], dimensions, type_id } = {} } = item;

    if (type_id === PRODUCT_TYPE.simple || click) {
        return dimensions ? JSON.parse(dimensions) : null;
    }

    if (type_id === 'configurable') {
        const correctVarint = variants.find(({ sku }) => sku === actionSku);
        const custom = correctVarint ? correctVarint.dimensions : false;

        return custom ? JSON.parse(custom) : null;
    }
};

/** @namespace GtmNew/EventData/BaseProduct/Data/productType */
export const productType = (attribute_set_name = '') => attribute_set_name.split(' ')[0];

/** @namespace GtmNew/EventData/BaseProduct/Data/baseProductData */
export const baseProductData = (item, click = false, notInGetProduct = true, event = '') => {
    const {
        qty = false, price, product: {
            type_id = PRODUCT_TYPE.simple, categories,
            sku: parentSku, position = 0, name: parentName,
            variants, attribute_set_name = ''
        }, product
    } = item;

    const data = {
        name: parentName,
        id: parentSku,
        price: (type_id === PRODUCT_TYPE.configurable && notInGetProduct === false)
            ? getPrice(variants[0], type_id, price, notInGetProduct)
            : getPrice(product, type_id, price, notInGetProduct),
        ...getQty(qty),
        ...getPosition(position, click),
        category: getCategories(categories),
        brand: getBrand(product),
        ...getBaseCustomDimensions(item, click),
        dimension5: productType(attribute_set_name) // Dimension 5
    };

    return data;
};
