import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { Html } from 'Component/Html/Html.component';

import './CategoryDescription.style';

/** @namespace Scandipwa/Component/CategoryDescription/Component */
export class CategoryDescriptionComponent extends PureComponent {
    static propTypes = {
        description: PropTypes.string
    };

    static defaultProps = {
        description: ''
    };

    /**
     * Overriden to check if undifined
     */
    render() {
        const { description } = this.props;

        if (!description) {
            return null;
        }

        return (
            <div block="CategoryDescription">
                <Html content={ description } />
            </div>
        );
    }
}

export default CategoryDescriptionComponent;
