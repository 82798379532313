/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable no-magic-numbers */
/* eslint-disable react/boolean-prop-naming */
/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import Modal from 'react-modal';

import CloseIcon from 'Component/CloseIcon';
import Html from 'Component/Html';
import Image from 'Component/Image';
import Loader from 'Component/Loader';
import PRODUCT_TYPE from 'Component/Product/Product.config';
import { SWATCH_ATTRIBUTE_SOURCE } from 'Component/ProductCard/ProductCard.config';
import ProductConfigurableAttributes
from 'Component/ProductConfigurableAttributes/ProductConfigurableAttributes.container';
import ProductPrice from 'Component/ProductPrice';
import TextPlaceholder from 'Component/TextPlaceholder';
import TierPrices from 'Component/TierPrices';
import { GRID_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import { LayoutType } from 'Type/Layout';
import { PriceType, ProductType } from 'Type/ProductList';
import { filterConfigurableOptions } from 'Util/Product';

import './AddToBasketLinksCard.style';

if (document.getElementById('AddToBasket')) {
    Modal.setAppElement('#AddToBasket');
}

/** @namespace Scandipwa/Component/AddToBasketLinksCard/Component */
export class AddToBasketLinksCardComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        registerSharedElement: PropTypes.func.isRequired,
        getActiveProduct: PropTypes.func.isRequired,
        inStock: PropTypes.bool.isRequired,
        productPrice: PriceType.isRequired,
        setActiveProduct: PropTypes.func.isRequired, // might not be used
        parameters: PropTypes.objectOf(PropTypes.string).isRequired,
        isLinks: PropTypes.bool.isRequired,
        children: PropTypes.element,
        isMobile: PropTypes.bool.isRequired,
        layout: LayoutType,
        isLoading: PropTypes.bool,
        addToBasket: PropTypes.func.isRequired,
        thumbnail: PropTypes.string,
        setQuickView: PropTypes.func.isRequired,
        isQuickViewVisible: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        isAdding: PropTypes.bool.isRequired,
        showStockNotifyPopup: PropTypes.func.isRequired
    };

    static defaultProps = {
        children: null,
        layout: GRID_LAYOUT,
        thumbnail: '',
        isLoading: false
    };

    imageRef = createRef();

    registerSharedElement = () => {
        const { registerSharedElement } = this.props;
        registerSharedElement(this.imageRef);
    };

    /**
     * Render swatch image for PDP related products
     */
    renderConsumableSwatch() {
        const {
            product: {
                attributes: {
                    color: {
                        attribute_value,
                        attribute_options: {
                            [attribute_value]: {
                                label,
                                swatch_data: {
                                    value
                                } = {}
                            } = {}
                        } = {}
                    } = {}
                } = {}
            } = {}
        } = this.props;

        return (
            <div
              block="ProductCard"
              elem="Swatch"
              mix={ { block: 'AddToBasketLinksCard', elem: 'Swatch' } }
              mods={ { isEmpty: !value } }
            >
                { value && ( // If no value, omit the image
                    <img
                      alt={ label }
                      block="ProductCard"
                      elem="Swatch-Image"
                      src={ `${SWATCH_ATTRIBUTE_SOURCE}${value}` }
                      mix={ { block: 'AddToBasketLinksCard', elem: 'Swatch-Image' } }
                    />
                ) }
            </div>
        );
    }

    /**
     * Render swatch image for PDP related products
     */
    renderQuickViewConsumableSwatch() {
        const {
            product: {
                capacity_colour = {}
            } = {}
        } = this.props;

        return (
            <div
              block="AddToBasketLinksCard"
              elem="Swatch"
            >
                { capacity_colour.map((colour) => this.renderSwatchItems(colour)) }
            </div>
        );
    }

    renderSwatchItems(colour) {
        const {
            capacity_unity,
            capacity_value,
            colour_thumb_image_url,
            colour_mnemonic,
            colour_name
        } = colour;

        return (
            <div
              block="AddToBasketLinksCard"
              elem="QuickViewSwatch"
              key={ colour_mnemonic }
            >
                <img
                  alt={ colour_name }
                  block="AddToBasketLinksCard"
                  elem="Swatch-Image"
                  src={ `${colour_thumb_image_url}` }
                />
                <p
                  block="AddToBasketLinksCard"
                  elem="QuickViewSwatchText"
                >
                    { `${ capacity_value } ${capacity_unity}` }
                </p>
            </div>
        );
    }

    getConfigurableAttributes() {
        const {
            product: { configurable_options: configurableOptions = {}, variants = {} }
        } = this.props;

        const filteredOptions = filterConfigurableOptions(configurableOptions, variants);

        return Object.fromEntries(Object.entries(filteredOptions).filter(([, option]) => {
            const { attribute_options: attributeOptions = {} } = option;

            return Object.values(attributeOptions).some(({ swatch_data: swatchData }) => swatchData);
        }));
    }

    renderRecommendedBanner() {
        return (
            <div
              block="AddToBasketLinksCard"
              elem="Recommended"
            >
                RECOMMENDED
            </div>
        );
    }

    /**
     * Overridden to change click handler
     */
    renderCardLinkWrapper(children) {
        const { setQuickView } = this.props;

        return (
            <button
              block="ProductCard"
              elem="Link"
              mix={ { block: 'AddToBasketLinksCard', elem: 'Link' } }
              onClick={ setQuickView }
            >
              { children }
            </button>
        );
    }

    /**
     * Overridden to add label
     */
    renderQuickViewFigureReview(inStock, showLabel = false) {
        const outOfStockMessage = inStock !== undefined
            ? (!inStock && <span block="ProductCard" elem="OutOfStock">{ __('Out of stock') }</span>)
            : null;

        const mods = inStock !== undefined ? { outOfStock: !inStock } : {};

        return (
            <div
              block="ProductCard"
              elem="FigureReview"
              mix={ { block: 'AddToBasketLinksCard', elem: 'FigureReview' } }
            >
                { showLabel && this.renderLabel() }
                { outOfStockMessage }
                <figure
                  block="ProductCard"
                  elem="Figure"
                  mix={ { block: 'AddToBasketLinksCard', elem: 'Figure' } }
                  mods={ mods }
                >
                    { this.renderPicture() }
                </figure>
            </div>
        );
    }

    renderQuickView() {
        const {
            layout,
            inStock,
            isMobile,
            product: {
                type_id: type,
                manufacturer
            },
            isQuickViewVisible,
            onClose
        } = this.props;

        const isCompatible = manufacturer[0] === 'Compatible';
        const isConfigurable = type === PRODUCT_TYPE.configurable;
        const position = isMobile ? 'initial' : 'absolute';
        const style = { content: { overflow: 'unset', padding: '42px 13px', position: `${position}` } };
        return (
            <Modal
              isOpen={ isQuickViewVisible }
              onRequestClose={ onClose }
              id="QuickView"
              style={ style }
            >
                { isMobile && this.renderProductRemoveBtn() }
                <div
                  block="ProductCard"
                  elem="FigureReviewContainer"
                  mix={ { block: 'AddToBasketLinksCard', elem: 'FigureReviewContainer', mods: { isMobile } } }
                >
                    { isMobile && (
                        <div
                          block="AddToBasketLinksCard"
                          elem="QuickViewFigureWrapper"
                          mods={ { isMobile } }
                        >
                            { isCompatible && this.renderRecommendedBanner() }
                            { this.renderQuickViewFigureReview(inStock) }
                        </div>
                    ) }
                    { !isMobile && (
                        <>
                            { isCompatible && this.renderRecommendedBanner() }
                            { this.renderQuickViewFigureReview(inStock) }
                        </>
                    ) }
                    { isMobile && (
                        <div
                          block="ProductCard"
                          elem="MainInfo"
                          mix={ { block: 'AddToBasketLinksCard', elem: 'QuickViewMainInfo', mods: { isMobile } } }
                        >
                            <div
                              block="ProductCard"
                              elem="MainInfo-SkuAndTitle"
                              mix={ { block: 'AddToBasketLinksCard', elem: 'QuickViewMainInfo-SkuAndTitle' } }
                            >
                                { this.renderMainDetails() }
                                { this.renderQuickViewConsumableSwatch() }
                                { this.renderShortDescriptionContent() }
                            </div>
                        </div>
                    ) }
                </div>
                <div
                  block="ProductCard"
                  elem="Content"
                  mix={ { block: 'AddToBasketLinksCard', elem: 'QuickViewContent' } }
                  mods={ { layout, isConfigurable } }
                >
                    { !isMobile && (
                        <div
                          block="ProductCard"
                          elem="MainInfo-SkuAndTitle"
                          mix={ { block: 'AddToBasketLinksCard', elem: 'QuickViewMainInfo-SkuAndTitle' } }
                        >
                                { this.renderMainDetails() }
                                { this.renderQuickViewConsumableSwatch() }
                                { this.renderShortDescriptionContent() }
                        </div>
                    ) }
                    { this.renderQuickViewCardListCheckoutInfo() }
                </div>
                { !isMobile && this.renderProductRemoveBtn() }
            </Modal>
        );
    }

    // eslint-disable-next-line consistent-return
    renderLabel() {
        const {
            product: { manufacturer, attribute_set_name }
        } = this.props;

        if (!manufacturer || !manufacturer[0] || attribute_set_name !== 'Consumables Set') {
            return null;
        }

        if (manufacturer[0] === 'Compatible') {
            return (
                <div
                  block="ProductCard"
                  elem="PremiumLabel"
                >

                    <p>
                        { __('PREMIUM COMPATIBLE') }
                    </p>
                </div>
            );
        }

        if (manufacturer[0] !== 'Compatible' && attribute_set_name === 'Consumables Set') {
            return (
            <div
              block="ProductCard"
              elem="GenuineLabel"
            >
                <p>
                    { __('GENUINE') }
                </p>
            </div>
            );
        }
    }

    renderPicture() {
        const { product: { id, name }, thumbnail } = this.props;

        this.sharedComponent = (
            <Image
              imageRef={ this.imageRef }
              src={ thumbnail }
              alt={ name }
              ratio="custom"
              mix={ { block: 'ProductCard', elem: 'Picture' } }
              isPlaceholder={ !id }
            />
        );

        return (
            <>
                { this.sharedComponent }
                <img
                  style={ { display: 'none' } }
                  alt={ name }
                  src={ thumbnail }
                />
            </>
        );
    }

    /**
     * Overridden to add label
     */
    renderFigureReview(inStock, showLabel = false) {
        const outOfStockMessage = inStock !== undefined
            ? (!inStock && <span block="ProductCard" elem="OutOfStock">{ __('Out of stock') }</span>)
            : null;

        const mods = inStock !== undefined ? { outOfStock: !inStock } : {};

        return (
            <div
              block="ProductCard"
              elem="FigureReview"
            >
                { showLabel && this.renderLabel() }
                { outOfStockMessage }
                <figure
                  block="ProductCard"
                  elem="Figure"
                  mods={ mods }
                >
                    { this.renderPicture() }
                </figure>
            </div>
        );
    }

    renderTextPlaceholder() {
        return <TextPlaceholder />;
    }

    renderPrice() {
        const {
            getActiveProduct,
            inStock,
            product: {
                type_id: baseType,
                compat_basket_saving
            } = {},
            productPrice
        } = this.props;

        const {
            price_range: priceRange,
            type_id: typeId,
            price_tiers: priceTiers
        } = getActiveProduct();

        if (!priceRange) {
            return this.renderTextPlaceholder();
        }

        if (!inStock) {
            // If configurable, do not render price
            if (typeId === PRODUCT_TYPE.configurable) {
                return null;
            }

            // If bundle is out of stock, show out of stock msg
            if (typeId === PRODUCT_TYPE.bundle) {
                return (
                    <div
                      block="ProductCard"
                      elem="PriceWrapper"
                      mix={ { block: 'AddToBasketLinksCard', elem: 'PriceWrapper' } }
                    >
                        { __('Out of stock') }
                    </div>
                );
            }
        }

        // If product is not a variant.
        const isPreview = baseType !== PRODUCT_TYPE.configurable || typeId === baseType;

        if (!productPrice) {
            return null;
        }

        return (
            <div
              block="AddToBasketLinksCard"
              elem="PriceAndSaveWrapper"
            >
                { compat_basket_saving && (
                    <div
                      block="AddToBasketLinksCard"
                      elem="SaveAmount"
                    >
                        { compat_basket_saving }
                    </div>
                ) }
                <div
                  block="ProductCard"
                  elem="PriceWrapper"
                  mix={ { block: 'AddToBasketLinksCard', elem: 'PriceWrapper' } }
                >
                    <ProductPrice
                      meta
                      price={ productPrice }
                      priceType={ typeId }
                      tierPrices={ priceTiers }
                      isPreview={ isPreview }
                    />
                </div>
            </div>
        );
    }

    renderMainDetails() {
        const { product: { name } } = this.props;

        return (
            <p
              block="ProductCard"
              elem="Name"
              mods={ { isLoaded: !!name } }
            >
                <TextPlaceholder content={ name } length="medium" />
            </p>
        );
    }

    renderSku() {
        const { getActiveProduct } = this.props;
        const product = getActiveProduct();

        if (!product || !product.attributes || !product.attributes.part_no) {
            return null;
        }

        const { attribute_value } = product.attributes.part_no;

        return (
          <span block="ProductCard" elem="Sku" itemProp="sku">
            { __('Part No: %s', attribute_value) }
          </span>
        );
    }

    /**
     * Overridden to pass isLinks props
     * remove if it has no configurable product
     */
    renderConfigurableOptions() {
        const {
            setActiveProduct,
            parameters,
            product: { type_id: type, variants = {} },
            inStock,
            isLinks
        } = this.props;

        if (type !== PRODUCT_TYPE.configurable) {
            return null;
        }

        return (
            <div
              block="ProductCard"
              elem="ActionsAttributesWrapper"
            >
                <ProductConfigurableAttributes
                    // eslint-disable-next-line no-magic-numbers
                  numberOfPlaceholders={ [2, 4] }
                  mix={ { block: 'ProductCard', elem: 'Attributes' } }
                  parameters={ parameters }
                  variants={ variants }
                  updateConfigurableVariant={ setActiveProduct }
                  configurable_options={ this.getConfigurableAttributes() }
                  isContentExpanded
                  inStock={ inStock }
                  isLinks={ isLinks }
                />
            </div>
        );
    }

    /**
     * Overridden to change stock notify functionality
     */
    renderAddToCart() {
        const {
            layout,
            addToBasket,
            inStock,
            isAdding,
            showStockNotifyPopup
        } = this.props;

        const btnTitle = isAdding ? __('Adding...') : __('Add to basket');

        if (!inStock) {
            const {
                product: {
                    attributes: {
                        part_no: {
                            attribute_value: part_number
                        } = {}
                    } = {},
                    image: {
                        url
                    } = {},
                    name,
                    sku
                } = {},
                productPrice: {
                    price: {
                        finalPriceExclTax: {
                            value
                        } = {}
                    } = {}
                } = {}
            } = this.props;
            const stockProduct = {
                part_number,
                url,
                name,
                sku,
                value
            };

            return (
                <div block="AddToBasketLinksCard" elem="StockButtonWrapper">
                    <span>out of stock</span>
                    <button
                      block="Button AddToCart"
                      mods={ { outOfStock: !inStock } }
                      onClick={ showStockNotifyPopup(stockProduct) }
                    >
                    { __('Stock notify') }
                    </button>
                </div>

            );
        }

        if (inStock) {
            return (
                <button
                  block="Button AddToCart"
                  mods={ { layout } }
                  mix={ { block: 'AddToBasketLinksCard', elem: 'AddToCart' } }
                  onClick={ addToBasket }
                  disabled={ isAdding }
                >
                    { btnTitle }
                </button>
            );
        }

        return this.renderAddToCartButton(layout);
    }

    renderProductRemoveBtn() {
        const {
            onClose,
            isMobile
        } = this.props;

        return (
            <button
              block="AddToBasketLinksCard"
              elem="CloseBtn"
              onClick={ onClose }
              aria-label={ __('Remove') }
              mods={ { isMobile } }
            >
                <CloseIcon />
            </button>
        );
    }

    /**
     * Created to render short description into the card
     */
    renderShortDescriptionContent() {
        const { product: { short_description } } = this.props;
        const { html } = short_description || {};

        const htmlWithItemProp = `<div itemProp="description">${ html }</div>`;

        return (
            <div
              block="ProductCard"
              elem="ShortDescription"
              mix={ { block: 'AddToBasketLinksCard', elem: 'ShortDescription' } }
            >
                { html && <Html content={ htmlWithItemProp } /> }
            </div>
        );
    }

    /**
     * Overridden to remove tier prices on PDP from related products section
     */
    renderQuickViewCardListCheckoutInfo() {
        const {
            children, inStock, isMobile, isLinks
        } = this.props;

        return (
            <div
              block="ProductCard"
              elem="CheckoutInfo"
              mix={ { block: 'AddToBasketLinksCard', elem: 'QuickViewCheckoutInfo' } }
              mods={ { outOfStock: !inStock } }
            >
                <div
                  block="ProductCard"
                  elem="ActionWrapper"
                >
                    <div
                      block="ProductCard"
                      elem="AttributeWrapper"
                    >
                    { this.renderPrice() }
                    </div>
                    <div block="ActionRow">
                        { this.renderAddToCart() }
                    </div>
                </div>
                <div
                  block="ProductCard"
                  elem="AdditionalContent"
                >
                    { children }
                </div>
                { (isMobile && !isLinks) && this.renderTierPrices() }
            </div>
        );
    }

    /**
     * Overridden to remove tier prices on PDP from related products section
     */
    renderCardListCheckoutInfo({ price = false } = {}) {
        const {
            children, inStock, isMobile, isLinks
        } = this.props;

        return (
            <div
              block="ProductCard"
              elem="CheckoutInfo"
              mix={ { block: 'AddToBasketLinksCard', elem: 'CheckoutInfo' } }
              mods={ { outOfStock: !inStock } }
            >
                <div
                  block="ProductCard"
                  elem="AttributeWrapper"
                >
                    { !!price && this.renderPrice() }
                </div>
                <div
                  block="ProductCard"
                  elem="ActionWrapper"
                >
                    <div block="ActionRow">
                        { this.renderAddToCart() }
                    </div>
                </div>
                <div
                  block="ProductCard"
                  elem="AdditionalContent"
                >
                    { children }
                </div>
                { (isMobile && !isLinks) && this.renderTierPrices() }
            </div>
        );
    }

    renderTierPrices() {
        const { product } = this.props;

        return (
            <div
              block="ProductCard"
              elem="TierPricesWrapper"
            >
                <TierPrices product={ product } isExclTaxesVisible={ false } />
            </div>
        );
    }

    /**
     * Overridden to conditionally render swatches and SKU
     */
    renderLinksCardListContent() {
        const {
            layout,
            inStock,
            isMobile,
            isLinks = false,
            product: {
                type_id: type,
                manufacturer
            }
        } = this.props;

        const isCompatible = manufacturer[0] === 'Compatible';
        const isConfigurable = type === PRODUCT_TYPE.configurable;

        return this.renderCardLinkWrapper((
            <>
                <div
                  block="ProductCard"
                  elem="FigureReviewContainer"
                  mix={ { block: 'AddToBasketLinksCard', elem: 'FigureReviewContainer' } }
                >
                    { isMobile && (
                        <div
                          block="AddToBasketLinksCard"
                          elem="FigureWrapper"
                        >
                            { isCompatible && this.renderRecommendedBanner() }
                            { this.renderFigureReview(inStock) }
                        </div>
                    ) }

                    { !isMobile && (
                        <>
                            { isCompatible && this.renderRecommendedBanner() }
                            { this.renderFigureReview(inStock) }
                        </>
                    ) }

                    { isMobile && (
                        <div
                          block="ProductCard"
                          elem="MainInfo"
                        >
                            <div
                              block="ProductCard"
                              elem="MainInfo-SkuAndTitle"
                            >
                                { this.renderMainDetails() }
                                { isLinks && this.renderSku() }
                            </div>
                            <div>
                                { isLinks && this.renderConsumableSwatch() }
                                { this.renderPrice() }
                            </div>
                        </div>
                    ) }
                </div>
                <div
                  block="ProductCard"
                  elem="Content"
                  mix={ { block: 'AddToBasketLinksCard', elem: 'Content' } }
                  mods={ { layout, isConfigurable } }
                >
                    { !isMobile && (
                        <>
                            <div
                              block="ProductCard"
                              elem="MainInfo-SkuAndTitle"
                            >
                                { this.renderMainDetails() }
                                { isLinks && this.renderSku() }
                            </div>
                            { isLinks && this.renderConsumableSwatch() }
                            { this.renderPrice() }
                        </>
                    ) }
                    { this.renderCardListCheckoutInfo() }
                </div>
            </>
        ));
    }

    /* main details, rendersku render figure review done */
    render() {
        const {
            isLoading,
            isQuickViewVisible
        } = this.props;

        return (
            <>
            <li
              block="ProductCard"
              mix={ { block: 'AddToBasketLinksCard', elem: 'ListItem' } }
              mods={ { isLinks: true } }
            >
                <Loader isLoading={ isLoading } />
                { this.renderLinksCardListContent() }
            </li>
              { isQuickViewVisible && this.renderQuickView() }
            </>
        );
    }
}

export default AddToBasketLinksCardComponent;
