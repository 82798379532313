/**
 * Amasty Custom Forms compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const TEXT = 'text';
export const TEXT_INPUT = 'textinput';
export const DROPDOWN = 'dropdown';
export const CHECKBOX = 'checkbox';
export const CHECKBOX_TWO = 'checkboxtwo';
export const RADIO = 'radio';
export const RADIO_TWO = 'radiotwo';
export const NUMBER = 'number';
export const HEADING_FIRST = 'hone';
export const HEADING_SECOND = 'htwo';
export const HEADING_THIRD = 'hthree';
export const GOOGLE_MAP = 'googlemap';
export const RATING = 'rating';
export const LISTBOX = 'listbox';
export const FILE = 'file';
export const WYSIWYG = 'wysiwyg';
export const CUSTOM_FORM = 'CUSTOM_FORM';
export const DATE = 'date';
export const TIME = 'time';
export const SELECT = 'select';

export const TAG_NAME_ALIAS = {
    [HEADING_FIRST]: 'h1',
    [HEADING_SECOND]: 'h2',
    [HEADING_THIRD]: 'h3',
    [TEXT]: 'div'
};

export const TYPE_ALIAS = {
    [TEXT]: 'htext',
    [TEXT_INPUT]: 'text',
    [DROPDOWN]: 'select',
    [CHECKBOX]: 'am-checkbox',
    [CHECKBOX_TWO]: 'am-checkboxtwo',
    [RADIO]: 'am-radio',
    [RADIO_TWO]: 'am-radiotwo',
    [NUMBER]: 'am-number'
};

export const FIELD_TYPE_CUSTOM = {
    number: 'am-number',
    listbox: LISTBOX
};

export const UPLOAD_FILE_ENDPOINT = '/customformscandiweb/file/upload';
export const CALLBACK_REQUEST_PRODUCTNAME = 'callback-request-productname';
export const FORM_POPUP_ID = 'MyCustomFormPopup';
export const A_FORM_ID = 'amform-form-';

// vvv these events are dispatched from document.dispatch()
export const EVENT_CUSTOM_FORM_SUBMITED = 'AmastyCustomFormSubmited';
export const EVENT_CUSTOM_FORM_FAILED = 'AmastyCustomFormFailed';
// ^^^ these events are dispatched from document.dispatch()
