import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import ChooseManufacturerComponent from './ChooseManufacturer.component';

/** @namespace Scandipwa/Component/ChooseManufacturer/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    subcategories: state.CategoryReducer.category.children,
    level: state.CategoryReducer.category.level
});

/** @namespace Scandipwa/Component/ChooseManufacturer/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Scandipwa/Component/ChooseManufacturer/Container */
export class ChooseManufacturerContainer extends PureComponent {
    static propTypes = {
        subcategories: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                product_count: PropTypes.number,
                url: PropTypes.string
            })
        ).isRequired,
        level: PropTypes.number.isRequired
    };

    containerProps() {
        const { subcategories, level } = this.props;

        return {
            subcategories,
            level
        };
    }

    render() {
        return (
            <ChooseManufacturerComponent
              { ...this.containerProps() }
            />
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChooseManufacturerContainer));
