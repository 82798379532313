/** @namespace Scandipwa/Util/Event/GTM/sendEventToGTM */
export const sendEventToGTM = (formCode, customerEmail) => {
    const detail = {
        formCode,
        customerEmail
    };

    // vvv send it as a CustomEvent vvv
    document.dispatchEvent(new CustomEvent('AmastyCustomFormSubmited', { detail }));
};
