/* eslint-disable max-lines */
/**
 * Amasty Custom Forms compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Html from 'Component/Html';
import Popup from 'Component/Popup';
import Field from 'Component/PureForm/Field';
import FIELD_TYPE from 'Component/PureForm/Field/Field.config';
import Form from 'Component/PureForm/Form';
import history from 'Util/History';

import {
    CALLBACK_REQUEST_PRODUCTNAME,
    CHECKBOX,
    CHECKBOX_TWO,
    FILE,
    FORM_POPUP_ID,
    GOOGLE_MAP,
    HEADING_FIRST,
    HEADING_SECOND,
    HEADING_THIRD,
    LISTBOX,
    RADIO,
    RADIO_TWO,
    RATING,
    TAG_NAME_ALIAS,
    TEXT,
    TYPE_ALIAS,
    WYSIWYG
} from './CustomForms.config';

import './CustomForms.style';

/** @namespace Scandiweb/CustomForms/Component/CustomForms/Component/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Scandiweb/CustomForms/Component/CustomForms/Component/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showPopup: (payload) => dispatch(this.showPopup(FORM_POPUP_ID, payload))
});

/** @namespace Scandiweb/CustomForms/Component/CustomForms/Component */
export class CustomFormsComponent extends PureComponent {
     static propTypes = {
         // eslint-disable-next-line react/forbid-prop-types
         formFields: PropTypes.object.isRequired,
         formId: PropTypes.number.isRequired,
         submitButtonText: PropTypes.string.isRequired,
         onFormSuccess: PropTypes.func.isRequired,
         showCreateNewPopup: PropTypes.func.isRequired,
         isSubmitted: PropTypes.bool.isRequired,
         popupBtn: PropTypes.string.isRequired,
         isPopup: PropTypes.bool.isRequired,
         title: PropTypes.string.isRequired,
         getStyle: PropTypes.func.isRequired,
         fileRef: PropTypes.func.isRequired,
         handleListChange: PropTypes.func.isRequired,
         handleGdprClick: PropTypes.func.isRequired,
         isGdprEnabled: PropTypes.bool.isRequired,
         gdprText: PropTypes.string.isRequired,
         // eslint-disable-next-line react/forbid-prop-types
         uploadedFile: PropTypes.object.isRequired,
         onFileUpload: PropTypes.func.isRequired,
         changeActivePage: PropTypes.func.isRequired,
         cleanUploadedFile: PropTypes.func.isRequired,
         formatBytes: PropTypes.func.isRequired,
         isGdprCheck: PropTypes.bool.isRequired,
         activePage: PropTypes.number.isRequired,
         selectedOptions: PropTypes.arrayOf().isRequired,
         allowed_extension: PropTypes.arrayOf().isRequired,
         selectedFile: PropTypes.shape.isRequired
     };

     renderInput(field) {
         const {
             required,
             type,
             label,
             name,
             placeholder,
             maxlength,
             value,
             rows,
             min,
             max,
             step,
             values,
             style,
             multiple
         } = field;
         const { getStyle, uploadedFile: { name: fileName = '' } = {} } = this.props;
         const isFileType = type === 'file';
         const {
             location: {
                 state: {
                     productName
                 } = {}
             } = {}
         } = history;

         if (fileName && isFileType) {
             return this.renderUploadedFileInfo();
         }

         if (name === CALLBACK_REQUEST_PRODUCTNAME) {
             return (
                <div style={ { display: 'none' } }>
                    <Field
                      attr={ {
                          id: name,
                          name,
                          lable: label,
                          validateOn: ['onChange'],
                          validationRule: {
                              isRequired: required
                          },
                          defaultValue: productName || ''
                      } }
                      key={ name }
                      label={ label }
                      type={ TYPE_ALIAS[type] ?? type }
                      maxlength={ maxlength ? parseInt(maxlength, 10) : null }
                      value={ value || '' }
                      rows={ rows ? parseInt(rows, 10) : null }
                      options={ values }
                      min={ min ? parseInt(min, 10) : null }
                      max={ max ? parseInt(max, 10) : null }
                      step={ step ? parseInt(step, 10) : null }
                      multiple={ multiple }
                    />
                </div>
             );
         }

         return (
             <Field
               attr={ {
                   id: name,
                   name,
                   placeholder: placeholder || '',
                   lable: label,
                   'aria-label': placeholder || '',
                   validateOn: ['onChange'],
                   validationRule: {
                       isRequired: required
                   }
               } }
               key={ name }
               label={ label }
               type={ TYPE_ALIAS[type] ?? type }
               maxlength={ maxlength ? parseInt(maxlength, 10) : null }
               value={ value || '' }
               rows={ rows ? parseInt(rows, 10) : null }
               options={ values }
               min={ min ? parseInt(min, 10) : null }
               max={ max ? parseInt(max, 10) : null }
               step={ step ? parseInt(step, 10) : null }
               style={ getStyle(style) }
               multiple={ multiple }
             />
         );
     }

     renderUploadedFileInfo() {
         const { cleanUploadedFile, uploadedFile: { name, size, type }, formatBytes } = this.props;
         if (name.indexOf(' ') >= 0) {
             cleanUploadedFile();
             return null;
         }

         return (
             <div>
                 <p>{ __('Uploaded file info:') }</p>
                 <p>{ __('File name: %s', name) }</p>
                 <p>{ __('File size: %s', formatBytes(size)) }</p>
                 <p>{ __('File type: %s', type) }</p>
                 <button
                   block="CustomForm"
                   elem="DeleteFile"
                   onClick={ cleanUploadedFile }
                 >
                     { __('Delete Uploaded file') }
                 </button>
             </div>
         );
     }

     renderText(field) {
         const {
             type, name, label, layout, style
         } = field;
         const { getStyle } = this.props;

         return React.createElement(
             TAG_NAME_ALIAS[type],
             {
                 className: `Field Field_layout-${ layout }`,
                 name,
                 style: getStyle(style)
             },
             label
         );
     }

     renderCheckboxGroup(field) {
         const {
             values, name, type, style
         } = field;
         const { getStyle } = this.props;
         const mix = (type === 'checkbox') ? 'one' : 'two';

         return (
             <div block="Field">
                 { this.renderLabel(field) }
                 <fieldset name={ field.name } id={ field.name }>
                     { values.map((value) => (
                         <div block="CustomForm" elem="Checkbox">
                             <Field
                               attr={ {
                                   id: `${ name }-${ value.value }`,
                                   value: value.value,
                                   name,
                                   'aria-label': value.label || ''
                               } }
                               type="checkbox"
                               checked={ value }
                               name={ `${ name }-${ value.value }` }
                               mix={ { block: 'Option_layout', elem: mix } }
                               style={ getStyle(style) }
                             />
                             <p>{ value.label }</p>
                         </div>
                     )) }
                 </fieldset>
             </div>
         );
     }

     renderRadioGroup(field) {
         const {
             values, name, type, style
         } = field;
         const { getStyle } = this.props;
         const mix = (type === 'radio') ? 'one' : 'two';

         return (
             <div block="Field">
                 { this.renderLabel(field) }
                 <fieldset name={ field.name } id={ field.name }>
                     { values.map((value) => (
                     <Field
                       attr={ {
                           id: `${ name }-${ value.value }`,
                           value: value.value,
                           name,
                           'aria-label': value.label || ''
                       } }
                       label={ value.label }
                       type="radio"
                       name={ `${ name }-${ value.value }` }
                       mix={ { block: 'Option_layout', elem: mix } }
                       style={ getStyle(style) }
                     />
                     )) }
                 </fieldset>
             </div>
         );
     }

     renderLabel(field) {
         const {
             name, required, label
         } = field;

         return (
             <div block="Field" elem="LabelContainer">
                 <label
                   block="Field"
                   elem="Label"
                   mods={ { required } }
                   htmlFor={ name }
                 >
                     { label }
                 </label>
             </div>
         );
     }

     renderListBox(field) {
         const { selectedOptions = [], handleListChange } = this.props;
         const { values: options, name, label } = field;

         return (
             <div className="Field">
                 <p>{ label }</p>
                 <select
                   value={ selectedOptions }
                   onChange={ handleListChange }
                   multiple
                   size={ 3 }
                   name={ name }
                   id={ name }
                 >
                     { options.map((option) => (
                         <option value={ option.value }>{ option.label }</option>
                     )) }
                 </select>
             </div>
         );
     }

     renderFileUpload(field) {
         const {
             selectedFile, allowed_extension, onFileUpload, fileRef
         } = this.props;

         return (
             <div block="CustomForm" elem="File">
                 { this.renderLabel(field) }
                 <input
                   name={ field.name }
                   id={ field.name }
                   ref={ fileRef }
                   type="file"
                   value={ selectedFile }
                   accept={ allowed_extension }
                   onChange={ onFileUpload }
                 />
                 <div>{ __('*Supports only single file upload') }</div>
                 <div>{ __('*Supports only files without whitespaces in filename') }</div>
             </div>
         );
     }

     renderField(field) {
         const { type } = field;

         if ([TEXT, HEADING_FIRST, HEADING_SECOND, HEADING_THIRD].includes(type)) {
             return this.renderText(field);
         }

         // don't render field types that are not-fully compatible with ScandiPWA
         if ([GOOGLE_MAP, RATING].includes(type)) {
             return null;
         }

         if ([CHECKBOX, CHECKBOX_TWO].includes(type)) {
             return this.renderCheckboxGroup(field);
         }

         if ([RADIO, RADIO_TWO].includes(type)) {
             return this.renderRadioGroup(field);
         }

         if (type.includes(WYSIWYG)) {
             return this.renderHtml(field);
         }

         if (type.includes(FILE)) {
             return this.renderFileUpload(field);
         }

         if (type.includes(LISTBOX)) {
             return this.renderListBox(field);
         }

         return this.renderInput(field);
     }

     renderPageNumber(formFields, index) {
         if (formFields.length <= 1) {
             return null;
         }

         return (
             <div block="CustomForm" elem="PageNumber">
                 { __('Page %s of %s', (index + 1), formFields.length) }
             </div>
         );
     }

     renderCustomForm() {
         const { formFields, activePage } = this.props;

         return formFields.map((formPage, index) => (
             <div block="CustomForm" elem="Page" mods={ { active: ((index + 1) === activePage) } }>
                 { this.renderPageNumber(formFields, index) }
                 { formPage.map((field) => this.renderField(field)) }
             </div>
         ));
     }

     renderHtml({ value = '' }) {
         const {
             location: {
                 state: {
                     productName
                 } = {}
             } = {}
         } = history;
         const replaceValue = value.replace('{{product_name}}', productName || ' ');

         return (
             <div block="CustomForm" elem="Html">
                 <Html content={ replaceValue } />
             </div>
         );
     }

     renderPopupActions() {
         const { popupBtn, showCreateNewPopup } = this.props;

         return (
             <button
               block="Button"
               id="customForm-popup-button"
               mix={ { block: 'CustomForm', elem: 'Button' } }
               onClick={ showCreateNewPopup }
             >
                 { popupBtn }
             </button>
         );
     }

     renderPopup() {
         const { onFormSuccess } = this.props;

         return (
             <Popup
               id={ FORM_POPUP_ID }
               clickOutside={ false }
               mix={ { block: 'MyCustomFormPopup' } }
             >
               { this.renderFormWithFields(onFormSuccess) }
             </Popup>
         );
     }

     renderGdpr() {
         const {
             isGdprEnabled,
             gdprText = '',
             isGdprCheck,
             handleGdprClick,
             activePage,
             formFields
         } = this.props;

         if (!isGdprEnabled || (activePage !== formFields.length)) {
             return null;
         }

         return (
             <div block="CustomForm" elem="Gdpr">
                 <Field
                   type={ FIELD_TYPE.checkbox }
                   attr={ {
                       id: 'termsAndConditions',
                       name: 'termsAndConditions',
                       value: 'termsAndConditions',
                       checked: isGdprCheck
                   } }
                   events={ {
                       onChange: handleGdprClick
                   } }
                   mix={ { block: 'CustomForm', elem: 'Checkbox' } }
                 />
                 <Html content={ gdprText } />
             </div>
         );
     }

     renderNextPageAction() {
         const { changeActivePage } = this.props;

         return (
             <button
               block="Button"
               mix={ { block: 'CustomForm', elem: 'Button' } }
               onClick={ changeActivePage }
             >
                 { __('Next Step') }
             </button>
         );
     }

     renderActions() {
         const {
             submitButtonText,
             formId,
             isGdprEnabled,
             isGdprCheck,
             activePage,
             formFields = []
         } = this.props;

         if (activePage !== formFields.length) {
             return this.renderNextPageAction();
         }

         return (
             <button
               type="submit"
               block="Button"
               id={ `customForm-${ formId }` }
               mix={ { block: 'CustomForm', elem: 'Button' } }
               disabled={ isGdprEnabled && !isGdprCheck }
             >
                 { submitButtonText }
             </button>
         );
     }

     renderFormWithPopup() {
         return (
             <div block="CustomForm">
                 { this.renderPopupActions() }
                 { this.renderPopup() }
             </div>
         );
     }

     renderFormHasBeenSubmittedAlready(title) {
         return (
             <div block="CustomForm IsSubmitted">
                 <h3>
                     { __('Form %s is already submitted!', title) }
                 </h3>
             </div>
         );
     }

     renderFormWithFields(onFormSuccess) {
         return (
             <Form
               onSubmit={ onFormSuccess }
               mix={ { block: 'CustomForm' } }
             >
                 { this.renderFormTitle() }
                 { this.renderCustomForm() }
                 { this.renderGdpr() }
                 { this.renderActions() }
             </Form>
         );
     }

     renderFormTitle() {
         const { title } = this.props;

         return (
             <h3>
                 { title }
             </h3>
         );
     }

     renderForm() {
         const {
             onFormSuccess, formFields, isPopup, isSubmitted, title
         } = this.props;

         if (isSubmitted) {
             return this.renderFormHasBeenSubmittedAlready(title);
         }

         if (isPopup) {
             return this.renderFormWithPopup();
         }

         if (formFields) {
             return this.renderFormWithFields(onFormSuccess);
         }

         return null;
     }

     render() {
         return (
             <div block="CustomForm">
                 { this.renderForm() }
             </div>
         );
     }
}

export default CustomFormsComponent;
