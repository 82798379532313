import React, { Children, cloneElement } from 'react';

import { AMAZON_PAY_FE_LOCATIONS } from '../component/AmazonPay/AmazonPay.config';
import AmazonPay from '../component/AmazonPay/AmazonPay.container';

export const addAmazonPayExpress = (args, callback, instance) => {
    const { hasOutOfStockProductsInCart } = instance.props;
    const alternatePaymentsWrapper = callback(...args);

    if (hasOutOfStockProductsInCart) {
        return alternatePaymentsWrapper;
    }

    const lastIndex = alternatePaymentsWrapper.props.children.length
        ? alternatePaymentsWrapper.props.children.length - 1
        : 0;

    return cloneElement(
        alternatePaymentsWrapper,
        alternatePaymentsWrapper.props,
        Children.map(
            alternatePaymentsWrapper.props.children,
            (child, i) => {
                if (i !== lastIndex) {
                    return child;
                }

                return (
                    <>
                        { child }
                        <AmazonPay location={ AMAZON_PAY_FE_LOCATIONS.CART } />
                    </>
                );
            }
        )
    );
};

export default {
    'Route/CartPage/Component': {
        'member-function': {
            renderAlternatePayments: addAmazonPayExpress
        }
    }
};
