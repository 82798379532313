/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import {
    SEARCH
} from 'Component/Header/Header.config';

import { KLEVU_SEARCH_OVERLAY_ID } from '../component/KlevuSearch/KlevuSearch.config';

const onSearchBarFocus = (args, callback, instance) => {
    const {
        setNavigationState,
        goToPreviousNavigationState,
        showOverlay,
        navigationState: { name },
        device
    } = instance.props;

    if (
        (!device.isMobile && name === SEARCH)
    ) {
        return;
    }

    showOverlay(KLEVU_SEARCH_OVERLAY_ID);

    setNavigationState({
        name: SEARCH,
        onBackClick: () => {
            goToPreviousNavigationState();
        }
    });
};

const hideSearchOverlay = (args, callback, instance) => {
    const {
        hideActiveOverlay, activeOverlay
    } = instance.props;

    document.activeElement.blur();

    if (activeOverlay === KLEVU_SEARCH_OVERLAY_ID) {
        hideActiveOverlay();
    }
};

export default {
    'Component/Header/Container': {
        'member-function': {
            onSearchBarFocus,
            hideSearchOverlay
        }
    }
};
