/* eslint-disable max-len */
import { PureComponent } from 'react';

/** @namespace Scandipwa/Component/CopyIcon/Component */
export class CopyIconComponent extends PureComponent {
    render() {
        return (
            <svg
              block="CopyIcon"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M21.841 3.09098L19.409 0.659016C18.9871 0.237058 18.4148 3.12036e-06 17.818 0L9.75 0C8.50734 0 7.5 1.00734 7.5 2.25V4.5H3.75C2.50734 4.5 1.5 5.50734 1.5 6.75V21.75C1.5 22.9927 2.50734 24 3.75 24H14.25C15.4927 24 16.5 22.9927 16.5 21.75V19.5H20.25C21.4927 19.5 22.5 18.4927 22.5 17.25V4.68197C22.5 4.08523 22.2629 3.51294 21.841 3.09098ZM13.9688 21.75H4.03125C3.95666 21.75 3.88512 21.7204 3.83238 21.6676C3.77963 21.6149 3.75 21.5433 3.75 21.4688V7.03125C3.75 6.95666 3.77963 6.88512 3.83238 6.83238C3.88512 6.77963 3.95666 6.75 4.03125 6.75H7.5V17.25C7.5 18.4927 8.50734 19.5 9.75 19.5H14.25V21.4688C14.25 21.5433 14.2204 21.6149 14.1676 21.6676C14.1149 21.7204 14.0433 21.75 13.9688 21.75ZM19.9688 17.25H10.0312C9.95666 17.25 9.88512 17.2204 9.83238 17.1676C9.77963 17.1149 9.75 17.0433 9.75 16.9688V2.53125C9.75 2.45666 9.77963 2.38512 9.83238 2.33238C9.88512 2.27963 9.95666 2.25 10.0312 2.25H15V6.375C15 6.99633 15.5037 7.5 16.125 7.5H20.25V16.9688C20.25 17.0433 20.2204 17.1149 20.1676 17.1676C20.1149 17.2204 20.0433 17.25 19.9688 17.25ZM20.25 5.25H17.25V2.25H17.7015C17.7761 2.25 17.8476 2.27962 17.9004 2.33236L20.1676 4.59961C20.1938 4.62573 20.2145 4.65674 20.2286 4.69086C20.2427 4.72499 20.25 4.76156 20.25 4.7985V5.25Z" />
            </svg>
        );
    }
}

export default CopyIconComponent;
