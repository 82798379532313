/**
 * @category  Printerbase
 * @author    Scandiweb <info@scandiweb.com>
 * @namespace Scandipwa/Component/CheckoutAddressForm/CheckoutAddressFormInstance
 */

const retrievePurchaseOrderNumber = () => document.getElementById('purchaseOrderNumber')?.value;

const savePaymentMethodAndPlaceOrderPlugin = (args, callback, instance) => {
    const purchaseOrderNumber = retrievePurchaseOrderNumber();

    if (!purchaseOrderNumber) {
        return callback.apply(instance, args);
    }

    const newArgs = Array.from(args);

    newArgs[0].paymentMethod.purchase_order_number = purchaseOrderNumber;

    return callback.apply(
        instance,
        newArgs
    );
};

export default {
    'Route/Checkout/Container': {
        'member-function': {
            savePaymentMethodAndPlaceOrder: savePaymentMethodAndPlaceOrderPlugin
        }
    }
};
