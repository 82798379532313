/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

export const getKlevuFields = () => {
    const klevuField = new Field('klevu');
    klevuField.addFieldList(
        [
            'klevu_api_key',
            'klevu_display_cat',
            'klevu_display_cms',
            'klevu_limit',
            'klevu_search_url_v2',
            'klevu_display_suggestions'
        ]
    );

    return klevuField;
};

export const _getStoreConfigFields = (args, callback) => ([
    ...callback(...args),
    'secure_base_link_url',
    getKlevuFields()
]);

export default {
    'Query/Config/Query': {
        'member-function': {
            _getStoreConfigFields
        }
    }
};
