import Field from 'SourceComponent/PureForm/Field';
import FIELD_TYPE from 'SourceComponent/PureForm/Field/Field.config';
import FieldGroup from 'SourceComponent/PureForm/FieldGroup';

function renderOptionalPurchaseOrder() {
    const { id } = this.props;

    return (
            <FieldGroup
              attr={ {
                  id,
                  name: 'PurchaseOrder'
              } }
            >
                <Field
                  type={ FIELD_TYPE.text }
                  attr={ {
                      id: 'purchaseOrderNumber',
                      name: 'purchaseOrderNumber',
                      placeholder: __('Purchase Order Number'),
                      'aria-label': __('Purchase Order Number')
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: false
                  } }
                  addRequiredTag
                  mix={ { block: 'PurchaseOrderNumber', elem: 'Input' } }
                />
            </FieldGroup>
    );
}

const renderPlugin = (args, callback, instance) => {
    const { optional = false } = instance.props;

    if (optional) {
        return renderOptionalPurchaseOrder.apply(instance, args);
    }

    return callback.apply(instance, args);
};

export default {
    'Scandiweb/PrinterbaseTheme/Component/PurchaseOrder/Component': {
        'member-function': {
            renderField: renderPlugin
        }
    }
};
