/**
 * Wyomind google customer reviews compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import BadgeConfigQuery from '../query/BadgeConfig.query';
import OrderConfigQuery from '../query/OrderConfig.query';

const getGcrConfigs = (args, callback, _instance) => (
    [
        ...callback(args),
        BadgeConfigQuery.getQuery(),
        OrderConfigQuery.getQuery()
    ]
);

export const config = {
    'Store/Config/Dispatcher': {
        'member-function': {
            prepareRequest: getGcrConfigs
        }
    }

};
export default config;
