/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import { stringify } from 'rebem-classname';

import {
    Link as SourceLink
} from 'SourceComponent/Link/Link.component';

/** @namespace Scandipwa/Component/Link/Component */
export class LinkComponent extends SourceLink {
    /**
     * Overridden to add class name to the link
     */
    renderRelativePathLink() {
        const {
            isOpenInNewTab,
            children,
            to,
            className,
            bemProps,
            ...props
        } = this.props;
        const classNameConverted = `${ className } ${ stringify(bemProps)}`;

        if (isOpenInNewTab) {
            return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <a
                  { ...props }
                  onClick={ this.scrollToElement }
                  className={ classNameConverted }
                  href={ to }
                  rel="noopener noreferrer"
                  target="_blank"
                >
                    { children }
                </a>
            );
        }

        return (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
              { ...props }
              onClick={ this.scrollToElement }
              className={ classNameConverted }
            >
                { children }
            </div>
        );
    }
}

export default LinkComponent;
