import TagManager from 'react-gtm-module';

import { UPDATE_CUSTOMER_DETAILS } from 'Store/MyAccount/MyAccount.action';

import { EVENT_KEY_NEWSLETTER_SUBSCRIPTION } from '../component/GoogleTagManager/GoogleTagManager.events';

export const fireNewsletterSubscriptionEvent = (customerEmail) => {
    TagManager.dataLayer({
        dataLayer: {
            event: EVENT_KEY_NEWSLETTER_SUBSCRIPTION,
            customerEmail
        }
    });
};

const MyAccountReducer = (args, callback) => {
    const [state, action] = args;

    const {
        type
    } = action;

    switch (type) {
    case UPDATE_CUSTOMER_DETAILS:
        const { customer: { is_subscribed: isSubscribed } = {}, isSignedIn } = state;
        const { customer: { is_subscribed: willBeSubscribed, email } = {} } = action;

        const shouldFireEvent = isSignedIn && isSubscribed === false && willBeSubscribed && email;
        if (shouldFireEvent) {
            fireNewsletterSubscriptionEvent(email);
        }

        return callback(...args);

    default:
        return callback(...args);
    }
};

export default {
    'Store/MyAccount/Reducer/MyAccountReducer': {
        function: MyAccountReducer
    }
};
