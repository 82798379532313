import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import CategorySubacategoriesTiles from './CategorySubacategoriesTiles.component';

/** @namespace Scandipwa/Component/CategorySubacategoriesTiles/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    subcategories: state.CategoryReducer.category.children,
    title: state.CategoryReducer.category.name
});

/** @namespace Scandipwa/Component/CategorySubacategoriesTiles/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Scandipwa/Component/CategorySubacategoriesTiles/Container */
export class CategorySubacategoriesTilesContainer extends PureComponent {
    static propTypes = {
        subcategories: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                product_count: PropTypes.number,
                url: PropTypes.string
            })
        ),
        title: PropTypes.string
    };

    static defaultProps = {
        subcategories: [],
        title: ''
    };

    containerProps() {
        const { subcategories, title } = this.props;

        return {
            subcategories,
            title
        };
    }

    render() {
        return (
            <CategorySubacategoriesTiles
              { ...this.containerProps() }
            />
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CategorySubacategoriesTilesContainer));
