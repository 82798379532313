/**
 * @category  Scandiweb
 * @package   ScandiPWA/CatalogLoadMore
 * @author    Ramona Cunska <info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import React from 'react';

import history from 'Util/History';
import { getQueryParam } from 'Util/Url';

import { getNextPageUrl } from '../util/NextPageUrl';

import './ProductList.override.style.plugin';

export class ProductListComponentPlugin {
    /**
     * Extended to add additional props to component.
     */
    propTypes = (args) => ({
        ...args,
        isPageLoading: PropTypes.bool.isRequired,
        isNextPagePreloaded: PropTypes.bool.isRequired,
        isLoadMoreVisible: PropTypes.bool.isRequired,
        loadMoreCount: PropTypes.number.isRequired,
        onLoadMoreButtonClick: PropTypes.func.isRequired
    });

    /**
     * We need to remove page scroll-up for desktop devices when load more is enabled
     * therefore we can't call the callback method.
     */
    componentDidUpdate = (args, callback, instance) => {
        const {
            isInfiniteLoaderEnabled
        } = instance.props;

        if (isInfiniteLoaderEnabled) {
            instance.observePageChange();
        }

        history.listen((location, action) => {
            window.history.scrollRestoration = 'manual';

            const {
                productRefs
            } = instance.props;

            if (action === 'POP') {
                const curPage = getQueryParam('page', location) || 1;
                const currentPageRefs = productRefs?.[curPage] || [];
                const lastProductRef = currentPageRefs[currentPageRefs.length - 1]?.current;
                if (lastProductRef) {
                    const elementHeight = lastProductRef.offsetHeight;
                    lastProductRef.scrollIntoView({ block: 'start' });
                    window.scrollBy(0, -(elementHeight * 2));
                }
            }
        });
    };

    /**
     * Extended to replace pagination with "Load more" button
     */
    renderPagination = (args, callback, instance) => {
        const { isInfiniteLoaderEnabled } = instance.props;

        if (isInfiniteLoaderEnabled) {
            return this.renderLoadMoreButton.apply(instance);
        }

        return callback.apply(instance, args);
    };

    /**
     * Extended to remove the last page from display in case if it is preloaded and "load more"
     * button was not yet clicked, and to adjust placeholder display.
     */
    renderPages = (args, callback, instance) => {
        const {
            pages,
            isVisible,
            isLoading,
            isPageLoading,
            isNextPagePreloaded,
            isInfiniteLoaderEnabled
        } = instance.props;

        if (isLoading) {
            return callback.apply(instance, args);
        }

        const visiblePages = Object.entries(pages);

        if (isNextPagePreloaded && !isPageLoading) {
            visiblePages.splice(-1);
        }

        const pageRenders = visiblePages.map(instance.renderProductPage);

        /**
         * Add placeholders to the end of pages if needed.
         * Placeholders contain the element that is observed and calls next page preloading
         * so we don't want to display it if the next page is already preloaded or loading.
        */
        if (isVisible && isInfiniteLoaderEnabled && !isNextPagePreloaded && !isPageLoading) {
            const key = Math.max(...Object.keys(pages)) + 1;
            pageRenders.push(instance.renderPage({ key }));
        }

        return pageRenders;
    };

    renderLoadMoreButton() {
        const {
            isLoadMoreVisible,
            isNextPagePreloaded,
            isPageLoading,
            onLoadMoreButtonClick,
            loadMoreCount
        } = this.props;

        if (!isLoadMoreVisible) {
            return null;
        }

        const label = (!isNextPagePreloaded && isPageLoading)
            ? __('Loading...')
            : __('Show more (%s product%s)', loadMoreCount, loadMoreCount > 1 ? 's' : '');

        // We need to generate a link address to the next page for the href property so that
        // all products can be accessible by bots
        const nextPage = getNextPageUrl(location);

        return (
            <div
              block="ProductList"
              elem="LoadMoreButtonWrapper"
            >
                <a
                  block="ProductList"
                  elem="LoadMoreButton"
                  mix={ { block: 'Button' } }
                  onClick={ onLoadMoreButtonClick }
                  href={ nextPage }
                >
                    { label }
                </a>
            </div>
        );
    }
}

const {
    componentDidUpdate,
    renderPagination,
    renderPages
} = new ProductListComponentPlugin();

export default {
    'Component/ProductList/Component': {
        'member-function': {
            componentDidUpdate,
            renderPagination,
            renderPages
        }
    }
};
