import {
    KLEVU_SET_SEARCHPAGE_CATEGORIES,
    KLEVU_SET_SEARCHPAGE_PRODUCT_IDS,
    UPDATE_DISPLAY_PRODUCT,
    UPDATE_TOTAL_RESULTS
} from './KlevuSearch.actions';

export const initialState = {
    isDisplayProduct: true,
    totalProductsNum: 0,
    totalOthersNum: 0,
    searchPageProductIds: [],
    searchPageCategories: []
};

/** @namespace KlevuSearch/Store/KlevuSearch/Reducer/KlevuSearchReducer */
export const KlevuSearchReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
    case UPDATE_TOTAL_RESULTS:
        const {
            totalProductsNum,
            totalOthersNum
        } = payload;

        return {
            ...state, totalOthersNum, totalProductsNum
        };
    case UPDATE_DISPLAY_PRODUCT:
        return {
            ...state, isDisplayProduct: payload.isDisplayProduct
        };
    case KLEVU_SET_SEARCHPAGE_CATEGORIES:
        const { searchPageCategories } = payload;

        return {
            ...state,
            searchPageCategories: Array.from(searchPageCategories)
        };

    case KLEVU_SET_SEARCHPAGE_PRODUCT_IDS:
        const { searchPageProductIds } = payload;

        return {
            ...state,
            searchPageProductIds: Array.from(searchPageProductIds)
        };
    default:
        return state;
    }
};

export default KlevuSearchReducer;
