/**
 * Mageplaza Social Login compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import FacebookIcon from 'Component/FacebookIcon';
import GoogleIcon from 'Component/GoogleIcon';

import './SocialLoginButton.style';

/** @namespace Scandiweb/MageplazaSocialLogin/Component/SocialLoginButton/Component */
export class SocialLoginButtonComponent extends PureComponent {
    static propTypes = {
        label: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired
    };

    renderIcon(label) {
        switch (label) {
        case 'Facebook':
            return (
                <FacebookIcon />
            );
        case 'Google':
            return (
                <GoogleIcon />
            );
        default:
            return '';
        }
    }

    renderButton() {
        const { label, type, onClick } = this.props;

        return (
            <div block="SocialLoginButton">
                <button
                  block="SocialLoginButton"
                  mods={ { type } }
                  mix={ { block: 'Button' } }
                  onClick={ onClick }
                >
                    { this.renderIcon(label) }
                    { label }
                </button>
            </div>
        );
    }

    render() {
        return (
            <div block="SocialLoginButton">
                { this.renderButton() }
            </div>
        );
    }
}

export default SocialLoginButtonComponent;
