import { AMAZON_PAY_STEPS } from '../../component/AmazonPay/AmazonPay.config';
import {
    AMAZON_PAY_CHECKOUT_SESSION,
    AMAZON_PAY_CONFIG,
    AMAZON_PAY_FORM_CHECKOUT_SESSION,
    AMAZON_PAY_RESET_STATE,
    AMAZON_PAY_SHIPPING_CARRIER,
    AMAZON_PAY_SHIPPING_METHODS,
    AMAZON_PAY_STATE
} from './AmazonPay.action';

/** @namespace Amazonpay/Store/AmazonPay/Reducer/getInitialState */
export const getInitialState = () => ({
    currentStep: AMAZON_PAY_STEPS.AMAZON_CHECKOUT,
    error: null,
    isLoading: true,
    isCustomAddressExpanded: false,
    isPaymentProcessing: false,
    isLoadingAddress: false,
    isLoadingShipments: false
});

/** @namespace Amazonpay/Store/AmazonPay/Reducer/getInitialConfig */
export const getInitialConfig = () => ({
    merchantId: null,
    publicKeyId: null,
    ledgerCurrency: null,
    checkoutLanguage: null,
    sandbox: null,
    buttonColor: null,
    productType: null,
    buttonDisplayLocations: {
        pdp: null,
        miniCart: null,
        payments: null
    },
    amazonScript: null
});

/** @namespace Amazonpay/Store/AmazonPay/Reducer/getInitialCheckoutSessionConfig */
export const getInitialCheckoutSessionConfig = () => ({
    merchantId: null,
    ledgerCurrency: null,
    buttonColor: null,
    checkoutLanguage: null,
    payOnly: null,
    sandbox: null,
    loginPayload: null,
    loginSignature: null,
    checkoutPayload: null,
    checkoutSignature: null,
    paynowPayload: null,
    paynowSignature: null,
    publicKeyId: null
});

// Read only checkout session which comes from the server and Amazon API
/** @namespace Amazonpay/Store/AmazonPay/Reducer/getInitialCheckoutSession */
export const getInitialCheckoutSession = () => ({
    shippingAddress: {
        city: null,
        company: null,
        country_id: null,
        email: null,
        firstname: null,
        lastname: null,
        postcode: null,
        region: null,
        region_code: null,
        region_id: null,
        telephone: null,
        street: null
    },
    billingAddress: {
        city: null,
        company: null,
        country_id: null,
        email: null,
        firstname: null,
        lastname: null,
        postcode: null,
        region: null,
        region_code: null,
        region_id: null,
        telephone: null,
        street: null
    }
});

/** @namespace Amazonpay/Store/AmazonPay/Reducer/getInitialShippingEstimate */
export const getInitialShippingEstimate = () => ({
    selectedCarrier: {
        method_code: '',
        carrier_code: ''
    },
    shippingMethods: []
});

// Checkout session which is editable by user in checkout billing step
/** @namespace Amazonpay/Store/AmazonPay/Reducer/getInitialFormCheckoutSession */
export const getInitialFormCheckoutSession = () => ({
    formShippingAddress: {
        isInitialized: false
    }
});

/** @namespace Amazonpay/Store/AmazonPay/Reducer/getInitialReducerState */
export const getInitialReducerState = () => ({
    state: getInitialState(),
    config: getInitialConfig(),
    checkoutSessionConfig: getInitialCheckoutSessionConfig(),
    checkoutSession: getInitialCheckoutSession(),
    formCheckoutSession: getInitialFormCheckoutSession(),
    shippingEstimate: getInitialShippingEstimate()
});

/** @namespace Amazonpay/Store/AmazonPay/Reducer/AmazonPayReducer */
export const AmazonPayReducer = (
    state = getInitialReducerState(),
    action
) => {
    const {
        state: currentState,
        config,
        formCheckoutSession,
        formCheckoutSession: {
            formShippingAddress
        },
        shippingEstimate
    } = state;

    const {
        type,
        state: actionState,
        config: actionConfig,
        checkoutSession: actionCheckoutSession = {},
        formCheckoutSession: actionFormCheckoutSession = {},
        shippingEstimate: {
            selectedCarrier: actionSelectedCarrier,
            shippingMethods: actionShippingMethods
        } = {}
    } = action;

    const {
        shippingAddress: actionCheckoutShippingAddress
    } = actionCheckoutSession;

    const {
        formShippingAddress: actionFormShippingAddress = {}
    } = actionFormCheckoutSession;

    const {
        isInitialized
    } = actionFormShippingAddress;

    switch (type) {
    case AMAZON_PAY_RESET_STATE:
        return {
            ...state,
            state: {
                ...getInitialState()
            }
        };
    case AMAZON_PAY_STATE:
        return {
            ...state,
            state: {
                ...currentState,
                ...actionState
            }
        };
    case AMAZON_PAY_CONFIG:
        return {
            ...state,
            config: {
                ...config,
                ...actionConfig
            }
        };
    case AMAZON_PAY_CHECKOUT_SESSION:
        return {
            ...state,
            checkoutSession: {
                ...actionCheckoutSession
            },
            // Set initial form data just once.
            formCheckoutSession: {
                ...formCheckoutSession,
                formShippingAddress: isInitialized ? formShippingAddress : actionCheckoutShippingAddress,
                isInitialized: true
            }
        };
    case AMAZON_PAY_FORM_CHECKOUT_SESSION:
        return {
            ...state,
            formCheckoutSession: {
                ...formCheckoutSession,
                ...actionFormCheckoutSession
            }
        };
    case AMAZON_PAY_SHIPPING_METHODS:
        return {
            ...state,
            shippingEstimate: {
                ...shippingEstimate,
                shippingMethods: actionShippingMethods
            }
        };
    case AMAZON_PAY_SHIPPING_CARRIER:
        return {
            ...state,
            shippingEstimate: {
                ...shippingEstimate,
                selectedCarrier: actionSelectedCarrier
            }
        };
    default:
        return state;
    }
};

export default AmazonPayReducer;
