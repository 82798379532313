/**
 * Wyomind google customer reviews compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/WyomindGoogleCustomerReviews/Query/OrderConfig/Query/OrderConfigQuery */
export class OrderConfigQuery {
    getQuery() {
        return new Field('orderConfig')
            .addFieldList(this._getOrderConfigQueryFields());
    }

    _getOrderConfigQueryFields() {
        return [
            'eta',
            'merchantId',
            'optinStyle',
            'listProducts',
            'gtinAttribute'
        ];
    }
}

export default new OrderConfigQuery();
