/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { waitForCallback } from 'Util/Wait';

/**
 * No-operate function.
 * Can be used as a fallback if real function wasn't passed.
 * In this case instead of creating empty function every time we can reuse existing.
 * Examples: default props, argument default values.
 * @namespace Scandipwa/Util/Common/Index/noopFn */
export const noopFn = () => {};

/** @namespace Scandipwa/Util/Common/Index/toCouponArray */
export const toCouponArray = (couponCode) => (couponCode || '')
    .toUpperCase()
    .split(',')
    .map((code) => code.trim())
    .filter((code) => code.length);

/** @namespace Scandipwa/Util/Common/Index/decodeString */
export const decodeString = (string) => {
    try {
        return decodeURIComponent(string);
    } catch (e) {
        return string;
    }
};

/** @namespace Scandipwa/Util/Common/Index/getElemBoundingClientRect */
export const getElemBoundingClientRect = async (elem) => {
    // eslint-disable-next-line fp/no-let
    let boundingClientRect = null;

    const observer = new IntersectionObserver((entries) => {
        const [elemEntry] = entries;

        boundingClientRect = elemEntry?.boundingClientRect ?? null;

        observer.disconnect();
    });

    if (elem) {
        observer.observe(elem);
    }

    await waitForCallback(() => boundingClientRect !== null);

    return boundingClientRect;
};

/** @namespace Scandipwa/Util/Common/Index/orderCorrectStatusLabel */
export const orderCorrectStatusLabel = (statusLabel) => {
    switch (statusLabel) {
    case 'Sent to ERP':
        return 'Received';
    default:
        return statusLabel;
    }
};

window.getElemBoundingClientRect = getElemBoundingClientRect;
