import ProductListQuery from 'query/ProductList.query';

import { BASE_TEN } from 'Component/ProductList/ProductList.config';
import { fetchQuery } from 'Util/Request';

export const addSalableQuantityData = async (_args, callback, _instance) => {
    const [data] = _args;

    if (!data.products) {
        return callback.apply(_instance, _args);
    }

    const { products: { items } } = data;
    if (items && items.length > 0) {
        const productIds = items.map(({ id }) => parseInt(id, BASE_TEN));

        await fetchQuery(ProductListQuery.getSalableQty(productIds)).then(
            (_data) => {
                function matchingPID(product) {
                    return product.productId === this.id;
                }

                items.forEach((_product) => {
                    const { id } = _product;
                    const { salableQuantity } = _data;
                    const { qty } = salableQuantity.find(matchingPID, { id });

                    if (qty) {
                        Object.assign(_product, { salableQuantity: qty });
                    }
                });
            }
        );
    }

    return callback.apply(_instance, _args);
};

export default {
    'Scandipwa/Store/ProductList/Dispatcher': {
        'member-function': {
            onSuccess: addSalableQuantityData
        }
    }
};
