import TagManager from 'react-gtm-module';

import { EVENT_GTM_CHECKOUT, EVENT_KEY_SHIPPING } from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

const fireShippingEmailEvent = (email) => {
    TagManager.dataLayer({
        dataLayer: {
            event: EVENT_KEY_SHIPPING,
            email
        }
    });
};

const componentDidMount = (args, callback, instance) => {
    const { event } = instance.props;

    const { customer: { email } = {} } = instance.props;
    if (email) {
        fireShippingEmailEvent(email);
    }
    event(EVENT_GTM_CHECKOUT, { step: 1 });

    callback.apply(instance, args);
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};

export default {
    'Component/CheckoutShipping/Container': {
        'member-function': {
            componentDidMount
        }
    },
    'Component/CheckoutShipping/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
