import ExpandableContentShowMore from 'Component/ExpandableContentShowMore';
import ProductAttributeValue from 'Component/ProductAttributeValue';
import {
    ProductConfigurableAttributesComponent as SourceProductConfigurableAttributes
} from 'SourceComponent/ProductConfigurableAttributes/ProductConfigurableAttributes.component';

import './ProductConfigurableAttributes.style.override';

/** @namespace Scandipwa/Component/ProductConfigurableAttributes/Component */
export class ProductConfigurableAttributesComponent extends SourceProductConfigurableAttributes {
    /**
     * New method because overriding the original renderConfigurableAttributeValue has no effect
     */
    renderSwatchConfigurableAttributeValue(attribute) {
        const {
            getIsConfigurableAttributeAvailable,
            handleOptionClick,
            getLink,
            isSelected,
            showProductAttributeAsLink,
            inStock
        } = this.props;

        const { attribute_value } = attribute;

        return (
            <ProductAttributeValue
              key={ attribute_value }
              attribute={ attribute }
              isSelected={ isSelected(attribute) }
              isAvailable={ getIsConfigurableAttributeAvailable(attribute) && inStock }
              onClick={ handleOptionClick }
              getLink={ getLink }
              showProductAttributeAsLink={ showProductAttributeAsLink }
              isFormattedAsImageAndText
            />
        );
    }

    /**
     * Overridden for replacing the method renderConfigurableAttributeValue with renderSwatchConfigurableAttributeValue
     */
    renderSwatch(option) {
        const { attribute_values, attribute_code } = option;

        return (
            <div
              block="ProductConfigurableAttributes"
              elem="SwatchList"
              key={ attribute_code }
            >
                <ExpandableContentShowMore>
                    { attribute_values.map((attribute_value) => (
                        this.renderSwatchConfigurableAttributeValue({ ...option, attribute_value })
                    )) }
                </ExpandableContentShowMore>
            </div>
        );
    }
}

export default ProductConfigurableAttributesComponent;
