import { EVENT_GTM_CHECKOUT_OPTION } from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

const onPaymentMethodSelection = (args, callback, instance) => {
    const { event, selectedPaymentCode } = instance.props;

    const data = {
        option: selectedPaymentCode,
        step: 2,
        action: 'payment'
    };

    event(EVENT_GTM_CHECKOUT_OPTION, data);
    callback.apply(instance, args);
};

const containerProps = (args, callback, instance) => {
    const {
        event
    } = instance.props;

    return {
        ...callback(...args),
        event
    };
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};

export default {
    'Component/CheckoutPayments/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Component/CheckoutPayments/Container': {
        'member-function': { containerProps }
    },
    'Component/CheckoutPayments/Component': {
        'member-function': {
            componentDidMount: onPaymentMethodSelection,
            componentDidUpdate: onPaymentMethodSelection
        }
    }
};
