/**
 * Amasty Custom Forms compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Suspense } from 'react';

import Loader from 'Component/Loader/Loader.component';

import CustomForm from '../component/CustomForms';
import { A_FORM_ID } from '../component/CustomForms/CustomForms.config';

export class HtmlComponentPlugin {
    overrideRules = (originalMember) => {
        const newRules = [
            {
                query: { name: ['form'] },
                replace: this.replaceForm
            }
        ];

        return [...originalMember, ...newRules];
    };

    replaceForm = ({ attribs }) => {
        const { id } = attribs;

        if (id.includes(A_FORM_ID)) {
            return (
                <Suspense fallback={ <Loader isLoading /> }>
                    <CustomForm
                      id={ id.substr(A_FORM_ID.length) }
                    />
                </Suspense>
            );
        }

        return null;
    };
}

export const { overrideRules } = new HtmlComponentPlugin();

export default {
    'Component/Html/Component': {
        'member-property': {
            rules: overrideRules
        }
    }
};
