import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { hideActivePopup } from 'Store/Overlay/Overlay.action';
import { ChildrenType } from 'Type/Common';
import { ProductType } from 'Type/ProductList';
import { toggleScroll } from 'Util/Browser';
import history from 'Util/History';
import { scrollToElement } from 'Util/Scroll';
import { appendWithStoreCode } from 'Util/Url';

import ConsumablesPromoCTAButton from './ConsumablesPromoCtaButton.component';
import { POPUP_CLOSE_TIMEOUT } from './ConsumablesPromoCtaButton.config';

/** @namespace Scandipwa/Component/ConsumablesPromoCtaButton/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Scandipwa/Component/ConsumablesPromoCtaButton/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    hideActivePopup: (shouldPopupClose) => dispatch(hideActivePopup(shouldPopupClose))
});

/** @namespace Scandipwa/Component/ConsumablesPromoCtaButton/Container */
export class ConsumablesPromoCtaButtonContainer extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        children: ChildrenType.isRequired,
        hideActivePopup: PropTypes.func.isRequired
    };

    containerFunctions = {
        handleCtaButtonClick: this.handleCtaButtonClick.bind(this)
    };

    unfreezeScroll() {
        toggleScroll(true);
        document.body.style.marginTop = 0;
    }

    hidePopup() {
        const { hideActivePopup } = this.props;

        this.unfreezeScroll();
        hideActivePopup(true);
    }

    getLinkToConsumablesCategory() {
        const {
            product: {
                attributes: {
                    consumable_category_url: {
                        attribute_value = null
                    } = {}
                } = {}
            } = {}
        } = this.props;

        return appendWithStoreCode(attribute_value);
    }

    handleCtaButtonClick() {
        this.hidePopup();

        const selector = '.ProductLinks';
        const elem = document.querySelector(selector);
        const linkTo = this.getLinkToConsumablesCategory();

        // if the consumables section doesn't exists (PLP or search page) go to consumables page
        if (!elem) {
            history.push(linkTo);
            return;
        }

        setTimeout(() => {
            scrollToElement(selector);
        }, POPUP_CLOSE_TIMEOUT);
    }

    containerProps() {
        const { children } = this.props;

        return {
            children
        };
    }

    render() {
        return (
            <ConsumablesPromoCTAButton
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsumablesPromoCtaButtonContainer);
