/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-lines */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import AddToBasketLinksCard from 'Component/AddToBasketLinksCard';
import ContentWrapper from 'Component/ContentWrapper';
import ProductCard from 'Component/ProductCard';
import {
    ACCESSORIES,
    CONSUMABLES,
    DEFAULT,
    PAPER,
    WARRANTIES
} from 'Component/ProductLinks/ProductLinks.config';
import { GRID_LAYOUT, LIST_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import { RELATED } from 'Store/LinkedProducts/LinkedProducts.reducer';
import { ProductType } from 'Type/ProductList';

import './AddToBasketProductLinks.style';

/** @namespace Scandipwa/Component/AddToBasketProductsLinks/AddToBasketProductLinks/Component */
export class AddToBasketProductLinksComponent extends PureComponent {
    static propTypes = {
        numberOfProductsToDisplay: PropTypes.number.isRequired,
        linkedProducts: PropTypes.objectOf(ProductType).isRequired,
        linkType: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        productCardProps: PropTypes.shape({
            siblingsHaveBrands: PropTypes.bool,
            siblingsHavePriceBadge: PropTypes.bool,
            siblingsHaveTierPrice: PropTypes.bool,
            siblingsHaveConfigurableOptions: PropTypes.bool
        }).isRequired,
        productCardFunctions: PropTypes.objectOf(PropTypes.func).isRequired,
        getRestOfProductTypes: PropTypes.func.isRequired,
        isMobile: PropTypes.bool.isRequired,
        visible: PropTypes.shape({
            Consumables: PropTypes.number,
            Accessories: PropTypes.number,
            Stationery: PropTypes.number,
            Paper: PropTypes.number,
            Warranties: PropTypes.number,
            Media: PropTypes.number
        }).isRequired,
        showMore: PropTypes.func.isRequired,
        showStockNotifyPopup: PropTypes.func.isRequired
    };

    /**
     * Created to define render order for each related product block
     */
    renderMap = {
        [CONSUMABLES]: () => this.renderItemsByProductType(CONSUMABLES),
        [PAPER]: () => this.renderItemsByProductType(PAPER),
        [ACCESSORIES]: () => this.renderItemsByProductType(ACCESSORIES),
        [WARRANTIES]: () => this.renderItemsByProductType(WARRANTIES),
        [DEFAULT]: () => this.renderItemsByRestOfProductTypes()
    };

    renderProductCard = this.renderProductCard.bind(this);

    /**
     * Overridden to render different card component for related products
     */
    renderProductCard(product, i) {
        const {
            productCardProps: {
                siblingsHaveBrands,
                siblingsHavePriceBadge,
                siblingsHaveTierPrice,
                siblingsHaveConfigurableOptions
            },
            productCardFunctions: {
                setSiblingsHaveBrands,
                setSiblingsHavePriceBadge,
                setSiblingsHaveTierPrice,
                setSiblingsHaveConfigurableOptions
            },
            linkType,
            showStockNotifyPopup
        } = this.props;
        const { id = i } = product;
        const isRelated = linkType === RELATED;

        const cardProps = {
            block: 'AddToBasketLinks',
            elem: 'Card',
            product,
            key: id,
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveTierPrice,
            siblingsHaveConfigurableOptions,
            setSiblingsHaveBrands,
            setSiblingsHavePriceBadge,
            setSiblingsHaveTierPrice,
            setSiblingsHaveConfigurableOptions,
            layout: isRelated ? LIST_LAYOUT : GRID_LAYOUT,
            isLinks: isRelated,
            showStockNotifyPopup
        };

        /* copy is related over from productlinks,
        create an new linkcard */
        if (isRelated) {
            return (
                <AddToBasketLinksCard
                  { ...cardProps }
                />
            );
        }

        return (
            <ProductCard
              { ...cardProps }
            />
        );
    }

    /**
     * Functionality to map not defined product_type related products
     */
    renderItemsByRestOfProductTypes() {
        const { getRestOfProductTypes } = this.props;

        const productTypes = getRestOfProductTypes();

        if (!productTypes.length) {
            return null;
        }

        return productTypes.map((type) => this.renderItemsByProductType(type));
    }

    renderHeading() {
        const { title, linkType } = this.props;

        if (linkType === RELATED) {
            return null;
        }

        return (
            <h2 block="AddToBasketProductsLinks" elem="Title">
                { title }
            </h2>
        );
    }

    /**
     * Functionality to render header for each related product block
     */
    renderProductTypeBlockHeader(title) {
        const { isMobile } = this.props;

        if (isMobile) {
            return (
                <div block="AddToBasketProductsLinks" elem="RelatedProductHeader">
                    <span>
                        { title }
                    </span>
                </div>
            );
        }

        return (
            <div block="AddToBasketProductsLinks" elem="RelatedProductHeader">
                <span
                  block="AddToBasketProductsLinks"
                  elem="RelatedProductHeader"
                  mix={ { block: 'RelatedProductsHeader', elem: 'Title' } }
                >
                    { title }
                </span>
                { title === CONSUMABLES ? (
                    <span
                      block="AddToBasketProductsLinks"
                      elem="RelatedProductHeader"
                      mix={ { block: 'RelatedProductsHeader', elem: 'Colour' } }
                    >
                        { __('Colour') }
                    </span>
                ) : (
                    <span>
                        { __('   ') }
                    </span>
                ) }
                <span
                  block="AddToBasketProductsLinks"
                  elem="RelatedProductHeader"
                  mix={ { block: 'RelatedProductsHeader', elem: 'Price' } }
                >
                    { __('Price') }
                </span>
                <span>
                    { __('   ') }
                </span>
            </div>
        );
    }

    renderShowMoreButton(categoryItems, headerLabel) {
        const { visible, showMore } = this.props;

        if (visible[headerLabel] >= categoryItems.length) {
            return null;
        }

        return (
            <div
              block="AddToBasketProductsLinks"
              elem="ButtonWrapper"
            >
                    <button
                      block="AddToBasketProductsLinks"
                      elem="ShowMoreButton"
                      type="button"
                      id={ headerLabel }
                      onClick={ (e) => showMore(e) }
                    >
                        View more
                    </button>
            </div>
        );
    }

    /**
     * Override to add ref to related block
     */
    renderItemsByProductType(type) {
        const {
            linkType,
            linkedProducts: { [linkType]: { items } },
            relatedProductRef,
            visible
        } = this.props;

        const categoryItems = items.filter(({
            attributes: {
                product_type: {
                    attribute_value,
                    attribute_options: {
                        [attribute_value]: {
                            label
                        } = {}
                    } = {}
                } = {}
            }
        }) => label === type);

        if (!categoryItems.length) {
            return null;
        }

        const {
            attributes: {
                product_type: {
                    attribute_value,
                    attribute_options: {
                        [attribute_value]: {
                            label: headerLabel
                        } = {}
                    } = {}
                } = {}
            }
        } = categoryItems[0]; // get label from the first item

        return (
            <div
              block="AddToBasketProductsLinks"
              elem="ProductsByTypeWrapper"
              ref={ (ref) => {
                  relatedProductRef[type] = ref;
              } }
            >
                { this.renderProductTypeBlockHeader(headerLabel) }
                { categoryItems.slice(0, visible[headerLabel]).map(this.renderProductCard) }
                { this.renderShowMoreButton(categoryItems, headerLabel) }
            </div>
        );
    }

    /**
     * Overridden to render related products by grouped product type
     */
    renderItems() {
        const {
            linkType,
            linkedProducts: { [linkType]: { items } },
            numberOfProductsToDisplay
        } = this.props;

        if (!items) {
            return Array.from(
                { length: numberOfProductsToDisplay },
                (_, i) => this.renderProductCard({}, i)
            );
        }

        items.sort((a, b) => a.position - b.position);

        if (linkType !== RELATED) {
            return items.slice(0, numberOfProductsToDisplay).map(this.renderProductCard);
        }

        // eslint-disable-next-line no-lone-blocks
        return Object.entries(this.renderMap).map(([_, render]) => render());
    }

    renderTitle() {
        return (
            <div
              block="AddToBasketProductsLinks"
              elem="Title"
            >
                Related products for your printer
            </div>
        );
    }

    /**
     * Overridden to render product link buttons
     */
    render() {
        const { linkType } = this.props;
        return (
            <ContentWrapper
              block="AddToBasketProductsLinks"
              label={ __('Linked products') }
              mix={ { block: 'AddToBasketProductsLinks', elem: 'ContentWrapper' } }

            >
                <div
                  block="AddToBasketProductsLinks"
                  elem="Wrapper"
                >
                    { this.renderTitle() }
                    { this.renderHeading() }
                    <ul block="AddToBasketProductsLinks" elem="List" mods={ { isRelated: linkType === RELATED } }>
                        { this.renderItems() }
                    </ul>
                </div>
            </ContentWrapper>
        );
    }
}

export default AddToBasketProductLinksComponent;
