/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Subscribe } from 'unstated';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductCardContainer
} from 'Component/ProductCard/ProductCard.container';
import SharedTransitionContainer from 'Component/SharedTransition/SharedTransition.unstated';

import ProductWidgetCardComponent from './ProductWidgetCard.component';

export {
    mapDispatchToProps,
    mapStateToProps
};

/** @namespace Scandipwa/Component/ProductWidgetCard/Container */
export class ProductWidgetCardContainer extends ProductCardContainer {
    static propTypes = {
        ...this.propTypes,
        urlKey: PropTypes.string
    };

    setCardHeight(element) {
        if (element) {
            const elementHeight = element.offsetHeight;

            element.addEventListener('mouseenter', () => {
                const elementHeightOnHover = element.offsetHeight;
                const heightGain = elementHeight - elementHeightOnHover;

                document.documentElement.style.setProperty('--widget-card-height-gain', `${heightGain}px`);
            });
        }
    }

    containerFunctions = {
        ...this.containerFunctions,
        setCardHeight: this.setCardHeight.bind(this)
    };

    containerProps() {
        const {
            urlKey
        } = this.props;

        return {
            ...super.containerProps(),
            urlKey
        };
    }

    render() {
        return (
            <Subscribe to={ [SharedTransitionContainer] }>
                { ({ registerSharedElement }) => (
                    <ProductWidgetCardComponent
                      { ...this.containerProps() }
                      { ...this.containerFunctions }
                      registerSharedElement={ registerSharedElement }
                    />
                ) }
            </Subscribe>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductWidgetCardContainer);
