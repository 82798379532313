/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { UPDATE_ATTACHMENTS, UPDATE_ATTACHMENTS_CONFIG } from './Attachments.action';

export const initialState = {
    attachments: [],
    config: {
    }
};
/** @namespace MageworxAttachment/Store/Attachments/Reducer/combineAttachmentsBySection */
export const combineAttachmentsBySection = (attachments) => {
    // Get all possible non-duplicate section IDs
    const uniqueSectionIds = Array.from(
        new Set(attachments.map(({ section_id }) => section_id))
    );

    // Create unique section ID/name map
    const uniqueSections = uniqueSectionIds.map((sectionId) => ({
        section_id: sectionId,
        section_name: attachments.find(({ section_id }) => section_id === sectionId)?.section_name,
        section_description: attachments.find(({ section_id }) => section_id === sectionId)?.section_description
    }));

    return uniqueSections.map((section) => {
        const { section_id, section_name, section_description } = section;

        return {
            section_id,
            section_name,
            section_description,
            items: attachments
                // Find all attachments that belong to set section ID
                .filter(({ section_id: sectionId }) => sectionId === section_id)
                // Result is array of array, flatten its depth by 1
                .flat(1)
        };
    });
};

/** @namespace MageworxAttachment/Store/Attachments/Reducer/AttachmentsReducer */
export const AttachmentsReducer = (state = initialState, action) => {
    const { attachments, type, config } = action;

    switch (type) {
    case UPDATE_ATTACHMENTS:
        return {
            ...state,
            attachments: combineAttachmentsBySection(attachments)
        };
    case UPDATE_ATTACHMENTS_CONFIG:
        return {
            ...state,
            config
        };
    default:
        return state;
    }
};

export default AttachmentsReducer;
