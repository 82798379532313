import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import CheckoutAddressForm from 'Component/CheckoutAddressForm';
import CheckoutAddressTable from 'Component/CheckoutAddressTable';
import Link from 'Component/Link/Link.component';
import Loader from 'Component/Loader';
import { BILLING_STEP } from 'Route/Checkout/Checkout.config';
import { MY_ACCOUNT_URL } from 'Route/MyAccount/MyAccount.config';
import { ADDRESS_BOOK } from 'Type/Account';

import { isAddressNull } from '../../util/AmazonPay.util';

/** @namespace Amazonpay/Component/AmazonAddress/Component */
export class AmazonAddressComponent extends PureComponent {
    static propTypes = {
        onCheckoutAddressTableClick: PropTypes.func.isRequired,
        onShippingEstimationFieldsChange: PropTypes.func.isRequired,
        toggleCustomAddress: PropTypes.func.isRequired,
        isCustomAddressExpanded: PropTypes.bool.isRequired,

        shippingAddress: PropTypes.shape().isRequired,
        formShippingAddress: PropTypes.shape().isRequired
    };

    renderAddressTable = () => {
        const {
            onCheckoutAddressTableClick,
            shippingAddress
        } = this.props;

        return (
            <CheckoutAddressTable
              onClick={ onCheckoutAddressTableClick }
              isSelected
              title="title"
              address={ shippingAddress }
              isAmazonPay
            />
        );
    };

    renderNoAddresses() {
        return (
            <div>
                <p>{ __('You have no configured addresses.') }</p>
                <p>
                    <Link to={ `${ MY_ACCOUNT_URL }/${ ADDRESS_BOOK }` }>
                        { __('Go to Address Book to configure them!') }
                    </Link>
                </p>
            </div>
        );
    }

    renderOptionalCustomAddress() {
        const {
            toggleCustomAddress,
            isCustomAddressExpanded
        } = this.props;

        const addressButtonText = isCustomAddressExpanded ? __('Use amazon address') : __('Use custom address');

        return (
            <div
              block="CheckoutAddressBook"
              elem="CustomAddressWrapper"
            >
                <button
                  block="CheckoutAddressBook"
                  elem="Button"
                  mods={ { isCustomAddressExpanded } }
                  mix={ { block: 'Button', mods: { isHollow: true } } }
                  type="button"
                  onClick={ toggleCustomAddress }
                >
                    { addressButtonText }
                </button>
                { isCustomAddressExpanded && this.renderCustomAddress() }
            </div>
        );
    }

    renderCustomAddress() {
        const {
            onShippingEstimationFieldsChange,
            formShippingAddress
        } = this.props;

        return (
            <CheckoutAddressForm
              onShippingEstimationFieldsChange={ onShippingEstimationFieldsChange }
              address={ formShippingAddress }
              id={ BILLING_STEP }
            />
        );
    }

    renderTitle() {
        return (
            <h2
              block="Checkout"
              elem="Heading"
            >
                { __('Select a Shipping Address') }
            </h2>
        );
    }

    renderContent() {
        const {
            isCustomAddressExpanded
        } = this.props;

        return (
            <>
                { !isCustomAddressExpanded && this.renderTitle() }
                { !isCustomAddressExpanded && this.renderAddressTable() }
            </>
        );
    }

    renderContentOrLoader() {
        const {
            shippingAddress
        } = this.props;

        if (isAddressNull(shippingAddress)) {
            return <Loader />;
        }

        return this.renderContent();
    }

    render() {
        return this.renderContentOrLoader();
    }
}

export default AmazonAddressComponent;
