import { connect } from 'react-redux';

import InkTonerSearchFieldComponent from './InkTonerSearchField.component';

/** @namespace Scandipwa/Component/InkTonerSearchField/Container/mapStateToProps */
// eslint-disable-next-line no-unused-vars
export const mapStateToProps = (state) => ({});

/** @namespace Scandipwa/Component/InkTonerSearchField/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(InkTonerSearchFieldComponent);
