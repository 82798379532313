import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductContainer as SourceProductContainer
} from 'SourceComponent/Product/Product.container';
import { validateGroup } from 'SourceUtil/Validator';

/** @namespace Scandipwa/Component/Product/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
});

/** @namespace Scandipwa/Component/Product/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
});

/** @namespace Scandipwa/Component/Product/Container */
export class ProductContainer extends SourceProductContainer {
    /**
     * checks if product has errors before adding to cart
     * @returns {boolean}
     */
    hasError() {
        if (!this.validator) {
            return false;
        }

        const validationOutput = validateGroup(this.validator);

        const {
            errorMessages = [],
            errorFields = [],
            values = []
        } = validationOutput !== true ? validationOutput : {};
        const { showError } = this.props;

        if (
            errorFields.length
            || errorMessages.length
            || this.validateConfigurableProduct()
            || this.filterAddToCartFileErrors(values)
        ) {
            this.scrollOptionsIntoView();
            this.setState({ addToCartTriggeredWithError: true });
            showError(__('Incorrect or missing options!'));

            return true;
        }

        return false;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer);
