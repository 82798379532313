/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import PropTypes from 'prop-types';

import {
    ExpandableContentShowMoreComponent as PrinterbaseExpandableContentShowMoreComponent
} from 'PrinterbaseComponent/ExpandableContentShowMore/ExpandableContentShowMore.component';
import { getElemBoundingClientRect } from 'Util/Common';

/** @namespace Scandipwa/Component/ExpandableContentShowMore/Component */
export class ExpandableContentShowMoreComponent extends PrinterbaseExpandableContentShowMoreComponent {
    static propTypes= {
        ...PrinterbaseExpandableContentShowMoreComponent.propTypes,
        forceOpen: PropTypes.bool
    };

    static defaultProps = {
        ...PrinterbaseExpandableContentShowMoreComponent.defaultProps,
        forceOpen: true
    };

    __construct(props) {
        super.__construct(props);

        this.state = {
            ...this.state,
            isOpen: true
        };
    }

    /**
     * Extended to implement forceOpen
     */
    componentDidMount() {
        this.initializeExpandableContentState();
    }

    async initializeExpandableContentState() {
        const { isOpen } = this.state;
        const { forceOpen } = this.props;

        if (isOpen) {
            if (this.expandableRef.current) {
                const { height = 0 } = await getElemBoundingClientRect(this.expandableRef.current) || {};
                this.expandableContentHeight = height;
            }
            this.setState({ isOpen: false });
        }

        if (forceOpen) {
            this.setState({ isOpen: true });
        }
    }

    /**
     * Extended to implement forceOpen
     */
    getExpandableContentHeight() {
        const { isOpen } = this.state;
        const { forceOpen, showElemCount, children: { length } } = this.props;

        if (isOpen && length <= showElemCount) {
            this.setState({ isOpen: false });

            return;
        }

        this.expandableContentHeight = 'auto';
        this.setState({ isOpen: true }, async () => {
            if (this.expandableRef.current) {
                const { height = 0 } = await getElemBoundingClientRect(this.expandableRef.current) || {};
                this.expandableContentHeight = height;
            }
            if (!forceOpen) {
                this.setState({ isOpen: false });
            }
        });
    }

    renderExpandableChildren() {
        const { isOpen } = this.state;
        const { children, showElemCount } = this.props;

        const child = children.slice(showElemCount);
        const style = {
            height: isOpen ? this.expandableContentHeight : 0
        };

        return (
            <div
              ref={ this.expandableRef }
              block="ExpandableContentShowMore"
              elem="ExpandableChildren"
              style={ style }
            >
                { child }
            </div>
        );
    }
}

export default ExpandableContentShowMoreComponent;
