/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { PaymentMethodType } from 'Type/Checkout';

import { PaypalConfigsType } from '../../type/Paypal.type';
import PaypalPayment from './PaypalPayment.component';
import { ACCEPTANCE_MARK_POPUP_SETTINGS, ACCEPTANCE_MARK_POPUP_TITLE } from './PaypalPayment.config';

/** @namespace Scandiweb/PaypalExpress/Component/PaypalPayment/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    paypalConfigs: state.ConfigReducer?.paypalConfigs
});

/** @namespace Scandiweb/PaypalExpress/Component/PaypalPayment/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Scandiweb/PaypalExpress/Component/PaypalPayment/Container */
export class PaypalPaymentContainer extends PureComponent {
    static propTypes = {
        method: PaymentMethodType.isRequired,
        onClick: PropTypes.func.isRequired,
        isSelected: PropTypes.bool,
        paypalConfigs: PaypalConfigsType
    };

    static defaultProps = {
        isSelected: false,
        paypalConfigs: {}
    };

    containerFunctions = {
        handleClick: this.handleClick.bind(this),
        handleAcceptanceMarkClick: this.handleAcceptanceMarkClick.bind(this)
    };

    handleClick() {
        const {
            onClick,
            method
        } = this.props;

        onClick(method);
    }

    handleAcceptanceMarkClick() {
        const {
            paypalConfigs: {
                paymentAcceptanceMarkUrl
            } = {}
        } = this.props;

        window.open(
            paymentAcceptanceMarkUrl,
            ACCEPTANCE_MARK_POPUP_TITLE,
            ACCEPTANCE_MARK_POPUP_SETTINGS
        );
    }

    containerProps() {
        const {
            method,
            isSelected,
            paypalConfigs: {
                paymentAcceptanceMarkImageUrl
            }
        } = this.props;

        return {
            method,
            isSelected,
            paymentAcceptanceMarkImageUrl
        };
    }

    render() {
        return (
            <PaypalPayment
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaypalPaymentContainer);
