import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { CustomerType } from 'Type/Account';
import { isSignedIn } from 'Util/Auth';
import transformToNameValuePair from 'Util/Form/Transform';

import CheckoutSuccessNewsletter from './CheckoutSuccessNewsletter.component';

export const NewsletterSubscriptionDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/NewsletterSubscription/NewsletterSubscription.dispatcher'
);

/** @namespace Scandipwa/Component/CheckoutSuccessNewsletter/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    customer: state.MyAccountReducer.customer
});

/** @namespace Scandipwa/Component/CheckoutSuccessNewsletter/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    subscribeToNewsletter: (email, name) => NewsletterSubscriptionDispatcher.then(
        ({ default: dispatcher }) => dispatcher.subscribeToNewsletter(dispatch, email, name)
    ),
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace Scandipwa/Component/CheckoutSuccessNewsletter/Container */
export class CheckoutSuccessNewsletterContainer extends PureComponent {
    static propTypes = {
        showNotification: PropTypes.func.isRequired,
        subscribeToNewsletter: PropTypes.func.isRequired,
        guestEmail: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        customer: CustomerType.isRequired
    };

    containerFunctions = {
        onSubSuccess: this.onSubSuccess.bind(this),
        onSubError: this.onSubError.bind(this)
    };

    state = {
        email: ''
    };

    componentDidMount() {
        this.setEmail();
        window.scrollTo(0, 0);
        this.changeCheckoutStyle();
    }

    containerProps = () => {
        const {
            name,
            customer
        } = this.props;

        const {
            email
        } = this.state;

        return {
            email,
            name,
            customer
        };
    };

    setEmail() {
        const {
            guestEmail,
            customer
        } = this.props;

        this.setState({ email: isSignedIn() ? customer.email : guestEmail });
    }

    onSubError() {
        const { showNotification } = this.props;
        showNotification('info', __('Something went Wrong'));
    }

    changeCheckoutStyle() {
        if (window.location.pathname === '/checkout/success') {
            document.getElementsByClassName('Checkout-Wrapper')[0].style.display = 'block';
        }
    }

    async onSubSuccess(form, fields) {
        const {
            subscribeToNewsletter
        } = this.props;

        const {
            email,
            name
        } = transformToNameValuePair(fields);

        try {
            await subscribeToNewsletter(email, name);
        } catch (error) {
            showNotification('error', __('Something went Wrong'));
        }
    }

    render() {
        return (
             <CheckoutSuccessNewsletter
               { ...this.containerFunctions }
               { ...this.containerProps() }
             />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutSuccessNewsletterContainer);
