/**
 * @category  ScandiPWA
 * @author    ScandiWeb Team <info@scandiweb.com>
 * @package   @scandipwa/amazonpay-printerbase-compatibility
 */
import { showNotification } from 'SourceStore/Notification/Notification.action';
import { fetchMutation } from 'SourceUtil/Request';
import getStore from 'Util/Store';

import CheckoutShippingMethodQuery from '../../../query/CheckoutShippingMethod.query';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Scandipwa/Plugin/Component/CheckoutShipping/UpdateShippingMethodInBackEnd/updateShippingMethodShippingInformationInBackEnd */
export function updateShippingMethodShippingInformationInBackEnd(CheckoutShippingContainerInstance, shippingMethod) {
    const { totals: cartTotals } = CheckoutShippingContainerInstance.props;
    const state = getStore().getState();
    const {
        cartTotals: { shipping_addresses = [] },
        isLoading: isCartLoading
    } = state.CartReducer;

    if (isCartLoading || shipping_addresses.length === 0) {
        return;
    }

    const cartId = cartTotals.id;

    const updateShippingMethodMutation = CheckoutShippingMethodQuery.getUpdateCartShippingMethodMutation(
        cartId,
        shippingMethod.carrier_code,
        shippingMethod.method_code
    );

    if (CheckoutShippingContainerInstance) {
        CheckoutShippingContainerInstance.setState({ isLoading: true });
    }

    fetchMutation(updateShippingMethodMutation)
        .then(
            /** @namespace Scandipwa/Plugin/Component/CheckoutShipping/UpdateShippingMethodInBackEnd/updateShippingMethodShippingInformationInBackEnd/catch/finally/then/catch/fetchMutation/then */
            () => {
                // eslint-disable-next-line no-param-reassign
                CheckoutShippingContainerInstance.savedAddressInBackEnd = true;

                // The ideal way to put make code be executed at this point is to create a plugin for this function
                // and call whatever code you want to, eg: AmazonpayButtons.mustReRender();
            }
        )
        .catch(
            /** @namespace Scandipwa/Plugin/Component/CheckoutShipping/UpdateShippingMethodInBackEnd/updateShippingMethodShippingInformationInBackEnd/catch/finally/then/catch */
            (error) => {
                showNotification('error', error);
            }
        ).finally(
            /** @namespace Scandipwa/Plugin/Component/CheckoutShipping/UpdateShippingMethodInBackEnd/updateShippingMethodShippingInformationInBackEnd/catch/finally */
            () => {
                if (CheckoutShippingContainerInstance) {
                    CheckoutShippingContainerInstance.setState({
                        isLoading: false,
                        mustReRender: false
                    });
                }

                const { dispatch } = getStore();
                CartDispatcher.then(
                    ({ default: dispatcher }) => {
                        dispatcher.updateInitialCartData(dispatch);
                    }
                );
            }
        );
}
