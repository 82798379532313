export const containerProps = (args, callback, instance) => {
    const { isDisplayProduct, sortFields } = instance.props;

    const filteredOptions = Object.prototype.hasOwnProperty.call(sortFields, 'options') && !isDisplayProduct
        ? sortFields.options.filter(({ value }) => value !== 'price')
        : sortFields.options;

    const filteredSortFields = { ...sortFields, options: filteredOptions };

    return {
        ...callback(...args),
        sortFields: filteredSortFields,
        isDisplayProduct
    };
};

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        isDisplayProduct: state.KlevuSearchReducer.isDisplayProduct
    };
};

export const renderCategorySort = (args, callback, instance) => {
    const {
        sortFields, selectedSort, onSortChange, isMatchingInfoFilter, isDisplayProduct
    } = instance.props;

    const newEnv = {
        props: {
            sortFields,
            selectedSort,
            onSortChange,
            isMatchingInfoFilter
        }
    };

    sortFields.options = isDisplayProduct
        ? sortFields.options
        : sortFields.options.filter(({ value }) => value !== 'price');

    return callback.apply(newEnv, ...args);
};

export default {
    'Route/SearchPage/Component': {
        'member-function': {
            renderCategorySort
        }
    },
    'Route/SearchPage/Container': {
        'member-function': {
            containerProps
        }
    },
    'Route/SearchPage/Container/mapStateToProps': {
        function: mapStateToProps
    }
};
