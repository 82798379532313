import { RELATED_POP_UP } from 'component/AddToBasket/AddToBasket.config';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { connect } from 'react-redux';

import { PROMO_POPUP_ID } from 'Component/AddToCart/AddToCart.config';
import {
    AddToCartContainer as SourceAddToCartContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/AddToCart/AddToCart.container';
import LinkedProductsReducer from 'Store/LinkedProducts/LinkedProducts.reducer';
import { withReducers } from 'Util/DynamicReducer';
import { ADD_TO_CART } from 'Util/Product';
import { magentoProductTransform } from 'Util/Product/Transform';

import { PRODUCT_TYPES } from './AddToCart.config';

export const LinkedProductsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/LinkedProducts/LinkedProducts.dispatcher'
);
/** @namespace Scandipwa/Component/AddToCart/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps,
    relatedProductsData: state.LinkedProductsReducer.linkedProducts
});

/** @namespace Scandipwa/Component/AddToCart/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    fetchRelatedProductsData: (product_links) => LinkedProductsDispatcher.then(
        ({ default: dispatcher }) => dispatcher.handleData(dispatch, product_links)
    )
});

/** @namespace Scandipwa/Component/AddToCart/Container */
export class AddToCartContainer extends SourceAddToCartContainer {
    state = {
        ...this.state,
        relatedProductsData: {},
        isLoaded: false
    };

    static propTypes = {
        ...this.propTypes,
        fetchRelatedProductsData: PropTypes.func.isRequired
    };

    getIsPrinter() {
        const {
            product: {
                attributes: {
                    product_type: {
                        attribute_value,
                        attribute_options: {
                            [attribute_value]: {
                                label
                            } = {}
                        } = {}
                    } = {}
                } = {}
            } = {}
        } = this.props;

        return PRODUCT_TYPES.includes(label);
    }

    /**
     * Method to show promo popup
     */
    showPromoPopup(id) {
        const { showPopup } = this.props;

        showPopup(id);
    }

    /**
     * gets related products for each product on call
     */
    async relatedProductsData() {
        const {
            fetchRelatedProductsData,
            product: { product_links } = {}
        } = this.props;

        fetchRelatedProductsData(product_links);

        this.setState({ isLoaded: true });
    }

    /**
     * Overridden to add relatedProducts data method calls
     */
    async addProductToCart(e) {
        const {
            product,
            addToCart
        } = this.props;

        e.stopPropagation();

        if ((!product || Object.keys(product).length === 0) && !addToCart) {
            return;
        }

        e.preventDefault();
        this.setState({ isAdding: true });

        if (!this.validate()) {
            return;
        }

        if (typeof addToCart === 'function') {
            try {
                await addToCart();

                if (this.getIsPrinter()) {
                    this.relatedProductsData();
                    this.setState({ isPopupVisible: true });
                    this.showPromoPopup(RELATED_POP_UP);
                } else {
                    this.setState({ isPopupVisible: true });
                    this.showPromoPopup(PROMO_POPUP_ID);
                }
            } finally {
                this.setState({ isAdding: false });
            }
        } else {
            const {
                quantity,
                cartId,
                fallbackAddToCart
            } = this.props;
            const magentoProduct = magentoProductTransform(ADD_TO_CART, product, quantity);

            try {
                await fallbackAddToCart({
                    products: magentoProduct,
                    cartId
                });
                this.setState({ isPopupVisible: true });
                this.showPromoPopup();
            } finally {
                this.setState({ isAdding: false });
            }
        }

        this.setState({ isAdding: false });
    }

    _processForOverlay(linkedProducts) {
        const { related } = linkedProducts;

        if (!related.items) {
            return linkedProducts;
        }

        try {
            related.items = related.items.filter((item) => (
                item.attribute_set_name !== 'Hardware Set'
            ));

            // Sort items based on manufacturer, giving priority to 'Compatible' items
            related.items.sort((a, b) => {
                if (a.manufacturer[0] === 'Compatible' && b.manufacturer[0] !== 'Compatible') {
                    return -1;
                }

                return 1;
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }
        // Filter out items with attribute_set_name equal to 'Hardware Set'

        return linkedProducts;
    }

    /**
     * Overridden to pass isPrinter prop
     */
    containerProps() {
        const { isLoaded } = this.state;
        const { relatedProductsData, showStockNotifyPopup } = this.props;

        const processedData = this._processForOverlay(relatedProductsData);

        return {
            ...super.containerProps(),
            isPrinter: this.getIsPrinter(),
            relatedProductsData: processedData,
            isLoaded,
            showStockNotifyPopup
        };
    }
}

export const MemoizedAddToCartContainer = memo(AddToCartContainer, (prevProps, nextProps) => {
    const {
        mix,
        ...restProps
    } = prevProps;

    const {
        mix: nextMix,
        ...restNextProps
    } = nextProps;

    if (
        JSON.stringify(mix) !== JSON.stringify(nextMix)
    ) {
        return false;
    }

    const restPropsAreNotEqual = Object.keys(nextProps).some(
        (key) => (typeof restProps[key] === 'function' ? false : restProps[key] !== restNextProps[key])
    );

    return !restPropsAreNotEqual;
});

export default withReducers({
    LinkedProductsReducer
})(connect(mapStateToProps, mapDispatchToProps)(MemoizedAddToCartContainer));
