/**
 * Resolves a string or a number into an array of number(s)
 * @param {string|number} ids
 * @returns {Array.<number>} Array of ids
 * @namespace MageworxAttachment/Util/Widget/Attachments/resolveAttachmentsIds
 */
export function resolveAttachmentsIds(ids) {
    if (typeof ids === 'number') {
        return [ids];
    }

    return ids.split(',').map((id) => Number(id));
}
