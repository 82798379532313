/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @package scandipwa/printerbase
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './ProductCapacityColour.style';

/** @namespace Scandipwa/Component/ProductCapacityColour/Component */
export class ProductCapacityColourComponent extends PureComponent {
    static propTypes = {
        productSku: PropTypes.string.isRequired,
        capacityColour: PropTypes.arrayOf(PropTypes.shape({
            colour_mnemonic: PropTypes.string,
            colour_name: PropTypes.string,
            colour_thumb_image_url: PropTypes.string,
            capacity_value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            capacity_unity: PropTypes.string
        })).isRequired,
        mods: PropTypes.objectOf(PropTypes.bool)
    };

    static defaultProps = {
        mods: {}
    };

    renderCapacityColourItems(capacityColour, productSku, attributeMods) {
        if (!Array.isArray(capacityColour) || !productSku) {
            return null;
        }

        return (
            capacityColour.map(this.renderCapacityColorItem.bind(null, productSku, attributeMods))
        );
    }

    renderCapacityColorItem(productSku, attributeMods, item) {
        const {
            colour_thumb_image_url: imgSrc,
            colour_name: imgAlt,
            capacity_value: capacityValue,
            capacity_unity: capacityUnit
        } = item;

        const itemKey = `${productSku}-${item.colour_mnemonic}`;

        return (
            <div block="ProductCapacityColour" elem="Item" key={ itemKey } mods={ attributeMods }>
                <div block="ProductCapacityColour" elem="ItemImage" mods={ attributeMods }>
                    <img src={ imgSrc } alt={ imgAlt } title={ imgAlt } />
                </div>
                <div block="ProductCapacityColour" elem="ItemInfo" mods={ attributeMods }>
                    <div block="ProductCapacityColour" elem="ItemInfoValue" mods={ attributeMods }>
                        { capacityValue }
                    </div>
                    <div block="ProductCapacityColour" elem="ItemInfoLabel" mods={ attributeMods }>
                        { capacityUnit }
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { capacityColour, productSku, mods: attributeMods } = this.props;

        return (
            this.renderCapacityColourItems(capacityColour, productSku, attributeMods)
        );
    }
}

export default ProductCapacityColourComponent;
