/* eslint-disable max-lines */
/**
 * Amasty Custom Forms compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { createRef } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import history from 'Util/History';
import { fetchMutation } from 'Util/Request';
import DataContainer from 'Util/Request/DataContainer';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

import CustomFormsQuery from '../../query/CustomForms.query';
import CustomForms from './CustomForms.component';
import {
    CHECKBOX,
    CHECKBOX_TWO,
    CUSTOM_FORM,
    DATE, EVENT_CUSTOM_FORM_FAILED, EVENT_CUSTOM_FORM_SUBMITED, FILE,
    FORM_POPUP_ID,
    LISTBOX,
    SELECT,
    TIME,
    UPLOAD_FILE_ENDPOINT
} from './CustomForms.config';

/** @namespace Scandiweb/CustomForms/Component/CustomForms/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Scandiweb/CustomForms/Component/CustomForms/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    showPopup: (payload) => dispatch(showPopup(FORM_POPUP_ID, payload)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay())
});

/** @namespace Scandiweb/CustomForms/Component/CustomForms/Container */
export class CustomFormsContainer extends DataContainer {
     static propTypes = {
         id: PropTypes.number.isRequired,
         hideActiveOverlay: PropTypes.func.isRequired,
         showPopup: PropTypes.func.isRequired
     };

     fileRef = createRef();

     state = {
         customForm: {},
         formIsSubmitted: BrowserDatabase.getItem(CUSTOM_FORM) || [],
         isSubmitted: false,
         uploadedFile: '',
         isGdprCheck: false,
         selectedOptions: [],
         activePage: 1
     };

     containerProps() {
         const {
             customForm: {
                 form_json = null,
                 form_id = null,
                 submit_button = null,
                 popup_button = '',
                 popup_show = null,
                 gdpr_enabled = null,
                 gdpr_text = '',
                 title = ''
             },
             isSubmitted,
             uploadedFile = {},
             isGdprCheck,
             selectedOptions,
             activePage
         } = this.state;

         return {
             formFields: JSON.parse(form_json),
             formId: form_id,
             submitButtonText: submit_button,
             popupBtn: popup_button,
             isPopup: popup_show,
             isGdprEnabled: gdpr_enabled,
             gdprText: gdpr_text,
             isSubmitted,
             title,
             uploadedFile,
             isGdprCheck,
             selectedOptions,
             activePage,
             fileRef: this.fileRef
         };
     }

     containerFunctions = {
         onFormSuccess: this.onFormSuccess.bind(this),
         onFileUpload: this.onFileUpload.bind(this),
         showCreateNewPopup: this.showCreateNewPopup.bind(this),
         getStyle: this.getStyle,
         formatBytes: this.formatBytes,
         cleanUploadedFile: this.cleanUploadedFile.bind(this),
         handleGdprClick: this.handleGdprClick.bind(this),
         handleListChange: this.handleListChange.bind(this),
         changeActivePage: this.changeActivePage.bind(this)
     };

     componentDidMount() {
         const { id } = this.props;

         if (id) {
             this.requestFormData();
         }
     }

     changeActivePage() {
         const { activePage } = this.state;
         this.setState({ activePage: (activePage + 1) });
     }

     handleListChange(e) {
         e.preventDefault();
         const { value } = e.target;
         const { selectedOptions } = this.state;
         const tmp = selectedOptions;

         if (selectedOptions.some((elem) => elem === value)) {
             const index = selectedOptions.indexOf(value);
             if (index > -1) {
                 tmp.splice(index, 1);
                 this.setState({ selectedOptions: tmp });
             }
         } else {
             tmp.push(value);
             this.setState({ selectedOptions: tmp });
         }
     }

     handleGdprClick() {
         const { isGdprCheck } = this.state;
         this.setState({ isGdprCheck: !isGdprCheck });
     }

     formatBytes(bytes) {
         const sizeSet = 1024;
         const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
         const fixedSize = Math.floor(Math.log(bytes) / Math.log(sizeSet));

         return `${parseFloat((bytes / Math.pow(sizeSet, fixedSize)).toFixed(2)) } ${ sizes[fixedSize]}`;
     }

     cleanUploadedFile() {
         this.setState({ uploadedFile: {} });
     }

     onFileUpload() {
         const { current: { files = [] } } = this.fileRef;
         if (files.length) {
             const file = files[0];
             this.setState({ uploadedFile: file });
         } else {
             this.cleanUploadedFile();
         }
     }

     onPageLoad = ({ customform: customForm }) => {
         this.setState({ customForm });
     };

     requestFormData() {
         const { id } = this.props;

         this.fetchData(
             [CustomFormsQuery.getQuery(id)],
             this.onPageLoad
         );
     }

     getStyle(styleStr) {
         if (!styleStr || !styleStr.includes(':')) {
             return {};
         }

         const style = {};
         const styleList = styleStr.split(';');

         styleList.forEach((styleElement) => {
             const [property, value] = styleElement.split(':');

             if (property && value) {
                 style[property.trim()] = value.trim();
             }
         });

         return style;
     }

     getValidDateTime(type, value) {
         if (type === DATE) {
             return value.split(' ')[0];
         }

         return value.split(' ')[1];
     }

     onFormSuccess(form, fields = []) {
         const {
             customForm:
             { form_json, form_id }, selectedOptions, uploadedFile
         } = this.state;
         const listTmp = selectedOptions;
         const formFields = JSON.parse(form_json);
         const formData = { form_id };
         const groupNames = [];
         const data = {};

         data.form_id = form_id;
         fields.forEach(({ name, value, type }) => {
             if (value) {
                 switch (type) {
                 case DATE:
                     data[name] = this.getValidDateTime(type, value);
                     break;
                 case TIME:
                     data[name] = this.getValidDateTime(type, value);
                     break;
                 case SELECT:
                     if (name.includes(LISTBOX)) {
                         data[name] = listTmp.join(' | ');
                     } else {
                         data[name] = value;
                     }
                     break;
                 case FILE:
                     data[name] = uploadedFile.name;
                     break;
                 case CHECKBOX:
                     data[name] = this.getCheckboxValues(fields, name);
                     break;
                 default:
                     data[name] = value;
                     break;
                 }
             }
         });

         const results = { form_data: JSON.stringify(data) };

         if (this.checkSelected(groupNames, formData, formFields)) {
             this.handleForm(results);
         }
     }

     getCheckboxValues(fields, name) {
         const checkboxes = fields.reduce((acc = [], field) => {
             if (field.name === name && field.value && acc.length) {
                 return [...acc, field.value];
             } if ((field.name === name && field.value && !acc.length)) {
                 return [field.value];
             }

             return acc;
         });

         return checkboxes.join(' | ');
     }

     checkSelected(groupNames, formData, formFields) {
         const { showNotification } = this.props;

         const fieldsCheck = formFields[0].map((field) => {
             if (field.required && [CHECKBOX, CHECKBOX_TWO].includes(field.type)) {
                 const isSelected = groupNames.map((name) => {
                     if (name === field.name) {
                         if (formData[name].length < 1) {
                             showNotification('error', __('You need to select at least one "%s" option!', field.label));
                             this.setState({ activePage: 1 });
                             return false;
                         }
                     }
                 });

                 return !isSelected.some((status) => status === false);
             }
         });

         return !fieldsCheck.some((status) => status === false);
     }

     showCreateNewPopup() {
         const { showPopup } = this.props;

         showPopup({ action: CUSTOM_FORM });
     }

     handleForm(fields) {
         const { showNotification, hideActiveOverlay, id: formId } = this.props;
         const {
             customForm: {
                 success_message,
                 success_url,
                 isSurvey,
                 code
             },
             formIsSubmitted, uploadedFile
         } = this.state;

         formIsSubmitted.push(formId);

         fetchMutation(CustomFormsQuery.getFormFields(fields)).then(
             /** @namespace Scandiweb/CustomForms/Component/CustomForms/Container/CustomFormsContainer/handleForm/fetchMutation/then */
             () => {
                 // vvv add a custom event to dispatch on form submit.
                 this.dispatchCustomFormEvent(EVENT_CUSTOM_FORM_SUBMITED, fields, code, formId);

                 hideActiveOverlay();

                 if (isSurvey) {
                     BrowserDatabase.setItem(
                         formIsSubmitted,
                         CUSTOM_FORM,
                         ONE_MONTH_IN_SECONDS
                     );
                 }

                 if (uploadedFile) {
                     this.handleFileUpload();
                 }

                 showNotification('success', success_message);
                 if (success_url !== '/') {
                     history.push(success_url);
                 }
             },
             /** @namespace Scandiweb/CustomForms/Component/CustomForms/Container/CustomFormsContainer/handleForm/fetchMutation/then/catch */
             (error) => {
                 this.dispatchCustomFormEvent(EVENT_CUSTOM_FORM_FAILED, fields, code, formId, error);
                 // vvv add a custom event to dispatch on form submit error.

                 showNotification('error', error[0].message);
                 this.setState({ activePage: 1 });
             }
         );
     }

     getFormEmail({ form_data: formData = '' }) {
         const { customForm: { email_field } } = this.state;
         const { [email_field]: customerEmail } = JSON.parse(formData) ?? {};

         return customerEmail;
     }

     dispatchCustomFormEvent(eventName, fields, formCode, formId, error) {
         const customerEmail = this.getFormEmail(fields);

         const detail = {
             formCode,
             formId,
             error,
             customerEmail
         };

         document.dispatchEvent(new CustomEvent(eventName, { detail }));
     }

     handleFileUpload() {
         const { uploadedFile } = this.state;
         const data = new FormData();

         data.append('file', uploadedFile);

         fetch(UPLOAD_FILE_ENDPOINT,
             {
                 method: 'POST',
                 body: data
             });
     }

     checkSubmitted() {
         const { id: formId } = this.props;
         const { formIsSubmitted } = this.state;
         const isSubmitted = formIsSubmitted.some((submittedFormId) => submittedFormId === formId);

         this.setState({ isSubmitted });
     }

     render() {
         const { customForm: { isSurvey } } = this.state;

         if (isSurvey) {
             this.checkSubmitted();
         }

         return (
             <CustomForms
               { ...this.containerProps() }
               { ...this.containerFunctions }
             />
         );
     }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomFormsContainer);
