import { Field } from 'Util/Query';

const getDimensions = () => new Field('dimensions');

export const aroundGetProductInterfaceFields = (args, callback, instance) => {
    const fields = callback.apply(instance, args);
    const isVariant = args[0] === undefined ? false : args[0];
    const { notRequireCategories } = instance.options;

    if (!isVariant) {
        fields.push(instance._getAttributesField());
    }

    if (!notRequireCategories) {
        fields.push(instance._getCategoriesField());
    }

    fields.push(instance._getPriceRangeField());

    fields.push(
        getDimensions()
    );

    return fields;
};

export default {
    'Scandipwa/Query/ProductList/Query': {
        'member-function': {
            _getProductInterfaceFields: aroundGetProductInterfaceFields
        }
    }
};
