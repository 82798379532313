import ContentWrapper from '@scandipwa/scandipwa/src/component/ContentWrapper';
import ExpandableContent from '@scandipwa/scandipwa/src/component/ExpandableContent';
import { lazy, Suspense } from 'react';

import Loader from 'Component/Loader/Loader.component';
import { PRODUCT_ATTACHMENTS } from 'Route/ProductPage/ProductPage.config';

export const ProductAttachments = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-attachments" */
    '../component/ProductAttachments'
));

export const AttachmentsConfig = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../store/Attachments/AttachmentsConfig.dispatcher'
);

export const ATTACHMENTS = 'attachments';
export const DOWNLOADS = __('Downloads');

const tabMap = (member, instance) => {
    const { attachmentsConfig: { tab_title } } = instance.props;

    return {
        ...member,
        [ATTACHMENTS]: {
            name: tab_title || DOWNLOADS,
            shouldTabRender: () => {
                const { isAttachmentsTabEmpty } = instance.props;

                return !isAttachmentsTabEmpty;
            },
            render: (key) => instance.renderProductAttachmentsTab(key)
        }
    };
};

/**
 * Overridden to pass setTabExpands
 */
const renderProductAttachmentsTab = (args, callback, instance) => {
    const {
        attachments,
        wrapperMix,
        isProductAttachmentsExpanded,
        attachmentsConfig: {
            tab_title
        },
        setTabExpands,
        setProductAttachmentsExpanded
    } = instance.props;

    const heading = tab_title || DOWNLOADS;
    const defaultMix = { block: 'ProductAttachments', elem: 'Wrapper' };

    return (
        <Suspense fallback={ <Loader /> } key={ `attachments_${attachments.length || 'none'}` }>
            <ContentWrapper
              label="Product attachments"
              wrapperMix={ wrapperMix || defaultMix }
              mix={ { block: 'ProductAttachments' } }
            >
              <ExpandableContent
                mix={ { block: 'ProductAttachments', elem: 'Content' } }
                heading={ heading }
                isArrow
                renderIconOnDesktop
                onClick={ setProductAttachmentsExpanded }
                isContentExpanded={ isProductAttachmentsExpanded }
                setTabExpands={ setTabExpands }
              >
                    <ProductAttachments
                      sections={ attachments }
                      key={ `attachments_${attachments.length || 'none'}` }
                    />
              </ExpandableContent>
            </ContentWrapper>
        </Suspense>
    );
};

const containerProps = (args, callback, instance) => ({
    ...callback(...args),
    isAttachmentsTabEmpty: instance.isProductAttachmentsTabEmpty(),
    attachments: instance.props.attachments,
    attachmentsConfig: instance.props?.config || {},
    dynamicTabs: instance.state.tabs,
    areAttachmentConfigsLoaded: instance.areAttachmentConfigsLoaded(),
    isConfigLoaded: instance.state.configLoaded
});

const isProductAttachmentsTabEmpty = (args, callback, instance) => {
    const { attachments } = instance.props;

    return attachments.length === 0;
};

const areAttachmentConfigsLoaded = (args, callback, instance) => {
    const { config } = instance.props;

    return Boolean(Object.keys(config).length);
};

const mapStateToProps = (args, callback) => {
    const [state] = args;
    const { AttachmentsReducer: { attachments, config } } = state;

    return {
        ...callback(...args),
        attachments,
        config
    };
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        updateAttachmentsConfig: () => AttachmentsConfig.then(
            ({ default: dispatcher }) => dispatcher.handleData(dispatch)
        )
    };
};

const componentDidMount = (args, callback, instance) => {
    const { updateAttachmentsConfig } = instance.props;
    updateAttachmentsConfig().then(() => instance.setState({ configLoaded: true }));
    return callback(...args);
};

const shouldTabsRender = (args, callback, instance) => {
    const { tabMap, props: { areAttachmentConfigsLoaded, attachmentsConfig: { tab_title } } } = instance;

    if (areAttachmentConfigsLoaded) {
        Object.keys(tabMap).forEach((key) => {
            if (tabMap[key].name.value === DOWNLOADS) {
                tabMap[key].name = tab_title || DOWNLOADS;
            }
        });
    }

    return callback(...args);
};

export default {
    'Route/ProductPage/Container': {
        'member-function': {
            containerProps,
            renderProductAttachmentsTab,
            componentDidMount,
            areAttachmentConfigsLoaded,
            isProductAttachmentsTabEmpty
        }
    },
    'Route/ProductPage/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Route/ProductPage/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Route/ProductPage/Component': {
        'member-function': {
            renderProductAttachmentsTab,
            shouldTabsRender
        },
        'member-property': {
            tabMap
        }
    }
};
