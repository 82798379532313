/**
 * Amasty Custom Forms compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { PureComponent } from 'react';

import { Field } from 'Util/Query';

/** @namespace Scandiweb/CustomForms/Query/CustomForms/Query/CustomFormsQuery */
export class CustomFormsQuery extends PureComponent {
    getFormFields(formFields) {
        return new Field('amCustomFormSubmit')
            .addArgument('input', 'AmFormSubmitInput!', formFields)
            .addField('status');
    }

    getQuery(id) {
        if (!id) {
            throw new Error('Missing argument form `id`!');
        }

        const customForm = new Field('customform')
            .addFieldList(this._getAmastyFormFields());

        if (id) {
            customForm.addArgument('formId', 'Int!', id);
        }

        return customForm;
    }

    _getAmastyFormFields() {
        return [
            'form_id',
            'title',
            'code',
            'success_url',
            'status',
            'created_at',
            'customer_group',
            'store_id',
            'send_notification',
            'send_to',
            'submit_button',
            'success_message',
            'form_json',
            'email_field',
            'email_field_hide',
            'popup_show',
            'popup_button',
            'form_title',
            'gdpr_enabled',
            'gdpr_text',
            'advanced_google_key',
            'advanced_date_format',
            'is_form_available',
            'isSurvey'
        ];
    }
}

export default new CustomFormsQuery();
