/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const DELIVERY_INFORMATION = 'deliveryInformation';
export const DELIVERY_INFORMATION_TAB_QUERY = 'deliveryInformationTabQuery';
export const DELIVERY_INFORMATION_CMS_BLOCK_IDENTIFIER = 'delivery_information';
export const DELIVERY_INFORMATION_LABEL = __('Delivery Information');
