import { PRICE_TYPE_PERCENT } from 'PrinterbaseUtil/Product/Transform';
import { formatPrice } from 'Util/Price/Price';

export * from 'PrinterbaseUtil/Product/Transform';

/** @namespace Scandipwa/Util/Product/Transform/customizableOptionToLabel */
export const customizableOptionToLabel = (option, currencyCode = 'USD') => {
    const {
        price,
        display_price,
        price_type,
        title = ''
    } = option || {};
    const noPrice = price === 0 && (!display_price || display_price === '0');
    const priceLabel = noPrice ? '' : ` + ${ formatPrice(display_price, currencyCode) }`;
    const percentLabel = (noPrice || price_type !== PRICE_TYPE_PERCENT) ? '' : ` (${ price }%)`;

    return {
        baseLabel: title.replaceAll('\\n', ''),
        priceLabel: `${ priceLabel } ${ percentLabel }`
    };
};

/**
 * Converts customizable products options into select field options,
 * meaning into [uid:label] pair
 *
 * @param options
 * @namespace Scandipwa/Util/Product/Transform/customizableOptionsToSelectTransform */
export const customizableOptionsToSelectTransform = (options, currencyCode = 'USD') => (
    options.reduce((result = [], option) => {
        const {
            uid,
            title,
            position
        } = option;

        const {
            priceLabel,
            baseLabel
        } = customizableOptionToLabel(option, currencyCode);

        result.push({
            id: uid,
            name: title,
            value: uid,
            label: baseLabel,
            subLabel: priceLabel,
            sort_order: position
        });

        return result;
    }, [])
);
