/**
 * Wyomind google customer reviews compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { BadgeConfigType } from '../../type/BadgeConfig.type';
import { parseCss } from '../../util/util';
import { GCR_BODY_CLASS, USER_DEFINED } from './Badge.config';

/** @namespace Scandiweb/WyomindGoogleCustomerReviews/Component/Badge/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    badgeConfig: state.ConfigReducer.badgeConfig
});

/** @namespace Scandiweb/WyomindGoogleCustomerReviews/Component/Badge/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Scandiweb/WyomindGoogleCustomerReviews/Component/Badge/Container/BadgeContainer */
export class BadgeContainer extends PureComponent {
    static propTypes = {
        badgeConfig: BadgeConfigType.isRequired
    };

    containerFunctions = {};

    componentDidUpdate() {
        const {
            badgeConfig: { merchantId }
        } = this.props;

        if (merchantId) {
            this.initGcr();
        }
    }

    initGcr() {
        const {
            badgeConfig: { position }
        } = this.props;

        if (document.body.classList.contains(GCR_BODY_CLASS)) {
            // ^^^ Already initialized
            return;
        }

        document.body.classList.add(GCR_BODY_CLASS);

        this.renderBadge();
        this.appendGoogleRenderBadgeScript();

        if (position === USER_DEFINED) {
            this.appendGooglePlatformScript();
            this.appendGcrContainer();
        }
    }

    appendGoogleRenderBadgeScript() {
        const script = document.createElement('script');

        script.src = 'https://apis.google.com/js/platform.js?onload=renderBadge';
        script.async = true;
        script.defer = true;

        document.body.appendChild(script);
    }

    renderBadge() {
        const {
            badgeConfig: { merchantId, position, lang }
        } = this.props;

        window.renderBadge = () => {
            const ratingBadgeContainer = document.createElement('div');
            document.body.appendChild(ratingBadgeContainer);

            window.gapi.load('ratingbadge', () => {
                window.gapi.ratingbadge.render(ratingBadgeContainer, {
                    merchant_id: merchantId,
                    position
                });
            });
        };

        window.___gcfg = {
            lang
        };
    }

    appendGooglePlatformScript() {
        const newScript = document.createElement('script');
        newScript.src = 'https://apis.google.com/js/platform.js';
        newScript.defer = true;
        newScript.async = true;
        document.body.appendChild(newScript);
    }

    appendGcrContainer() {
        const {
            badgeConfig: { merchantId, css }
        } = this.props;

        const gcrContainer = document.createElement('g:ratingbadge');
        gcrContainer.setAttribute('id', 'gcr_container');
        gcrContainer.setAttribute('merchant_id', merchantId);
        const parsedCss = parseCss(css);
        gcrContainer.style.cssText += parsedCss;
        document.body.appendChild(gcrContainer);
    }

    render() {
        return null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BadgeContainer);
