/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './Top5number.style';

/** @namespace Scandipwa/Component/Top5number/Component */
export class Top5numberComponent extends PureComponent {
    static propTypes = {
        number: PropTypes.number,
        productName: PropTypes.string
    };

    static defaultProps = {
        number: 0,
        productName: ''
    };

    render() {
        const {
            number,
            productName
        } = this.props;

        return (
            <div block="Top5number">
                { number }
                { ' ' }
                { productName }
            </div>
        );
    }
}

export default Top5numberComponent;
