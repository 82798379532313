/* eslint-disable max-len */
import { PureComponent } from 'react';

/** @namespace Scandipwa/Component/PaypalIcon/Component */
export class PaypalIconComponent extends PureComponent {
    render() {
        return (
            <svg width="74" height="18" viewBox="0 0 74 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M27.5629 4.02551H23.4837C23.2046 4.02551 22.9672 4.22831 22.9236 4.50387L21.2738 14.964C21.241 15.1704 21.4009 15.3565 21.6102 15.3565H23.5577C23.8368 15.3565 24.0742 15.1537 24.1177 14.8775L24.5627 12.0562C24.6056 11.7801 24.8436 11.5773 25.1222 11.5773H26.4135C29.1006 11.5773 30.6513 10.277 31.0563 7.7003C31.2389 6.57299 31.0641 5.68725 30.5362 5.06693C29.9565 4.38577 28.9282 4.02551 27.5629 4.02551ZM28.0335 7.84583C27.8104 9.30955 26.6921 9.30955 25.6107 9.30955H24.9951L25.427 6.57597C25.4526 6.41075 25.5958 6.28908 25.7628 6.28908H26.0449C26.7815 6.28908 27.4764 6.28908 27.8355 6.70898C28.0496 6.9595 28.1152 7.33169 28.0335 7.84583Z" fill="#253B80" />
                <path d="M39.7565 7.79876H37.8031C37.6367 7.79876 37.4929 7.92044 37.4673 8.08566L37.3808 8.63201L37.2442 8.43399C36.8213 7.82023 35.8783 7.61505 34.9371 7.61505C32.7785 7.61505 30.9348 9.24995 30.5758 11.5433C30.3891 12.6873 30.6545 13.7812 31.3034 14.5441C31.8987 15.2456 32.7505 15.5378 33.7638 15.5378C35.5031 15.5378 36.4676 14.4195 36.4676 14.4195L36.3805 14.9622C36.3477 15.1698 36.5076 15.3559 36.7157 15.3559H38.4753C38.755 15.3559 38.9912 15.1531 39.0353 14.8769L40.0911 8.19123C40.1245 7.98545 39.9652 7.79876 39.7565 7.79876ZM37.0336 11.6006C36.8452 12.7166 35.9594 13.4657 34.8297 13.4657C34.2625 13.4657 33.8092 13.2838 33.5181 12.939C33.2294 12.5967 33.1197 12.1094 33.2115 11.5666C33.3875 10.4602 34.2881 9.68655 35.4005 9.68655C35.9552 9.68655 36.4062 9.87086 36.7032 10.2186C37.0008 10.5699 37.1189 11.0602 37.0336 11.6006Z" fill="#253B80" />
                <path d="M50.1597 7.79877H48.1968C48.0095 7.79877 47.8336 7.89181 47.7274 8.04749L45.0201 12.0354L43.8725 8.20317C43.8003 7.96339 43.579 7.79877 43.3285 7.79877H41.3995C41.1651 7.79877 41.0023 8.02781 41.0769 8.2485L43.239 14.5936L41.2063 17.4632C41.0464 17.6893 41.2075 18 41.4836 18H43.4442C43.6303 18 43.8045 17.9093 43.91 17.7567L50.4389 8.3326C50.5952 8.10714 50.4347 7.79877 50.1597 7.79877Z" fill="#253B80" />
                <path d="M56.6588 4.02551H52.579C52.3005 4.02551 52.0631 4.22831 52.0196 4.50387L50.3698 14.964C50.337 15.1704 50.4968 15.3565 50.705 15.3565H52.7985C52.993 15.3565 53.1594 15.2145 53.1898 15.0212L53.658 12.0562C53.701 11.7801 53.939 11.5773 54.2175 11.5773H55.5083C58.1959 11.5773 59.7461 10.277 60.1517 7.7003C60.3348 6.57299 60.1589 5.68725 59.631 5.06693C59.0518 4.38577 58.0241 4.02551 56.6588 4.02551ZM57.1294 7.84583C56.907 9.30955 55.7886 9.30955 54.7066 9.30955H54.0917L54.5241 6.57597C54.5498 6.41075 54.6917 6.28908 54.8593 6.28908H55.1414C55.8775 6.28908 56.5729 6.28908 56.932 6.70898C57.1461 6.9595 57.2112 7.33169 57.1294 7.84583Z" fill="#179BD7" />
                <path d="M68.8518 7.79876H66.8996C66.732 7.79876 66.5894 7.92044 66.5644 8.08566L66.4779 8.63201L66.3407 8.43399C65.9178 7.82023 64.9754 7.61505 64.0342 7.61505C61.8756 7.61505 60.0326 9.24995 59.6735 11.5433C59.4874 12.6873 59.7516 13.7812 60.4006 14.5441C60.997 15.2456 61.8476 15.5378 62.861 15.5378C64.6003 15.5378 65.5647 14.4195 65.5647 14.4195L65.4777 14.9622C65.4448 15.1698 65.6047 15.3559 65.8141 15.3559H67.573C67.8516 15.3559 68.0889 15.1531 68.1325 14.8769L69.1888 8.19123C69.221 7.98545 69.0612 7.79876 68.8518 7.79876ZM66.129 11.6006C65.9417 12.7166 65.0548 13.4657 63.9251 13.4657C63.359 13.4657 62.9045 13.2838 62.6135 12.939C62.3248 12.5967 62.2162 12.1094 62.3069 11.5666C62.484 10.4602 63.3835 9.68655 64.4959 9.68655C65.0506 9.68655 65.5015 9.87086 65.7985 10.2186C66.0974 10.5699 66.2155 11.0602 66.129 11.6006Z" fill="#179BD7" />
                <path d="M71.1546 4.3124L69.4804 14.964C69.4476 15.1703 69.6074 15.3564 69.8156 15.3564H71.4988C71.7785 15.3564 72.0159 15.1536 72.0588 14.8775L73.7098 4.41797C73.7426 4.21159 73.5828 4.0249 73.3746 4.0249H71.4898C71.3234 4.0255 71.1803 4.14718 71.1546 4.3124Z" fill="#179BD7" />
                <path d="M4.33384 17.3891L4.64579 15.4077L3.95092 15.3916H0.632812L2.93872 0.770569C2.94588 0.726431 2.96914 0.685275 3.00314 0.656049C3.03714 0.626822 3.08068 0.610718 3.12601 0.610718H8.7208C10.5782 0.610718 11.86 0.997223 12.5292 1.7601C12.8429 2.11797 13.0427 2.49195 13.1394 2.90351C13.2408 3.33534 13.2426 3.85128 13.1435 4.48055L13.1364 4.52647V4.92968L13.4501 5.10742C13.7144 5.24759 13.9243 5.40804 14.0854 5.59175C14.3538 5.89773 14.5273 6.28662 14.6007 6.74769C14.6764 7.22187 14.6514 7.78612 14.5273 8.42493C14.3842 9.15977 14.1528 9.79977 13.8402 10.3235C13.5527 10.806 13.1865 11.2062 12.7517 11.5164C12.3365 11.811 11.8433 12.0347 11.2856 12.1779C10.7452 12.3186 10.129 12.3896 9.45325 12.3896H9.01784C8.70649 12.3896 8.40408 12.5017 8.16669 12.7027C7.9287 12.9079 7.77124 13.1883 7.72292 13.4948L7.69012 13.6732L7.13899 17.1654L7.11394 17.2937C7.10738 17.3342 7.09605 17.3545 7.07934 17.3682C7.06443 17.3808 7.04296 17.3891 7.02208 17.3891H4.33384Z" fill="#253B80" />
                <path d="M13.7473 4.57312C13.7306 4.67989 13.7115 4.78904 13.69 4.90117C12.9522 8.68929 10.428 9.99792 7.20411 9.99792H5.56265C5.16839 9.99792 4.83616 10.2842 4.77473 10.6731L3.93432 16.0031L3.69633 17.5139C3.65637 17.7692 3.8532 17.9994 4.11087 17.9994H7.02219C7.36694 17.9994 7.6598 17.7489 7.71408 17.4089L7.74271 17.261L8.29086 13.7825L8.32605 13.5916C8.37973 13.2504 8.67319 12.9999 9.01794 12.9999H9.45336C12.274 12.9999 14.4821 11.8547 15.1275 8.54077C15.3971 7.15639 15.2575 6.00045 14.5441 5.18747C14.3282 4.94233 14.0604 4.73894 13.7473 4.57312Z" fill="#179BD7" />
                <path d="M12.9754 4.26526C12.8626 4.23245 12.7463 4.20263 12.627 4.17579C12.5072 4.14954 12.3843 4.12628 12.2578 4.106C11.8153 4.03443 11.3303 4.00043 10.8108 4.00043H6.42566C6.3177 4.00043 6.21511 4.02488 6.12325 4.06902C5.92105 4.16624 5.77075 4.35771 5.73436 4.59211L4.8015 10.5006L4.77466 10.673C4.83609 10.2841 5.16832 9.99782 5.56258 9.99782H7.20404C10.4279 9.99782 12.9521 8.6886 13.6899 4.90108C13.712 4.78895 13.7305 4.67979 13.7472 4.57303C13.5605 4.47402 13.3583 4.38932 13.1406 4.31715C13.0869 4.29925 13.0314 4.28196 12.9754 4.26526Z" fill="#222D65" />
                <path d="M5.73435 4.59214C5.77074 4.35773 5.92104 4.16627 6.12324 4.06964C6.21569 4.0255 6.31769 4.00105 6.42565 4.00105H10.8108C11.3303 4.00105 11.8153 4.03505 12.2578 4.10662C12.3843 4.1269 12.5071 4.15016 12.627 4.17641C12.7463 4.20325 12.8626 4.23307 12.9754 4.26588C13.0314 4.28258 13.0869 4.29988 13.1412 4.31717C13.3589 4.38934 13.5611 4.47464 13.7478 4.57305C13.9673 3.17316 13.746 2.22002 12.9891 1.35695C12.1546 0.406785 10.6486 0 8.72142 0H3.12663C2.73297 0 2.39716 0.286301 2.33632 0.675789L0.00595793 15.4471C-0.0399694 15.7394 0.185492 16.003 0.480143 16.003H3.93424L4.80149 10.5007L5.73435 4.59214Z" fill="#253B80" />
            </svg>
        );
    }
}

export default PaypalIconComponent;
