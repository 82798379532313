import { CURRENCY_FORMATTING_LOCALE } from './Price.config';

export {
    calculateFinalPrice, formatCurrency, roundPrice, getLowestPriceTiersPrice
} from 'SourceUtil/Price/Price';

/**
 * Overridden to format price according to the British locale
 * and remove currencyDisplay ('narrowSymbol' not supported by older Safari versions)
 */
/** @namespace Scandipwa/Util/Price/formatPrice */
export const formatPrice = (price, currency = 'GBP') => new Intl.NumberFormat(
    CURRENCY_FORMATTING_LOCALE,
    { style: 'currency', currency }
).format(price);

/**
 * Overridden to remove currencyDisplay ('narrowSymbol' not supported by older Safari versions)
 */
/** @namespace Scandipwa/Util/Price/formatPriceParts */
export const formatPriceParts = (price, currency = 'USD') => {
    const language = navigator.languages ? navigator.languages[0] : navigator.language;

    return new Intl.NumberFormat(
        language,
        { style: 'currency', currency }
    ).formatToParts(price);
};

/**
 * Imports variables into the string and returns it.
 */
/** @namespace Scandipwa/Util/Price/replacePlaceholders */
export const replacePlaceholders = (str, variables) => {
    // eslint-disable-next-line fp/no-let
    let result = str;
    Object.keys(variables).forEach((key) => {
        const placeholder = `{{${key}}}`;

        result = result.replace(new RegExp(placeholder, 'g'), variables[key]);
    });

    return result;
};
