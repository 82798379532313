/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import DiscountBannerCoppyButton from './DiscountBannerCoppyButton.component';

/** @namespace Scandipwa/Component/DiscountBannerCoppyButton/Container */
export class DiscountBannerCoppyButtonContainer extends PureComponent {
    static propTypes = {
        children: PropTypes.array
    };

    static defaultProps = {
        children: []
    };

    state = {
        isCopied: false
    };

    containerFunctions = {
        copyCodeToClipboard: this.copyCodeToClipboard.bind(this)
    };

    copyCodeToClipboard() {
        const {
            children = []
        } = this.props;

        if (children.length > 0) {
            const { data: textData } = children[0];

            navigator.clipboard.writeText(textData);
            this.setState({ isCopied: true });
        }

        return '';
    }

    containerProps() {
        const {
            children = []
        } = this.props;
        const {
            isCopied
        } = this.state;

        if (children.length > 0) {
            const { data: textData } = children[0];

            return {
                textData,
                isCopied
            };
        }

        return '';
    }

    render() {
        return (
            <DiscountBannerCoppyButton
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default DiscountBannerCoppyButtonContainer;
