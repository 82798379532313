/**
 * Printerbase
 *
 * @author Scandiweb Team <info@scandiweb.com>
 * @package @scandiweb/printerbase
 */
import { Field } from 'SourceUtil/Query';
/** @namespace Scandipwa/Query/CheckoutShippingMethod/Query */
export class CheckoutShippingMethodQuery {
    /**
     * Update shipping method for provided cartId (only one allowed)
     *
     * @param {String} cartId
     * @param {String} carrierCode
     * @param {String} methodCode
     */
    getUpdateCartShippingMethodMutation(cartId, carrierCode, methodCode) {
        const mutationInput = {
            cart_id: cartId,
            shipping_methods: [
                {
                    carrier_code: carrierCode,
                    method_code: methodCode
                }
            ]
        };

        const cartField = new Field('cart');
        const shippingAddresses = new Field('shipping_addresses');
        const selectedShippingMethod = new Field('selected_shipping_method');
        selectedShippingMethod.addFieldList([
            'carrier_code',
            'method_code'
        ]);

        shippingAddresses.addField(selectedShippingMethod);
        cartField.addField(shippingAddresses);

        return new Field('setShippingMethodsOnCart')
            .addArgument('input', 'SetShippingMethodsOnCartInput', mutationInput)
            .addField(cartField);
    }

    // Mutation to reset shipping method on cart if we are not on checkout page
    getResetShippingMutation(quoteId) {
        return new Field('pbResetShippingMethodOnCart')
            .addArgument('guestCartId', 'String', quoteId)
            .addField('grand_total');
    }
}

export default new CheckoutShippingMethodQuery();
