import { AMAZON_PAY } from '../component/AmazonPay/AmazonPay.config';

const selectPaymentMethod = (args, callback, instance) => {
    callback.apply(instance, args);

    const { setOrderButtonVisibility } = instance.props;
    const { code } = args[0];

    if (!setOrderButtonVisibility) {
        return;
    }

    setOrderButtonVisibility(true);
    if (code === AMAZON_PAY) {
        setOrderButtonVisibility(false);
    }
};

export default {
    'Component/CheckoutPayments/Container': {
        'member-function': {
            selectPaymentMethod
        }
    }
};
