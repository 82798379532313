export const PRODUCT_INFORMATION = 'information';
export const PRODUCT_ATTRIBUTES = 'attributes';
export const PRODUCT_REVIEWS = 'reviews';
export const PROMO_TERMS = 'promoTerms';
export const DISCOUNT_BANNER = 'discount_banner';
export const PRINTERS = 'Printers';
export const CONSUMABLES = 'Consumables';
export const PRODUCT_ATTACHMENTS = 'productAttachments';
export const WILL_IT_WORK = 'will_it_work';
export const NEED_HELP_BLOCK = 'need_help';
export const REVIEW_TAB = 'reviewTab';
export const CATEGORY_LEVEL_NUMBER = 3;
export const TIME_OUT = 10000;
export const PRODUCT_SYNDICATED_CONTENT = 'product_syndicated_content';
export const WORLD_SYNC_JS = 'WORLD_SYNC_JS';
