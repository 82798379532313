import { APPEND_PAGE } from 'Store/ProductList/ProductList.action.js';

export const KLEVU_UPDATE_PAGE_COUNT = 'KLEVU_UPDATE_PAGE_COUNT';
export const KLEVU_UPDATE_CURRENT_PAGE = 'KLEVU_UPDATE_CURRENT_PAGE';

/** @namespace KlevuSearch/Store/ProductList/Action/appendPage */
export const appendPage = (items, currentPage) => ({
    type: APPEND_PAGE,
    items,
    currentPage
});

/** @namespace KlevuSearch/Store/ProductList/Action/changeTotalPageCount */
export const changeTotalPageCount = (total_pages) => ({
    type: KLEVU_UPDATE_PAGE_COUNT,
    total_pages
});

/** @namespace KlevuSearch/Store/ProductList/Action/updateCurrentPage */
export const updateCurrentPage = (current_page) => ({
    type: KLEVU_UPDATE_CURRENT_PAGE,
    current_page
});
