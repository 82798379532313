import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    CategorySubcategoriesContainer as SourceCategorySubcategoriesContainer,
    mapDispatchToProps,
    mapStateToProps
} from 'SourceComponent/CategorySubcategories/CategorySubcategories.container';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Scandipwa/Component/CategorySubcategories/Container */
export class CategorySubcategoriesContainer extends SourceCategorySubcategoriesContainer {
    static propTypes = {
        ...SourceCategorySubcategoriesContainer.propTypes,
        option: PropTypes.shape({
            id: PropTypes.number,
            value_string: PropTypes.string,
            label: PropTypes.string,
            categoryData: PropTypes.shape({
                image: PropTypes.string,
                description: PropTypes.string,
                display_mode: PropTypes.string
            })
        }).isRequired
    };

    handleCategoryClick(e) {
        const { hideActiveOverlay, option } = this.props;
        const {
            id,
            label,
            value_string,
            categoryData
        } = option;

        e.preventDefault();
        hideActiveOverlay();

        history.push({
            pathname: appendWithStoreCode(value_string),
            state: {
                category: id,
                title: label,
                categoryData
            },
            search: history.location.search
        });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategorySubcategoriesContainer);
