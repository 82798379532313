import PropTypes from 'prop-types';

import {
    ContentWrapperComponent as SourceContentWrapperComponent
} from 'SourceComponent/ContentWrapper/ContentWrapper.component';

/** @namespace Scandipwa/Component/ContentWrapper/Component */
export class ContentWrapperComponent extends SourceContentWrapperComponent {
    static propTypes = {
        ...this.propTypes,
        id: PropTypes.string
    };

    static defaultProps = {
        ...this.defaultProps,
        id: null
    };

    render() {
        const {
            mix, label, isNotSection, id
        } = this.props;

        if (isNotSection) {
            return this.renderContentWrapper();
        }

        return (
            <section id={ id } mix={ mix } aria-label={ label }>
                { this.renderContentWrapper() }
            </section>
        );
    }
}

export default ContentWrapperComponent;
