/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { AttachmentsReducer } from '../store/Attachments';

/** @namespace MageworxAttachment/Plugin/*/
export class StoreIndexPlugin {
    aroundGetReducer = (args, callback, instance) => ({
        ...callback.apply(instance, args),
        AttachmentsReducer
    });
}

const { aroundGetReducer } = new StoreIndexPlugin();

export const config = {
    'Store/Index/getStaticReducers': {
        function: [
            {
                position: 100,
                implementation: aroundGetReducer
            }
        ]
    }
};

export default config;
