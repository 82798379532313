import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CategorySubacategoriesTiles from 'Component/CategorySubacategoriesTiles';
import Link from 'Component/Link';

import { LEVEL_TITLE, PRINTER_MODEL_LEVEL } from './ChooseManufacturer.config';

import './ChooseManufacturer.style';

/** @namespace Scandipwa/Component/ChooseManufacturer/Component */
export class ChooseManufacturerComponent extends PureComponent {
    static propTypes = {
        subcategories: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                product_count: PropTypes.number,
                url: PropTypes.string
            })
        ).isRequired,
        level: PropTypes.number.isRequired
    };

    renderSubcategories() {
        const { subcategories } = this.props;
        const sortedList = subcategories.sort((a, b) => a.name.localeCompare(b.name));

        return sortedList
            .filter((category) => category.product_count !== 0)
            .map((category) => {
                const {
                    id, name, product_count, url
                } = category;

                return (
                    <Link
                      key={ id }
                      to={ url }
                      block="ChooseManufacturer"
                      elem="SubcategoryLink"
                    >
                        { name }
                        <span
                          block="ChooseManufacturer"
                          elem="SubcategoryProductCount"
                        >
                            { ` (${ product_count })` }
                        </span>
                    </Link>
                );
            });
    }

    render() {
        const { level } = this.props;

        return (
            <>
                <div block="ChooseManufacturer">
                    <h3 block="ChooseManufacturer" elem="Heading">{ LEVEL_TITLE[level] }</h3>
                    <div block="ChooseManufacturer" elem="Subcategories">
                        { this.renderSubcategories() }
                    </div>
                </div>
                { level === PRINTER_MODEL_LEVEL && (
                    <CategorySubacategoriesTiles />
                ) }
            </>
        );
    }
}

export default ChooseManufacturerComponent;
