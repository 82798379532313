import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Html from 'Component/Html';

import './Top5description.style';

/** @namespace Scandipwa/Component/Top5description/Component */
export class Top5descriptionComponent extends PureComponent {
    static propTypes = {
        description: PropTypes.string
    };

    static defaultProps = {
        description: ''
    };

    renderDescription() {
        const {
            description
        } = this.props;

        if (!description) {
            return '';
        }

        return <Html content={ description } />;
    }

    render() {
        return (
            <div block="Top5description">
                { this.renderDescription() }
            </div>
        );
    }
}

export default Top5descriptionComponent;
