import { getElemBoundingClientRect } from 'Util/Common';
import { getFixedElementHeight } from 'Util/CSS';

export * from 'SourceUtil/Scroll/Scroll';

/**
 * Scroll to an element.
 * @param { string } selector - examples: '.element', '#element'
 * @namespace Scandipwa/Util/Scroll/scrollToElement */
export const scrollToElement = async (selector) => {
    const elem = document.querySelector(selector);

    if (!elem) {
        return;
    }

    const { top: elemToWindowTopDist = 0 } = await getElemBoundingClientRect(elem) || {};
    const { top: windowToPageTopDist = 0 } = await getElemBoundingClientRect(document.body) || {};
    const topToElemDistance = elemToWindowTopDist - windowToPageTopDist;
    const {
        total: totalFixedElementHeight,
        bottom: bottomFixedElementHeight
    } = getFixedElementHeight();

    const elemMaxOffsetHeight = screen.height > elem.offsetHeight + bottomFixedElementHeight
        ? elem.offsetHeight
        : screen.height - totalFixedElementHeight;
    const scrollTo = topToElemDistance - (screen.height - bottomFixedElementHeight - elemMaxOffsetHeight);

    // checking if button is in a view-port
    if (-windowToPageTopDist >= scrollTo) {
        return;
    }

    window.scrollTo({ behavior: 'smooth', top: scrollTo });
};
