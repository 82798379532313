import PropTypes from 'prop-types';

import {
    ProductAttributeValueComponent as SourceProductAttributeValue
} from 'SourceComponent/ProductAttributeValue/ProductAttributeValue.component';

import './ProductAttributeValue.style.override';

/** @namespace Scandipwa/Component/ProductAttributeValue/Component */
export class ProductAttributeValueComponent extends SourceProductAttributeValue {
    static propTypes = {
        ...this.propTypes,
        isFormattedAsImageAndText: PropTypes.bool
    };

    /**
     * Overridden to add the option for showing image and text together
     */
    renderImageValue(img, label) {
        const { isFormattedAsImageAndText, isFormattedAsText, isSelected } = this.props;

        if (isFormattedAsText) {
            return label || __('N/A');
        }

        if (isFormattedAsImageAndText) {
            return (
                <div block="ProductAttributeValue" elem="ImageAndText" mods={ { isSelected } }>
                    <img
                      block="ProductAttributeValue"
                      elem="Image"
                      src={ `/media/attribute/swatch${img}` }
                      alt={ label }
                    />
                    <p block="ProductAttributeValue" elem="Text">
                        { label || __('N/A') }
                    </p>
                </div>
            );
        }

        return (
            <img
              block="ProductAttributeValue"
              elem="Image"
              src={ `/media/attribute/swatch${img}` }
              alt={ label }
            />
        );
    }

    /**
     * Overridden add itemProp
     */
    render() {
        const {
            getLink,
            attribute,
            isAvailable,
            attribute: { attribute_code, attribute_value },
            mix,
            isFormattedAsText,
            showProductAttributeAsLink,
            isSelected
        } = this.props;

        if (attribute_code && !attribute_value) {
            return null;
        }

        const href = getLink(attribute);
        // Invert to apply css rule without using not()
        const isNotAvailable = !isAvailable;

        if (attribute_code === 'gtin') {
            return (
                <div
                  block="ProductAttributeValue"
                  mods={ { isSelected } }
                  mix={ mix }
                  itemProp="gtin"
                >
                  { this.renderAttributeByType() }
                </div>
            );
        }

        if (isFormattedAsText) {
            return (
                <div
                  block="ProductAttributeValue"
                  mods={ { isSelected } }
                  mix={ mix }
                >
                    { this.renderAttributeByType() }
                </div>
            );
        }

        if (!showProductAttributeAsLink) {
            return (
                <span
                  block="ProductAttributeValue"
                  mods={ { isNotAvailable, isSelected } }
                  onClick={ this.clickHandler }
                  onKeyDown={ this.clickHandler }
                  role="link"
                  tabIndex="-1"
                  aria-hidden={ isNotAvailable }
                  mix={ mix }
                >
                { this.renderAttributeByType() }
                </span>
            );
        }

        return (
            <a
              href={ href }
              block="ProductAttributeValue"
              mods={ { isNotAvailable, isSelected } }
              onClick={ this.clickHandler }
              aria-hidden={ isNotAvailable }
              mix={ mix }
            >
                { this.renderAttributeByType() }
            </a>
        );
    }
}

export default ProductAttributeValueComponent;
