import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { ChildrenType } from 'Type/Common';

/** @namespace Scandipwa/Component/ConsumablesPromoCtaButton/Component */
export class ConsumablesPromoCtaButtonComponent extends PureComponent {
    static propTypes = {
        children: ChildrenType.isRequired,
        handleCtaButtonClick: PropTypes.func.isRequired
    };

    render() {
        const { children, handleCtaButtonClick } = this.props;

        return (
            <button
              block="Button"
              mods={ { isSecondary: true } }
              onClick={ handleCtaButtonClick }
            >
                { children }
            </button>
        );
    }
}

export default ConsumablesPromoCtaButtonComponent;
