import { getQueryParam } from 'Util/Url';

/** @namespace Amazonpay/Util/AmazonPay/Util/getAmazonCheckoutSessionId */
export function getAmazonCheckoutSessionId() {
    const queryParams = new URLSearchParams(location.search);
    const amazonCheckoutSessionId = queryParams.get('amazonCheckoutSessionId');

    return amazonCheckoutSessionId;
}

/** @namespace Amazonpay/Util/AmazonPay/Util/isAmazonCheckout */
export function isAmazonCheckout() {
    return !!getAmazonCheckoutSessionId();
}

/** @namespace Amazonpay/Util/AmazonPay/Util/getOrderFromUrl */
export const getOrderFromUrl = (instance) => {
    const orderId = getQueryParam('id', instance.props.location);

    if (!orderId) {
        return false;
    }

    return orderId.replace('/', '');
};

/** @namespace Amazonpay/Util/AmazonPay/Util/transformAddressForAddressForm */
export function transformAddressForAddressForm(address) {
    const { region, region_code, region_id } = address;

    return {
        ...address,
        region: {
            region,
            region_code,
            region_id
        }
    };
}

/** @namespace Amazonpay/Util/AmazonPay/Util/transformAddressForQuery */
export function transformAddressForQuery(address) {
    const newAddress = {};
    const streetArray = [];

    Object.keys(address).forEach((key) => {
        if (key.includes('street')) {
            streetArray.push(address[key]);
        } else {
            newAddress[key] = address[key];
        }
    });
    newAddress.street = streetArray;

    const {
        city,
        company,
        country_id,
        firstname,
        lastname,
        postcode,
        region_string,
        street,
        telephone
    } = newAddress;

    // Flatten out region object if its nested
    const { region: regionObject = {} } = address;
    const { region_id, region, region_code } = regionObject;

    return {
        city,
        company,
        country_id,
        firstname,
        lastname,
        postcode,
        street,
        telephone,
        region: region_string || region,
        region_id,
        region_code
    };
}

/** @namespace Amazonpay/Util/AmazonPay/Util/isAddressNull */
export const isAddressNull = (address) => {
    const {
        city,
        company,
        country_id,
        email,
        firstname,
        lastname,
        postcode,
        region_code,
        region,
        region_id,
        street,
        telephone
    } = address;

    return !(city
        || company
        || country_id
        || email
        || firstname
        || lastname
        || postcode
        || region_code
        || region
        || region_id
        || street
        || telephone);
};
