/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const WHATS_IN_THE_BOX = 'whatsInTheBox';
export const WHATS_IN_THE_BOX_MAGENTO_ATTRIBUTE = 'whats_in_the_box';
export const WHATS_IN_THE_BOX_LABEL = __("What's in the box");
export const WHATS_IN_THE_BOX_ITEM_SEPARATOR = '|';
