/* eslint-disable max-lines */
/* eslint-disable jsx-a11y/click-events-have-key-events, @scandipwa/scandipwa-guidelines/only-render-in-component */
import PropTypes from 'prop-types';
import { Suspense } from 'react';

import CloseIcon from 'Component/CloseIcon';
import Popup from 'Component/Popup';
import SearchIcon from 'Component/SearchIcon';
import SearchOverlay from 'Component/SearchOverlay';
import {
    SearchFieldComponent as PrinterbaseSearchFieldComponent
} from 'PrinterbaseComponent/SearchField/SearchField.component';
import { scrollToTop } from 'Util/Browser';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import { SEARCH_MOBILE_POPUP } from './SearchField.config';

import './SearchField.style.override';

/** @namespace Scandipwa/Component/SearchField/Component */
export class SearchFieldComponent extends PrinterbaseSearchFieldComponent {
    static propTypes = {
        ...this.propTypes,
        fieldMix: PropTypes.arrayOf(
            PropTypes.shape({
                block: PropTypes.string,
                elem: PropTypes.string
            })
        ),
        searchButtonText: PropTypes.string,
        placeholder: PropTypes.string
    };

    static defaultProps = {
        ...this.defaultProps,
        fieldMix: [],
        searchButtonText: '',
        placeholder: ''
    };

    /**
     * Handle button with text click
     */
    onSearchButtonPress = () => {
        const { searchCriteria, hideActiveOverlay, onSearchBarChange } = this.props;
        const search = searchCriteria.trim().replace(/\s/g, '+');
        const trimmedSearch = searchCriteria.trim();

        if (trimmedSearch !== '') {
            history.push(appendWithStoreCode(`/search/${ search }`));
            hideActiveOverlay();
            onSearchBarChange({ target: { value: '' } });
            this.searchBarRef.current.blur();
            this.closeSearch();
            scrollToTop();
        }
    };

    /**
     * Overridden to render button with text if it is passed
     */
    renderSearchIcon() {
        const {
            searchButtonText,
            fieldMix,
            isActive,
            device: { isMobile }
        } = this.props;

        if (searchButtonText !== '') {
            return (
                <div
                  block="SearchField"
                  elem="SearchIcon"
                  mix={ fieldMix }
                  role="button"
                  tabIndex="0"
                  onClick={ this.onSearchButtonPress }
                  aria-label={ __('Search') }
                >
                    { searchButtonText }
                </div>
            );
        }

        if (isActive && !isMobile) {
            return (
                <div
                  block="SearchField"
                  elem="CloseIcon"
                  role="button"
                  tabIndex="0"
                  onClick={ this.closeSearch }
                  aria-label={ __('Close') }
                >
                    <CloseIcon />
                </div>
            );
        }

        return (
            <div
              block="SearchField"
              elem="SearchIcon"
              role="button"
              tabIndex="0"
              onClick={ this.onSearchButtonPress }
              aria-label={ __('Search') }
            >
                <SearchIcon />
            </div>
        );
    }

    showMobilePopup = (e) => {
        const { showPopup } = this.props;
        e.preventDefault();
        e.stopPropagation();
        showPopup(SEARCH_MOBILE_POPUP);
    };

    renderMobilePopup = () => {
        const {
            searchCriteria,
            onSearchBarFocus,
            isActive,
            device: {
                isMobile
            },
            placeholder
        } = this.props;
        const placeholderText = placeholder || (
            isMobile ? __('Search by Printer or Keyword...') : __('Enter your printer model or cartridge code...')
        );

        return (
            <Popup
              id={ SEARCH_MOBILE_POPUP }
              mix={ { block: 'SearchField', elem: 'Popup' } }
            >
                <input
                  id="search-field"
                  ref={ this.searchBarRef }
                  block="SearchField"
                  elem="Mobile-Input"
                  onFocus={ onSearchBarFocus }
                  onChange={ this.handleChange }
                  onKeyDown={ this.onSearchEnterPress }
                  value={ searchCriteria }
                  mods={ { isActive } }
                  // eslint-disable-next-line max-len
                  placeholder={ placeholderText }
                  autoComplete="off"
                  aria-label={ __('Search') }
                />
                { this.renderSearchIcon() }
                <Suspense fallback={ this.renderOverlayFallback() }>
                    <SearchOverlay
                      isHideOverlay={ !isMobile }
                      clearSearch={ this.clearSearch }
                      searchCriteria={ searchCriteria }
                    />
                </Suspense>
            </Popup>
        );
    };

    renderMobileSearchButton = () => {
        const {
            device: { isMobile }
        } = this.props;

        if (isMobile) {
            return (
                <div
                  block="SearchField"
                  elem="SearchInnerWrapper"
                >
                    <button
                      block="SearchField"
                      elem="Input"
                      onClick={ this.showMobilePopup }
                      aria-label={ __('Search') }
                    >
                       { __('Search by Printer or Keyword...') }
                    </button>
                    { this.renderSearchIcon() }
                    { this.renderMobilePopup() }
                </div>
            );
        }

        return '';
    };

    /**
     * Overridden to change placeholder on Cms block
     * Overridden to change search field on mobile
     */
    renderSearch() {
        const {
            searchCriteria,
            onSearchBarFocus,
            isActive,
            device: {
                isMobile
            },
            placeholder
        } = this.props;

        const placeholderText = placeholder || (
            isMobile ? __('Search by Printer or Keyword...') : __('Enter your printer model or cartridge code...')
        );

        if (isMobile) {
            this.renderMobileSearchButton();
        }

        return (
            <div
              block="SearchField"
              elem="SearchInnerWrapper"
            >
                <input
                  id="search-field"
                  ref={ this.searchBarRef }
                  block="SearchField"
                  elem="Input"
                  onFocus={ onSearchBarFocus }
                  onChange={ this.handleChange }
                  onKeyDown={ this.onSearchEnterPress }
                  value={ searchCriteria }
                  mods={ { isActive } }
                  // eslint-disable-next-line max-len
                  placeholder={ placeholderText }
                  autoComplete="off"
                  aria-label={ __('Search') }
                />
                { this.renderSearchIcon() }
                <Suspense fallback={ this.renderOverlayFallback() }>
                    <SearchOverlay
                      isHideOverlay={ !isMobile }
                      clearSearch={ this.clearSearch }
                      searchCriteria={ searchCriteria }
                    />
                </Suspense>
            </div>
        );
    }
}

export default SearchFieldComponent;
