import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductCompareButtonContainer as SourceProductCompareButtonContainer
} from 'PrinterbaseComponent/ProductCompareButton/ProductCompareButton.container';

export {
    mapDispatchToProps,
    mapStateToProps
};

/** @namespace Scandipwa/Component/ProductCompareButton/Container */
export class ProductCompareButtonContainer extends SourceProductCompareButtonContainer {
    /**
     * Overridden to add e.stopPropagation()
     */
    async handleClick(e) {
        const {
            productId,
            addProductToCompare,
            removeComparedProduct
        } = this.props;

        e.preventDefault();
        e.stopPropagation();

        this.setState({ isLoading: true });

        if (this.isActive()) {
            await removeComparedProduct(productId);
        } else {
            await addProductToCompare(productId);
        }

        this.setState({ isLoading: false });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCompareButtonContainer);
