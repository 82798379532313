import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Loader from 'Component/Loader';

import { AMAZON_PAY_FE_LOCATIONS } from './AmazonPay.config';

import './AmazonPay.style';

/** @namespace Amazonpay/Component/AmazonPay/Component */
export class AmazonPayComponent extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        amazonButtonIdRef: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.shape({ current: PropTypes.string })
        ]).isRequired,
        amazonPayButtonContainerRef: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.shape({ current: PropTypes.string })
        ]).isRequired,
        location: PropTypes.string.isRequired
    };

    renderButtonOrLoader() {
        const { isLoading, amazonButtonIdRef, amazonPayButtonContainerRef } = this.props;

        if (isLoading) {
            return <Loader isLoading />;
        }

        return (<div id={ amazonButtonIdRef.current } ref={ amazonPayButtonContainerRef } />);
    }

    render() {
        const { location } = this.props;
        const styleAttribute = location === AMAZON_PAY_FE_LOCATIONS.MINICART;

        return (
            <div
              block="AmazonPay"
              mix={ {
                  block: 'No-CheckoutBilling',
                  elem: 'Button'
              } }
              mods={ {
                  isPadding: styleAttribute
              } }
              title="Pay using the information already stored in your Amazon account"
            >
                { this.renderButtonOrLoader() }
            </div>
        );
    }
}

export default AmazonPayComponent;
