/* eslint-disable max-lines */
import FIELD_TYPE from 'Component/PureForm/Field/Field.config';
import {
    getEmailField, getVatFields
} from 'PrinterbaseComponent/MyAccountAddressForm/MyAccountAddressForm.form';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

/**
 * Returns fields for street
 * @param props
 * @returns {[{addRequiredTag: boolean, validateOn: string[], validationRule: {isRequired: boolean}, label: *, type: string, attr: {defaultValue: (*|string), name: string, placeholder: *}}]|*[]}
 * @namespace Scandipwa/Component/MyAccountAddressForm/Form/getStreetFields */
export const getStreetFields = (props) => {
    const {
        addressLinesQty = 1,
        address: { street = [] }
    } = props;
    const range = { max: 50 };

    if (addressLinesQty === 1) {
        return [{
            type: FIELD_TYPE.text,
            label: __('Street address'),
            attr: {
                name: 'street',
                defaultValue: street[0] || '',
                placeholder: __('Your street address')
            },
            addRequiredTag: true,
            validateOn: ['onChange'],
            validationRule: {
                isRequired: true,
                range
            }
        }];
    }

    const streets = [];

    // eslint-disable-next-line fp/no-loops, fp/no-let
    for (let i = 0; i < addressLinesQty; i++) {
        streets.push({
            type: FIELD_TYPE.text,
            label: __('Street address line %s', i + 1),
            attr: {
                name: `street_${i}`,
                defaultValue: street[i] || '',
                placeholder: __('Your street address line %s', i + 1)
            },
            addRequiredTag: i === 0,
            validateOn: ['onChange'],
            validationRule: {
                isRequired: i === 0,
                range
            }
        });
    }

    return streets;
};

/** overriden to change label to county */
/** @namespace Scandipwa/Component/MyAccountAddressForm/Form/getRegionFields */
export const getRegionFields = (props) => {
    const {
        region: { region, region_id: regionId = 1 } = {},
        regionDisplayAll,
        availableRegions,
        isStateRequired
    } = props;
    const range = { max: 100 };

    if (!regionDisplayAll && !isStateRequired) {
        return [];
    }

    if (!availableRegions || !availableRegions.length) {
        return [
            {
                type: FIELD_TYPE.text,
                label: __('County'),
                attr: {
                    id: 'address-region-id',
                    name: 'region_string',
                    defaultValue: region,
                    placeholder: __('Your County')
                },
                addRequiredTag: isStateRequired,
                validateOn: ['onChange'],
                validationRule: {
                    isRequired: isStateRequired,
                    range
                }
            }
        ];
    }

    return [
        {
            type: FIELD_TYPE.select,
            label: __('County'),
            attr: {
                name: 'region_id',
                defaultValue: regionId,
                selectPlaceholder: __('Select region...')
            },
            options: availableRegions.map(({ id, name }) => ({ id, label: name, value: id })),
            addRequiredTag: isStateRequired,
            validateOn: ['onChange'],
            validationRule: {
                isRequired: isStateRequired,
                range
            }
        }
    ];
};
/** overriden to merge addressGroups back into 1 object for fetchify extension */
/** @namespace Scandipwa/Component/MyAccountAddressForm/Form/myAccountAddressForm */
export const myAccountAddressForm = (props, events = {}) => {
    const {
        default_billing: defaultBilling,
        default_shipping: defaultShipping,
        countryId,
        firstname = '',
        lastname = '',
        company = '',
        city = '',
        countries = [],
        postcode = '',
        telephone = '',
        addressLinesQty = 1,
        handleNameInputsChange
    } = props;
    const range = { max: 100 };
    const { onCountryChange, onZipcodeBlur } = events || {};
    const fields = [
        {
            type: FIELD_TYPE.checkbox,
            label: __('This is default Billing Address'),
            attr: {
                name: 'default_billing',
                defaultChecked: defaultBilling
            }
        },
        {
            type: FIELD_TYPE.checkbox,
            label: __('This is default Shipping Address'),
            attr: {
                name: 'default_shipping',
                defaultChecked: defaultShipping
            }
        },
        {
            type: FIELD_TYPE.text,
            label: __('First name'),
            attr: {
                name: 'firstname',
                defaultValue: firstname
            },
            events: {
                onChange: handleNameInputsChange
            },
            addRequiredTag: true,
            validateOn: ['onChange'],
            validationRule: {
                inputType: VALIDATION_INPUT_TYPE.alphaSpace,
                isRequired: true,
                range
            }
        },
        {
            type: FIELD_TYPE.text,
            label: __('Last name'),
            attr: {
                name: 'lastname',
                defaultValue: lastname
            },
            events: {
                onChange: handleNameInputsChange
            },
            addRequiredTag: true,
            validateOn: ['onChange'],
            validationRule: {
                inputType: VALIDATION_INPUT_TYPE.alphaSpace,
                isRequired: true,
                range
            }
        },
        ...getEmailField(props),
        {
            type: FIELD_TYPE.text,
            label: __('Telephone'),
            attr: {
                name: 'telephone',
                defaultValue: telephone
            },
            addRequiredTag: true,
            validateOn: ['onChange'],
            validationRule: {
                match: /^[+0-9][0-9 ]*[0-9]+$/,
                isRequired: true,
                range
            }
        },
        {
            name: 'addressGroup',
            mods: { address: true },
            fields: [
                {
                    type: FIELD_TYPE.select,
                    label: __('Country'),
                    attr: {
                        id: 'address-country-id',
                        name: 'country_id',
                        defaultValue: countryId,
                        selectPlaceholder: __('Select country...')
                    },
                    events: {
                        onChange: onCountryChange
                    },
                    options: countries,
                    addRequiredTag: true,
                    validateOn: ['onChange'],
                    validationRule: {
                        isRequired: true,
                        range
                    }
                },
                {
                    type: FIELD_TYPE.text,
                    label: __('Company'),
                    attr: {
                        name: 'company',
                        defaultValue: company
                    },
                    addRequiredTag: false,
                    validateOn: ['onChange'],
                    validationRule: {
                        isRequired: false,
                        range
                    }
                },
                {
                    name: 'streetGroup',
                    mods: {
                        street: true,
                        multipleFields: addressLinesQty > 0
                    },
                    fields: getStreetFields(props)
                },
                {
                    type: FIELD_TYPE.text,
                    label: __('City'),
                    attr: {
                        name: 'city',
                        defaultValue: city
                    },
                    addRequiredTag: true,
                    validateOn: ['onChange'],
                    validationRule: {
                        isRequired: true,
                        range
                    }
                },
                {
                    type: FIELD_TYPE.text,
                    label: __('Post code'),
                    attr: {
                        name: 'postcode',
                        defaultValue: postcode
                    },
                    events: {
                        onBlur: onZipcodeBlur
                    },
                    addRequiredTag: true,
                    validateOn: ['onChange', 'onBlur'],
                    validationRule: {
                        isRequired: true,
                        range
                    }
                },
                ...getRegionFields(props, events)
            ]
        },
        ...getVatFields(props)
    ];

    if (window.location.pathname !== '/my-account/address-book') {
        return fields.splice(2);
    }

    return fields;
};

export default myAccountAddressForm;
