import { BILLING_STEP } from 'Route/Checkout/Checkout.config';

import AmazonPay from '../component/AmazonPay';
import { AMAZON_PAY, AMAZON_PAY_ACTION } from '../component/AmazonPay/AmazonPay.config';

const renderCompleteOrderButtonPlugin = (args, callback, instance) => {
    const { paymentMethod, checkoutStep } = instance.props;

    const shouldRenderAmazonBtn = paymentMethod === AMAZON_PAY && checkoutStep === BILLING_STEP;

    if (shouldRenderAmazonBtn) {
        const {
            isMobile
        } = instance.props;

        return (
            <div block="Checkout" elem="StickyButtonWrapper">
                <div block="Checkout" elem="StickyButtonWrapperRow">
                    { isMobile && instance.renderOrderTotal() /* On mobile, render total separately */ }
                    <AmazonPay type={ AMAZON_PAY_ACTION.PAY_NOW } />
                </div>
            </div>

        );
    }

    return callback.apply(instance, args);
};

export default {
    'Scandiweb/PrinterbaseTheme/Component/CheckoutOrderSummary/Component': {
        'member-function': {
            renderCompleteOrderButton: renderCompleteOrderButtonPlugin
        }
    }
};
