import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductCardContainer as PrinterbaseProductCardContainer
} from 'PrinterbaseComponent/ProductCard/ProductCard.container';
import { updateCurrentPage } from 'Store/ProductList/ProductList.action';
import { ProductType } from 'Type/ProductList';
import { formatPrice } from 'Util/Price';
import { replacePlaceholders } from 'Util/Price/Price';

/** @namespace Scandipwa/Component/ProductCard/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    currentPage: state.ProductListReducer.currentArgs.currentPage
});

/** @namespace Scandipwa/Component/ProductCard/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    updateCurrentPage: (curPage) => dispatch(updateCurrentPage(curPage))
});

/** @namespace Scandipwa/Component/ProductCard/Container */
export class ProductCardContainer extends PrinterbaseProductCardContainer {
    static propTypes = {
        ...PrinterbaseProductCardContainer.propTypes,
        showSimilarProductsPopup: PropTypes.func,
        showStockNotifyPopup: PropTypes.func,
        showRequestDetailsPopup: PropTypes.func,
        linkedProducts: PropTypes.objectOf(ProductType),
        categoryPageTemplate: PropTypes.number,
        showShortDescription: PropTypes.bool,
        categoryProductList: PropTypes.bool,
        productRef: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.shape({ current: PropTypes.instanceOf(Element) })
        ]),
        pageNumber: PropTypes.string,
        updateCurrentPage: PropTypes.func,
        currentPage: PropTypes.number
    };

    static defaultProps = {
        ...PrinterbaseProductCardContainer.defaultProps,
        showSimilarProductsPopup: () => {},
        showStockNotifyPopup: () => {},
        showRequestDetailsPopup: () => {},
        linkedProducts: {},
        showShortDescription: false,
        productRef: { current: null },
        pageNumber: null
    };

    getCardsLinkedProducts() {
        return null;
    }

    setCardHeight(element) {
        if (element) {
            const elementHeight = element.offsetHeight;

            element.addEventListener('mouseenter', () => {
                const elementHeightOnHover = element.offsetHeight;
                const heightGain = elementHeight - elementHeightOnHover;

                document.documentElement.style.setProperty('--widget-card-height-gain', `${heightGain}px`);
            });
        }
    }

    checkForDate(datefrom) {
        const today = new Date();
        const startDate = new Date(`${datefrom}T00:00:00`);

        return startDate < today;
    }

    updateCurrentPageState(page) {
        const { updateCurrentPage, currentPage } = this.props;

        if (currentPage !== page) {
            updateCurrentPage(page);
        }
    }

    getOldPriceHtml() {
        const { productPrice } = this.containerProps();
        const product = this.getActiveProduct();

        if (!productPrice || !product) {
            return null;
        }

        const {
            attributes: {
                special_price_text: { attribute_value: specialPriceText } = {},
                special_price_discount_mode: { attribute_value: specialPriceMode } = {}
            } = {}
        } = product;

        const {
            price: {
                originalPrice: {
                    value: originalPriceValue,
                    valueFormatted: originalPriceFormatted,
                    currency
                } = {},
                finalPrice: {
                    value: specialPrice
                },
                discount: { percentOff } = {}
            } = {}
        } = productPrice;

        if (percentOff === 0 || originalPriceValue === 0) {
            return null;
        }

        const discount = specialPriceMode === '1'
            ? formatPrice(Math.round(originalPriceValue - specialPrice), currency)
            : `${Math.round(percentOff)}%`;

        const result = specialPriceText
            ? replacePlaceholders(
                specialPriceText,
                { discount, oldPrice: originalPriceFormatted }
            )
            : originalPriceFormatted;

        return {
            isHtml: !!specialPriceText,
            text: result
        };
    }

    containerFunctions = {
        ...this.containerFunctions,
        setCardHeight: this.setCardHeight.bind(this),
        checkForDate: this.checkForDate.bind(this),
        getOldPriceHtml: this.getOldPriceHtml.bind(this),
        updateCurrentPageState: this.updateCurrentPageState.bind(this)
    };

    containerProps() {
        const {
            showSimilarProductsPopup,
            showStockNotifyPopup,
            showRequestDetailsPopup,
            categoryPageTemplate,
            showShortDescription,
            categoryProductList,
            productRef,
            pageNumber
        } = this.props;

        return {
            ...super.containerProps(),
            showSimilarProductsPopup,
            showStockNotifyPopup,
            showRequestDetailsPopup,
            upsellProducts: this.getCardsLinkedProducts(),
            categoryPageTemplate,
            showShortDescription,
            categoryProductList,
            productRef,
            pageNumber
        };
    }

    componentDidUpdate(prevProps) {
        const { product: { salable_qty } } = this.props;
        const { product: { salable_qty: prevSalableQty } } = prevProps;

        if (salable_qty !== prevSalableQty) {
            this.forceUpdate();
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
