/* eslint-disable max-lines */
/* eslint-disable no-magic-numbers */
/* eslint-disable react/boolean-prop-naming */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Subscribe } from 'unstated';

import PRODUCT_TYPE from 'Component/Product/Product.config';
import SharedTransitionContainer from 'Component/SharedTransition/SharedTransition.unstated';
import { GRID_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import { showNotification } from 'Store/Notification/Notification.action';
import { ChildrenType } from 'Type/Common';
import { LayoutType } from 'Type/Layout';
import { TotalsType } from 'Type/MiniCart';
import { ProductType } from 'Type/ProductList';
import history from 'Util/History';
import { ADD_TO_CART, getNewParameters, getVariantIndex } from 'Util/Product';
import {
    getMaxQuantity,
    getMinQuantity,
    getName,
    getPrice,
    getProductInStock,
    getSmallImage
} from 'Util/Product/Extract';
import { magentoProductTransform } from 'Util/Product/Transform';
import { appendWithStoreCode, objectToUri } from 'Util/Url';

import NewCard from './AddToBasketLinksCard.component';
import { COUPON_CODE } from './AddToBasketLinksCard.config';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Scandipwa/Component/AddToBasketLinksCard/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile,
    baseLinkUrl: state.ConfigReducer.base_link_url || '',
    productUsesCategories: state.ConfigReducer.product_use_categories || false,
    categoryUrlSuffix: state.ConfigReducer.category_url_suffix,
    cartId: state.CartReducer.id,
    totals: state.CartReducer.cartTotals
});

/** @namespace Scandipwa/Component/AddToBasketLinksCard/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    fallbackAddToCart: (options) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.addProductToCart(dispatch, options)
    ),
    applyCouponToCart: (couponCode) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.applyCouponToCart(dispatch, couponCode)
    ),
    updateInitialCartData: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
    )
});

/** @namespace Scandipwa/Component/AddToBasketLinksCard/Container */
export class AddToBasketLinksCardContainer extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        parameters: PropTypes.objectOf(PropTypes.string),
        isLinks: PropTypes.bool,
        children: ChildrenType,
        isMobile: PropTypes.bool.isRequired,
        layout: LayoutType,
        isLoading: PropTypes.bool,
        productUsesCategories: PropTypes.bool.isRequired,
        categoryUrlSuffix: PropTypes.string.isRequired,
        baseLinkUrl: PropTypes.string.isRequired,
        showNotification: PropTypes.func.isRequired,
        cartId: PropTypes.string,
        fallbackAddToCart: PropTypes.func.isRequired,
        applyCouponToCart: PropTypes.func.isRequired,
        updateInitialCartData: PropTypes.func.isRequired,
        totals: TotalsType.isRequired,
        showStockNotifyPopup: PropTypes.func.isRequired
    };

    static defaultProps = {
        isLinks: false,
        parameters: {},
        children: null,
        layout: GRID_LAYOUT,
        isLoading: false,
        cartId: ''
    };

    state = {
        // eslint-disable-next-line react/destructuring-assignment
        parameters: this.props.parameters,
        isAdding: false,
        isPopupVisible: false,
        quantity: 1,
        isQuickViewVisible: false
    };

    containerFunctions = {
        setActiveProduct: this.updateConfigurableVariant.bind(this),
        getActiveProduct: this.getActiveProduct.bind(this),
        showSelectOptionsNotification: this.showSelectOptionsNotification.bind(this),
        addToBasket: this.addToBasket.bind(this),
        setQuickView: this.setQuickView.bind(this),
        onClose: this.onClose.bind(this)
    };

    globalValidationMap = [
        this.validateStock.bind(this),
        this.validateQuantity.bind(this),
        this.validateCustomizable.bind(this),
        this.validateByType.bind(this)
    ];

    typeValidationMap = {
        [PRODUCT_TYPE.bundle]: this.validateBundle.bind(this),
        [PRODUCT_TYPE.downloadable]: this.validateDownloadable.bind(this),
        [PRODUCT_TYPE.configurable]: this.validateConfigurable.bind(this),
        [PRODUCT_TYPE.grouped]: this.validateGroup.bind(this)
    };

    componentDidUpdate() {
        const { isMobile } = this.props;

        if (isMobile) {
            document.documentElement.style.setProperty('--screen-width', `${screen.width}px`);
        }
    }

    containerProps() {
        const {
            parameters,
            isAdding,
            isPopupVisible,
            quantity,
            isQuickViewVisible
        } = this.state;

        const {
            isLinks,
            product,
            children,
            isMobile,
            layout,
            isLoading,
            product: { price_range, type_id },
            showStockNotifyPopup
        } = this.props;

        const dynamic_price = false;

        return {
            isLinks,
            product,
            parameters,
            children,
            isMobile,
            layout,
            isLoading,
            linkTo: this.getLinkTo(),
            inStock: getProductInStock(product),
            productPrice: getPrice(price_range, dynamic_price, {}, type_id),
            thumbnail: getSmallImage(this.getActiveProduct()) || getSmallImage(product),
            isAdding,
            isPopupVisible,
            quantity,
            isQuickViewVisible,
            showStockNotifyPopup
        };
    }

    validate() {
        // eslint-disable-next-line fp/no-let
        let isValid = true;

        this.globalValidationMap.forEach((step) => {
            if (!step()) {
                isValid = false;
            }
        });

        return isValid;
    }

    validateStock() {
        const { product, showNotification } = this.props;
        const inStock = getProductInStock(product);

        if (!inStock) {
            const name = getName(product);
            showNotification('info', __('Sorry! The product %s is out of stock!', name));
        }

        return inStock;
    }

    validateQuantity() {
        const {
            product, showNotification, product: { type_id: typeId }
        } = this.props;
        const { quantity } = this.state;
        const minQty = getMinQuantity(product);
        const maxQty = getMaxQuantity(product);
        const inRange = quantity >= minQty && quantity <= maxQty;
        const isValid = typeId === PRODUCT_TYPE.grouped || inRange;

        if (!isValid) {
            if (quantity < minQty) {
                showNotification('info', __('Sorry! Minimum quantity for this product is %s!', minQty));
            } else {
                showNotification('info', __('Sorry! Maximum quantity for this product is %s!', maxQty));
            }
        }

        return isValid;
    }

    validateByType() {
        const { product: { type_id } = {} } = this.props;
        const { [type_id]: typeValidationFn } = this.typeValidationMap;

        if (!typeValidationFn) {
            return true;
        }

        return typeValidationFn();
    }

    validateBundle() {
        return true;
    }

    validateCustomizable() {
        return true;
    }

    validateDownloadable() {
        return true;
    }

    validateGroup() {
        return true;
    }

    validateConfigurable() {
        return true;
    }

    async addToBasket(e) {
        const {
            product,
            cartId,
            applyCouponToCart,
            updateInitialCartData
        } = this.props;

        e.stopPropagation();
        if ((!product || Object.keys(product).length === 0)) {
            return;
        }

        e.preventDefault();
        this.setState({ isAdding: true });

        if (!this.validate()) {
            return;
        }

        const {
            fallbackAddToCart
        } = this.props;
        const { quantity } = this.state;
        const magentoProduct = magentoProductTransform(ADD_TO_CART, product, quantity);

        try {
            await fallbackAddToCart({
                products: magentoProduct,
                cartId
            });

            await updateInitialCartData();

            const {
                totals: {
                    applied_coupons = []
                }
            } = this.props;

            // PRINT-656 - Client requested tmp hardcoded solution to disable BASKET10 coupon applying.
            // eslint-disable-next-line no-constant-condition
            if (!applied_coupons && false) {
                applyCouponToCart(COUPON_CODE);
            }

            this.setState({ isPopupVisible: true });
        } finally {
            this.setState({ isAdding: false });
        }

        this.setState({ isAdding: false });
    }

    /**
     * Overridden to add events
     */
    showSelectOptionsNotification(e) {
        const { showNotification } = this.props;

        // needed to fix onClick problems
        e.stopPropagation();

        showNotification('info', __('Please, select product options!'));
    }

    getLinkTo() {
        const {
            baseLinkUrl,
            productUsesCategories,
            categoryUrlSuffix,
            product: { url, url_rewrites = [] },
            product
        } = this.props;

        const { pathname: storePrefix } = new URL(baseLinkUrl || window.location.origin);
        const { location: { pathname } } = history;

        if (!url) {
            return undefined;
        }

        const { parameters } = this.state;
        const { state: { category = null } = {} } = history.location;
        const categoryUrlPart = pathname.replace(storePrefix, '').replace(categoryUrlSuffix, '');
        const productUrl = `${categoryUrlPart}/${url.replace(storePrefix, '')}`;

        // if 'Product Use Categories' is enabled then use the current window location to see if the product
        // has any url_rewrite for that path. (if not then just use the default url)
        const rewriteUrl = url_rewrites.find(({ url }) => url.includes(productUrl)) || {};
        const rewriteUrlPath = productUsesCategories
            ? (rewriteUrl.url && appendWithStoreCode(rewriteUrl.url)) || url
            : url;

        return {
            pathname: rewriteUrlPath,
            state: { product, prevCategoryId: category },
            search: objectToUri(parameters)
        };
    }

    /**
     * Returns currently selected product, differs from prop product, for
     * configurable products, as active product can be one of variants.
     * @returns {*}
     */
    getActiveProduct() {
        const { selectedProduct } = this.state;
        const { product } = this.props;

        return selectedProduct ?? product;
    }

    setQuickView() {
        this.setState({ isQuickViewVisible: true });
    }

    onClose() {
        this.setState({ isQuickViewVisible: false });
    }

    /**
     * Updates configurable products selected variant
     * @param key
     * @param value
     */
    updateConfigurableVariant(key, value) {
        const { parameters: prevParameters } = this.state;

        const parameters = getNewParameters(prevParameters, key, value);
        this.setState({ parameters });

        const { product: { variants, configurable_options } } = this.props;
        const { selectedProduct } = this.state;

        const newIndex = Object.keys(parameters).length === Object.keys(configurable_options).length
            ? getVariantIndex(variants, parameters)
        // Not all parameters are selected yet, therefore variantIndex must be invalid
            : -1;

        const newProduct = newIndex === -1 ? null : variants[newIndex];

        if (newProduct !== selectedProduct) {
            this.setState({
                selectedProduct: newProduct,
                parameters
            });
        }
    }

    render() {
        return (
            <Subscribe to={ [SharedTransitionContainer] }>
                { ({ registerSharedElement }) => (
                    <NewCard
                      { ...this.containerFunctions }
                      { ...this.containerProps() }
                      registerSharedElement={ registerSharedElement }
                    />
                ) }
            </Subscribe>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToBasketLinksCardContainer);
