/**
 * Wyomind google customer reviews compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/WyomindGoogleCustomerReviews/Query/BadgeConfig/Query/BadgeConfigQuery */
export class BadgeConfigQuery {
    getQuery() {
        return new Field('badgeConfig')
            .addFieldList(this._getBadgeConfigQueryFields());
    }

    _getBadgeConfigQueryFields() {
        return [
            'isFrontEndTest',
            'merchantId',
            'position',
            'lang',
            'css'
        ];
    }
}

export default new BadgeConfigQuery();
