import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import LookingForPrinterSearchFieldComponent from './LookingForPrinterSearchField.component';

/** @namespace Scandipwa/Component/LookingForPrinterSearchField/Container/mapDispatchToProps */
// eslint-disable-next-line no-unused-vars
export const mapDispatchToProps = (dispatch) => ({

});

/** @namespace Scandipwa/Component/LookingForPrinterSearchField/Container/mapStateToProps */
// eslint-disable-next-line no-unused-vars
export const mapStateToProps = (state) => ({

});
/** @namespace Scandipwa/Component/LookingForPrinterSearchField/Container */
export class LookingForPrinterSearchFieldContainer extends PureComponent {
    containerFunctions = {
        onSearchOutsideClick: this.onSearchOutsideClick.bind(this),
        onSearchBarFocus: this.onSearchBarFocus.bind(this),
        onSearchBarChange: this.onSearchBarChange.bind(this),
        onClearSearchButtonClick: this.onClearSearchButtonClick.bind(this)
    };

    __construct() {
        this.state = {
            searchCriteria: '',
            isSearchFocused: false
        };
    }

    onSearchOutsideClick() {
        this.setState({ isSearchFocused: false });
    }

    onSearchBarFocus() {
        this.setState({ isSearchFocused: true });
    }

    onSearchBarChange({ target: { value: searchCriteria } }) {
        this.setState({ searchCriteria });
    }

    onClearSearchButtonClick() {
        return false;
    }

    containerProps() {
        const { searchCriteria, isSearchFocused } = this.state;

        return {
            searchCriteria,
            isSearchFocused
        };
    }

    render() {
        return (
            <LookingForPrinterSearchFieldComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LookingForPrinterSearchFieldContainer));
