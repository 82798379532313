import { cloneElement } from 'react';

import ProductListPage from 'Component/ProductListPage';

import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};

const renderPage = (args, callback, instance) => {
    const {
        event
    } = instance.props;

    const originalElement = callback(...args);

    return cloneElement(
        originalElement,
        { event }
    );
};

const containerProps = (args, callback, instance) => {
    const {
        event
    } = instance.props;

    return {
        ...callback(...args),
        event
    };
};

export default {
    'Component/ProductList/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Component/ProductList/Container': {
        'member-function': {
            containerProps
        }
    },
    'Component/ProductList/Component': {
        'member-function': {
            renderPage
        }
    }
};
