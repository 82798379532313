/**
 * Wyomind google customer reviews compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';

export const BadgeConfigType = PropTypes.shape({
    merchantId: PropTypes.string,
    position: PropTypes.string,
    lang: PropTypes.string,
    css: PropTypes.string
});
