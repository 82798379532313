/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/klaviyo
 * @author  Jort Geurts <info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { KLAVIYO_SCRIPT_ID } from './KlaviyoAnalytics.config';

/** @namespace KlaviyoSpwa/Component/KlaviyoAnalytics/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    klaviyo: state.ConfigReducer.klaviyo
});

/** @namespace KlaviyoSpwa/Component/KlaviyoAnalytics/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace KlaviyoSpwa/Component/KlaviyoAnalytics/Container */
export class KlaviyoAnalyticsContainer extends PureComponent {
    static propTypes = {
        klaviyo: PropTypes.shape({
            klaviyo_enabled: PropTypes.bool,
            klaviyo_url: PropTypes.string,
            klaviyo_key: PropTypes.string
        }).isRequired
    };

    componentDidMount() {
        if (window.hasLoaded) {
            this.injectKlaviyoScript();
            return;
        }

        // Reduce Klaviyo impact on main thread performance
        window.addEventListener('load', () => {
            this.injectKlaviyoScript();
            window.hasLoaded = true;
        });
    }

    componentDidUpdate() {
        this.injectKlaviyoScript();
    }

    componentWillUnmount() {
        this.removeKlaviyoScript();
    }

    injectKlaviyoScript() {
        const { klaviyo } = this.props;

        if (!klaviyo) {
            return;
        }

        const {
            klaviyo_enabled,
            klaviyo_url,
            klaviyo_key
        } = klaviyo;

        if (klaviyo_enabled && klaviyo_url && klaviyo_key && !document.getElementById(KLAVIYO_SCRIPT_ID)) {
            const script = document.createElement('script');
            script.src = `${klaviyo_url}${klaviyo_key}`;
            script.id = KLAVIYO_SCRIPT_ID;

            document.head.insertBefore(script, document.head.childNodes[0]);
        }
    }

    removeKlaviyoScript() {
        const script = document.getElementById(KLAVIYO_SCRIPT_ID);
        if (script) {
            script.remove();
        }
    }

    render() {
        return null;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(KlaviyoAnalyticsContainer));
