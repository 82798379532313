import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { klevuConfigType } from '../../../packages/@scandipwa/klevu-search/src/types/KlevuSearch';
import KlevuBannerComponent from './KlevuBanner.component';

/** @namespace Scandipwa/Component/KlevuBanner/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Scandipwa/Component/KlevuBanner/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    klevuSearchTerm: state.ConfigReducer.klevu_banner_search_term,
    isKlevuBannerEnabled: state.ConfigReducer.klevu_display_banner,
    klevuConfig: state.ConfigReducer.klevu
});

/** @namespace Scandipwa/Component/KlevuBanner/Container */
export class KlevuBannerContainer extends PureComponent {
    static propTypes = {
        klevuSearchTerm: PropTypes.string,
        search: PropTypes.string.isRequired,
        klevuConfig: klevuConfigType,
        isKlevuBannerEnabled: PropTypes.bool
    };

    static defaultProps = {
        klevuSearchTerm: '',
        isKlevuBannerEnabled: false,
        klevuConfig: {}
    };

    state = {
        klevuBanner: {}
    };

    componentDidUpdate(prevProps) {
        const {
            isKlevuBannerEnabled,
            search
        } = this.props;
        const searchTerm = search.replace(/\+/g, ' ');

        if (isKlevuBannerEnabled && search !== prevProps.search) {
            this.fetchBanner(searchTerm);
        }
    }

    checkSearchTerm(searchTerm) {
        const {
            klevuSearchTerm
        } = this.props;
        const terms = klevuSearchTerm.split(',');

        return terms.includes(searchTerm);
    }

    async fetchBanner(searchTerm) {
        const {
            klevuConfig: {
                klevu_api_key
            } = {}
        } = this.props;

        if (!klevu_api_key) {
            return;
        }

        try {
            const res = await fetch(`https://js.klevu.com/klevu-js-v1/klevu-js-api/${klevu_api_key}-banner.json`);
            const resFmt = await res.json();

            if (resFmt.klevu_banner) {
                if (this.checkSearchTerm(searchTerm)) {
                    resFmt.klevu_banner.forEach((banner) => {
                        if (banner.showForTerms.includes(searchTerm)) {
                            this.setState({ klevuBanner: banner });
                        }
                    });
                } else {
                    resFmt.klevu_banner.forEach((banner) => {
                        if (banner.showForTerms === null) {
                            this.setState({ klevuBanner: banner });
                        }
                    });
                }
            }
        } catch (e) {
            // handle error
        }
    }

    containerProps() {
        const {
            klevuBanner
        } = this.state;

        return {
            klevuBanner
        };
    }

    render() {
        const {
            isKlevuBannerEnabled,
            search
        } = this.props;
        const searchTerm = search.replace(/\+/g, ' ');

        if (!isKlevuBannerEnabled || !(this.checkSearchTerm(searchTerm) || this.checkSearchTerm('any search term'))) {
            return null;
        }

        return (
            <KlevuBannerComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KlevuBannerContainer);
