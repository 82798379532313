/**
 * @category  ScandiPWA
 * @author    ScandiWeb Team <info@scandiweb.com>
 * @package   @scandipwa/amazonpay-printerbase-compatibility
 */
import { CheckoutAddressFormInstance } from 'Component/CheckoutAddressForm/CheckoutAddressFormInstance';

import { updateShippingMethodShippingInformationInBackEnd } from './UpdateShippingMethodInBackEnd';

const componentDidMountPlugin = (args, callback, instance) => {
    const ret = callback.apply(instance, args);
    CheckoutAddressFormInstance.setCheckoutShippingContainerInstance(instance);

    return ret;
};

/**
 * Plugin to update shipping method on backend afer resetting shipping method
 * automatically, so the AmazonPay button gets correct information
 * when accessing a cart previously saved. Carts previously saved got
 * shipping method reset on page load
 */
const resetShippingMethodPlugin = (args, callback, instance) => {
    const callBackReturn = callback.apply(instance, args);

    const { selectedShippingMethod } = instance.state;
    if (selectedShippingMethod) {
        updateShippingMethodShippingInformationInBackEnd(instance, selectedShippingMethod);
    }

    return callBackReturn;
};

/**
 * Updates shipping method on backend cart whenever it changes in FE.
 * It is necessary because some payments methods do not use checkout form to submit info,
 * but grab the cart/customer data from backend whenever they get triggered
 * (custom buttons in cart, like Paypal or AmazonPay)
 */
const onShippingMethodSelectPlugin = (args, callback, instance) => {
    const callbackReturn = callback.apply(instance, args);
    updateShippingMethodShippingInformationInBackEnd(instance, args[0]);

    return callbackReturn;
};

/**
 * Printerbase theme removed setDetailsStep
 * Here we are re-adding setDetailsStep to CheckoutShipping container,
 * because PayPal extension relies on it
 */
const containerPropsPlugin = (args, callback, instance) => {
    const defaultContainerProps = callback.apply(args);
    const { setDetailsStep } = instance.props;

    return {
        ...defaultContainerProps,
        setDetailsStep
    };
};

export default {
    'Component/CheckoutShipping/Container': {
        'member-function': {
            componentDidMount: componentDidMountPlugin,
            containerProps: containerPropsPlugin,
            onShippingMethodSelect: onShippingMethodSelectPlugin
        }
    },
    'Scandiweb/PrinterbaseTheme/Component/CheckoutShipping/Container': {
        'member-function': {
            resetShippingMethod: resetShippingMethodPlugin
        }
    }
};
