/**
 * Wyomind google customer reviews compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import OrderDataQuery from 'Query/OrderData.query';
import { CustomerType } from 'SourceType/Account';
import { fetchQuery } from 'Util/Request';

import BadgeConfigQuery from '../../query/BadgeConfig.query';
import OrderConfigQuery from '../../query/OrderConfig.query';
import { BadgeConfigType } from '../../type/BadgeConfig.type';
import { OrderConfigType } from '../../type/OrderConfig.type';
import { getEstimatedDeliveryDate } from '../../util/util';

/** @namespace Scandiweb/WyomindGoogleCustomerReviews/Component/OrderSurvey/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    customer: state.MyAccountReducer.customer,
    badgeConfig: state.ConfigReducer.badgeConfig,
    orderConfig: state.ConfigReducer.orderConfig
});

/** @namespace Scandiweb/WyomindGoogleCustomerReviews/Component/OrderSurvey/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Scandiweb/WyomindGoogleCustomerReviews/Component/OrderSurvey/Container/OrderSurveyContainer */
export class OrderSurveyContainer extends PureComponent {
    static propTypes = {
        customer: CustomerType.isRequired,
        email: PropTypes.string.isRequired,
        orderId: PropTypes.string.isRequired,
        badgeConfig: BadgeConfigType.isRequired,
        orderConfig: OrderConfigType.isRequired
    };

    componentDidMount() {
        this.openGoogleSurvey();
    }

    componentWillUnmount() {
        const script = document.getElementById('renderOptIn');
        if (script) {
            script.parentNode.removeChild(script);
        }
    }

    openGoogleSurvey() {
        const {
            customer,
            email,
            orderId,
            badgeConfig: { merchantId, lang },
            orderConfig: { eta, optinStyle, delivery_country }
        } = this.props;

        if (!customer || !email || !merchantId) {
            const queries = [
                fetchQuery(OrderDataQuery.getQuery(orderId.replace('/', ''))),
                fetchQuery(BadgeConfigQuery.getQuery()),
                fetchQuery(OrderConfigQuery.getQuery())
            ];

            Promise.all(queries).then(
            /** @namespace Scandiweb/WyomindGoogleCustomerReviews/Component/OrderSurvey/Container/OrderSurveyContainer/openGoogleSurvey/then/catch/all/then */
                (data) => {
                    const [
                        { orderByIncrementId: { country_id, customer_email } },
                        { badgeConfig: { merchantId, lang } },
                        { orderConfig: { eta, optinStyle, delivery_country } }
                    ] = data;

                    this.openPopup(
                        merchantId,
                        orderId,
                        customer_email,
                        !delivery_country ? country_id : delivery_country,
                        eta,
                        optinStyle,
                        lang
                    );
                }
            ).catch(
            /** @namespace Scandiweb/WyomindGoogleCustomerReviews/Component/OrderSurvey/Container/OrderSurveyContainer/openGoogleSurvey/then/catch */
                (error) => {
                    console.log(error);
                }
            );

            return;
        }
        this.openPopup(merchantId, orderId, email, delivery_country, eta, optinStyle, lang);
    }

    openPopup(
        merchantId, orderId, email, delivery_country, eta, optinStyle, lang
    ) {
        const script = document.createElement('script');

        script.src = 'https://apis.google.com/js/platform.js?onload=renderOptIn';
        script.id = 'renderOptIn';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        window.renderOptIn = () => {
            window.gapi.load('surveyoptin', () => {
                window.gapi.surveyoptin.render(
                    {
                        merchant_id: merchantId,
                        order_id: orderId,
                        email,
                        delivery_country,
                        estimated_delivery_date: getEstimatedDeliveryDate(
                            new Date(), !parseInt(eta, 10) ? 0 : parseInt(eta, 10)
                        ),
                        opt_in_style: optinStyle
                    }
                );
            });
        };

        window.___gcfg = {
            lang: lang || 'en_US'
        };
    }

    render() {
        return null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderSurveyContainer);
