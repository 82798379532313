/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-magic-numbers */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction, react/no-array-index-key */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ProductCard from 'Component/ProductCard';
import ProductWidgetCard from 'Component/ProductWidgetCard';
import RenderWhenVisible from 'Component/RenderWhenVisible';
import {
    ProductListPageComponent as PrinterbaseProductListPageComponent
} from 'PrinterbaseComponent/ProductListPage/ProductListPage.component';
import { GRID_LAYOUT, LIST_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';

/** @namespace Scandipwa/Component/ProductListPage/Component/mapStateToProps */
export const mapStateToProps = (state) => ({
    urlKey: state.ConfigReducer.sale_url_key
});

/** @namespace Scandipwa/Component/ProductListPage/Component */
export class ProductListPageComponent extends PrinterbaseProductListPageComponent {
    static propTypes = {
        ...this.propTypes,
        showSimilarProductsPopup: PropTypes.func.isRequired,
        categoryPageTemplate: PropTypes.number,
        urlKey: PropTypes.string
    };

    state = {
        ...this.state,
        visible: 9
    };

    /**
     * method to use the show more functionality
     */
    showMore() {
        const { visible } = this.state;
        this.setState({ visible: visible + 9 });
    }

    renderFallback = (key) => <div key={ key } style={ { height: 300 } } />;

    /**
     * Overridden to add showSimilarProductsPopup and render different component for Widget cards
     * Overridden to pass showStockNotifyPopup
     */
    renderPageItems() {
        const {
            items,
            selectedFilters,
            mix: {
                mods: {
                    layout
                } = {}
            },
            handleChangeProduct,
            history: { push },
            isWidget,
            showSimilarProductsPopup,
            showStockNotifyPopup,
            showRequestDetailsPopup,
            categoryPageTemplate,
            urlKey,
            categoryProductList
        } = this.props;

        const { visible } = this.state;
        const cardProps = {
            selectedFilters,
            layout: isWidget ? LIST_LAYOUT : layout,
            handleChangeProduct,
            push,
            categoryProductList,
            ...this.containerProps()
        };

        if (isWidget && window.location.pathname === `/${urlKey}`) {
            const sortedItems = items.sort((a, b) => a.custom_position - b.custom_position).slice(0, visible);

            return sortedItems.map((product, i) => (
                <ProductWidgetCard
                  product={ product }
                  key={ i }
                  isWidget
                  categoryPageTemplate={ categoryPageTemplate }
                  urlKey={ urlKey }
                  { ...cardProps }
                />
            ));
        }

        if (isWidget) {
            const sortedItems = items.sort((a, b) => a.custom_position - b.custom_position).slice(0, visible);

            return sortedItems.map((product, i) => (
                <ProductWidgetCard
                  product={ product }
                  key={ i }
                  isWidget
                  categoryPageTemplate={ categoryPageTemplate }
                  { ...cardProps }
                />
            ));
        }

        return items.map((product, i) => (
            <RenderWhenVisible
              margin="300px 0px 300px 0px"
              fallback={ this.renderFallback }
            >
                <ProductCard
                  product={ product }
                  // eslint-disable-next-line react/no-array-index-key
                  key={ i }
                  showSimilarProductsPopup={ showSimilarProductsPopup }
                  showStockNotifyPopup={ showStockNotifyPopup }
                  showRequestDetailsPopup={ showRequestDetailsPopup }
                  categoryPageTemplate={ categoryPageTemplate }
                  { ...cardProps }
                />
            </RenderWhenVisible>
        ));
    }

    /**
     * Overridden to pass isWidget prop so the placeholders for the pods on homepage are rendered correctly
     */
    renderPlaceholders() {
        const {
            categoryPageTemplate,
            numberOfPlaceholders, mix: {
                mods: {
                    layout = GRID_LAYOUT
                } = {}
            },
            isWidget
        } = this.props;

        return Array.from(
            { length: numberOfPlaceholders },
            (_, i) => (
                <ProductCard
                  key={ i }
                  product={ {} }
                  layout={ layout }
                  isWidget={ isWidget }
                  categoryPageTemplate={ categoryPageTemplate }
                />
            )
        );
    }

    renderShowMoreButton() {
        const { items, urlKey } = this.props;
        const { visible } = this.state;
        const loadMoreCount = items.length - visible;
        const label = (`Show more (${loadMoreCount} product${loadMoreCount > 1 ? 's' : ''})`);

        if (!(window.location.pathname === `/${urlKey}`) || loadMoreCount < 1) {
            return null;
        }

        return (
            <button
              block="ProductListPage"
              elem="ShowMoreButton"
              type="button"
              onClick={ () => this.showMore() }
            >
                { label }
            </button>
        );
    }

    /**
     * Overridden to add show more button for black friday page
     */
    render() {
        const {
            pageNumber,
            wrapperRef,
            mix
        } = this.props;

        return (
            <>
            <ul
              block="ProductListPage"
              mix={ { ...mix, elem: 'Page' } }
              key={ pageNumber }
              ref={ wrapperRef }
            >
                { this.renderItems() }
            </ul>
            { this.renderShowMoreButton() }
            </>
        );
    }
}

export default withRouter(connect(mapStateToProps)(ProductListPageComponent));
