import Field from 'Component/PureForm/Field';
import { FIELD_TYPE } from 'Component/PureForm/Field/Field.config';
import {
    ProductCustomizableOption as SourceProductCustomizableOption
} from 'SourceComponent/ProductCustomizableOption/ProductCustomizableOption.component';
/** @namespace Scandipwa/Component/ProductCustomizableOption/Component */
export class ProductCustomizableOptionComponent extends SourceProductCustomizableOption {
    renderSelectValues() {
        const {
            showSubscriptionPopup,
            updateSelectedValues,
            getDropdownOptions,
            isRequired,
            uid
        } = this.props;

        return (
            <div block="ProductCustomizableItem" elem="DropdownWrapper">
                <Field
                  type={ FIELD_TYPE.select }
                  attr={ {
                      id: `customizable-options-dropdown-${ uid }`,
                      name: `customizable-options-dropdown-${ uid }`,
                      selectPlaceholder: __('Select option...')
                  } }
                  mix={ { block: 'ProductCustomizableItem', elem: 'Select' } }
                  options={ getDropdownOptions() }
                  events={ {
                      onChange: () => {
                          updateSelectedValues();
                      }
                  } }
                  validationRule={ {
                      isRequired
                  } }
                  validateOn={ ['onChange'] }
                  showSubscriptionPopup={ showSubscriptionPopup }
                />
            </div>
        );
    }
}

export default ProductCustomizableOptionComponent;
