import {
    CmsPageQuery as SourceCmsPageQuery
} from 'SourceQuery/CmsPage.query';

/** @namespace Scandipwa/Query/CmsPage/Query */
export class CmsPageQuery extends SourceCmsPageQuery {
    /**
     * Overridden to return cms page identifier field
     */
    _getPageFields() {
        return [
            'identifier',
            ...super._getPageFields()
        ];
    }
}

export default new CmsPageQuery();
