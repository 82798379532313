import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { DEFINED_PRODUCT_TYPES } from 'Component/ProductLinks/ProductLinks.config';
import { ProductType } from 'Type/ProductList';

import AddToBasketProductLinks from './AddToBasketProductLinks.component';
import { VIEW_MORE_COUNT } from './AddToBasketProductLinks.config';

/** @namespace Scandipwa/Component/AddToBasketProductsLinks/AddToBasketProductLinks/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace Scandipwa/Component/AddToBasketProductsLinks/AddToBasketProductLinks/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Scandipwa/Component/AddToBasketProductsLinks/AddToBasketProductLinks/Container */
export class AddToBasketProductLinksContainer extends PureComponent {
    static propTypes = {
        linkedProducts: PropTypes.objectOf(ProductType).isRequired,
        linkType: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        numberOfProductsToDisplay: PropTypes.number,
        isMobile: PropTypes.bool.isRequired,
        showStockNotifyPopup: PropTypes.func.isRequired
    };

     static defaultProps = {
         numberOfProductsToDisplay: VIEW_MORE_COUNT
     };

     relatedProductRef = {};

    state = {
        siblingsHaveBrands: false,
        siblingsHavePriceBadge: false,
        siblingsHaveTierPrice: false,
        siblingsHaveConfigurableOptions: false,
        visible: {
            Consumables: VIEW_MORE_COUNT,
            Accessories: VIEW_MORE_COUNT,
            Stationery: VIEW_MORE_COUNT,
            Paper: VIEW_MORE_COUNT,
            Warranties: VIEW_MORE_COUNT,
            Media: VIEW_MORE_COUNT
        }
    };

    containerFunctions = {
        getRestOfProductTypes: this.getRestOfProductTypes.bind(this),
        showMore: this.showMore.bind(this)
    };

    containerProps() {
        const {
            linkType,
            linkedProducts,
            title,
            numberOfProductsToDisplay,
            isMobile,
            showStockNotifyPopup
        } = this.props;
        const {
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveTierPrice,
            siblingsHaveConfigurableOptions,
            visible
        } = this.state;

        return {
            linkType,
            linkedProducts,
            title,
            numberOfProductsToDisplay,
            isMobile,
            productCardFunctions: {
                setSiblingsHaveBrands: () => this.setState({ siblingsHaveBrands: true }),
                setSiblingsHavePriceBadge: () => this.setState({ siblingsHavePriceBadge: true }),
                setSiblingsHaveTierPrice: () => this.setState({ siblingsHaveTierPrice: true }),
                setSiblingsHaveConfigurableOptions: () => this.setState({ siblingsHaveConfigurableOptions: true })
            },
            productCardProps: {
                siblingsHaveBrands,
                siblingsHavePriceBadge,
                siblingsHaveTierPrice,
                siblingsHaveConfigurableOptions
            },
            relatedProductRef: this.relatedProductRef,
            visible,
            showStockNotifyPopup
        };
    }

    /**
     * method to use the show more functionality
     */
    showMore(e) {
        const { visible } = this.state;
        const val = e.target.id;

        this.setState({ visible: { ...visible, [val]: visible[val] + VIEW_MORE_COUNT } });
    }

    /**
     * Get not defined product types to render them after defined
     */
    getRestOfProductTypes() {
        const {
            linkType,
            linkedProducts: {
                [linkType]: {
                    items = []
                } = {}
            }
        } = this.props;

        return items.map(({
            attributes: {
                product_type: {
                    attribute_value,
                    attribute_options: {
                        [attribute_value]: {
                            label
                        } = {}
                    } = {}
                } = {}
            }
        }) => label).filter(
            (value, index, self) => self.indexOf(value) === index && !DEFINED_PRODUCT_TYPES.includes(value)
        );
    }

    render() {
        const {
            linkType,
            linkedProducts: {
                [linkType]: {
                    items = []
                } = {}
            }
        } = this.props;

        if (items.length === 0) {
            return null;
        }

        return (
            <AddToBasketProductLinks
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToBasketProductLinksContainer);
