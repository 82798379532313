import { Field } from 'Util/Query';

/** @namespace KlevuSearch/Query/Category/Query/CategoryQuery */
export class CategoryQuery {
    getQuery(ids) {
        const items = new Field('items').addFieldList([
            'name',
            'image',
            'url',
            'id'
        ]);

        return new Field('categories')
            .addField(items)
            .addArgument(
                'filters',
                'CategoryFilterInput',
                {
                    ids: {
                        in: ids
                    }
                }
            );
    }
}

export default new CategoryQuery();
