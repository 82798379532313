import { UPDATE_COMPATIBLE_CONSUMABLE } from './ProductCompatibleConsumable.action';

/** @namespace Scandipwa/Store/ProductCompatibleConsumable/Reducer/CompatibleConsumableReducer */
export const CompatibleConsumableReducer = (state = {}, action) => {
    switch (action.type) {
    case UPDATE_COMPATIBLE_CONSUMABLE:
        const { compatibleConsumable } = action;

        return compatibleConsumable;
    default:
        return state;
    }
};

export default CompatibleConsumableReducer;
