/**
 * @category  Printerbase
 * @author    Scandiweb <info@scandiweb.com>
 * @package   @scandipwa/amazonpay-printerbase-compatibility
 */

import { AMAZON_PAY } from '@scandipwa/amazonpay/src/component/AmazonPay/AmazonPay.config';

const renderCompleteOrderButtonPlugin = (args, callback, instance) => {
    const { paymentMethod } = instance.props;

    if (!paymentMethod || paymentMethod === AMAZON_PAY) {
        return null;
    }

    return callback.apply(instance, args);
};

/*
    Don't touch, paypal extension doesn't work without plugging
    this function into PrinterbaseTheme/Component/CheckoutOrderSummary/Component lol
*/
export default {
    'Scandiweb/PrinterbaseTheme/Component/CheckoutOrderSummary/Component': {
        'member-function': {
            renderCompleteOrderButton: renderCompleteOrderButtonPlugin
        }
    }
};
