import ProductListQuery from 'query/ProductList.query';

import { getIndexedProduct } from 'Util/Product';
import { fetchQuery } from 'Util/Request';

export const updateHistoryStateProduct = ([product = null]) => {
    const { product: stateProduct = null } = history?.state?.state || {};

    if (
        !product
        || !stateProduct
        || JSON.stringify(stateProduct) === JSON.stringify(product)
    ) {
        return;
    }

    window.history.replaceState({
        ...(history?.state || {}),
        state: {
            ...(history?.state?.state || {}),
            product: {
                ...(stateProduct || {}),
                ...(getIndexedProduct(product) || {})
            }
        }
    }, '');
};

export const addSalableQuantityDataPDP = async (_args, callback, _instance) => {
    const [data] = _args;

    if (!data.products) {
        return callback.apply(_instance, _args);
    }

    const { products: { items } } = data;
    if (items && items.length > 0) {
        const productIds = items.map(({ id }) => parseInt(id, 10));

        await fetchQuery(ProductListQuery.getSalableQty(productIds)).then(
            (_data) => {
                function matchingPID(product) {
                    return product.productId === this.id;
                }

                items.forEach((_product) => {
                    const { id } = _product;
                    const { salableQuantity } = _data;
                    const { qty } = salableQuantity.find(matchingPID, { id });

                    if (qty) {
                        Object.assign(_product, { salableQuantity: qty });
                    }
                });
            }
        );
    }

    updateHistoryStateProduct(items);

    return callback.apply(_instance, _args);
};

export default {
    'Store/Product/Dispatcher': {
        'member-function': {
            onSuccess: addSalableQuantityDataPDP
        }
    }
};
