import { OUT_OF_STOCK } from 'Component/Product/Product.config';
import ProductListQuery from 'Query/ProductList.query';
import {
    LINKED_PRODUCTS as SOURCE_LINKED_PRODUCTS,
    LinkedProductsDispatcher as SourceLinkedProductsDispatcher
} from 'SourceStore/LinkedProducts/LinkedProducts.dispatcher';
import { getIndexedProduct } from 'Util/Product';
import { fetchQuery } from 'Util/Request';

export const LINKED_PRODUCTS = SOURCE_LINKED_PRODUCTS;

/** @namespace Scandipwa/Store/LinkedProducts/Dispatcher */
export class LinkedProductsDispatcher extends SourceLinkedProductsDispatcher {
    /**
     * Overriden to add pageSize
     * @return {Query} ProductList query
     * @memberof LinkedProductsDispatcher
     * @param product_links
     */
    prepareRequest(product_links) {
        product_links.sort((a, b) => a.position - b.position);
        const relatedSKUs = product_links.reduce((links, link) => {
            const { linked_product_sku } = link;

            return [...links, `${ linked_product_sku.replace(/ /g, '%20') }`];
        }, []);

        return [
            ProductListQuery.getQuery({
                args: {
                    filter: {
                        productsSkuArray: relatedSKUs
                    },
                    pageSize: 100
                },
                notRequireInfo: true,
                notRequireCategories: true
            })
        ];
    }

    _processResponseForPLP(data, product_links) {
        const { products: { items } } = data;

        const indexedBySku = items.reduce((acc, item) => {
            const { sku } = item;

            acc[sku] = getIndexedProduct(item);

            return acc;
        }, {});

        const linkedProducts = product_links.reduce((acc, link) => {
            const { linked_product_sku, link_type } = link;

            if (
                indexedBySku[linked_product_sku]
                && acc[link_type]
                && indexedBySku[linked_product_sku].attribute_set_name !== 'Hardware Set'
                && indexedBySku[linked_product_sku].stock_status !== OUT_OF_STOCK
            ) {
                acc[link_type].items.push(
                    indexedBySku[linked_product_sku]
                );

                acc[link_type].total_count++;
            }

            return acc;
        }, {
            related: { total_count: 0, items: [] }
        });

        return linkedProducts;
    }

    async fetchRelatedProductsData(product_links) {
        const query = this.prepareRequest(product_links);
        const data = await fetchQuery(query);
        const list = this._processResponseForPLP(data, product_links);

        return (list);
    }

    /**
     * Overridden to add positions for products
     */
    _processResponse(data, product_links) {
        const { products: { items } } = data;

        const itemsWithPositions = items.map((item) => {
            const { sku } = item;
            const productLink = product_links.find(({ linked_product_sku }) => linked_product_sku === sku);

            if (productLink) {
                return { ...item, ...productLink };
            }

            return item;
        });

        const indexedBySku = itemsWithPositions.reduce((acc, item) => {
            const { sku } = item;
            acc[sku] = getIndexedProduct(item);

            return acc;
        }, {});

        const linkedProducts = product_links.reduce((acc, link) => {
            const { linked_product_sku, link_type } = link;

            if (indexedBySku[linked_product_sku] && acc[link_type]) {
                acc[link_type].items.push(
                    indexedBySku[linked_product_sku]
                );

                acc[link_type].total_count++;
            }

            return acc;
        }, {
            upsell: { total_count: 0, items: [] },
            related: { total_count: 0, items: [] },
            crosssell: { total_count: 0, items: [] },
            associated: { total_count: 0, items: [] }
        });

        return linkedProducts;
    }
}

export default new LinkedProductsDispatcher();
