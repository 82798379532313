import {
    CategorySubcategories as SourceCategorySubcategories
} from 'SourceComponent/CategorySubcategories/CategorySubcategories.component';

/** @namespace Scandipwa/Component/CategorySubcategories/Component */
export class CategorySubcategoriesComponent extends SourceCategorySubcategories {
    renderProductCount() {
        const {
            option: {
                productCount
            } = {}
        } = this.props;

        if (!productCount && productCount !== 0) {
            return null;
        }

        return (
            <span
              block="ProductAttributeValue"
              elem="TextNumber"
            >
                { ` (${productCount})` }
            </span>
        );
    }

    /**
     * Overriden to pass the product count
     */
    render() {
        const {
            handleCategoryClick,
            option: {
                value_string,
                label
            }
        } = this.props;

        return (
            <a
              href={ value_string }
              block="ProductAttributeValue"
              mix={ { block: 'CategorySubcategories' } }
              onClick={ handleCategoryClick }
            >
                <span
                  block="ProductAttributeValue"
                  elem="Text"
                  mix={ {
                      block: 'CategorySubcategories',
                      elem: 'Label'
                  } }
                >
                    { label }
                    { this.renderProductCount() }
                </span>
            </a>
        );
    }
}

export default CategorySubcategoriesComponent;
