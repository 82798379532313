import ProductCapacityColourComponent from 'Component/ProductCapacityColour';

export * from 'PrinterbaseUtil/Product/Product';

// ↓ The main reason why these are here is to prevent duplication.
// ↓ Used in ProductActions and ProductCard.

/**
 * Render the cost_per_page attribute
 */
/** @namespace Scandipwa/Util/Product/renderCostPerPage */
export const renderCostPerPage = (cost_per_page, attributeMods) => {
    const { attribute_value } = cost_per_page;

    if (!attribute_value) {
        return null;
    }

    // /(?=per)/ => split at 'per', but don't remove it from the string
    const formatted = attribute_value.split(/(?=per)/);

    const value = formatted[0]; // 1p, 3.4p, etc...
    const per_page = formatted[1];

    return (
        <div block="AttributesWrapper" elem="CostPerPage" mods={ attributeMods }>
            <div block="AttributesWrapper-CostPerPage" elem="Value">
                { value }
            </div>
            <div block="AttributesWrapper-CostPerPage" elem="Label">
                { per_page }
            </div>
        </div>
    );
};

/**
 * Render the capacity color attributes, already parsed in backend
 */
/** @namespace Scandipwa/Util/Product/renderCapacityColourAttribute */
export function renderCapacityColourAttribute(productSku, capacityColour, attributeMods) {
    return (
        <ProductCapacityColourComponent
          productSku={ productSku }
          capacityColour={ capacityColour }
          mods={ attributeMods }
        />
    );
}

/** @namespace Scandipwa/Util/Product/renderAttributes */
export const renderAttributes = (attributes, capacityColour, productSku, isMobile, isPlp = false) => {
    const { cost_per_page } = attributes;
    const siblingsCount = (attributes.length ?? 0)
                        + (capacityColour.length ?? 0) - 1; // -1 for the cost per page attribute itself

    const hasMultipleSiblings = siblingsCount > 1;
    const attributeMods = {
        isPlp,
        hasMultipleSiblings,
        hasFewSiblings: !hasMultipleSiblings,
        hasMultipleSiblingsAndIsPlp: hasMultipleSiblings && isPlp
    };

    if (!(capacityColour.length && productSku) && !cost_per_page) {
        return null;
    }

    if (capacityColour.length < 2) {
        return (
            <div block="AttributesWrapper" mods={ attributeMods }>
                { capacityColour
                  && productSku
                  && renderCapacityColourAttribute(productSku, capacityColour, attributeMods) }
                { cost_per_page && renderCostPerPage(cost_per_page, attributeMods) }
            </div>
        );
    }

    if (isMobile) {
        const wrappedColorsAttributeMods = {
            ...attributeMods,
            areColorsWrapped: true
        };

        return (
            <div block="AttributesWrapper" mods={ attributeMods }>
                <div className="AttributesWrapperSpammedColumn">
                    { cost_per_page && renderCostPerPage(cost_per_page, wrappedColorsAttributeMods) }
                </div>
                <div className="AttributesWrapperColoursColumn">
                    { capacityColour
                      && productSku
                      && renderCapacityColourAttribute(productSku, capacityColour, wrappedColorsAttributeMods) }
                </div>
            </div>
        );
    }

    return (
        <div block="AttributesWrapper" mods={ attributeMods }>
            { cost_per_page && renderCostPerPage(cost_per_page, attributeMods) }
            { capacityColour && productSku && renderCapacityColourAttribute(productSku, capacityColour, attributeMods) }
        </div>
    );
};
