import { connect } from 'react-redux';

import {
    CheckoutAddressBookContainer as SourceCheckoutAddressBookContainer,
    mapDispatchToProps
} from 'SourceComponent/CheckoutAddressBook/CheckoutAddressBook.container';

/** @namespace Scandipwa/Component/CheckoutAddressBook/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    customer: state.MyAccountReducer.customer,
    isCartLoading: state.CartReducer.isLoading
});

/** @namespace Scandipwa/Component/CheckoutAddressBook/Container */
export class CheckoutAddressBookContainer extends SourceCheckoutAddressBookContainer {
    /**
     * Overriden to check condition before fire query
     */
    componentDidUpdate(prevProps, prevState) {
        super.componentDidUpdate(prevProps, prevState);
        const {
            isCartLoading,
            onAddressSelect,
            isBilling,
            isSameAsBilling
        } = this.props;
        const { selectedAddressId } = this.state;
        const defaultAddressId = CheckoutAddressBookContainer._getDefaultAddressId(this.props);
        const customerSelectedAddress = selectedAddressId ?? defaultAddressId;

        if (customerSelectedAddress) {
            onAddressSelect(customerSelectedAddress);

            const shouldEstimateShipping = !isBilling || (isBilling && isSameAsBilling);
            const { isCartLoading: preIsCartLoading } = prevProps;

            if (!isCartLoading && shouldEstimateShipping && !preIsCartLoading) {
                this.estimateShipping((customerSelectedAddress));
            }
        }
    }

    /**
     * Overriden to check condition before fire query
     */
    estimateShipping(addressId) {
        const {
            onShippingEstimationFieldsChange,
            customer: { addresses = [] }
        } = this.props;
        const { isCartLoading } = this.props;

        const address = addresses.find(({ id }) => id === addressId);

        if (!address || isCartLoading) {
            return;
        }

        const {
            city,
            country_id,
            postcode,
            region: {
                region_id,
                region
            } = {}
        } = address;

        if (!country_id) {
            return;
        }

        onShippingEstimationFieldsChange({
            city,
            country_id,
            region_id,
            region,
            postcode
        });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutAddressBookContainer);
