/**
 * Wyomind google customer reviews compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import OrderSurvey from '../component/OrderSurvey';

const render = (args, callback, instance) => {
    const { props } = instance;
    const { orderID, email } = props;

    return (
        <>
            { callback(...args) }
            <OrderSurvey orderId={ orderID } email={ email } />
        </>
    );
};

export const config = {
    'Component/CheckoutSuccess/Component': {
        'member-function': {
            render
        }
    }
};

export default config;
