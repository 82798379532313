import PropTypes from 'prop-types';

import {
    TierPricesComponent as PrinterbaseTierPricesComponent
} from 'PrinterbaseComponent/TierPrices/TierPrices.component';
import { formatPrice } from 'Util/Price';

import './TierPrices.style.override';

/** @namespace Scandipwa/Component/TierPrices/Component */
export class TierPricesComponent extends PrinterbaseTierPricesComponent {
    static propTypes = {
        ...this.propTypes,
        isMobile: PropTypes.bool.isRequired
    };

    /**
     * Overridden to change title
     */
    renderTierPricesTitle() {
        return (
            <div block="TierPrices" elem="TierPricesTitle">
                { __('Multi-buy discount') }
            </div>
        );
    }

    /**
     * Overridden to change exc. vat
     */
    renderTierPriceExclTaxes(priceExclTaxes = 0) {
        const { isExclTaxesVisible } = this.props;

        if (!isExclTaxesVisible) {
            return null;
        }

        return (
            <td block="TierPrices" elem="ExcTaxes">
                { __('(%s ex VAT)', Number(priceExclTaxes)) }
            </td>
        );
    }

    /**
     * Overridden to render additional tier price data.
     * Render different layout on mobile.
     */
    renderDetailedTierPrice = ({
        discount: { amount_off },
        final_price: { value, currency },
        quantity
    }) => {
        const {
            isMobile,
            product: {
                price_range: {
                    minimum_price: {
                        final_price: { value: minPriceForOneUnit }

                    }
                }
            }
        } = this.props;

        // Don't show offers that make no sense
        if (value >= minPriceForOneUnit) {
            return null;
        }

        /**
         * The difference between regular price and a tier one is always 'floored'.
         */
        const amountOff = Math.round(amount_off);

        /**
         * Calculating the tier price excluding taxes basing on the 'regular_price_excl_tax'.
         */
        // eslint-disable-next-line no-magic-numbers
        const tierPriceInclTaxes = value + value * 0.2;
        const formattedTierPrice = formatPrice(tierPriceInclTaxes, currency);

        // eslint-disable-next-line no-magic-numbers
        const fixedTierPriceExclTaxes = parseFloat(value).toPrecision(4);

        if (isMobile) {
            return (
                <tr block="TierPrices" elem="Item" key={ quantity }>
                    { this.renderQuantityRange(quantity) }
                    { this.renderFormattedTierPrice(formattedTierPrice) }
                </tr>
            );
        }

        return (
            <tr block="TierPrices" elem="Item" key={ quantity }>
                { this.renderQuantityRange(quantity) }
                { this.renderQuantityPriceSeparator() }
                { this.renderFormattedTierPrice(formattedTierPrice) }
                { this.renderTierPriceExclTaxes(fixedTierPriceExclTaxes) }
                { this.renderAmountOff(amountOff, currency) }
            </tr>
        );
    };
}

export default TierPricesComponent;
