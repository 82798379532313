import PaypalIcon from 'Component/PaypalIcon';
import Field from 'Component/PureForm/Field';
import FIELD_TYPE from 'Component/PureForm/Field/Field.config';
import SagepayIcon from 'Component/SagepayIcon';
import {
    CheckoutPaymentComponent as PrinterbaseCheckoutPaymentComponent
} from 'PrinterbaseComponent/CheckoutPayment/CheckoutPayment.component';

import {
    PAYPAL_EXPRESS,
    SAGE_PAY
} from './CheckoutPayment.config';

import './CheckoutPayment.override.second.style';

/** @namespace Scandipwa/Component/CheckoutPayment/Component */
export class CheckoutPaymentComponent extends PrinterbaseCheckoutPaymentComponent {
    renderPaymentIcon() {
        const {
            method: { code }
        } = this.props;

        switch (code) {
        case PAYPAL_EXPRESS:
            return <PaypalIcon />;
        case SAGE_PAY:
            return <SagepayIcon />;
        default:
            return '';
        }
    }

    /**
     * Overridden to change field to radio
     */
    render() {
        const {
            isSelected,
            method: { title }
        } = this.props;

        // disable radio in order to skip direct clicks on radio and handle clicks on entire button instead
        return (
            <li block="CheckoutPayment">
                <button
                  block="CheckoutPayment"
                  mods={ { isSelected } }
                  elem="Button"
                  type="button"
                  onClick={ this.onClick }
                >
                    <Field
                      type={ FIELD_TYPE.radio }
                      attr={ {
                          id: `option-${ title }`,
                          name: `option-${ title }`,
                          checked: isSelected
                      } }
                      isDisabled
                    />
                    <span>{ title }</span>
                    { this.renderPaymentIcon() }
                </button>
            </li>
        );
    }
}

export default CheckoutPaymentComponent;
