import { lazy } from 'react';

import RenderWhenVisible from 'Component/RenderWhenVisible';

import { resolveAttachmentsIds } from '../util/Widget/Attachments';

export const AttachmentsWidget = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget-attachments" */
    '../component/AttachmentsWidget'
));

export const ATTACHMENTS_WIDGET = 'AttachmentsWidget';
export const ATTACHMENTS_SECTIONS_WIDGET = 'AttachmentsSectionsWidget';
export const WIDGETS_CONTENT_ALL = 'all';

const renderMap = (originalMember) => ({
    ...originalMember,
    [ATTACHMENTS_WIDGET]: {
        component: AttachmentsWidget
    },
    [ATTACHMENTS_SECTIONS_WIDGET]: {
        component: AttachmentsWidget
    }
});

const renderContent = (args, callback, instance) => {
    const { type } = instance.props;
    const {
        component: Widget,
        fallback
    } = instance.renderMap[type] || {};

    if (type === ATTACHMENTS_WIDGET || type === ATTACHMENTS_SECTIONS_WIDGET) {
        if (Widget !== undefined) {
            const {
                title, attachmentIds, sectionIds, assignType
            } = instance.props;

            const isOnlyAttachments = type === ATTACHMENTS_WIDGET;

            // Only resolves ids if ids have been passed into widget
            // otherwise request with empty params to get all attachments or sections
            const ids = assignType === WIDGETS_CONTENT_ALL
                ? null
                : resolveAttachmentsIds(isOnlyAttachments ? attachmentIds : sectionIds);

            return (
                    <RenderWhenVisible fallback={ fallback }>
                        <Widget
                          title={ title }
                          ids={ ids }
                          assignType={ assignType }
                          type={ type }
                        />
                    </RenderWhenVisible>
            );
        }
    }

    return callback(...args);
};

export default {
    'Component/WidgetFactory/Component': {
        'member-property': {
            renderMap
        },
        'member-function': {
            renderContent
        }
    }
};
