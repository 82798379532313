/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import {
    ExpandableContentComponent as PrinterbaseExpandableContentComponent
} from 'PrinterbaseComponent/ExpandableContent/ExpandableContent.component';
import { getElemBoundingClientRect } from 'Util/Common';
import { getFixedElementHeight } from 'Util/CSS';

import {
    PRODUCT_ATTACHMENTS,
    PROMO_TERMS,
    REVIEW_TAB
} from './ExpandableContent.config';

/** @namespace Scandipwa/Component/ExpandableContent/Component */
export class ExpandableContentComponent extends PrinterbaseExpandableContentComponent {
    /**
     * Overridden to fix the scroll functionality of Promo terms and Product attachments tabs
     */
    async scrollToExpandedContent(scrollContent) {
        const { isContentExpanded } = this.state;
        const elem = this.expandableContentRef && this.expandableContentRef.current;
        const { classList = [] } = elem || {};
        const [, classToScroll = {}] = classList;

        // check condition for scrolling to review tab, promo tab and product attachment tab
        if ((isContentExpanded && !elem) || ((classToScroll !== scrollContent) && scrollContent)) {
            return;
        }

        const { top: elemToWindowTopDist = 0 } = await getElemBoundingClientRect(elem) || {};
        const { top: windowToPageTopDist = 0 } = await getElemBoundingClientRect(document.body) || {};
        const topToElemDistance = elemToWindowTopDist - windowToPageTopDist;
        const {
            total: totalFixedElementHeight,
            top: topFixedElementHeight,
            bottom: bottomFixedElementHeight
        } = getFixedElementHeight();

        const elemMaxOffsetHeight = screen.height > elem.offsetHeight + bottomFixedElementHeight
            ? elem.offsetHeight
            : screen.height - totalFixedElementHeight;
        const helperCalculation = topToElemDistance - (screen.height - bottomFixedElementHeight - elemMaxOffsetHeight);
        const scrollTo = topToElemDistance - topFixedElementHeight;

        // checking if button is in a view-port
        if (-windowToPageTopDist >= helperCalculation) {
            return;
        }

        window.scrollTo({ behavior: 'smooth', top: scrollTo });
    }

    /**
     * Overridden to scroll to the correct block
     */
    componentDidUpdate() {
        const { isContentExpanded } = this.state;
        const { isScrollToExpandableDisabled } = this.props;
        const scrollContentClass = this.getScrollContentClassPDP();

        if (isContentExpanded && !isScrollToExpandableDisabled) {
            this.scrollToExpandedContent(scrollContentClass);
        }
    }

    getScrollContentClassPDP() {
        const { setTabExpands = {} } = this.props;

        switch (setTabExpands) {
        case (PRODUCT_ATTACHMENTS):
            return 'ProductAttachments-Content';
        case (PROMO_TERMS):
            return 'PromoTerms-Content';
        case (REVIEW_TAB):
            return 'ProductReviews-Content';
        default:
            return false;
        }
    }
}

export default ExpandableContentComponent;
