import PropTypes from 'prop-types';

import Html from 'Component/Html';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import { ProductCardComponent as PrinterbaseProductCard } from 'Component/ProductCard/ProductCard.component';

/** @namespace Scandipwa/Component/ProductWidgetCard/Component */
export class ProductWidgetCardComponent extends PrinterbaseProductCard {
    static propTypes = {
        ...this.propTypes,
        setCardHeight: PropTypes.func.isRequired,
        urlKey: PropTypes.string,
        checkForDate: PropTypes.func.isRequired
    };

    renderBestSellersPromotions() {
        const {
            product: { promotions },
            checkForDate
        } = this.props;

        if (!promotions || promotions.length === 0) {
            return null;
        }

        const result = promotions.find((promotion) => checkForDate(promotion.datefrom));
        const isWidgetPromo = typeof result !== 'undefined';

        return (
            <div
              block="ProductCard"
              elem="BestSellersPromotions"
              mods={ { isWidgetPromo } }
            >
                { promotions.map((promotion) => {
                    const { color, name, icon } = promotion;

                    return (
                        <div
                          block="ProductCard"
                          elem="BestSellersPromoListItem"
                          mods={ { isWidgetPromo } }
                        >
                            { icon && <Html content={ icon } /> }
                            <p style={ { color } }>{ name }</p>
                        </div>
                    );
                }) }
            </div>
        );
    }

    /**
     * Overridden to add promotions
     */
    renderCheckoutInfo({ view = false, compare = false } = {}) {
        const { inStock, isMobile } = this.props;

        return (
            <div block="ProductCard" elem="CheckoutInfo" mods={ { outOfStock: !inStock } }>
                <div block="ProductCard" elem="AttributeWrapper" mods={ { havePromotions: !isMobile } }>
                    { !isMobile && this.renderBestSellersPromotions() }
                    { this.renderPrice() }
                </div>
                { (view || compare)
                && (
                    <div block="ProductCard" elem="ActionWrapper">
                        <div block="ActionRow">
                            { view && this.renderViewTheProduct() }
                            { compare && this.renderProductCompareButton() }
                        </div>
                    </div>
                ) }
            </div>
        );
    }

    renderSaleBanner() {
        const {
            product: { discount_percentage } = {}
        } = this.props;
        const text = `SAVE ${discount_percentage}`;

        return (
            <div
              block="ProductCard"
              elem="SaleBanner"
            >
                { text }
            </div>
        );
    }

    renderBlackFridayBuyNow() {
        const { linkTo } = this.props;

        return (
            <Link
              block="ProductCard"
              elem="BuyProductButton"
              mix={ { block: 'Button' } }
              to={ linkTo }
            >
                <span>{ __('BUY NOW') }</span>
            </Link>
        );
    }

    renderBlackFridayPromotions() {
        const {
            product: { promotions }
        } = this.props;

        if (!promotions || promotions.length === 0) {
            return null;
        }

        return (
            <div block="ProductCard" elem="BlackFridayPromotions">
                { promotions.map((promotion) => {
                    const { name } = promotion;

                    return (
                        <p>{ name }</p>
                    );
                }) }
            </div>
        );
    }

    renderBlackFridayCheckoutInfo() {
        return (
            <div block="ProductCard" elem="CheckoutInfo">
                <div block="ProductCard" elem="AttributeWrapper">
                    { this.renderBlackFridayPromotions() }
                    { this.renderPrice() }
                </div>
                <div block="ProductCard" elem="ActionWrapper">
                    <div block="ActionRow">
                        { this.renderBlackFridayBuyNow() }
                    </div>
                </div>
            </div>
        );
    }

    /**
     * New method for rendering figure review
     */
    renderBlackFridayFigureReview(inStock) {
        const { linkTo } = this.props;
        const outOfStockMessage = inStock !== undefined
            ? (!inStock && <span block="ProductCard" elem="OutOfStock">{ __('SOLD OUT') }</span>)
            : null;

        const mods = inStock !== undefined ? { outOfStock: !inStock } : {};

        return (
            <div block="ProductCard" elem="BlackFridayFigureReview">
                { outOfStockMessage }
                <Link
                  to={ linkTo }
                >
                <figure block="ProductCard" elem="Figure" mods={ mods }>
                    { this.renderPicture() }
                </figure>
                </Link>
            </div>
        );
    }

    /**
     * Overridden to remove link wrapper and render promotions
     */
    renderWidgetCardListContent() {
        const {
            renderContent,
            isMobile,
            inStock,
            urlKey
        } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        if (window.location.pathname === `/${urlKey}`) {
            return (
                <div block="ProductCard" elem="MainContentContainer">
                    <div block="ProductCard" elem="Content">
                        { this.renderSaleBanner() }
                        { this.renderName(false) }
                    </div>
                    { this.renderBlackFridayFigureReview(inStock) }
                    <div block="ProductCard" elem="CheckoutInfoWrapper">
                        { this.renderBlackFridayCheckoutInfo() }
                    </div>
                </div>
            );
        }

        return (
            <>
                { !isMobile && this.renderFigureReview() }
                <div block="ProductCard" elem="MainContentContainer">
                    <div block="ProductCard" elem="Content">
                        { isMobile && this.renderFigureReview() }
                        { this.renderBrand() }
                        <div>
                            { this.renderName(false) }
                            { isMobile && this.renderBestSellersPromotions() }
                        </div>
                    </div>
                    <div block="ProductCard" elem="CheckoutInfoWrapper">
                        { this.renderCheckoutInfo({ view: true, compare: true }) }
                    </div>
                </div>
            </>
        );
    }

    render() {
        const {
            mix, isLoading, setCardHeight
        } = this.props;

        return (
            <li
              block="ProductCard"
              mix={ mix }
              ref={ setCardHeight }
            >
                <Loader isLoading={ isLoading } />
                { this.renderWidgetCardListContent() }
            </li>
        );
    }
}

export default ProductWidgetCardComponent;
