/**
 * Wyomind google customer reviews compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const MINUTE_IN_MILLISECONDS = 60000;

/** @namespace Scandiweb/WyomindGoogleCustomerReviews/Util/parseCss */
export const parseCss = (css) => css.replace(/\s+/gm, ' ');

/** @namespace Scandiweb/WyomindGoogleCustomerReviews/Util/getEstimatedDeliveryDate */
export const getEstimatedDeliveryDate = (date, eta) => {
    const edd = new Date(date);
    edd.setDate(edd.getDate() + eta);
    const eddDateString = new Date(edd.getTime() - (edd.getTimezoneOffset() * MINUTE_IN_MILLISECONDS))
        .toISOString()
        .split('T')[0];

    return eddDateString;
};
