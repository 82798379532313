/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import AddNewPromo from 'Component/AddNewPromo';
import AddToBasketProductLinks from 'Component/AddToBasketProductsLinks';
import Image from 'Component/Image';
import { RELATED } from 'Store/LinkedProducts/LinkedProducts.reducer';
import { PriceType, ProductType } from 'Type/ProductList';

import './AddToBasket.style';

/** @namespace Scandipwa/Component/AddToBasket/Component */
export class AddToBasketComponent extends PureComponent {
    static propTypes = {
        relatedProductsData: PropTypes.objectOf(ProductType).isRequired,
        product: ProductType.isRequired,
        cartPrice: PriceType.isRequired,
        itemsQty: PropTypes.number.isRequired,
        isMobile: PropTypes.bool.isRequired,
        hidePromoPopup: PropTypes.func.isRequired,
        goToBasket: PropTypes.func.isRequired,
        isLoaded: PropTypes.bool.isRequired,
        showStockNotifyPopup: PropTypes.func.isRequired
    };

    renderAddNewPromo() {
        return <AddNewPromo />;
    }

    renderPrice() {
        const {
            cartPrice: {
                subtotal_excluding_tax,
                subtotal_including_tax
            },
            cartPrice,
            itemsQty
        } = this.props;

        if (!cartPrice) {
            return null;
        }

        // eslint-disable-next-line fp/no-let
        let cartItems = `${itemsQty} item in basket`;

        if (itemsQty > 1) {
            cartItems = `${itemsQty} items in basket`;
        } else if (itemsQty === 1) {
            cartItems = '1 item in basket';
        }

        const priceExTax = `£${subtotal_excluding_tax.toFixed(2)} `;
        const priceIncTax = `£${subtotal_including_tax.toFixed(2)} `;

        return (
            <div
              block="AddToBasket"
              elem="CartInfo"
            >
                <div
                  block="AddToBasket"
                  elem="Qty"
                >
                    { cartItems }
                </div>
                <div
                  block="AddToBasket"
                  elem="PriceWrapper"
                >
                    <div
                      block="AddToBasket"
                      elem="ExTax"
                    >
                        <span>{ priceExTax }</span>
                        ex VAT
                    </div>
                    <div
                      block="AddToBasket"
                      elem="IncTax"
                    >
                        <span>{ priceIncTax }</span>
                        inc VAT
                    </div>
                </div>
            </div>
        );
    }

    /**
     * New method to render popup content
     */
    renderCartContent() {
        const {
            product: { image: { url }, name },
            hidePromoPopup,
            goToBasket
        } = this.props;

        return (
            <div
              block="AddToBasket"
              elem="CartWrapper"
            >
              <div block="AddToBasket" elem="CartHeading">{ __('Item Added to Basket') }</div>
              <div block="AddToBasket" elem="CartProductInfo">
                <Image
                  src={ url }
                  mix={ { block: 'ProductCard', elem: 'Picture' } }
                />
                <span
                  block="ProductCard"
                  elem="Name"
                  mix={ { block: 'AddToBasket', elem: 'CartProductName' } }
                >
                    { name }
                </span>

              </div>
              { this.renderPrice() }
              <div
                block="AddToBasket"
                elem="ButtonWrapper"
              >
                <button
                  block="Button AddToCart"
                  elem="Checkout"
                  onClick={ goToBasket }
                >
                    { __('GO TO BASKET') }
                </button>
                <button
                  block="Button AddToCart"
                  elem="ContinueShopping"
                  onClick={ hidePromoPopup }
                >
                    { __('continue shopping') }
                </button>
              </div>
            </div>
        );
    }

    render() {
        const {
            relatedProductsData,
            isMobile,
            isLoaded,
            showStockNotifyPopup
        } = this.props;

        if (isMobile) {
            return (
            <div
              block="AddToBasket"
              elem="Wrapper"
              id="AddToBasket"
            >
            <div
              block="AddToBasket"
              elem="SideWrapper"
            >
                  { this.renderAddNewPromo() }
                  { this.renderCartContent() }
            </div>
              <AddToBasketProductLinks
                linkedProducts={ relatedProductsData }
                linkType={ RELATED }
                showStockNotifyPopup={ showStockNotifyPopup }
              />
            </div>
            );
        }

        return (
            <div
              block="AddToBasket"
              elem="Wrapper"
              id="AddToBasket"
            >
              { !isLoaded
                && (
                    <div
                      block="AddToBasket"
                      elem="WrapperAlternative"
                    />
                ) }
                <AddToBasketProductLinks
                  linkedProducts={ relatedProductsData }
                  linkType={ RELATED }
                  showStockNotifyPopup={ showStockNotifyPopup }
                />
                <div
                  block="AddToBasket"
                  elem="SideWrapper"
                >
                    { this.renderCartContent() }
                    { this.renderAddNewPromo() }
                </div>
            </div>
        );
    }
}

export default AddToBasketComponent;
