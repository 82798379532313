/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { showNotification } from 'Store/Notification/Notification.action';
import { QueryDispatcher } from 'Util/Request';

import AttachmentsQuery from '../../query/Attachments.query';
import { updateAttachments } from './Attachments.action';

/** @namespace MageworxAttachment/Store/Attachments/Dispatcher */
export class AttachmentsDispatcher extends QueryDispatcher {
    __construct() {
        super.__construct();
    }

    onSuccess({ attachments }, dispatch) {
        dispatch(updateAttachments(attachments));
    }

    onError(error, dispatch) {
        dispatch(showNotification('error', __('Error fetching product attachments!'), error));
    }

    prepareRequest(options) {
        if (Array.isArray(options)) {
            // Fetches attachments by id
            return AttachmentsQuery.getAttachments(options);
        }

        return AttachmentsQuery.getQuery(options);
    }
}

export default new (AttachmentsDispatcher)();
