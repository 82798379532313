import CheckoutSuccessNewsletterComponent from 'Component/CheckoutSuccessNewsletter';
import {
    CheckoutSuccessComponent as SourceCheckoutSuccess
} from 'SourceComponent/CheckoutSuccess/CheckoutSuccess.component';

import { SUCCESS_MESSAGE } from './CheckoutSuccess.config';

/** @namespace Scandipwa/Component/CheckoutSuccess/Component */
export class CheckoutSuccessComponent extends SourceCheckoutSuccess {
    /**
     * Removed renderCreateAccountButton since we have such opportunity during previous step
     */
    render() {
        const {
            orderID,
            email,
            firstName
        } = this.props;

        return (
            <div block="CheckoutSuccess">
                <h3>{ __('Your order # is: %s', orderID) }</h3>
                <CheckoutSuccessNewsletterComponent
                  guestEmail={ email }
                  name={ firstName }
                />
                <p>{ SUCCESS_MESSAGE }</p>
                { this.renderButtons() }
            </div>
        );
    }
}

export default CheckoutSuccessComponent;
