import ChevronIcon from 'Component/ChevronIcon';
import { BOTTOM, TOP } from 'Component/ChevronIcon/ChevronIcon.config';
import ClickOutside from 'Component/ClickOutside';
import {
    FieldSelectComponent as PrinterbaseFieldSelectComponent
} from 'PrinterbaseComponent/PureForm/FieldSelect/FieldSelect.component';

/** @namespace Scandipwa/Component/PureForm/FieldSelect/Component */
export class FieldSelectComponent extends PrinterbaseFieldSelectComponent {
    renderOption = (option) => {
        const {
            id,
            label,
            subLabel,
            isPlaceholder = false,
            isHovered
        } = option;

        const {
            isExpanded,
            handleSelectListOptionClick,
            showSubscriptionPopup
        } = this.props;

        return (
            <li
              onClick={ showSubscriptionPopup }
              block="FieldSelect"
              elem="Option"
              mods={ { isExpanded, isPlaceholder, isHovered } }
              key={ id }
                /**
                 * Added 'o' as querySelector does not work with
                 * ids, that consist of numbers only
                 */
              id={ `o${id}` }
              role="menuitem"
                // eslint-disable-next-line react/jsx-no-bind
              onMouseDown={ () => handleSelectListOptionClick(option) }
                // eslint-disable-next-line react/jsx-no-bind
              onTouchStart={ () => handleSelectListOptionClick(option) }
                // eslint-disable-next-line react/jsx-no-bind
              onKeyPress={ () => handleSelectListOptionClick(option) }
              tabIndex={ isExpanded ? '0' : '-1' }
            >
                { label }
                { subLabel && (
                    <strong>
                        { ` ${subLabel}` }
                    </strong>
                ) }
            </li>
        );
    };

    renderOptions() {
        const {
            options,
            isExpanded
        } = this.props;

        return (
            <ul
              block="FieldSelect"
              elem="Options"
              role="menu"
              mods={ { isExpanded } }
            >
                { options.map(this.renderOption) }
            </ul>
        );
    }

    render() {
        const {
            attr: { id = '' } = {},
            isExpanded,
            handleSelectExpand,
            handleSelectListKeyPress,
            handleSelectExpandedExpand
        } = this.props;

        return (
            <ClickOutside onClick={ handleSelectExpandedExpand }>
                <div
                  id={ `${ id }_wrapper` }
                  block="FieldSelect"
                  mods={ { isExpanded } }
                  onClick={ handleSelectExpand }
                  onKeyPress={ handleSelectListKeyPress }
                  role="button"
                  tabIndex="0"
                  aria-label="Select dropdown"
                  aria-expanded={ isExpanded }
                >
                            <div block="FieldSelect" elem="Clickable">
                                { this.renderNativeSelect() }
                                <ChevronIcon direction={ isExpanded ? TOP : BOTTOM } />
                            </div>
                </div>
            </ClickOutside>
        );
    }
}

export default FieldSelectComponent;
