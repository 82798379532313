/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @package scandipwa/printerbase
 */
import SourceCheckoutDeliveryOption from 'PrinterbaseComponent/CheckoutDeliveryOption/CheckoutDeliveryOption.component';

/** @namespace Scandipwa/Component/CheckoutDeliveryOption/Component */
export class CheckoutDeliveryOption extends SourceCheckoutDeliveryOption {
    /**
     * Overridden in order to render 'Method Title' instead of 'Carrier Title'.
     * Carrier title does not hold the desired information because of "WebshopApp Matrix" extension
     * uses the 'Method Title' field
     */
    renderRow() {
        const {
            option: {
                method_title
            } = {}
        } = this.props;

        return (
            <div block="CheckoutDeliveryOption" elem="Row">
                <div block="CheckoutDeliveryOption" elem="Title">
                    { method_title }
                </div>
                { this.renderRate() }
                { this.renderPrice() }
                { this.renderAvailabilityMessage() }
            </div>
        );
    }
}

export default CheckoutDeliveryOption;
