import { EVENT_GTM_CHECKOUT_OPTION } from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

const selectedShippingMethod = (args, callback, instance) => {
    const { event, option: { method_title } = {} } = instance.props;
    const data = {
        option: method_title,
        step: 1,
        action: 'shipping'
    };

    if (!method_title) {
        return callback.apply(instance, args);
    }
    event(EVENT_GTM_CHECKOUT_OPTION, data);
    return callback.apply(instance, args);
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};

const containerProps = (args, callback, instance) => {
    const {
        event
    } = instance.props;

    return {
        ...callback(...args),
        event
    };
};

const defaultShippingMethodSelected = (args, callback, instance) => {
    const { event } = instance.props;
    const { selectedShippingMethod: { method_title } = {} } = instance.state;
    const data = {
        option: method_title,
        step: 1,
        action: 'shipping'
    };

    if (!method_title) {
        return callback.apply(instance, args);
    }

    event(EVENT_GTM_CHECKOUT_OPTION, data);
    return callback.apply(instance, args);
};

export default {
    'Component/CheckoutDeliveryOption/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Component/CheckoutDeliveryOption/Container': {
        'member-function': {
            containerProps,
            onOptionClick: selectedShippingMethod
        }
    },
    'Route/Checkout/Container': {
        'member-function': {
            componentWillUnmount: defaultShippingMethodSelected
        }
    }
};
