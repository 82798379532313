import PropTypes from 'prop-types';

import AddToBasket from 'Component/AddToBasket';
import { RELATED_POP_UP } from 'Component/AddToBasket/AddToBasket.config';
import { PROMO_POPUP_ID } from 'Component/AddToCart/AddToCart.config';
import ConsumablesPromo from 'Component/ConsumablesPromo';
import Popup from 'Component/Popup';
import {
    AddToCartComponent as PrinterbaseAddToCartComponent
} from 'PrinterbaseComponent/AddToCart/AddToCart.component';
import { ProductType } from 'Type/ProductList';

import './AddToCart.override.second.style';

/** @namespace Scandipwa/Component/AddToCart/Component */
export class AddToCartComponent extends PrinterbaseAddToCartComponent {
    static propTypes = {
        ...this.propTypes,
        isPrinter: PropTypes.bool.isRequired,
        relatedProductsData: PropTypes.objectOf(ProductType).isRequired,
        addToCart: PropTypes.func.isRequired,
        isLoaded: PropTypes.bool.isRequired
    };

    renderConsumablesPromo() {
        const { isPrinter, product } = this.props;

        if (!isPrinter) {
            return null;
        }

        return <ConsumablesPromo product={ product } />;
    }

    /**
     * Overridden to add cmsBlock
     */
    renderPopUp() {
        const {
            onClose,
            relatedProductsData,
            product,
            hidePromoPopup,
            deleteBodyMarginAndGoToCheckout,
            addToCart,
            isLoaded,
            isPrinter,
            showStockNotifyPopup
        } = this.props;

        if (!product) {
            return null;
        }

        if (!isPrinter) {
            return (
                <Popup
                  id={ PROMO_POPUP_ID }
                  onClose={ onClose }
                  clickOutside={ false }
                  contentMix={ { block: 'AddToCart', elem: 'Popup', mods: { isPrinter } } }
                  noHeader
                >
                    { this.renderConsumablesPromo() }
                    <div block="AddToCart" elem="PopupContent">
                        { this.renderPopUpContent() }
                    </div>
                </Popup>
            );
        }

        if (!relatedProductsData) {
            return null;
        }

        return (
            <Popup
              id={ RELATED_POP_UP }
              onClose={ onClose }
              clickOutside={ false }
              contentMix={ { block: 'AddToBasket', elem: 'Popup' } }
            >
                <AddToBasket
                  relatedProductsData={ relatedProductsData }
                  product={ product }
                  hidePromoPopup={ hidePromoPopup }
                  deleteBodyMarginAndGoToCheckou={ deleteBodyMarginAndGoToCheckout }
                  addToCart={ addToCart }
                  isLoaded={ isLoaded }
                  showStockNotifyPopup={ showStockNotifyPopup }
                />
            </Popup>
        );
    }

    /**
     * Overridden to change button title & render PromoPopup
     */
    render() {
        const {
            mix,
            layout,
            isDisabled,
            isAdding,
            isPopupVisible,
            addProductToCart
        } = this.props;

        const btnTitle = isAdding ? __('Adding...') : __('Add to basket');

        return (
            <>
              <button
                onClick={ addProductToCart }
                block="Button AddToCart"
                mix={ mix }
                mods={ { layout } }
                disabled={ isDisabled || isAdding }
              >
                { this.renderCartIcon() }
                <span>{ btnTitle }</span>
              </button>
              { isPopupVisible && this.renderPopUp() }
            </>
        );
    }
}

export default AddToCartComponent;
