import { PureComponent } from 'react';

import CmsBlock from 'Component/CmsBlock';

import { ADDNEW_PROMO } from './AddNewPromo.config';

import './AddNewPromo.style';

/** @namespace Scandipwa/Component/AddNewPromo/Component */
export class AddNewPromoComponent extends PureComponent {
    render() {
        return (
            <div block="AddNewPromo">
                <CmsBlock identifier={ ADDNEW_PROMO } />
            </div>
        );
    }
}

export default AddNewPromoComponent;
