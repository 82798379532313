import {
    MyAccountQuery as SourceMyAccountQuery
} from 'SourceQuery/MyAccount.query';
import { Field } from 'Util/Query';

/** @namespace Scandipwa/Query/MyAccount/Query */
export class MyAccountQuery extends SourceMyAccountQuery {
    _getAddressFields() {
        return [
            'id',
            'customer_id',
            'country_id',
            'street',
            'telephone',
            'postcode',
            'city',
            'firstname',
            'lastname',
            'middlename',
            'prefix',
            'suffix',
            'default_shipping',
            'default_billing',
            'vat_id',
            'company',
            this._getRegionField()
        ];
    }

    /**
     * Get ResetPassword mutation
     * @param {{token: String, password: String, password_confirmation: String, customer_id: Number}} options A object containing different aspects of query, each item can be omitted
     * @return {Field}
     * @memberof MyAccount
     */
    getResetPasswordMutation(options) {
        const {
            customer_id, token, password, password_confirmation
        } = options;

        return new Field('s_resetPassword')
            .addArgument('token', 'String!', token)
            .addArgument('password', 'String!', password)
            .addArgument('password_confirmation', 'String!', password_confirmation)
            .addArgument('customer_id', 'String!', customer_id)
            .addField('status');
    }

    getCreateAccountMutation(options) {
        const { customer, password, is_checkout } = options;

        return new Field('createCustomer')
            .addArgument('input', 'CustomerInput!', { ...customer, password, is_checkout })
            .addField(this._getCustomerField());
    }
}

export default new MyAccountQuery();
