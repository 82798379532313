import { connect } from 'react-redux';

import {
    LinkContainer as SourceLinkContainer,
    mapDispatchToProps,
    mapStateToProps
} from 'SourceComponent/Link/Link.container';
import { appendWithStoreCode } from 'Util/Url';

/** @namespace Scandipwa/Component/Link/Container */
export class LinkContainer extends SourceLinkContainer {
    getTo() {
        const { to } = this.props;

        if (typeof to === 'string') {
            // in case this URL is absolute or used for scroll, do not append store
            if (/^https?:\/\//.test(to) || /^#/.test(to)) {
                return to;
            }

            return appendWithStoreCode(to);
        }

        const { pathname } = to;

        return {
            pathname: appendWithStoreCode(pathname),
            ...to
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkContainer);
