import {
    getInitialState
} from 'SourceStore/ProductListInfo/ProductListInfo.reducer';
import {
    UPDATE_INFO_LOAD_STATUS,
    UPDATE_PRODUCT_LIST_INFO
} from 'Store/ProductListInfo/ProductListInfo.action';

export {
    getInitialState
};

export const SORT_BY_LABEL_ATTRIBUTES = ['manufacturer'];

/** @namespace Scandipwa/Store/ProductListInfo/Reducer/sortAttributeOptionsByLabel */
export const sortAttributeOptionsByLabel = (attribute_values, attribute_options) => {
    attribute_values.sort((a, b) => {
        if (attribute_options[a].label > attribute_options[b].label) {
            return 1;
        }
        if (attribute_options[a].label < attribute_options[b].label) {
            return -1;
        }

        return 0;
    });
};

/** @namespace Scandipwa/Store/ProductListInfo/Reducer/reduceFilters */
export const reduceFilters = (filters) => filters.reduce((co, item) => {
    const {
        request_var: attribute_code,
        name: attribute_label,
        position: attribute_position,
        filter_items,
        is_boolean,
        has_swatch
    } = item;

    if (!filter_items) {
        return '';
    }

    const { attribute_values, attribute_options } = filter_items.reduce((attribute, option) => {
        const { value_string } = option;
        const { attribute_values, attribute_options } = attribute;

        attribute_values.push(value_string);

        return {
            ...attribute,
            attribute_options: {
                ...attribute_options,
                [value_string]: option
            }
        };
    }, { attribute_values: [], attribute_options: {} });

    if (SORT_BY_LABEL_ATTRIBUTES.indexOf(attribute_code) !== -1) {
        sortAttributeOptionsByLabel(attribute_values, attribute_options);
    }

    return {
        ...co,
        [attribute_code]: {
            attribute_code,
            attribute_label,
            attribute_position,
            attribute_values,
            attribute_type: 'select',
            attribute_options,
            is_boolean,
            has_swatch
        }
    };
}, {});

/** @namespace Scandipwa/Store/ProductListInfo/Reducer/ProductListReducer */
export const ProductListReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;

    switch (type) {
    case UPDATE_PRODUCT_LIST_INFO: {
        const {
            selectedFilter,
            products: {
                filters: availableFilters = [],
                min_price,
                max_price,
                sort_fields: sortFields
            } = {}
        } = action;

        return {
            ...state,
            filters: reduceFilters(availableFilters),
            sortFields,
            minPrice: Math.floor(min_price),
            maxPrice: Math.ceil(max_price),
            isLoading: false,
            selectedFilter
        };
    }

    case UPDATE_INFO_LOAD_STATUS: {
        const { isLoading } = action;

        return {
            ...state,
            isLoading
        };
    }

    default:
        return state;
    }
};

export default ProductListReducer;
