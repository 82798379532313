import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps,
    UrlRewritesContainer as SourceUrlRewritesContainer
} from 'SourceRoute/UrlRewrites/UrlRewrites.container';
import { updateUrlRewrite } from 'SourceStore/UrlRewrites/UrlRewrites.action';

export {
    mapStateToProps
};

/** @namespace Scandipwa/Route/UrlRewrites/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    updateUrlRewrite: (urlResolver, urlParam) => dispatch(updateUrlRewrite(urlResolver || { notFound: true }, urlParam))
});

/** @namespace Scandipwa/Route/UrlRewrites/Container */
export class UrlRewritesContainer extends SourceUrlRewritesContainer {
    requestUrlRewrite() {
        const { requestUrlRewrite, updateUrlRewrite } = this.props;

        if (this.getIsLoading()) {
            const state = history?.state?.state || {};
            const typeKey = Object.keys(state).find((key) => UrlRewritesContainer.stateMapping[key]);

            if (typeKey && state[typeKey] !== true) {
                const id = state[typeKey]?.id || state[typeKey];
                const sku = state[typeKey]?.sku || null;
                const type = UrlRewritesContainer.stateMapping[typeKey];

                if (id) {
                    return updateUrlRewrite({ id, sku, type }, location.pathname);
                }
            }
        }

        return requestUrlRewrite(location.pathname);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UrlRewritesContainer);
