import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    RouterContainer as SourceRouterContainer
} from 'SourceComponent/Router/Router.container';
import {
    isMobile,
    isMobileClientHints,
    isUsingClientHints
} from 'Util/Mobile';
import { debounce } from 'Util/Request';

import { WINDOW_RESIZE_DEBOUNCE_DELAY } from './Router.config';

export {
    mapDispatchToProps
};
/** @namespace Scandipwa/Component/Router/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    description: state.MetaReducer.description,
    meta_keywords: state.MetaReducer.keywords,
    canonical_url: state.MetaReducer.canonical_url,
    jsonLDScripts: state.MetaReducer.jsonLDScripts,
    robots: state.MetaReducer.robots
});

/** @namespace Scandipwa/Component/Router/Container */
export class RouterContainer extends SourceRouterContainer {
    componentDidMount() {
        this.debouncedHandleResize = debounce(this.handleResize, WINDOW_RESIZE_DEBOUNCE_DELAY);

        window.addEventListener('resize', this.debouncedHandleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.debouncedHandleResize);
    }

    // Update to include extra meta tags
    componentDidUpdate(prevProps) {
        const { isLoading, updateMeta } = this.props;
        const { isLoading: prevIsLoading } = prevProps;

        if (!isLoading && isLoading !== prevIsLoading) {
            const {
                default_description,
                default_keywords,
                default_title,
                title_prefix,
                title_suffix,
                meta_title,
                status_code,
                description,
                meta_keywords,
                jsonLDScripts,
                canonical_url,
                robots
            } = this.props;

            const { value: metaTitle = meta_title } = meta_title;

            updateMeta({
                default_title,
                title: metaTitle,
                default_description,
                description,
                default_keywords,
                keywords: default_keywords,
                title_prefix,
                title_suffix,
                status_code,
                meta_keywords,
                jsonLDScripts,
                canonical_url,
                robots
            });
        }
    }

    handleResize = async () => {
        const { updateConfigDevice } = this.props;

        if (isUsingClientHints) {
            const { platform, model } = await isMobileClientHints.getDeviceData();
            const newDevice = {
                isMobile: isMobile.any(),
                android: isMobile.android(platform),
                ios: isMobile.iOS(platform),
                blackberry: isMobile.blackBerry(model),
                opera: isMobile.opera(model),
                safari: isMobile.safari(model),
                windows: isMobile.windows(model)
            };

            if (this.shouldUpdateDevice(newDevice)) {
                updateConfigDevice(newDevice);
            }
        } else {
            const newDevice = {
                isMobile: isMobile.any(),
                android: isMobile.android(),
                ios: isMobile.iOS(),
                blackberry: isMobile.blackBerry(),
                opera: isMobile.opera(),
                safari: isMobile.safari(),
                windows: isMobile.windows()
            };

            if (this.shouldUpdateDevice(newDevice)) {
                updateConfigDevice(newDevice);
            }
        }
    };

    shouldUpdateDevice(newDevice) {
        const { device } = this.props;

        return Object.keys(newDevice).some((key) => newDevice[key] !== device[key]);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouterContainer);
