/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/klaviyo
 * @author  Jort Geurts <info@scandiweb.com>
 */

export { default } from './KlaviyoAnalytics.container';
