import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import InkTonerSearchField from 'Component/InkTonerSearchField';
import { DeviceType } from 'Type/Device';

import './InkTonerSearchFieldBlock.style';

/** @namespace Scandipwa/Component/InkTonerSearchFieldBlock/Component */
export class InkTonerSearchFieldBlockComponent extends PureComponent {
    static propTypes = {
        onSearchOutsideClick: PropTypes.func.isRequired,
        onSearchBarFocus: PropTypes.func.isRequired,
        onSearchBarChange: PropTypes.func.isRequired,
        onClearSearchButtonClick: PropTypes.func.isRequired,
        searchCriteria: PropTypes.string.isRequired,
        isSearchFocused: PropTypes.bool.isRequired,
        device: DeviceType.isRequired,
        name: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        showPopup: PropTypes.func.isRequired
    };

    renderSearchField() {
        const {
            onSearchOutsideClick,
            onSearchBarFocus,
            onSearchBarChange,
            onClearSearchButtonClick,
            searchCriteria,
            isSearchFocused,
            device,
            showPopup
        } = this.props;

        //  isSuppliesSearch field switches Klevu search component to
        //  looking for suppliers using aditional API key
        return (
            <InkTonerSearchField
              key="lookingForToner"
              searchCriteria={ searchCriteria }
              onSearchOutsideClick={ onSearchOutsideClick }
              onSearchBarFocus={ onSearchBarFocus }
              onSearchBarChange={ onSearchBarChange }
              onClearSearchButtonClick={ onClearSearchButtonClick }
              isActive={ isSearchFocused }
              device={ device }
              isSuppliesSearch
              searchButtonText="Search"
              showPopup={ showPopup }
            />
        );
    }

    renderTitle() {
        const { title, name = {} } = this.props;

        if (!title) {
            const pageTitle = name === 'Ink Toner' ? '' : name;

            return <h1>{ `${__('Find')} ${pageTitle} ${__('Ink Toner')}` }</h1>;
        }

        return <h1>{ title }</h1>;
    }

    render() {
        return (
            <div block="InkTonerSearchField">
                { this.renderTitle() }
                <div block="InkTonerSearchField" elem="ContentWrapper">
                    <h2>{ __('Use our ink and cartridge finder') }</h2>
                    { this.renderSearchField() }
                </div>
            </div>

        );
    }
}

export default InkTonerSearchFieldBlockComponent;
