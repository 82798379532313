/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable max-lines */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Image from 'Component/Image';
import KlevuBanner from 'Component/KlevuBanner';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import Overlay from 'Component/Overlay';
import media from 'Util/Media';
import {
    formatPrice,
    roundPrice
} from 'Util/Price';

import {
    KLEVU_SEARCH_CATEGORY_ID,
    KLEVU_SEARCH_OVERLAY_ID,
    KLEVU_SEARCH_PRODUCTS_ID,
    KLEVU_SEARCH_SUPPLIES_OVERLAY_ID
} from './KlevuSearch.config';

import './KlevuSearch.style';

/** @namespace KlevuSearch/Component/KlevuSearch/Component */
export class KlevuSearchComponent extends PureComponent {
    static propTypes = {
        // eslint-disable-next-line react/forbid-prop-types
        klevuSearchResult: PropTypes.object.isRequired,
        base_link_url: PropTypes.string.isRequired,
        secure_base_link_url: PropTypes.string.isRequired,
        searchCriteria: PropTypes.string.isRequired,
        popularSearch: PropTypes.arrayOf(PropTypes.string).isRequired,
        isEmptyResponse: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool.isRequired,
        isSuggestions: PropTypes.bool.isRequired,
        isCategories: PropTypes.bool.isRequired,
        device: PropTypes.shape({ isMobile: PropTypes.bool }).isRequired,
        isSuppliesSearch: PropTypes.bool.isRequired,
        isCMS: PropTypes.bool.isRequired
    };

    getLinkTo(url, record) {
        const { isSuppliesSearch = false } = this.props;

        if (isSuppliesSearch) {
            return url;
        }

        return {
            pathname: url,
            state: this.getLinkStateFromQueryRecord(record)
        };
    }

    getLinkStateFromQueryRecord(record) {
        const { id } = record;

        const isCategoryRecord = id.indexOf('categoryid_') !== -1
            || id.indexOf('CAT') !== -1;

        if (isCategoryRecord) {
            return this.getCategoryStateFromQueryRecord(record);
        }

        return this.getProductStateFromQueryRecord(record);
    }

    getCategoryStateFromQueryRecord({
        id,
        name,
        imageUrl,
        categoryPageTitle = ''
    }) {
        const categoryId = parseInt(id?.replace('categoryid_', ''), 10) || null;
        const categoryName = categoryPageTitle === '' ? name : categoryPageTitle;

        return {
            category: categoryId,
            title: categoryName,
            categoryData: {
                image: media(imageUrl?.replace('media/', ''))
            }
        };
    }

    getProductStateFromQueryRecord({
        id,
        sku,
        name,
        image
    }) {
        const imgUrl = image?.replace('/needtochange', '') || '';

        return {
            product: {
                id: parseInt(id, 10) || null,
                sku: sku || null,
                name: name || '',
                image: {
                    path: this.getPathnameFromUrl(imgUrl),
                    url: imgUrl
                }
            }
        };
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    getPathnameFromUrl(url) {
        const { base_link_url, secure_base_link_url } = this.props;

        if (!url) {
            return '';
        }

        return [secure_base_link_url, base_link_url].reduce((url, baseLinkUrl) => url.replace(baseLinkUrl, ''), url);
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    getProductDataFromQueryRecord({
        id,
        sku,
        name,
        image
    }) {
        const imgUrl = image?.replace('/needtochange', '') || '';

        return {
            id: parseInt(id, 10),
            sku,
            name,
            image: {
                path: this.getPathnameFromUrl(imgUrl),
                url: imgUrl
            }
        };
    }

    renderSuggestion(item) {
        if (!item || !Object.keys(item).length) {
            return null;
        }
        const { suggest } = item;

        const withoutHtmlSuggest = suggest && suggest.replace(/<\/?b>/g, '');

        return (
            <Link
              to={ `/search/${withoutHtmlSuggest}` }
              key={ suggest }
            >
                { withoutHtmlSuggest }
            </Link>
        );
    }

    renderSuggestions() {
        const {
            isCategories,
            isSuggestions,
            klevuSearchResult: { suggestionResults = [] },
            isEmptyResponse
        } = this.props;

        const { suggestions } = suggestionResults.length > 0 ? suggestionResults[0] : { suggestions: [] };

        if (isCategories) {
            if (!isSuggestions || !suggestionResults.length || isEmptyResponse || suggestions.length === 0) {
                return null;
            }

            return (
                <div block="KlevuSearch" elem="Suggestions">
                    <span block="KlevuSearch" elem="BlockTitle">
                        { __('Suggestions') }
                    </span>
                    { suggestions.map(this.renderSuggestion) }
                </div>
            );
        }

        if (!isSuggestions) {
            return null;
        }

        if (isEmptyResponse || !suggestionResults.length) {
            return null;
        }

        if (!suggestionResults.length) {
            return null;
        }

        return (
            <div block="KlevuSearch" elem="Suggestions">
                <span block="KlevuSearch" elem="BlockTitle">
                    { __('Suggestions') }
                </span>
                { suggestionResults.map(this.renderSuggestion) }
            </div>
        );
    }

    renderCategoriesView() {
        const {
            klevuSearchResult: {
                queryResults,
                suggestionResults
            },
            isSuggestions
        } = this.props;

        if (!queryResults?.length) {
            return this.renderNoResult();
        }

        // array index 2 means that in query results there always is productList always third one
        const { records, meta: { totalResultsFound } } = queryResults[2];
        const { suggestions } = suggestionResults.length > 0 ? suggestionResults[0] : { suggestions: [] };
        const categoryRecords = queryResults[0].records.length;

        return (
            <div
              block="KlevuSearch"
              elem="CategoriesView"
              mods={ { isSuggestions: isSuggestions && Boolean(suggestions.length) } }
            >
                { categoryRecords !== 0 && this.renderAdditional() }
                <div
                  block="KlevuSearch"
                  elem="CategoriesGrid-Wrapper"
                >
                    <div
                      block="KlevuSearch"
                      elem="CategoriesGrid"
                      mods={ { isSuggestions: isSuggestions && Boolean(suggestions.length) } }
                    >
                        { records.map((item) => this.renderCategoryView(item)) }
                    </div>
                    { totalResultsFound > 0 && this.renderAllResultsLink() }
                </div>
            </div>
        );
    }

    renderAllResultsLink() {
        const {
            searchCriteria
        } = this.props;

        return (
            <div block="KlevuSearch" elem="AllResultsLink">
                <Link to={ `/search/${searchCriteria}` }>{ __('View all search results') }</Link>
            </div>
        );
    }

    renderCategoryView(record) {
        const {
            id,
            url,
            name,
            image,
            price,
            salePrice,
            storeBaseCurrency
        } = record;
        const isProductWithDiscount = parseFloat(salePrice) !== parseFloat(price);

        return (
            <div block="KlevuSearch" elem="CategoryView">
                <Link
                  to={ url }
                  key={ id }
                >
                    <Image
                      mix={ { block: 'KlevuSearch', elem: 'ProductImage' } }
                      src={ image && image.replace('/needtochange', '') }
                    />
                    <p block="KlevuSearch" elem="CategoryTitle">{ __(name) }</p>
                    <div block="KlevuSearch" elem="PriceBlock">
                        <div block="KlevuSearch" elem="ProductPrice" mods={ { isSpecialPrice: isProductWithDiscount } }>
                            { isProductWithDiscount && (
                                <div block="KlevuSearch" elem="ProductOldPrice">
                                    { __('%s', formatPrice(roundPrice(price), storeBaseCurrency)) }
                                </div>
                            ) }
                            { __('%s', formatPrice(roundPrice(salePrice), storeBaseCurrency)) }
                        </div>
                        <div block="KlevuSearch" elem="VatLabel">
                            { __('(inc. VAT)') }
                        </div>
                    </div>
                </Link>
            </div>
        );
    }

    renderCategory(category) {
        const { secure_base_link_url } = this.props;
        const {
            name, url, imageUrl, categoryPageTitle = ''
        } = category;

        const imageSrc = secure_base_link_url + imageUrl;
        const categoryName = categoryPageTitle === '' ? name : categoryPageTitle;

        return (
            <Link
              block="KlevuSearch"
              elem="Category"
              to={ url }
            >
                <Image
                  mix={ { block: 'KlevuSearch', elem: 'CategoryImage' } }
                  src={ imageSrc || '' }
                  isPlaceholder={ !imageUrl }
                />
                { categoryName }
            </Link>
        );
    }

    renderCategories() {
        const {
            klevuSearchResult: {
                queryResults = []
            }
        } = this.props;

        const categories = queryResults.find(({ id }) => id === KLEVU_SEARCH_CATEGORY_ID);
        const { records = [] } = categories || {};

        if (!records.length) {
            return null;
        }

        return (
            <div block="KlevuSearch" elem="Categories">
                <span block="KlevuSearch" elem="BlockTitle">
                    { __('Categories') }
                </span>
                { records.map(this.renderCategory.bind(this)) }
            </div>
        );
    }

    renderPopularSearch(term) {
        return (
            <li>
                <Link
                  to={ `/search/${term}` }
                >
                    { term }
                </Link>
            </li>
        );
    }

    renderPopularSearches() {
        const { isEmptyResponse, popularSearch } = this.props;

        if (!isEmptyResponse) {
            return null;
        }

        return (
            <div block="KlevuSearch" elem="Categories">
                <span block="KlevuSearch" elem="BlockTitle">
                    { __('Popular searches') }
                </span>
                <ul>
                    { popularSearch.map(this.renderPopularSearch) }
                </ul>
            </div>
        );
    }

    renderCmsRecords() {
        const {
            klevuSearchResult: {
                queryResults
            }
        } = this.props;

        if (!queryResults?.length) {
            return this.renderNoResult();
        }

        const cmsRecords = queryResults[1]?.records[0];

        return (
            <div block="KlevuSearch" elem="CmsRecords">
                <span block="KlevuSearch" elem="CmsTitle">
                    { __('Pages') }
                </span>
                <Link
                  to={ cmsRecords.url }
                >
                    { __(`${cmsRecords.name}`) }

                </Link>
            </div>
        );
    }

    renderAdditional() {
        const {
            klevuSearchResult: {
                queryResults
            },
            isCMS
        } = this.props;

        const renderCMS = queryResults[1].records.length > 0 && isCMS;

        return (
            <div block="KlevuSearch" elem="AdditionalWrapper">
                { this.renderCategories() }
                { this.renderPopularSearches() }
                { this.renderSuggestions() }
                { renderCMS && this.renderCmsRecords() }
                { this.renderAllResultsLink() }
            </div>
        );
    }

    renderProductAvailability(warehouse_filter) {
        const stockInformation = warehouse_filter
            ? 'Rīga Malēju'
            : __('Delivery');
        const isNotMainWarehouse = warehouse_filter && !warehouse_filter.includes('Rīga Malēju');

        return (
            <div block="KlevuSearch" elem="ProductAvailabilityWrapper">
                <span
                  block="KlevuSearch"
                  elem="ProductAvailability"
                  mods={ { isInStock: !!warehouse_filter, isNotMainWarehouse } }
                >
                    { stockInformation }
                </span>
            </div>
        );
    }

    renderProduct(item) {
        const {
            imageUrl,
            id,
            name,
            MANUFACTURER_NAME,
            sku,
            warehouse_filter,
            url
        } = item;
        const { secure_base_link_url } = this.props;

        return (
            <li key={ id }>
                <Link
                  block="KlevuSearch"
                  elem="ProductCard"
                  to={ {
                      pathname: this.getPathnameFromUrl(url),
                      state: {
                          product: this.getProductDataFromQueryRecord(item)
                      }
                  } }
                >
                    <div block="KlevuSearch" elem="ProductImageWrapper">
                        <Image
                          mix={ { block: 'KlevuSearch', elem: 'ProductImage' } }
                          src={ imageUrl && imageUrl.replace('/needtochange', '') }
                        />
                    </div>
                    <div block="KlevuSearch" elem="ProductInformation">
                        <span block="KlevuSearch" elem="ProductName">
                            { name }
                        </span>
                        <span block="KlevuSearch" elem="ProductSkuAndManufactory">
                            { `${MANUFACTURER_NAME ? `${MANUFACTURER_NAME} /` : ''} ${sku}` }
                        </span>
                        { this.renderProductAvailability(warehouse_filter) }
                    </div>
                </Link>
            </li>
        );
    }

    renderSuppliersItem(item) {
        const {
            imageUrl,
            id,
            name,
            url,
            price,
            storeBaseCurrency
        } = item;

        const productPrice = parseFloat(price);

        return (
            <li
              key={ id }
              block="KlevuSuppliersSearch"
              elem="SupplierItem"
            >
                <Link
                  block="KlevuSuppliersSearch"
                  elem="ProductCard"
                  to={ this.getLinkTo(url, item) }
                >
                    <div block="KlevuSuppliersSearch" elem="ProductImageWrapper">
                        <Image
                          mix={ { block: 'KlevuSuppliersSearch', elem: 'ProductImage' } }
                          src={ imageUrl && imageUrl.replace('/needtochange', '') }
                        />
                    </div>
                    <div block="KlevuSuppliersSearch" elem="ProductInformation">
                        <span block="KlevuSearch" elem="ProductName">
                            { name }
                        </span>
                        { productPrice > 0
                            && (
                                <div block="KlevuSuppliersSearch" elem="PriceBlock">
                                    <div block="KlevuSuppliersSearch" elem="SupplierPrice">
                                        { formatPrice(roundPrice(productPrice), storeBaseCurrency) }
                                    </div>
                                    <div block="KlevuSuppliersSearch" elem="VatLabel">
                                        { __('(inc. VAT)') }
                                    </div>
                                </div>

                            ) }
                    </div>
                </Link>
            </li>
        );
    }

    renderNoResult() {
        const { searchCriteria } = this.props;

        if (!searchCriteria || (searchCriteria && searchCriteria.length <= 2)) {
            return null;
        }

        return (
            <span block="KlevuSearch" elem="NoResult">
                { __('We\'re Sorry, No result found for term \'%s\'', searchCriteria) }
            </span>
        );
    }

    renderProducts() {
        const { klevuSearchResult: { queryResults = [] }, searchCriteria } = this.props;

        const products = queryResults.find(({ id }) => id === KLEVU_SEARCH_PRODUCTS_ID);
        const { records: items = [] } = products || [];

        if (!items.length) {
            return this.renderNoResult();
        }

        return (
            <div block="KlevuSearch" elem="Products">
                <div block="KlevuSearch" elem="ProductsTitles">
                    <span>{ __('Products') }</span>
                    <Link to={ `/search/${searchCriteria}` }>{ __('See all') }</Link>
                </div>
                <ul block="KlevuSearch" elem="ProductsResult">
                    { items.map(this.renderProduct.bind(this)) }
                </ul>
            </div>
        );
    }

    renderSuppliersItems() {
        const { klevuSearchResult: { queryResults = [] } } = this.props;

        const products = queryResults.find(({ id }) => id === KLEVU_SEARCH_PRODUCTS_ID);
        const { records: items = [] } = products || [];
        const { meta: { totalResultsFound } } = products;

        if (!items.length) {
            return this.renderNoResult();
        }

        return (
            <div block="KlevuSearch" elem="SuppliersWrap">
                <div block="KlevuSearch" elem="SppliersResultCount">
                    { __('%s product%s found', totalResultsFound, totalResultsFound > 1 ? 's' : '') }
                </div>
                <ul block="KlevuSearch" elem="SuppliersList">
                    { items.map(this.renderSuppliersItem.bind(this)) }
                </ul>
            </div>
        );
    }

    renderContent() {
        const {
            klevuSearchResult, isCategories, isCMS, searchCriteria
        } = this.props;

        if (!klevuSearchResult) {
            return null;
        }

        if (isCategories) {
            return (
                <div block="KlevuSearch" elem="Wrapper">
                    <KlevuBanner search={ searchCriteria } />
                    { this.renderCategoriesView() }
                </div>
            );
        }

        return (
            <div block="KlevuSearch" elem="Wrapper">
                { this.renderAdditional() }
                { isCMS && this.renderCmsRecords() }
                { this.renderProducts() }
            </div>
        );
    }

    renderSuppliersContent() {
        const { klevuSearchResult } = this.props;

        if (!klevuSearchResult) {
            return null;
        }

        return (
            <div block="KlevuSuppliersSearch" elem="Wrapper">
                { this.renderSuppliersItems() }
            </div>
        );
    }

    renderSuppliesSearch() {
        const {
            isLoading, device: { isMobile }, isSuppliesSearch, searchCriteria = {}
        } = this.props;
        const overlayId = isSuppliesSearch ? KLEVU_SEARCH_SUPPLIES_OVERLAY_ID : KLEVU_SEARCH_OVERLAY_ID;

        if (isMobile && (!searchCriteria || searchCriteria.length === 0)) {
            return '';
        }

        if (isMobile) {
            return (
                <div
                  mix={ { block: 'KlevuSuppliersSearch' } }
                  isRenderInPortal={ false }
                >
                    <Loader isLoading={ isLoading } />
                    { this.renderSuppliersContent() }
                </div>
            );
        }

        return (
            <Overlay
              id={ overlayId }
              mix={ { block: 'KlevuSuppliersSearch' } }
              isRenderInPortal={ false }
            >
                <Loader isLoading={ isLoading } />
                { this.renderSuppliersContent() }
            </Overlay>
        );
    }

    render() {
        const { isLoading, isSuppliesSearch } = this.props;

        const overlayId = isSuppliesSearch ? KLEVU_SEARCH_SUPPLIES_OVERLAY_ID : KLEVU_SEARCH_OVERLAY_ID;

        if (isSuppliesSearch) {
            return this.renderSuppliesSearch();
        }

        return (
            <Overlay
              id={ overlayId }
              mix={ { block: 'KlevuSearch' } }
              isRenderInPortal={ false }
            >
                <Loader isLoading={ isLoading } />
                { this.renderContent() }
            </Overlay>
        );
    }
}

export default KlevuSearchComponent;
