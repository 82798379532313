/* eslint-disable spaced-comment */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { MyAccountAddressForm as SourceMyAccountAddressFormComponent }
from 'SourceComponent/MyAccountAddressForm/MyAccountAddressForm.component';

import myAccountAddressForm from './MyAccountAddressForm.form';

/** @namespace Scandipwa/Component/MyAccountAddressForm/Component */
export class MyAccountAddressFormComponent extends SourceMyAccountAddressFormComponent {
    //#region GETTERS
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    get fieldMap() {
        const {
            address,
            countries,
            addressLinesQty,
            regionDisplayAll,
            showVatNumber,
            defaultCountry,
            company
        } = this.props;

        const {
            availableRegions,
            isStateRequired,
            countryId
        } = this.state;

        return myAccountAddressForm({
            company,
            address,
            countries,
            addressLinesQty,
            regionDisplayAll,
            showVatNumber,
            defaultCountry,
            availableRegions,
            isStateRequired,
            countryId,
            ...address
        }, {
            onCountryChange: this.onCountryChange,
            onZipcodeBlur: this.onZipcodeBlur
        });
    }
}

export default MyAccountAddressFormComponent;
