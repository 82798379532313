// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { klevuBannerType } from '../../../packages/@scandipwa/klevu-search/src/types/KlevuSearch';

import './KlevuBanner.style';

/** @namespace Scandipwa/Component/KlevuBanner/Component */
export class KlevuBannerComponent extends PureComponent {
    static propTypes = {
        klevuBanner: klevuBannerType.isRequired
    };

    renderBanner() {
        const { klevuBanner: { bannerImg, redirectUrl, bannerAltTag } = {} } = this.props;

        return (
            <div
              block="KlevuBanner"
              mix={ { block: 'ContentWrapper' } }
            >
                <a
                  href={ redirectUrl }
                >
                    <img
                      block="KlevuBanner"
                      elem="BannerImage"
                      src={ bannerImg }
                      alt={ bannerAltTag }
                    />
                </a>
            </div>
        );
    }

    render() {
        return (
            <>
                { this.renderBanner() }
            </>
        );
    }
}

export default KlevuBannerComponent;
