/**
 * @category  Printerbase
 * @author    Scandiweb <info@scandiweb.com>
 * @namespace Scandipwa/Component/CheckoutAddressForm/CheckoutAddressFormInstance
 */

import CheckoutAddressFormInstance from 'Component/CheckoutAddressForm/CheckoutAddressFormInstance';

const onSameAsBillingChangePlugin = (args, callback /* , instance */) => {
    callback(...args);

    const checkoutPaymentsInstance = CheckoutAddressFormInstance.getCheckoutPaymentsInstance();

    if (!checkoutPaymentsInstance) {
        return;
    }

    if (checkoutPaymentsInstance.isAddressDependantPaymentCodeSelected()) {
        checkoutPaymentsInstance.selectDefaulPaymentMethod();
    }
};

export default {
    'Scandiweb/PrinterbaseTheme/Route/Checkout/Container': {
        'member-function': {
            onSameAsBillingChange: onSameAsBillingChangePlugin
        }
    }
};
