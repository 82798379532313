import {
    CheckoutBillingComponent as PrinterbaseCheckoutBillingComponent
} from 'PrinterbaseComponent/CheckoutBilling/CheckoutBilling.component';

/** @namespace Scandipwa/Component/CheckoutBilling/Component */
export class CheckoutBillingComponent extends PrinterbaseCheckoutBillingComponent {
    /**
     * Overridden to remove Registration fields
     */
    render() {
        return (
            <div block="CheckoutBilling" elem="Wrapper">
                { this.renderAddresses() }
                { this.renderRelatedCheckbox() }
                { this.renderPopup() }
            </div>
        );
    }
}

export default CheckoutBillingComponent;
