import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ExpandableContent from 'Component/ExpandableContent';
import Html from 'Component/Html';
import { ChildrenType } from 'Type/Common';

import './CategoryFaqBlock.style';
/** @namespace Scandipwa/Component/CategoryFaqBlock/Component */
export class CategoryFaqBlockComponent extends PureComponent {
    static propTypes = {
        cmsBlock: PropTypes.shape({
            identifier: PropTypes.string,
            content: PropTypes.string,
            disabled: PropTypes.bool
        }),
        blockType: PropTypes.string,
        children: ChildrenType
    };

    static defaultProps = {
        cmsBlock: {},
        children: [],
        blockType: ''
    };

    renderPlaceholder() {
        const {
            children
        } = this.props;

        if (children.length) {
            return children;
        }

        return null;
    }

    renderBlock() {
        const {
            cmsBlock: {
                content: blockContent
            }
        } = this.props;

        const content = this.getStringFromBetween(blockContent, '</h4>', '<h4>');
        const faqTitles = content.map((title) => title[1]);
        const faqContents = content.map((faqContent) => faqContent[0]);
        const title = faqContents.shift();

        return (
            <div>
                <Html content={ title } />
                { faqTitles.map((title, index) => {
                    if (!title) {
                        return null;
                    }

                    const finalTitle = this.getStringFromBetween(title, '<strong>', '</strong>');

                    return (
                        <ExpandableContent
                          heading={ finalTitle || '' }
                          renderIconOnDesktop
                          isArrow
                          mix={ { block: 'FaqContentTab', elem: 'Content' } }
                        >
                            <div block="FaqContentTab" elem="Text">
                                <Html content={ faqContents[index].replace('<div class="middle-text">', '') || '' } />
                            </div>
                        </ExpandableContent>
                    );
                }) }
            </div>
        );
    }

    getStringFromBetween = (string, sub1, sub2) => {
        const result = string.split(sub1).map((string) => string.split(sub2));
        return result;
    };

    render() {
        const {
            cmsBlock: {
                identifier,
                disabled
            },
            blockType
        } = this.props;

        if (disabled) {
            return null;
        }

        if (!identifier) {
            return this.renderPlaceholder();
        }

        return (
            <div
              block="CmsBlock"
              elem="Wrapper"
              mods={ { type: blockType } }
            >
                <div block="FaqContentTab">
                    { this.renderBlock() }
                </div>
            </div>
        );
    }
}

export default CategoryFaqBlockComponent;
