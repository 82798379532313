/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Suspense } from 'react';

import ClickOutside from 'Component/ClickOutside';
import Popup from 'Component/Popup';
import { SEARCH_MOBILE_POPUP } from 'Component/SearchField/SearchField.config';
import SearchIcon from 'Component/SearchIcon';

import KlevuSearch from '../component/KlevuSearch';

import './SearchField.override.style.plugin';

const render = (args, callback, instance) => {
    const {
        isVisible,
        isActive
    } = instance.props;

    return (
        <div block="SearchField" mods={ { isVisible, isActive } }>
            <ClickOutside onClick={ instance.closeSearch }>
                <div block="SearchField" elem="Wrapper">
                    { instance.renderSearch() }
                </div>
            </ClickOutside>
        </div>
    );
};

const renderKlevuMobilePopup = (args, callback, instance) => {
    const {
        searchCriteria,
        onSearchBarFocus,
        isActive,
        isSuppliesSearch,
        device: { isMobile }
    } = instance.props;
    const { renderOverlayFallback } = instance;

    return (
        <Popup
          id={ SEARCH_MOBILE_POPUP }
          mix={ { block: 'SearchField', elem: 'Popup' } }
        >
            <div
              block="SearchField"
              elem="SearchInnerWrapper"
            >
                <div
                  block="SearchField"
                  elem="Input-Wrapper"
                >
                    <input
                      block="SearchField"
                      elem="Input"
                      onFocus={ onSearchBarFocus }
                      onChange={ instance.handleChange }
                      onKeyDown={ instance.onSearchEnterPress }
                      value={ searchCriteria }
                      mods={ { isActive } }
                      placeholder={ __('Search by Printer or Keyword...') }
                      autoComplete="off"
                      aria-label={ __('Search') }
                    />
                    <div
                      block="SearchField"
                      elem="SearchIcon"
                      role="button"
                      tabIndex="0"
                      aria-label={ __('Search') }
                    >
                        <SearchIcon />
                    </div>
                </div>
                <Suspense fallback={ renderOverlayFallback }>
                    <KlevuSearch
                      searchCriteria={ searchCriteria }
                      isSuppliesSearch={ isSuppliesSearch }
                      isMobile={ isMobile }
                    />
                </Suspense>
            </div>
        </Popup>
    );
};

const aroundOnSearchEnterPress = (args, callback, instance) => {
    const [e] = args;
    const { isSuppliesSearch = false } = instance.props;

    // Disable serchpage for consumers search
    if (e.key === 'Enter' && isSuppliesSearch) {
        return;
    }

    return callback.apply(instance, args);
};

const aroundOnSearchButtonPress = (args, callback, instance) => {
    const { isSuppliesSearch = false } = instance.props;

    // Disable serchpage for consumers search
    if (isSuppliesSearch) {
        return;
    }

    return callback.apply(instance, args);
};

const renderSearch = (args, callback, instance) => {
    const {
        searchCriteria,
        onSearchBarFocus,
        isActive,
        isSuppliesSearch,
        device: { isMobile }
    } = instance.props;

    const { renderOverlayFallback } = instance;

    if (isMobile && isSuppliesSearch) {
        return instance.renderMobileSearchButton();
    }

    return (
        <div
          block="SearchField"
          elem="SearchInnerWrapper"
        >
            <input
              ref={ instance.searchBarRef }
              block="SearchField"
              elem="Input"
              onFocus={ onSearchBarFocus }
              onChange={ instance.handleChange }
              onKeyDown={ instance.onSearchEnterPress }
              value={ searchCriteria }
              mods={ { isActive } }
              placeholder={ isMobile ? __('Search by Printer or Keyword...') : __('Enter your printer model or cartridge code...') }
              autoComplete="off"
              aria-label={ __('Search') }
            />
            { instance.renderSearchIcon() }
            <Suspense fallback={ renderOverlayFallback }>
                <KlevuSearch
                  searchCriteria={ searchCriteria }
                  isSuppliesSearch={ isSuppliesSearch }
                />
            </Suspense>
        </div>
    );
};

export default {
    'Component/SearchField/Component': {
        'member-function': {
            render,
            renderSearch,
            onSearchEnterPress: aroundOnSearchEnterPress,
            onSearchButtonPress: aroundOnSearchButtonPress,
            renderMobilePopup: renderKlevuMobilePopup
        }
    }
};
