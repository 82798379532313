import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import CategoryReducer from 'Store/Category/Category.reducer';
import { CategoryTreeType } from 'Type/Category';
import { withReducers } from 'Util/DynamicReducer';

import LookingForTop5ButtonComponent from './LookingForTop5Button.component';

/** @namespace Scandipwa/Component/LookingForTop5Button/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    category: state.CategoryReducer.category
});

/** @namespace Scandipwa/Component/LookingForTop5Button/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Scandipwa/Component/LookingForTop5Button/Container */
export class LookingForTop5ButtonContainer extends PureComponent {
    static propTypes = {
        category: CategoryTreeType.isRequired
    };

    containerProps() {
        const { category } = this.props;

        return {
            category
        };
    }

    render() {
        return (
            <LookingForTop5ButtonComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default withReducers({
    CategoryReducer
})(withRouter(
    connect(mapStateToProps, mapDispatchToProps)(LookingForTop5ButtonContainer)
));
