/**
 * Mageplaza Social Login compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import MyAccountLoader from '../component/MyAccountLoader';
import SocialLoginButtons from '../component/SocialLoginButtons';

const renderSocialLoginButtons = () => (
    <SocialLoginButtons />
);

const addMyAccountLoaderToRender = (args, callback, _instance) => (
    <>
        { callback(...args) }
        <MyAccountLoader />
    </>
);

export const config = {
    'Component/MyAccountSignIn/Component': {
        'member-function': {
            renderSignInForm: addMyAccountLoaderToRender
        }
    },
    'Route/LoginAccount/Component': {
        'member-function': {
            renderSocialLoginButtons
        }
    },
    'Component/Popup/Component': {
        'member-function': {
            renderSocialLoginButtons
        }
    }
};

export default config;
