/**
 * Wyomind google customer reviews compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { updateDeliveryCountry } from '../store/Config/OrderConfig.action';

const mapDispatchToProps = (args, callback, _instance) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        updateDeliveryCountry: (countryId) => dispatch(updateDeliveryCountry(countryId))
    };
};

const updateDeliveryCountryInOrderConfig = (args, callback, instance) => {
    const { billing_address: { country_id } } = args[0];
    const { updateDeliveryCountry } = instance.props;
    updateDeliveryCountry(country_id);

    return callback(...args);
};

export const config = {
    'Route/Checkout/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Route/Checkout/Container': {
        'member-function': {
            savePaymentMethodAndPlaceOrder: updateDeliveryCountryInOrderConfig
        }
    }
};

export default config;
