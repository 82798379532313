import { createPortal } from 'react-dom';

import SourceMetaComponent from 'SourceComponent/Meta/Meta.component';

/** @namespace Scandipwa/Component/Meta/Component */
export class MetaComponent extends SourceMetaComponent {
    renderJSONLD() {
        const { jsonLDScripts } = this.props;

        const scripts = (jsonLDScripts || [])?.length
            ? jsonLDScripts.map((value) => <script type="application/ld+json">{ value }</script>)
            : null;

        if (!scripts) {
            return null;
        }

        const newScripts = (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
                { scripts }
            </>
        );

        return createPortal(
            { ...newScripts },
            document.getElementsByTagName('head')[0]
        );
    }

    renderTitle() {
        const {
            default_title,
            title_prefix,
            title_suffix,
            title
        } = this.props;

        const titlePrefix = title_prefix ? `${ title_prefix } | ` : '';
        const titleSuffix = title_suffix ? ` ${ title_suffix }` : '';
        const { value = title } = title;

        return (
            <title>
                { `${ titlePrefix }${ value || default_title }${ titleSuffix }` }
            </title>
        );
    }

    /**
     * Overridden to avoid duplicated paths
     */
    renderCanonical() {
        const { canonical_url } = this.props;

        if (!canonical_url) {
            return null;
        }

        if (canonical_url.match(/\.html/g) && canonical_url.match(/\.html/g).length === 2) {
            const identifier = '.html';
            const new_canonical_url = canonical_url?.slice(0, canonical_url?.indexOf(identifier) + identifier.length);
            return (
                <link rel="canonical" href={ new_canonical_url } />
            );
        }

        return (
            <link rel="canonical" href={ canonical_url } />
        );
    }

    renderOgTagsMeta() {
        const { og_tags_metadata = [] } = this.props;

        return (
            <>
                { og_tags_metadata.map((tag) => {
                    const {
                        name = null,
                        property = null,
                        content = null
                    } = tag;

                    return (
                        <meta
                          key={ name || property }
                          name={ name }
                          property={ property }
                          content={ content }
                        />
                    );
                }) }
            </>
        );
    }

    render() {
        return (
            <>
                { createPortal(
                    { ...this.renderMeta() },
                    document.head
                ) }
                { createPortal(
                    { ...this.renderOgTagsMeta() },
                    document.head
                ) }
                { this.renderJSONLD() }
            </>
        );
    }
}

export default MetaComponent;
