import { memo } from 'react';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    PopupContainer as SourcePopupContainer
} from 'PrinterbaseComponent/Popup/Popup.container';

export * from 'PrinterbaseComponent/Popup/Popup.container';

/** @namespace Scandipwa/Component/Popup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    header_logo_src: state.ConfigReducer.header_logo_src
});

/** @namespace Scandipwa/Component/Popup/Container */
export class PopupContainer extends SourcePopupContainer {
    containerProps() {
        const { header_logo_src } = this.props;

        return {
            ...super.containerProps(),
            header_logo_src
        };
    }
}

export const MemoizedPopupContainer = memo(PopupContainer, (prevProps, nextProps) => {
    const {
        mix,
        ...restProps
    } = prevProps;

    const {
        mix: nextMix,
        ...restNextProps
    } = nextProps;

    if (
        JSON.stringify(mix) !== JSON.stringify(nextMix)
    ) {
        return false;
    }

    const restPropsAreNotEqual = Object.keys(nextProps).some(
        (key) => (typeof restProps[key] === 'function' ? false : restProps[key] !== restNextProps[key])
    );

    return !restPropsAreNotEqual;
});

export default connect(mapStateToProps, mapDispatchToProps)(MemoizedPopupContainer);
