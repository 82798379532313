import { connect } from 'react-redux';

import { showPopup } from 'Store/Popup/Popup.action';

import SearchField from './SearchField.component';

/** @namespace Scandipwa/Component/SearchField/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device
});

/** @namespace Scandipwa/Component/SearchField/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showPopup: (id, payload) => dispatch(showPopup(id, payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchField);
