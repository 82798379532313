import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/PureForm/Field';
import FIELD_TYPE from 'Component/PureForm/Field/Field.config';

import { BEST_SELLING_OTHERS, BEST_SELLING_PRINTERS } from './BestSellingToggleButtons.config';

import './BestSellingToggleButtons.style';

/** @namespace Scandipwa/Component/BestSellingToggleButtons/Component */
export class BestSellingToggleButtonsComponent extends PureComponent {
    static propTypes = {
        isMobile: PropTypes.bool.isRequired,
        setDisplayed: PropTypes.func.isRequired,
        listDisplayed: PropTypes.string.isRequired,
        printersTitle: PropTypes.string.isRequired,
        otherTitle: PropTypes.string.isRequired
    };

    /**
     * Overridden to fix the onChange not fire
     * Overridden to change title
     */
    renderField(field, title) {
        const {
            setDisplayed, listDisplayed
        } = this.props;

        const text = __('Best Selling ');
        const fieldTitle = !title ? field : title.replace(text, '');

        return (
            <Field
              type={ FIELD_TYPE.radio }
              mix={ {
                  block: 'BestSellingToggleButtons',
                  elem: 'Button',
                  mods: { isChecked: listDisplayed === field }
              } }
              attr={ {
                  name: 'bestSelling',
                  value: field,
                  checked: listDisplayed === field
              } }
              events={ {
                  onChange: setDisplayed
              } }
              label={ fieldTitle }
            />
        );
    }

    /**
     * Overridden to change title
     */
    renderFields() {
        const {
            printersTitle, otherTitle
        } = this.props;

        return (
            <div block="BestSellingToggleButtons" elem="Wrapper">
                { this.renderField(BEST_SELLING_PRINTERS, printersTitle) }
                { this.renderField(BEST_SELLING_OTHERS, otherTitle) }
            </div>
        );
    }

    render() {
        const { isMobile } = this.props;

        if (!isMobile) {
            return null;
        }

        return (
            <div block="BestSellingToggleButtons">
                <h3 block="BestSellingToggleButtons" elem="Heading">{ __('Best selling') }</h3>
                { this.renderFields() }
            </div>
        );
    }
}

export default BestSellingToggleButtonsComponent;
