/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable max-lines */
import PropTypes from 'prop-types';

import AddToCart from 'Component/AddToCart';
import CmsBlock from 'Component/CmsBlock';
import Html from 'Component/Html';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import ProductPrice from 'Component/ProductPrice';
import Top5description from 'Component/Top5description';
import Top5lead from 'Component/Top5lead';
import Top5number from 'Component/Top5number';
import Top5quote from 'Component/Top5quote';
import { UPSELL } from 'Component/UpsellProducts/UpsellProducts.config';
import {
    ProductCardComponent as PrinterbaseProductCard
} from 'PrinterbaseComponent/ProductCard/ProductCard.component';
import { GRID_LAYOUT, LIST_LAYOUT, TOP_5_PLP } from 'Route/CategoryPage/CategoryPage.config';
import { renderAttributes } from 'Util/Product/Product';

import {
    COMPATIBLE,
    CUSTOM_PDP_LAYOUT_TYPES,
    LOW_ON_STOCK,
    LOW_QTY,
    MAX_QTY,
    MAX_STOCK,
    OUT_OF_STOCK
} from './ProductCard.config';

import './ProductCard.style.override';

/** @namespace Scandipwa/Component/ProductCard/Component */
export class ProductCardComponent extends PrinterbaseProductCard {
    static propTypes = {
        ...this.propTypes,
        showSimilarProductsPopup: PropTypes.func.isRequired,
        showStockNotifyPopup: PropTypes.func.isRequired,
        showRequestDetailsPopup: PropTypes.func.isRequired,
        categoryPageTemplate: PropTypes.number,
        showShortDescription: PropTypes.bool,
        checkForDate: PropTypes.func.isRequired,
        productRef: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.shape({ current: PropTypes.instanceOf(Element) })
        ]),
        updateCurrentPageState: PropTypes.func
    };

    static defaultProps = {
        ...PrinterbaseProductCard.defaultProps,
        productRef: { current: null }
    };

    updateQueryParam(key, value) {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        url.searchParams.forEach((existingValue, existingKey) => {
            if (existingKey !== key) {
                params.set(existingKey, existingValue);
            }
        });
        params.set(key, value);
        window.history.replaceState({ ...window.history.state }, '', `${url.pathname}?${params}`);
    }

    handleCardClick = () => {
        const {
            hidePopup,
            product: { sku = '' },
            pageNumber = null,
            updateCurrentPageState
        } = this.props;
        const positionY = window.scrollY;
        const positionX = window.scrollX;

        localStorage.setItem('positionX', positionX);
        localStorage.setItem('positionY', positionY);
        localStorage.setItem('returnSku', sku);

        if (pageNumber && pageNumber > 1) {
            this.updateQueryParam('page', pageNumber);
        }

        updateCurrentPageState(pageNumber);
        hidePopup();
        this.registerSharedElement();
    };

    stringify = (obj) => {
        let cache = [];
        const str = JSON.stringify(obj, (key, value) => {
            if (typeof value === 'object' && value !== null) {
                if (cache.indexOf(value) !== -1) {
                    return undefined;
                }
                cache.push(value);
            }

            return value;
        });

        cache = null;

        return str;
    };

    handleScrollToMe() {
        const { productRef, product: { sku } } = this.props;
        const lastProductSku = localStorage.getItem('returnSku');

        if (sku === lastProductSku && productRef?.current) {
            localStorage.removeItem('returnSku');
            const { location: { pathname = '' } = {} } = window;

            if (pathname === '/' || pathname.includes('my-account')) {
                return;
            }

            productRef?.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            });
        }
    }

    componentDidMount() {
        this.handleScrollToMe();
    }

    componentDidUpdate() {
        this.handleScrollToMe();
    }

    // #region BUTTONS
    renderAddToCartButton(layout = GRID_LAYOUT) {
        const {
            addToCart,
            inStock,
            quantity,
            getActiveProduct,
            showStockNotifyPopup
        } = this.props;

        return (
                <AddToCart
                  mix={ { block: this.className, elem: 'AddToCart' } }
                  addToCart={ addToCart }
                  isDisabled={ !inStock }
                  isIconEnabled={ false }
                  layout={ layout }
                  quantity={ quantity }
                  product={ getActiveProduct() }
                  showStockNotifyPopup={ showStockNotifyPopup }
                />
        );
    }

    /**
     * Overridden to change stock notify functionality
     */
    renderAddToCart() {
        const {
            layout,
            showSelectOptionsNotification,
            inStock,
            showStockNotifyPopup
        } = this.props;

        if (!inStock) {
            const {
                product: {
                    attributes: {
                        part_no: {
                            attribute_value: part_number
                        } = {}
                    } = {},
                    image: {
                        url
                    } = {},
                    name,
                    sku
                } = {},
                productPrice: {
                    price: {
                        finalPriceExclTax: {
                            value
                        } = {}
                    } = {}
                } = {}
            } = this.props;
            const stockProduct = {
                part_number,
                url,
                name,
                sku,
                value
            };

            return (
                <button
                  block="Button AddToCart"
                  mods={ { outOfStock: !inStock } }
                  onClick={ showStockNotifyPopup(stockProduct) }
                >
                    { __('Stock notify') }
                </button>
            );
        }

        if (inStock && this.requiresConfiguration()) {
            return (
                <button
                  block="Button AddToCart"
                  mods={ { layout } }
                  onClick={ showSelectOptionsNotification }
                >
                    { __('Add to basket') }
                </button>
            );
        }

        return this.renderAddToCartButton(layout);
    }

    // eslint-disable-next-line consistent-return
    renderLabel() {
        const {
            product: { manufacturer, attribute_set_name }
        } = this.props;

        if (!manufacturer || !manufacturer[0] || attribute_set_name !== 'Consumables Set') {
            return null;
        }

        if (manufacturer[0] === 'Compatible') {
            return (
                <div block="ProductCard" elem="PremiumLabel">
                    <p>
                        { __('PREMIUM COMPATIBLE') }
                    </p>
                </div>
            );
        }

        if (manufacturer[0] !== 'Compatible' && attribute_set_name === 'Consumables Set') {
            return (
                <div block="ProductCard" elem="GenuineLabel">
                    <p>
                        { __('GENUINE') }
                    </p>
                </div>
            );
        }
    }

    renderPromotions() {
        const {
            product: { promotions },
            checkForDate
        } = this.props;

        if (!promotions || promotions.length === 0) {
            return null;
        }

        const result = promotions.find((promotion) => checkForDate(promotion.datefrom));
        const isActivePromoList = typeof result !== 'undefined';

        return (
            <div
              block="Promotion"
              elem="Wrapper"
              mods={ { isActivePromoList } }
            >
                <div block="Promotion" elem="Title">
                    <h3>{ __('Promotions') }</h3>
                </div>
                <div block="Promotion" elem="List">
                    { promotions.map((promotion) => {
                        const {
                            icon,
                            name,
                            color,
                            datefrom
                        } = promotion;
                        const isActive = checkForDate(datefrom);

                        return (
                            <div
                              block="Promotion"
                              elem="ListItem"
                              mods={ { isActive } }
                              key={ name }
                            >
                                { icon && <Html content={ icon } /> }
                                <p style={ { color } }>{ name }</p>
                            </div>
                        );
                    }) }
                </div>
            </div>
        );
    }

    renderQtyLabel() {
        const { inStock, product: { salableQuantity } } = this.props;

        if (!inStock) {
            return OUT_OF_STOCK;
        }

        if (salableQuantity > MAX_QTY) {
            return MAX_STOCK;
        }

        if (salableQuantity < LOW_QTY) {
            return LOW_ON_STOCK;
        }

        return !salableQuantity ? null : `${ salableQuantity } in stock`;
    }

    renderQty() {
        const {
            isMobile, inStock, product: { salableQuantity }
        } = this.props;

        const mods = { outOfStock: !inStock, lowOnStock: (salableQuantity <= LOW_QTY && inStock) };
        return (
            <div block="ProductCard" elem="QtyContainer" mods={ mods }>
                { isMobile && <span>{ this.renderQtyLabel() }</span> }
                { !isMobile && <span>{ __('Qty:') }</span> }
                <div block="ProductCard" elem="QtyField" mods={ mods }>
                    { isMobile && <span>{ __('Qty:') }</span> }
                    { this.renderQuantityChanger() }
                    { !isMobile && <span>{ this.renderQtyLabel() }</span> }
                </div>
            </div>
        );
    }

    /**
     * Overridden to add showSimilarProductsPopup functionality
     */
    renderSeeSimilarButton() {
        const { showSimilarProductsPopup, product: { product_links } = {}, product } = this.props;
        const hasUpSell = product_links?.reduce(
            (previousValue, { link_type }) => previousValue || link_type === UPSELL, false
        );

        return hasUpSell && (
            <button
              block="Button SeeSimilar"
                // eslint-disable-next-line react/jsx-no-bind
              onClick={ (e) => showSimilarProductsPopup(e, product) }
            >
                { __('see similar products') }
            </button>
        );
    }

    /**
     * Overridden to add label
     */
    renderFigureReview(inStock, showLabel = false) {
        const outOfStockMessage = inStock !== undefined
            ? (!inStock && <span block="ProductCard" elem="OutOfStock">{ __('Out of stock') }</span>)
            : null;

        const mods = inStock !== undefined ? { outOfStock: !inStock } : {};

        return (
            <div block="ProductCard" elem="FigureReview">
                { showLabel && this.renderLabel() }
                { outOfStockMessage }
                <figure block="ProductCard" elem="Figure" mods={ mods }>
                    { this.renderPicture() }
                </figure>
            </div>
        );
    }

    /*
       * For rendering the Category Short Description
       */
    renderCategoryShortDescription() {
        const { product: { attributes } } = this.props;

        const category_short_description = attributes?.category_short_description?.attribute_value;

        return category_short_description
            ? (
                <div block="ProductCard" elem="DescriptionWrapper">
                    <Html content={ category_short_description } />
                </div>
            )
            : null;
    }

    renderDiscontinuedLayout() {
        const {
            product: { replacement_text, replacement_url, name }
        } = this.props;
        const isMyAccount = window.location.pathname.includes('my-account');

        return (
            <div block="ProductCard" elem="CheckoutInfo" mix={ { block: 'Discontinued' } }>
                <div block="ProductCard" elem="Discontinued">
                    { !isMyAccount && (
                        <div block="ProductCard" elem="NameWrapper">
                            { replacement_text ? __(replacement_text) : __('%s has been replaced', name) }
                        </div>
                    ) }
                    <div block="ProductCard" elem="ActionWrapper">
                        <div block="ActionRow">
                            <Link block="Button" to={ replacement_url || '/' }>
                                { __('VIEW REPLACEMENT') }
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderCentralStockLayout(price, view) {
        const {
            children, inStock, isMobile, isLinks
        } = this.props;

        return (
            <div block="ProductCard" elem="CheckoutInfo" mods={ { outOfStock: !inStock } }>
                <div block="ProductCard" elem="AttributeWrapper">
                    { !!price && this.renderPrice() }
                    { this.renderQty() }
                </div>
                <div block="ProductCard" elem="DeliveryInfo">
                    <p>{ __('2 - 4 Day Delivery') }</p>
                </div>
                <div block="ProductCard" elem="ActionWrapper">
                    <div block="ActionRow">
                        { this.renderAddToCart() }
                        { this.renderProductActions() }
                    </div>
                </div>
                { view && this.renderViewTheProduct() }
                <div block="ProductCard" elem="AdditionalContent">
                    { children }
                </div>
                { (isMobile && !isLinks) && this.renderTierPrices() }
            </div>
        );
    }

    renderHidePriceLayout() {
        const {
            product: {
                attributes: {
                    part_no: {
                        attribute_value: part_number
                    } = {}
                } = {},
                image: {
                    url
                } = {},
                name,
                sku
            } = {},
            productPrice: {
                price: {
                    finalPriceExclTax: {
                        value
                    }
                } = {}
            } = {},
            showRequestDetailsPopup
        } = this.props;

        const stockProduct = {
            part_number,
            url,
            name,
            sku,
            value
        };

        return (
            <div block="ProductCard" elem="CheckoutInfo" mix={ { block: 'HidePrice' } }>
                <div block="ProductCard" elem="HidePrice">
                    <div block="ProductCard" elem="HidePriceWrapper">
                        { __('Call 0161 308 5800 for pricing and availability') }
                    </div>
                    <div block="ProductCard" elem="ActionWrapper">
                        <div block="ActionRow">
                            <button block="Button" onClick={ showRequestDetailsPopup(stockProduct) }>
                                { __('Request Details') }
                            </button>
                            { this.renderCompareButton() }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderMobileOldPrice() {
        const { getActiveProduct, isMobile, productPrice } = this.props;
        const product = getActiveProduct();

        if (!isMobile) {
            return null;
        }

        const {
            type_id: type,
            price_tiers: priceTiers
        } = product;

        const isMyAccount = window.location.pathname.includes('my-account');

        if (!productPrice || isMyAccount) {
            return null;
        }

        return (
            <div
              block={ this.className }
              elem="PriceWrapper"
            >
                <ProductPrice
                  meta
                  renderOnlyOldPrice
                  price={ productPrice }
                  priceType={ type }
                  product={ product }
                  tierPrices={ priceTiers }
                  isPreview={ false }
                  mix={ { block: this.className, elem: 'Price' } }
                />
            </div>
        );
    }

    renderPrice(isPreview = false) {
        const { getActiveProduct, productPrice, categoryProductList } = this.props;
        const product = getActiveProduct();

        const {
            type_id: type,
            price_tiers: priceTiers
        } = product;

        if (!productPrice) {
            return null;
        }

        return (
            <div
              block={ this.className }
              elem="PriceWrapper"
            >
                <ProductPrice
                  meta
                  hideMobileOldPrice={ categoryProductList }
                  price={ productPrice }
                  priceType={ type }
                  product={ product }
                  tierPrices={ priceTiers }
                  isPreview={ isPreview }
                  mix={ { block: this.className, elem: 'Price' } }
                  isSchemaRequired={ this.className === 'ProductActions' }
                />
            </div>
        );
    }

    /**
     * Overridden to remove tier prices on PDP from related products section
     */
    renderCardListCheckoutInfo({ price = false, view = false } = {}) {
        const {
            children, inStock, isMobile, isLinks, linkTo: { pathname }, product: {
                custom_pdp_layout, attributes: {
                    subscription_cms_block_box: {
                        attribute_value,
                        attribute_options: {
                            [attribute_value]: {
                                value
                            } = {}
                        } = {}
                    } = {}
                } = {}
            }
        } = this.props;

        if (custom_pdp_layout === CUSTOM_PDP_LAYOUT_TYPES.TYPE_1) {
            return this.renderDiscontinuedLayout();
        }

        if (custom_pdp_layout === CUSTOM_PDP_LAYOUT_TYPES.TYPE_2) {
            return this.renderCentralStockLayout(price, view);
        }

        if (custom_pdp_layout === CUSTOM_PDP_LAYOUT_TYPES.TYPE_3) {
            return this.renderHidePriceLayout();
        }

        const isMyAccount = window.location.pathname.includes('my-account');

        return (
            <div block="ProductCard" elem="CheckoutInfo" mods={ { outOfStock: !inStock } }>
                { this.renderMobileOldPrice() }
                <div block="ProductCard" elem="AttributeWrapper">
                    { !!price && this.renderPrice() }
                    { this.renderQty() }
                </div>
                <div block="ProductCard" elem="ActionWrapper">
                    <div block="ActionRow">
                        { this.renderAddToCart() }
                        { this.renderProductActions() }
                    </div>
                </div>
                { view && this.renderViewTheProduct() }
                <div block="ProductCard" elem="AdditionalContent">
                    { children }
                </div>
                { (isMobile && !isLinks) && this.renderTierPrices() }
                { value && !isMyAccount && (
                    <Link to={ `${pathname }` }>
                        <CmsBlock identifier={ value } />
                    </Link>
                ) }
            </div>
        );
    }

    findAttributes(attr_code) {
        const {
            product,
            product: {
                attributes
            }
        } = this.props;

        const defaultVal = {
            attribute_value: null
        };

        if (!product) {
            return defaultVal;
        }

        const filtered = attributes[attr_code];

        if (!filtered) {
            return defaultVal;
        }

        return filtered;
    }

    renderTop5Number() {
        const {
            product,
            product: {
                attributes,
                name
            }
        } = this.props;

        if (product && attributes) {
            const {
                attribute_value
            } = this.findAttributes('top5number');

            return <Top5number number={ attribute_value } productName={ name } />;
        }

        return null;
    }

    renderTop5Lead() {
        const {
            product,
            product: {
                attributes
            }
        } = this.props;

        if (product && attributes) {
            const { attribute_value } = this.findAttributes('top5lead');

            return <Top5lead lead={ attribute_value } />;
        }

        return null;
    }

    renderTop5Quote() {
        const {
            product,
            product: {
                attributes
            }
        } = this.props;

        if (product && attributes) {
            const { attribute_value } = this.findAttributes('top5quote');

            if (attribute_value) {
                return <Top5quote quote={ attribute_value } />;
            }
        }

        return null;
    }

    renderTop5Description() {
        const {
            product,
            product: {
                attributes
            }
        } = this.props;

        if (product && attributes) {
            const { attribute_value } = this.findAttributes('top5description');

            return <Top5description description={ attribute_value } />;
        }

        return null;
    }

    renderTop5() {
        const {
            layout,
            inStock,
            isMobile,
            product: {
                attributes = {},
                capacity_colour: capacityColour = [],
                sku: productSku = null
            },
            categoryPageTemplate
        } = this.props;

        return this.renderCardLinkWrapper((
            <div block="ProductCard" elem="LinkWrapper">
                { !isMobile && this.renderTop5Number() }
                { this.renderPromoteComsumableTonerBlock() }
                <div block="ProductCard" elem="Link">
                    <div
                      block="ProductCard"
                      elem="FigureReviewContainer"
                      mods={ { isTop5: categoryPageTemplate === TOP_5_PLP } }
                    >
                        { this.renderFigureReview(inStock, true) }
                        { isMobile && (
                            <div block="ProductCard" elem="MainInfo">
                                { this.renderTop5Number() }
                                { this.renderBrand() }
                                { this.renderReviewsAndPartNoWrapper() }
                            </div>
                        ) }
                    </div>
                    { isMobile && (
                        <>
                            { renderAttributes(attributes, capacityColour, productSku, isMobile, true) }
                            { this.renderTop5Lead() }
                            { this.renderTop5Quote() }
                            { this.renderPromotions() }
                            { this.renderTop5Description() }
                        </>
                    ) }
                    <div block="ProductCard" elem="Content" mods={ { layout } }>
                        { !isMobile && (
                            <div block="ProductCard" elem="MainInfo">
                                { this.renderBrand() }
                                { this.renderReviewsAndPartNoWrapper() }
                                <div block="ProductCard" elem="MainInfoInnerWrapper">
                                    { renderAttributes(attributes, capacityColour, productSku, isMobile, true) }
                                    { this.renderTierPrices() }
                                </div>
                                <div block="ProductCard" elem="Top5">
                                    { this.renderTop5Lead() }
                                    { this.renderTop5Quote() }

                                </div>
                                { this.renderPromotions() }
                                { this.renderTop5Description() }
                            </div>
                        ) }
                        { this.renderCardListCheckoutInfo({ price: true, view: true }) }
                    </div>
                </div>
            </div>
        ));
    }

    /**
     * Overridden to add promotion for toners
     */
    renderCardListContent() {
        const {
            categoryPageTemplate,
            layout,
            renderContent,
            inStock,
            isMobile,
            product: {
                attributes = {},
                capacity_colour: capacityColour = [],
                sku: productSku = null
            }
        } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        if (categoryPageTemplate === TOP_5_PLP) {
            return this.renderCardLinkWrapper(this.renderTop5());
        }

        return this.renderCardLinkWrapper((
            <div block="ProductCard" elem="LinkWrapper">
                { this.renderPromoteComsumableTonerBlock() }
                <div block="ProductCard" elem="Link">
                    <div block="ProductCard" elem="FigureReviewContainer">
                        { this.renderFigureReview(inStock, true) }
                        { isMobile && (
                            <div block="ProductCard" elem="MainInfo">
                                { this.renderMainDetails() }
                                { this.renderBrand() }
                                { this.renderReviewsAndPartNoWrapper() }
                            </div>
                        ) }
                    </div>
                    { isMobile && (
                        <>
                            { renderAttributes(attributes, capacityColour, productSku, isMobile, true) }
                            { this.renderPromotions() }
                            { this.renderCategoryShortDescription() }
                        </>
                    ) }
                    <div block="ProductCard" elem="Content" mods={ { layout } }>
                        { !isMobile && (
                            <div block="ProductCard" elem="MainInfo">
                                { this.renderMainDetails() }
                                { this.renderBrand() }
                                { this.renderReviewsAndPartNoWrapper() }
                                <div block="ProductCard" elem="MainInfoInnerWrapper">
                                    { renderAttributes(attributes, capacityColour, productSku, isMobile, true) }
                                    { this.renderTierPrices() }
                                </div>
                                { this.renderPromotions() }
                                { this.renderCategoryShortDescription() }
                            </div>
                        ) }
                        { this.renderCardListCheckoutInfo({ price: true, view: true }) }
                    </div>
                </div>
            </div>
        ));
    }

    /**
     * Created to render short description into the card
     */
    renderShortDescriptionContent() {
        const { product: { short_description }, showShortDescription } = this.props;
        const { html } = short_description || {};

        const htmlWithItemProp = `<div itemProp="description">${ html }</div>`;
        if (showShortDescription) {
            return (
                <div block="ProductCard" elem="ShortDescription">
                    { html && <Html content={ htmlWithItemProp } /> }
                </div>
            );
        }

        return null;
    }

    /**
     * Overridden to change click handler
     */
    renderCardLinkWrapper(children, mix = {}) {
        const {
            linkTo,
            product: { url }
        } = this.props;

        if (!url) {
            return (
                <div
                  block="ProductCard"
                  elem="Link"
                >
                    { children }
                </div>
            );
        }

        return (
            <Link
              block="ProductCard"
              elem="Link"
              to={ linkTo }
              onClick={ this.handleCardClick }
              mix={ mix }
            >
                { children }
            </Link>
        );
    }

    /**
     * Overridden to edit the content of the product Card
     */
    renderCardContent() {
        const {
            renderContent
        } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return this.renderCardLinkWrapper(
            <>
                { this.renderProductCompareButton() }
                <div block="ProductCard" elem="Details">
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            { this.renderPicture() }
                        </figure>
                    </div>
                    <div block="productCard">
                        <div block="ProductCard" elem="Content">
                            { this.renderMainDetails() }
                        </div>
                        { this.renderShortDescriptionContent() }
                    </div>
                </div>
                { this.renderCheckoutInfo({ view: true }) }
            </>
        );
    }

    /**
     * New method for handling the different layout cases
     */
    renderLayout() {
        const {
            children, layout
        } = this.props;

        if (layout === LIST_LAYOUT) {
            return [{ layout }, this.renderCardListContent()];
        }

        return [
            { layout },
            <>
                { this.renderCardContent() }
                <div block="ProductCard" elem="AdditionalContent">{ children }</div>
            </>
        ];
    }

    renderReviewsAndPartNoWrapper() {
        return (
            <div block="ProductCard" elem="ReviewsAndPartNoWrapper">
                { this.renderReviews() }
                { this.renderSku() }
            </div>
        );
    }

    /**
     * Overridden to change the condition
     */
    renderPromoteComsumableTonerBlock() {
        const {
            product: {
                manufacturer,
                attributes: {
                    compat_saving: {
                        attribute_value: compat_saving_value
                    } = {}
                } = {}
            } = {}
        } = this.props;

        if (!manufacturer || !manufacturer[0] || !compat_saving_value) {
            return null;
        }

        const compat_saving_string = compat_saving_value.split('+', 2);

        if (manufacturer[0] === COMPATIBLE) {
            return (
                <div block="ProductCard" elem="CompatSaving">
                    { `${ compat_saving_string[0] } ${ compat_saving_value.indexOf('+') >= 0 ? '+' : '' }` }
                    <br />
                    { compat_saving_string[1] }
                </div>
            );
        }

        return null;
    }

    /**
     * Overridden to render promotions
     */
    renderCheckoutInfo({ view = false, compare = false, promotions = false } = {}) {
        const { inStock } = this.props;

        return (
            <div block="ProductCard" elem="CheckoutInfo" mods={ { outOfStock: !inStock } }>
                <div block="ProductCard" elem="AttributeWrapper" mods={ { havePromotions: promotions } }>
                    { promotions && this.renderBestSellersPromotions() }
                    { this.renderPrice() }
                </div>
                { (view || compare)
                    && (
                        <div block="ProductCard" elem="ActionWrapper">
                            <div block="ActionRow">
                                { view && this.renderViewTheProduct() }
                                { compare && this.renderProductCompareButton() }
                            </div>
                        </div>
                    ) }
            </div>
        );
    }

    /**
     * Overridden to change rendering logic
     */
    render() {
        const {
            mix, isLoading, productRef
        } = this.props;
        const [mods, content] = this.renderLayout();

        return (
            <li
              block="ProductCard"
              mix={ mix }
              mods={ mods }
              ref={ productRef }
            >
                <Loader isLoading={ isLoading } />
                { content }
            </li>
        );
    }
}

export default ProductCardComponent;
