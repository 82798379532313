import { BILLING_STEP } from 'Route/Checkout/Checkout.config';

import PaypalSmartButtons from '../component/PaypalSmartButtons';
import { PAYPAL_PLACEMENT_CART_PAGE } from '../component/PaypalSmartButtons/PaypalSmartButtons.config';
import { PAYPAL_EXPRESS_PAYMENT_METHOD_CODE } from '../component/PaypalSmartButtons/PaypalSmartButtons.config';

const renderCompleteOrderButtonPlugin = (args, callback, instance) => {
    const { paymentMethod, checkoutStep } = instance.props;

    const shouldRenderPaypalBtn = paymentMethod === PAYPAL_EXPRESS_PAYMENT_METHOD_CODE && checkoutStep === BILLING_STEP;

    if (shouldRenderPaypalBtn) {
        const {
            isMobile
        } = instance.props;

        return (
            <div block="Checkout" elem="StickyButtonWrapper">
                <div block="Checkout" elem="StickyButtonWrapperRow">
                    { isMobile && instance.renderOrderTotal() /* On mobile, render total separately */ }
                    <PaypalSmartButtons page={ PAYPAL_PLACEMENT_CART_PAGE } />
                </div>
            </div>

        );
    }

    return callback.apply(instance, args);
};

export default {
    'Scandiweb/PrinterbaseTheme/Component/CheckoutOrderSummary/Component': {
        'member-function': {
            renderCompleteOrderButton: renderCompleteOrderButtonPlugin
        }
    }
};
